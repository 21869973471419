import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarRequisicionCompras,
    ENDPOINTObtenerRequisicionCompras,
    ENDPOINTListarRequisicionCompras,
    ENDPOINTActualizarRequisicionCompras,
    ENDPOINTEliminarRequisicionCompras,
    ENDPOINTDeshabilitarRequisicionCompras,
    ENDPOINTObtenerFolioRequisicionCompras
} from "./endpoints";
import axios from 'axios';

export async function listarRequisicionCompras(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRequisicionCompras, config);
}

export async function registraRequisicionCompras(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarRequisicionCompras, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerRequisicionCompras(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerRequisicionCompras + `/${params}`, config);
}

export async function actualizarRequisicionCompras(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarRequisicionCompras + `/${id}`, data, config);
}

export async function eliminarRequisicionCompras(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarRequisicionCompras + `/${id}`, data, config);
}

export async function actualizaDeshabilitarRequisicionCompras(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarRequisicionCompras + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroRequisicionCompras() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioRequisicionCompras, config);
}