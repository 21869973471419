import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarDoctores,
    ENDPOINTListarDoctoresPorCorreo,
    ENDPOINTListarDoctoresPorCorreoDoctor,
    ENDPOINTObtenerDoctores,
    ENDPOINTListarDoctores,
    ENDPOINTActualizarDoctores,
    ENDPOINTEliminarDoctores,
    ENDPOINTDeshabilitarDoctores,
    ENDPOINTObtenerUltimoDoc,
    ENDPOINTObtenerDoctoresPorRFC,
    ENDPOINTObtenerFolioDoctores,
    ENDPOINTObtenerDoctoresPorCorreo,
    ENDPOINTTotalDoctores,
} from "./endpoints";
import axios from 'axios';

export async function listarDoctores(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDoctores, config);
}

export async function listarDoctoresPorCorreo(correoConsultorio){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDoctoresPorCorreo + `?correoConsultorio=${correoConsultorio}`, config);
}

export async function listarDoctoresPorCorreoDoctor(email){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDoctoresPorCorreoDoctor + `?email=${email}`, config);
}

export async function registraDoctores(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarDoctores, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerDoctores(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerDoctores + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerDoctoresPorCorreo(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerDoctoresPorCorreo + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerDoctoresPorRFC(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerDoctoresPorRFC + `/${params}`, config);
}

export async function listarUltimoDoc(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUltimoDoc, config);
}

export async function actualizarDoctores(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarDoctores + `/${id}`, data, config);
}

export async function eliminarDoctores(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarDoctores + `/${id}`, data, config);
}

export async function actualizaDeshabilitarDoctores(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarDoctores + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroDoctor() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioDoctores, config);
}

export async function totalDoctores() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalDoctores, config);
}