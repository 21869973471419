import React from "react";
import './load.css'
export function Load() {
  return (
    <>
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    </>
  );
}
