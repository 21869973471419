import { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importar el idioma español de moment
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

// Traducciones personalizadas para el calendario en español
const messages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    showMore: total => `+ Ver más (${total})`,
};

function Calendario(props) {
    const { eventosIniciales } = props

    return (
        <div>
            <div style={{ height: 500 }}>
                <Calendar
                    localizer={localizer}
                    events={eventosIniciales}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    messages={messages} // Pasar las traducciones personalizadas al calendario
                    popup
                    views={['month', 'agenda']} // Excluir la vista de la semana

                />
            </div>
        </div>
    );
}

export default Calendario