import { useState, useEffect } from "react";
import MapQuest from "./MapQuest";
import Ubicacion from "./Ubicacion";
import { Load } from "../../load/load";

export const MapaUbicacion = () => {
  const [lat, setLat] = useState("20.394990760246436");
  const [lng, setLng] = useState("-99.98675954006715");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  let markers = [];

  const setCenter = (lat, lng) => {
    setLat(lat);
    setLng(lng);

    window.L.mapquest.Map.getMap("map").setView(
      new window.L.LatLng(lat, lng),
      12
    );
  };

  const addMaker = (lat, lng, title) => {
    const marker = window.L.mapquest
      .textMarker(new window.L.LatLng(lat, lng), {
        text: title || "",
        subtext: "",
        position: "right",
        type: "marker",
        icon: {
          primaryColor: "#a8190f",
          secondaryColor: "db2c2c",
          size: "md",
        },
      })
      .addTo(window.L.mapquest.Map.getMap("map"));

    markers.push(marker);

  };

  return (
    <>
      {loading && <Load />}
      <div className="containerFluid">
        <div>
          <div>
            <Ubicacion setCenter={setCenter} setMaker={addMaker} />
          </div>
        </div>
        <div>
          <MapQuest
            height="80vh"
            width="100%"
            center={[lat, lng]}
            tileLayer={"map"}
            zoom={12}
            apiKey="2eei5oDr1QNMmUGDCY8115cQclIbiAq3"
          />
        </div>
      </div>
    </>
  );
};
