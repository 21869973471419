import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarComedor,
    ENDPOINTObtenerComedor,
    ENDPOINTListarComedor,
    ENDPOINTActualizarComedor,
    ENDPOINTEliminarComedor,
    ENDPOINTDeshabilitarComedor
} from "./endpoints";
import axios from 'axios';

export async function listarComedor(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarComedor, config);
}

export async function registraComedor(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarComedor, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerComedor(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerComedor + `/${params}`, config);
}

export async function actualizarComedor(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarComedor + `/${id}`, data, config);
}

export async function eliminarComedor(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarComedor + `/${id}`, data, config);
}

export async function actualizaDeshabilitarComedor(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarComedor + `/${id}`, data, config);
}