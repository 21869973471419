import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import {
  actualizaDeshabilitarServicios,
  actualizarEstadosyOrden,
} from "../../api/servicios";
import { LogsInformativos } from "../logs/logs";
import { registraDepartamentosODT, listarDepartamentosODT, actualizarDepartamentosODT } from "../../api/departamentosODT";

const FinalizarOT = ({ data, setShow }) => {
  const idOT = data[0];
  const folioOT = data[2];

  const [listarUltimoDepartamento, setListUltimoDepartamento] = useState([]);

  const obtenerUltimoDepartamento = () => {
    try {
      listarDepartamentosODT()
        .then((response) => {
          const { data } = response;

          if (!listarUltimoDepartamento && data) {
            setListUltimoDepartamento(formatModelUltimoDepartamento(data));
          } else {
            const datosUltimoDepartamento = formatModelUltimoDepartamento(data);
            setListUltimoDepartamento(datosUltimoDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUltimoDepartamento();
  }, []);
  
  let fecha = new Date();

  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds(); // Obtener los minutos
  let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;

  const finalizarUltimoDepartamentoODT = () => {
    try {
      const dataTemp = {
        fechaSalida: datosFecha,
      };

      actualizarDepartamentosODT(listarUltimoDepartamento[0].id, dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha asignado un departamento a la orden de cliente " + idOT, dataTemp)
          toast.success(data.mensaje);
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = (e) => {
    console.log("idOT", idOT);
    e.preventDefault();
    try {
      const dataTemp = {
        nuevoEstado: "4",
        nuevoEstadoOrdenServicio: "5",
        fechaFin: datosFecha
      };
      actualizarEstadosyOrden(idOT, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        finalizarUltimoDepartamentoODT();
        LogsInformativos(
          "Se ha finalizado la orden de trabajo " + idOT,
          dataTemp
        );

        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit}>
          <h4 style={{ textAlign: "center" }}>¿Finalizar orden {folioOT}?</h4>
          <div style={{ textAlign: "center", marginTop: "2%" }}>
            <Button variant="success" onClick={onSubmit}>
              <FontAwesomeIcon icon={faFlagCheckered} /> Finalizar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

function formatModelUltimoDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folioODT: data.folioODT,
      departamento: data.departamento,
      fechaEntrada: data.fechaEntrada,
      fechaSalida: data.fechaSalida,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default FinalizarOT;
