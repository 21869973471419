import React, { useState } from 'react';
//var Openpay = require('openpay');
//var openpay = new Openpay('mh3qxtumcdo4u0fuvbg3','sk_bf32c8b3b2d342baace620c67b7f10c2');

function OpenpayIntegration() {
  const [customerData, setCustomerData] = useState({
    name: 'Johnis',
    email: 'johndoesd@example.com',
    last_name: 'Doesd',
    address: {
      city: 'Queretaro',
      state: 'Queretaro',
      line1: 'Calle Morelos no 10',
      line2: 'col. san pablo',
      postal_code: '76000',
      country_code: 'MX',
    },
    phone_number: '44209087658',
    requires_account: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const YOUR_API_KEY = 'sk_bf32c8b3b2d342baace620c67b7f10c2'; // Reemplaza con tu propia API Key
        const YOUR_API_SECRET = 'pk_2b13389c063a4b789e8e81e4040557e1'; // Reemplaza con tu propia Secret Key
  
        //const auth = btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`);
  
        const response = await fetch('https://sandbox-api.openpay.mx/v1/mh3qxtumcdo4u0fuvbg3/customers/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`)}`,
        },
        body: JSON.stringify(customerData),
      });

      if (response.ok) {
        const newCustomer = await response.json();
        console.log('Usuario creado:', newCustomer);
        // Aquí podrías mostrar un mensaje de éxito o redirigir a otra página
      } else {
        console.error('Error al crear usuario:', response.statusText);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <input
      type="text"
      name="name"
      placeholder="Nombre"
      value={customerData.name}
      onChange={handleChange}
    />
    <input
      type="text"
      name="last_name"
      placeholder="Apellido"
      value={customerData.last_name}
      onChange={handleChange}
    />
    <input
      type="email"
      name="email"
      placeholder="Correo electrónico"
      value={customerData.email}
      onChange={handleChange}
    />
    <input
      type="text"
      name="phone_number"
      placeholder="Número de teléfono"
      value={customerData.phone_number}
      onChange={handleChange}
    />
    <input
      type="text"
      name="address_city"
      placeholder="Ciudad"
      value={customerData.address.city}
      onChange={handleChange}
    />
    <input
      type="text"
      name="address_state"
      placeholder="Estado"
      value={customerData.address.state}
      onChange={handleChange}
    />
    <input
      type="text"
      name="address_line1"
      placeholder="Dirección línea 1"
      value={customerData.address.line1}
      onChange={handleChange}
    />
    <input
      type="text"
      name="address_line2"
      placeholder="Dirección línea 2"
      value={customerData.address.line2}
      onChange={handleChange}
    />
    <input
      type="text"
      name="address_postal_code"
      placeholder="Código Postal"
      value={customerData.address.postal_code}
      onChange={handleChange}
    />
     
      {/* No se muestra el campo country_code ya que está predefinido como "MX" */}
      <button type="submit">Crear Usuario</button>
    </form>
  );
}

export default OpenpayIntegration;
