import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarServicios,
    ENDPOINTObtenerServicios,
    ENDPOINTListarServicios,
    ENDPOINTListarServiciosRetrabajos,
    ENDPOINTListarServiciosODT,
    ENDPOINTActualizarServicios,
    ENDPOINTActualizarDepartamentoServicios,
    ENDPOINTEliminarServicios,
    ENDPOINTEliminarServiciosFolio,
    ENDPOINTDeshabilitarServicios,
    ENDPOINTListarServiciosFolio,
    ENDPOINTListarServiciosCorreo,
    ENDPOINTListarServiciosDepartamento,
    ENDPOINTListarServiciosFolioDepartamento,
    ENDPOINTObtenerFolioODTServicio,
    ENDPOINTObtenerHorasTranscurridas,
    ENDPOINTListarServiciosConRuta,
    ENDPOINTActualizarProcesoServicios,
    ENDPOINTActualizarEstadosyOrdenServicios,
    ENDPOINTGuardarImagenServicios,
    ENDPOINTObtenerServiciosPorFolio,
    ENDPOINTActualizarGarantiaServicios
} from "./endpoints";
import axios from 'axios';

export async function listarServicios(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServicios, config);
}

export async function listarServiciosRetrabajos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosRetrabajos, config);
}

export async function listarServiciosConRuta(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosConRuta, config);
}

export async function listarServiciosODT(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosODT, config);
}

export async function obtenerFolioODTServicio(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioODTServicio, config);
}

export async function calcularHorasTranscurridasOrdenesTrabajoSer(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerHorasTranscurridas, config);
}

export async function listarServiciosFolio(folio){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosFolio + `?folio=${folio}`, config);
}

export async function listarServiciosCorreo(email){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosCorreo + `?email=${email}`, config);
}

export async function listarServiciosDepartamento(departamento){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosDepartamento + `?departamento=${departamento}`, config);
}

export async function listarServiciosFolioDepartamento(folio, departamento){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosFolioDepartamento + `?folio=${folio}&&departamento=${departamento}`, config);
}

export async function registraServicios(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarServicios, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerServiciosPorFolio(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerServiciosPorFolio + `/${params}`, config);
}

export async function actualizarServicios(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarServicios + `/${id}`, data, config);
}

export async function actualizarGarantiaServicios(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarGarantiaServicios + `/${id}`, data, config);
}

export async function guardarImagenServicios(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTGuardarImagenServicios + `/${id}`, data, config);
}


export async function eliminarServicios(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarServicios + `/${id}`, data, config);
}

export async function eliminarServiciosFolio(folio, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarServiciosFolio + `/${folio}`, data, config);
}

export async function actualizaDeshabilitarServicios(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarServicios + `/${id}`, data, config);
}

export async function actualizaProcesosServicios(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarProcesoServicios + `/${id}`, data, config);
}

export async function actualizarDepartamentoServicios(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarDepartamentoServicios + `/${id}`, data, config);
}

export async function actualizarEstadosyOrden(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarEstadosyOrdenServicios + `/${id}`, data, config);
}