import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { eliminarColorimetria } from "../../../api/colorimetria";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function EliminarColorimetria({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        nombre: [1],
        tonos: [2],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            eliminarColorimetria(idDocumental).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha eliminado el sistema de colorimetria " + idDocumental)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalSerDentales'>
            <Form onSubmit={onSubmit} onChange={onChange}>
              <Form.Label className='lblNombreSis'>Nombre del sistema de colorimetria: </Form.Label>
              <Form.Control
                type='text'
                className='inpNombreSis'
                placeholder='Nombre'
                name="nombre"
                defaultValue={formData.nombre}
                disabled
              />
              <Form.Label className='lblTonos'>Tonos: </Form.Label>
              <Form.Control
                type='text'
                className='inpTonos'
                placeholder='Introduzca escalas separadas por comas. Por ejemplo: escala1, escala2, escala3'
                name="tonos"
                defaultValue={formData.tonos}
                disabled
              />
              <br />
              <label></label>
              <div className="divSubmit">
                <input className="submit" value="Enviar" type="submit" />
              </div>
            </Form>
          </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        nombre: data.nombre,
        tonos: data.tonos,
    };
}
