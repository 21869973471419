import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHandHoldingDollar,
  faPlus,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Badge, Col, Dropdown, Form, Row, Table, Button } from "react-bootstrap";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import AprobarPago from "./AprobarPago";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  listarAbonosPorRuta,
  listarAbonosPoridRecibio,
  listarAbonosidRecibio,
  listarFechaAbonosOrdenes,
} from "../../../api/abonosOrdenes";
import {
  listarRutasPorIdOrden,
  listarRutasidRecibio,
} from "../../../api/rutas";
import Select from "react-select";
import { listarConfiguracionRutas } from "../../../api/configuracionRutas";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Load } from "../../load/load";
import AprobarPagos from "./AprobarPagos";
import { withRouter } from "../../../utils/withRouter";

const TablaPagosOrdenes = (props) => {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const aprobarPago = (content) => {
    setTitulosModal("Aprobar pago");
    setContentModal(content);
    setShowModal(true);
  };

  const aprobarPagos = (content) => {
    setTitulosModal("Aprobar pagos");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "ordenServicio",
      label: "ORDEN DE CLIENTE",
    },
    {
      name: "cantidadRecibida",
      label: "Cantidad",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "nombreConsultorio",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreDoctor",
      label: "Doctor",
    },
    {
      name: "ruta",
      label: "RUTA",
    },
    {
      name: "idRecibio",
      label: "RECIBIÓ",
    },
    {
      name: "aprobo",
      label: "APROBÓ",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estadoTexto = "";

          if (estado == "1") {
            estadoTexto = "Aprobado";
          } else {
            estadoTexto = "Pendiente de aprobación";
          }

          return (
            <>
              <Badge bg={estado == "1" ? "success" : "warning"}>
                {estadoTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "metodoPago",
      label: "METODO DE PAGO",
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[9] == "0" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      aprobarPago(
                        <AprobarPago
                          history={history}
                          setShowModal={handleCloseModal}
                          dataOrden={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp;Recibir Pago
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
    obtenerAbonos(fechaInp); // Llama a obtenerAbonos después de cerrar el modal
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const cellStyles = {
    backgroundColor: "#9c182d",
    color: "#fff",
    fontWeight: "bold",
  };

  const cellStyles2 = {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
  };

  const paymentMethods = [
    'TARJETA',
    'EFECTIVO',
    'TRANSFERENCIA',
    'CHEQUES',
    'DEPOSITOS'
  ];

  const [fechaFormat, setFechaFormat] = useState("");
  const [fechaInp, setfechaInp] = useState("");

  useEffect(() => {
    let fecha = new Date();
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1;
    let year = fecha.getFullYear();
    const fechaHoy = `${dia}/${mes}/${year}`;
    const fechInp = `${year}-${mes}-${dia}`;
    setfechaInp(fechInp);
    setFechaFormat(fechaHoy);
  }, []);

  useEffect(() => {
    setFechaFormat(fechaInp);
  }, [fechaInp]);

  const [listarAbonos, setListAbonos] = useState([]);

  const obtenerAbonos = (fecha) => {
    try {
      listarFechaAbonosOrdenes(fecha)
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          if (data.length == 0) {
            setListAbonos([]);
          } else {
            const datosAbonos = formatModelAbonos(data);
            setListAbonos(datosAbonos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerAbonos(fechaFormat);
  }, [fechaFormat, location]);

  const [listRutasOrden, setlistRutasOrden] = useState([]);
  const obtenerRutasOrdenes = (ids) => {
    try {
      listarRutasPorIdOrden(ids)
        .then((response) => {
          const { data } = response;
          console.log("data Rutas", data);
          if (!listRutasOrden && data) {
            setlistRutasOrden(formatModelRutas(data));
          } else {
            const datosRutas = formatModelRutas(data);
            setlistRutasOrden(datosRutas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    console.log("listarAbonos", listarAbonos);
    if (listarAbonos.length > 0) {
      const idsOrden = listarAbonos.map((objeto) => objeto.idOrden);

      // Crear una cadena separada por comas
      const cadenaIdsOrden = idsOrden.join(",");
      console.log("cadenaIdsOrden ", cadenaIdsOrden);
      obtenerRutasOrdenes(cadenaIdsOrden);
    }
  }, [listarAbonos]);

  const [rutasAbonos, setrutasAbonos] = useState([]);
  useEffect(() => {
    if (listarAbonos.length > 0) {
      const abonosConRutas = listarAbonos.map((abono) => {
        // Filtrar las rutas que tienen ordenesAsignadas y abono.idOrden está en ordenesAsignadas
        const rutasAsignadas = listRutasOrden.reduce((acc, ruta) => {
          console.log("abono", abono);
          console.log("ruta", ruta);
          if (
            ruta.ordenesAsignadas &&
            ruta.ordenesAsignadas.includes(abono.idOrden)
          ) {
            acc.push(ruta);
          }
          return acc;
        }, []);
        console.log("rutasAsignadas", rutasAsignadas);
        // Crear un nuevo objeto con los datos del abono y las rutas asignadas encontradas
        const abonoConRutas = {
          ...abono,
          rutasAsignadas: rutasAsignadas.length > 0 ? rutasAsignadas : null,
        };

        return abonoConRutas;
      });
      const datosCompletos = formatModelAbonosConRutas(abonosConRutas);
      setrutasAbonos(datosCompletos);
      console.log(datosCompletos);
    } else {
      setrutasAbonos([]);
    }
  }, [listRutasOrden, listarAbonos]);

  const [listRutas, setListRutas] = useState([]);
  const obtenerRutas = () => {
    try {
      listarConfiguracionRutas()
        .then((response) => {
          const { data } = response;
          if (!listRutas && data) {
            setListRutas(formatModelRutasList(data));
          } else {
            const datosRutas = formatModelRutasList(data);
            setListRutas(datosRutas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listRecibio, setListRecibio] = useState([]);
  const obtenerRutasidRecibio = () => {
    try {
      listarAbonosidRecibio()
        .then((response) => {
          const { data } = response;
          if (!listRecibio && data) {
            setListRecibio(formatModelRutasIDRecibio(data));
          } else {
            const datosRutas = formatModelRutasIDRecibio(data);
            setListRecibio(datosRutas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerRutas();
    obtenerRutasidRecibio();
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [selectedOptionRecibe, setSelectedOptionRecibe] = useState(null);
  const handleSeleccionChangerecibe = (option) => {
    setSelectedOptionRecibe(option.value);
  };

  const obtenerAbonosPorRuta = (id) => {
    try {
      listarAbonosPorRuta(id)
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          if (data.length == 0) {
            setListAbonos([]);
          } else {
            const datosAbonos = formatModelAbonos(data);
            setListAbonos(datosAbonos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (selectedOption) {
      obtenerAbonosPorRuta(selectedOption);
    }
  }, [selectedOption]);

  const obtenerAbonosPoridRecibio = (id) => {
    try {
      listarAbonosPoridRecibio(id)
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          if (data.length == 0) {
            setListAbonos([]);
          } else {
            const datosAbonos = formatModelAbonos(data);
            setListAbonos(datosAbonos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (selectedOptionRecibe) {
      obtenerAbonosPoridRecibio(selectedOptionRecibe);
    }
  }, [selectedOptionRecibe]);

  const countRecordsWithEstadoZero = rutasAbonos.filter(record => record.estado == 0).length;

  // Mostrar el botón solo si hay al menos dos registros con estado igual a 0
  const isButtonVisible = countRecordsWithEstadoZero >= 2;

  return (
    <>
      {loading && <Load />}
      <div style={{ padding: "1%" }}>
        <div style={{ textAlign: "center", marginBottom: "1%" }}>
          <h2>Pagos a ordenes</h2>
        </div>
        <div style={{ marginBottom: "1%" }}>
          <Row>
            <Col sm={12} md={4} lg={4} className="mb-2">
              <Form.Label>Por día</Form.Label>
              <Form.Control
                type="date"
                value={fechaInp}
                onChange={(e) => setfechaInp(e.target.value)}
                style={{ marginRight: "1%" }}
              />
            </Col>
            <Col sm={12} md={4} lg={4} className="mb-2">
              <Form.Label>Por ruta</Form.Label>
              <Select
                options={listRutas}
                isSearchable={true}
                placeholder="Seleccionar..."
                onChange={(selectedOption) =>
                  handleSeleccionChange(selectedOption)
                }
              />
            </Col>
            <Col sm={12} md={4} lg={4} className="mb-2">
              <Form.Label>Por quien recibe</Form.Label>
              <Select
                options={listRecibio}
                isSearchable={true}
                placeholder="Seleccionar..."
                onChange={(selectedOption) =>
                  handleSeleccionChangerecibe(selectedOption)
                }
              />
            </Col>
          </Row>
        </div>
        <div>
        {isButtonVisible && (
          <div className="divButton">
            <Button
              className="btnAddTables"
              onClick={() =>
                aprobarPagos(
                  <AprobarPagos dataOrden={rutasAbonos} history={history} setShowModal={setShowModal} />
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} /> Aprobar
            </Button>
          </div>
          )}
        </div>
        <div>
          <MUIDataTable
            title={"Lista de pagos aprobados del día " + fechaFormat}
            data={rutasAbonos}
            columns={columns}
            options={options}
          />
          <Row className="mt-2 mt-md-2 mt-lg-2">
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <td colSpan="2" style={{ padding: '10px' }}>
                      <div style={{
                        display: 'flex', // Cambiado a flex para asegurar que todos los elementos estén en una línea
                        justifyContent: 'space-between', // Espacio uniforme entre los elementos
                        width: '100%',
                        flexWrap: 'wrap' // Permite que los elementos se ajusten si el espacio es insuficiente
                      }}>
                        {paymentMethods.map(method => (
                          <div key={method} style={{ flex: '1 1 16%', textAlign: 'center', padding: '5px' }}>
                            <div style={cellStyles}>Total {method.toLowerCase()}:</div>
                            <div style={cellStyles2}>
                              {formatCurrency(
                                rutasAbonos
                                  .filter(item => item.metodoPago === method)
                                  .reduce((acc, item) => acc + Number(item.cantidadRecibida), 0)
                              )}
                            </div>
                          </div>
                        ))}
                        <div style={{ flex: '1 1 16%', textAlign: 'center', padding: '5px' }}>
                          <div style={cellStyles}>Total General:</div>
                          <div style={cellStyles2}>
                            {formatCurrency(
                              rutasAbonos.reduce((acc, item) => acc + Number(item.cantidadRecibida), 0)
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
          {contentModal}
        </BasicModal>
      </div>
    </>
  );
};

function formatModelAbonos(data) {
  const dataTemp = [];
  data.forEach((item) => {
    // Verifica si hay al menos una orden y extrae el nombre del doctor
    const idOrden = item.ordenes.length > 0 ? item.ordenes[0]._id : "";
    const nombreDoctor =
      item.ordenes.length > 0 ? item.ordenes[0].nombreDoctor : "";
    const nombreConsultorio =
      item.ordenes.length > 0 ? item.ordenes[0].localidad : "";

    dataTemp.push({
      id: item._id,
      ordenServicio: item.ordenServicio,
      cantidadRecibida: item.cantidadRecibida,
      estado: item.estado,
      idRecibio: item.idRecibio,
      hizoCargo: item.hizoCargo,
      aprobo: item.aprobo,
      fecha: item.fecha,
      nombreDoctor: nombreDoctor,
      nombreConsultorio: nombreConsultorio,
      idOrden: idOrden,
      metodoPago: item.metodoPago
    });
  });
  return dataTemp;
}

function formatModelRutas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ruta: data.ruta.split("/")[0],
      observaciones: data.observaciones,
      nombreMensajero: data.nombreMensajero,
      fecha: data.fecha,
      vehiculoAsignado: data.vehiculoAsignado,
      numeroEconomico: data.numeroEconomico,
      ordenesAsignadas: data.ordenesAsignadas,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelAbonosConRutas(data) {
  const dataTemp = [];
  data.forEach((item) => {
    const ruta =
      item.rutasAsignadas != null
        ? item.rutasAsignadas[0].ruta
        : "Ruta no encontrada";
    dataTemp.push({
      id: item.id,
      ordenServicio: item.ordenServicio,
      cantidadRecibida: item.cantidadRecibida,
      estado: item.estado,
      idRecibio: item.idRecibio,
      hizoCargo: item.hizoCargo,
      aprobo: item.aprobo,
      fecha: item.fecha,
      nombreDoctor: item.nombreDoctor,
      nombreConsultorio: item.nombreConsultorio,
      idOrden: item.idOrden,
      ruta: ruta,
      metodoPago: !item.metodoPago ? "EFECTIVO" : item.metodoPago?.toUpperCase()
    });
  });
  return dataTemp;
}

const formatModelRutasList = (data) => {
  return data.map((ruta) => ({
    value: ruta._id,
    label: ruta.nombre.join(" - "), // Puedes personalizar el formato del label según tus necesidades
  }));
};

const formatModelRutasIDRecibio = (data) => {
  console.log("🚀 ~ formatModelRutasIDRecibio ~ data:", data);
  return data.map((ruta) => ({
    value: ruta,
    label: ruta, // Puedes personalizar el formato del label según tus necesidades
  }));
};

export default withRouter(TablaPagosOrdenes);
