import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMensajerosGastos,
    ENDPOINTObtenerMensajerosGastos,
    ENDPOINTListarMensajerosGastos,
    ENDPOINTActualizarMensajerosGastos,
    ENDPOINTEliminarMensajerosGastos,
    ENDPOINTDeshabilitarMensajerosGastos,
    ENDPOINTListarMensajerosGastosGral
} from "./endpoints";
import axios from 'axios';

export async function listarMensajerosGastos(correoMensajero){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMensajerosGastos + `?correoMensajero=${correoMensajero}`, config);
}

export async function registraMensajerosGastos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMensajerosGastos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMensajerosGastos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMensajerosGastos + `/${params}`, config);
}

export async function actualizarMensajerosGastos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMensajerosGastos + `/${id}`, data, config);
}

export async function eliminarMensajerosGastos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMensajerosGastos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMensajerosGastos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMensajerosGastos + `/${id}`, data, config);
}

export async function listarSaldosMensajeroGral(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMensajerosGastosGral, config);
}
