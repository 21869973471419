import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarEvento,
    ENDPOINTObtenerEvento,
    ENDPOINTListarEvento,
    ENDPOINTActualizarEvento,
    ENDPOINTEliminarEvento,
    ENDPOINTDeshabilitarEvento,
    ENDPOINTListarFolioEvento
} from "./endpoints";
import axios from 'axios';

export async function listarEvento(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarEvento, config);
}

export async function registraEvento(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarEvento, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerEvento(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerEvento + `/${params}`, config);
}

export async function actualizarEvento(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarEvento + `/${id}`, data, config);
}

export async function eliminarEvento(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarEvento + `/${id}`, data, config);
}

export async function actualizaDeshabilitarEvento(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarEvento + `/${id}`, data, config);
}

export async function listarEventosPorFolio(folioOrden){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarFolioEvento + `?folioOrden=${folioOrden}`, config);
}