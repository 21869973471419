import React, { useEffect, useState } from "react";
import { actualizaDeshabilitarOrdenCompra, obtenerOrdenCompra } from "../../../api/ordenCompra";
import { actualizaAlmacenMateriasPrimas, obtenerAlmacenMateriasPrimas } from "../../../api/almacenMateriasPrimas";
import { actualizarAlmacenProductosLimpieza, obtenerAlmacenProductosLimpieza } from "../../../api/almacenProductosLimpieza";
import { registraRecepcionCompra, obtenerNumeroRecepcionCompra } from "../../../api/recepcionCompra";
import { Button, Col, Form, InputGroup, Row, Table, Modal } from "react-bootstrap";
import { listarUnidadMedida } from "../../../api/unidadesMedida";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faHashtag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from "../../logs/logs";

const ActualizarEstadoOC = ({ data, setShow }) => {
  const idOC = data;

  const [ordenC, setOrdenC] = useState([]);
  const [folioActual, setFolioActual] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [autoriza, setAutoriza] = useState("");
  const [elabora, setElabora] = useState("");
  const [productosOrdenC, setProductosOrdenC] = useState([]);

  const obtenerFolio = () => {
    try {
      obtenerNumeroRecepcionCompra()
        .then((response) => {
          const { data } = response;
          const { noRecepcion } = data;
          setFolioActual(noRecepcion);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const obtenerOC = (idOC) => {
    try {
      obtenerOrdenCompra(idOC)
        .then((response) => {
          const { data } = response;
          if (!ordenC && data) {
            setOrdenC(data);
          } else {
            setOrdenC(data);
            //setFolioActual(data.folio);
            setProveedor(data.datosProveedor.razonSocial);
            setElabora(data.elaboro);
            setAutoriza(data.autorizo);
            setProductosOrdenC(data.productos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOC(idOC);
  }, []);

  const [listarUM, setListarUM] = useState([]);

  const obtenerUM = () => {
    try {
      listarUnidadMedida()
        .then((response) => {
          const { data } = response;
          if (!listarUM && data) {
            setListarUM(formatModelUM(data));
          } else {
            setListarUM(formatModelUM(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUM();
  }, []);

  /***********TABLA PROD**********/
  const [arrProductos, setArrProductos] = useState([]);
  const [producto, setProducto] = useState("");
  const [uMedida, setUMedida] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [precioUnitario, setPrecioUnitario] = useState("");

  const agregarProducto = () => {
    if (!producto || !uMedida || !cantidad || !precioUnitario) {
      toast.warning("Datos incompletos");
    } else {
      const nuevoProducto = {
        producto: producto,
        um: uMedida,
        cantidad: cantidad,
        pUnitario: precioUnitario,
        totalProducto: parseFloat(precioUnitario) * parseFloat(cantidad),
      };

      setProductosOrdenC([...productosOrdenC, nuevoProducto]);
      setProducto("");
      setCantidad("");
      setUMedida("");
      setPrecioUnitario("");
    }
  };

  const handleQuit = (index) => {
    const newArray = [...productosOrdenC];
    newArray.splice(index, 1);
    setProductosOrdenC(newArray);
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const [subtotal, setSubtotal] = useState(0);
  const [iva, setiva] = useState(0);
  const [totalOC, settotalOC] = useState(0);

  useEffect(() => {
    const sumaTotalOC = productosOrdenC.reduce(
      (totalProducto, venta) => totalProducto + parseFloat(venta.totalProducto),
      0
    );

    const ivaCalc = sumaTotalOC * 0.16;
    const totalCalc = sumaTotalOC + ivaCalc;

    setSubtotal(sumaTotalOC);
    setiva(ivaCalc);
    settotalOC(totalCalc);
  }, [productosOrdenC]);

  /**************************** */

  const actualizarAMP = () => {
    try {
      productosOrdenC.map((producto, index) => {
        obtenerAlmacenMateriasPrimas(producto.id).then((response) => {
          const { data } = response;
          try {
            const dataTemp = {
              existencia: Number(data.existencia) + Number(producto.cantidad)
            };
            actualizaAlmacenMateriasPrimas(producto.id, dataTemp).then((response) => {
              const { data } = response;
              LogsInformativos("Se ha actualizado la existencia del producto " + producto.id + " en el almacen de materias primas", producto);
            });
          } catch (e) {
            console.log(e);
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizarAPL = () => {
    try {
      productosOrdenC.map((producto, index) => {
        obtenerAlmacenProductosLimpieza(producto.id).then((response) => {
          const { data } = response;
          try {
            const dataTemp = {
              existencia: Number(data.existencia) + Number(producto.cantidad)
            };
            actualizarAlmacenProductosLimpieza(producto.id, dataTemp).then((response) => {
              const { data } = response;
              LogsInformativos("Se ha actualizado la existencia del producto " + producto.id + " en el almacen de productos de limpieza", producto);
            });
          } catch (e) {
            console.log(e);
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Obtener la fecha actual
  const currentDate = new Date();

  // Obtener el año, mes y día por separado
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
  const day = String(currentDate.getDate()).padStart(2, '0');

  // Formatear la fecha en el formato "aaaa-mm-dd"
  const formattedDate = `${year}-${month}-${day}`;

  const registrarRecepcion = () => {
    try {
      const dataTemp = {
        folio: folioActual,
        fecha: formattedDate,
        productos: productosOrdenC,
        elaboro: elabora,
        autorizo: autoriza,
        estado: "1"
      };
      registraRecepcionCompra(dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        LogsInformativos("Se ha registrado la recepcion de compra " + folioActual, dataTemp)
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizarOC = () => {
    try {
      const dataTemp = {
        estado: "1",
        productos: productosOrdenC,
        subtotal: productosOrdenC.reduce((accumulator, currentValue) => accumulator + !(currentValue.pUnitario * currentValue.cantidad) ? 0 : (currentValue.pUnitario * currentValue.cantidad), 0),
        iva: productosOrdenC.reduce((accumulator, currentValue) => accumulator + (!currentValue.IVA ? 0 : currentValue.IVA), 0),
        total: productosOrdenC.reduce((accumulator, currentValue) => accumulator + (!currentValue.totalProducto ? 0 : currentValue.totalProducto), 0),
      };
      actualizaDeshabilitarOrdenCompra(idOC, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        actualizarAMP();
        actualizarAPL();
        registrarRecepcion();
        LogsInformativos("Se ha actualizado la orden de compra " + dataTemp.elaboro, dataTemp)
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**************************** */

  /************************************************ */
  const [showModal, setShowModal] = useState(false);
  const [cantidadTemp, setCantidadTemp] = useState(0);
  const [pUnitario, setPUnitario] = useState(0);
  const [productoIndex, setProductoIndex] = useState(-1);
  const [productoNom, setProductoNom] = useState("");

  const handleOpenModal = (cantidad, index, nombre) => {
    setCantidadTemp(cantidad);
    setProductoIndex(index);
    setProductoNom(nombre)
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModificarCantidad = () => {
    // Actualizar la cantidad en el arreglo arrProductos
    const updatedProductos = [...productosOrdenC]; // Clonar el arreglo original
    updatedProductos[productoIndex].cantidadAprobada = cantidadTemp; // Actualizar la cantidad en el producto específico
    updatedProductos[productoIndex].pUnitario = pUnitario;
    updatedProductos[productoIndex].IVA = (pUnitario * cantidadTemp) * 0.16;
    updatedProductos[productoIndex].totalProducto = (pUnitario * cantidadTemp) + ((pUnitario * cantidadTemp) * 0.16);
    // Aquí puedes hacer una llamada a una función para enviar los datos actualizados al servidor, si es necesario
    // Luego, actualiza el estado y cierra el modal
    setArrProductos(updatedProductos);
    handleCloseModal();
  };
  /************************************************ */
  console.log(productosOrdenC)
  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Folio:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Folio de OC"
              value={folioActual}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Proveedor:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control type="text" disabled value={proveedor} />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Elabora:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien elabora"
              value={elabora}
              onChange={(e) => setElabora(e.target.value)}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Autoriza:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien autoriza"
              value={autoriza}
              onChange={(e) => setAutoriza(e.target.value)}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Producto</th>
                <th>Unidad de medida</th>
                <th>Cantidad</th>
                <th>Cantidad aprobada</th>
                <th>Precio unitario</th>
                <th>IVA</th>
                <th>Total</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {productosOrdenC.map((producto, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{producto.producto}</td>
                  <td>{producto.unidadMedida}</td>
                  <td>{producto.cantidad}</td>
                  <td>{!producto.cantidadAprobada ? 0 : producto.cantidadAprobada}</td>
                  <td>{formatCurrency(!producto.pUnitario ? 0 : producto.pUnitario)}</td>
                  <td>{formatCurrency(!producto.IVA ? 0 : producto.IVA)}</td>
                  <td>{formatCurrency(!producto.totalProducto ? 0 : producto.totalProducto)}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleOpenModal(producto.cantidad, index, producto.producto)}
                      title="Modificar cantidad"
                    >
                      <FontAwesomeIcon icon={faHashtag} />
                    </Button>
                    <Button variant="danger" onClick={() => handleQuit(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Modificar Cantidad aprobada</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formCantidad">
                <h4 style={{ textAlign: "center", fontStyle: "italic" }}>{productoNom}</h4>
                <Form.Label>Nueva Cantidad:</Form.Label>
                <Form.Control
                  type="number"
                  value={cantidadTemp}
                  onChange={(e) => setCantidadTemp(e.target.value)}
                />
                <Form.Label>Precio sin IVA:</Form.Label>
                <Form.Control
                  type="number"
                  value={pUnitario}
                  onChange={(e) => setPUnitario(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleModificarCantidad}>
                Actualizar
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col md={{ span: 4, offset: 8 }}>
            <Table>
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td>{formatCurrency(productosOrdenC.reduce((accumulator, currentValue) => accumulator + !(currentValue.pUnitario * currentValue.cantidad) ? 0 : (currentValue.pUnitario * currentValue.cantidad), 0))}</td>
                </tr>
                <tr>
                  <td>IVA:</td>
                  <td>{formatCurrency(productosOrdenC.reduce((accumulator, currentValue) => accumulator + (!currentValue.IVA ? 0 : currentValue.IVA), 0))}</td>
                </tr>
                <tr>
                  <td>Total:</td>
                  <td>{formatCurrency(productosOrdenC.reduce((accumulator, currentValue) => accumulator + (!currentValue.totalProducto ? 0 : currentValue.totalProducto), 0))}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={actualizarOC}>
            <FontAwesomeIcon icon={faCartPlus} />
            &nbsp;Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

function formatModelUM(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

const formatModelProveedores = (data) => {
  return data.map((proveedor) => ({
    value: proveedor.nombre,
    label: proveedor.nombre, // Puedes personalizar el formato del label según tus necesidades
  }));
};

export default ActualizarEstadoOC;
