import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Tabla } from "./Tabla";
import imagenDT from "../../assets/img/DT.jpeg";
import imagen from "../../assets/img/DP.jpeg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { eliminarServicios } from "../../api/servicios";
import queryString from "query-string";
import { Load } from "../load/load";
import { LogsInformativos } from "../logs/logs";

export default function EliminarServicio({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data.id;

  const dataTemp = {
    folio: data.folio,
    clasificacion: data.clasificacion,
    material: data.material,
    pieza: data.pieza,
    precio: data.precio,
    descripcionAdicional: data.descripcionAdicional,
    sistemaColor: data.sistemaColor,
    distencion: data.distencion,
    diente: data.diente,
    tonoInferior: data.tonoInferior,
    tonoMedio: data.tonoMedio,
    tonoSuperior: data.tonoSuperior,
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [fechaNueva, setFechaNueva] = useState(dia + "/" + mes + "/" + year);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      eliminarServicios(idDocumental).then((response) => {
        const { data } = response;
        console.log(data);
        LogsInformativos("Se ha eliminado el servicio " + idDocumental, dataTemp)

        toast.success(data.mensaje);

        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit} onChange={onChange}>
          <Form.Group className="datosGOS">
            <div className="divfolioOS2">
              <Form.Label className="folioOS2">Folio:</Form.Label>
              <Form.Control
                className="inpFolioOS2"
                type="text"
                placeholder="Folio"
                name="folio"
                value={formData.folio}
                disabled
              />
            </div>
            <Form.Label className="folioOS2">Clasificacion:</Form.Label>
            <Form.Control
              name="clasificacion"
              defaultValue={formData.clasificacion}
              className="inpFechaOS2"
              disabled
            />
            <Form.Label className="folioOS2">Material:</Form.Label>
            <Form.Control
              type="text"
              className="localidadOS"
              name="material"
              defaultValue={formData.material}
              placeholder="Localidad"
              disabled
            />
            <Form.Label className="folioOS2">Pieza:</Form.Label>
            <Form.Control
              type="text"
              className="nombreDoctorOS2"
              name="pieza"
              defaultValue={formData.pieza}
              placeholder="Nombre del Doctor"
              disabled
            />
          </Form.Group>
          <Form.Group>
            <div className="divNombrePaciente">
              <Form.Label className="lblNombrePaciente">
                Precio:
              </Form.Label>
              <Form.Control
                className="inpNombrePaciente"
                name="precio"
                defaultValue={formData.precio}
                placeholder="Nombre del Paciente"
                disabled
              />
            </div>
          </Form.Group>

          <div className="recibeOSDiv">
            <Form.Label className="recibeOS2">Descripcion adicional :</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de quien recibe"
              defaultValue={formData.descripcionAdicional}
              name="descripcionAdicional"
              disabled
            />
            <Form.Label className="fechaOS2">Sistema de color:</Form.Label>
            <Form.Control
              type="text"
              placeholder="dd/mm/aaaa"
              className="inpFecha"
              defaultValue={formData.sistemaColor}
              name="sistemaColor"
              disabled
            />
          </div>

          <div className="recibeOSDiv">
            <Form.Label className="recibeOS2">Tipo dentadura:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de quien recibe"
              defaultValue={formData.distencion}
              name="distencion"
              disabled
            />
            <Form.Label className="fechaOS2">Diente:</Form.Label>
            <Form.Control
              type="text"
              placeholder="dd/mm/aaaa"
              className="inpFecha"
              defaultValue={formData.diente}
              name="diente"
              disabled
            />
          </div>

          <div className="recibeOSDiv">
            <Form.Label className="fechaOS2">Tono inferior:</Form.Label>
            <Form.Control
              type="text"
              placeholder="dd/mm/aaaa"
              className="inpFecha"
              defaultValue={formData.tonoInferior}
              name="tonoInferior"
              disabled
            />
            <Form.Label className="fechaOS2">Tono medio:</Form.Label>
            <Form.Control
              type="text"
              placeholder="dd/mm/aaaa"
              className="inpFecha"
              defaultValue={formData.tonoMedio}
              name="tonoMedio"
              disabled
            />
            <Form.Label className="recibeOS2">Tono superior:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de quien recibe"
              defaultValue={formData.tonoSuperior}
              name="tonoSuperior"
              disabled
            />
          </div>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    clasificacion: data.clasificacion,
    material: data.material,
    pieza: data.pieza,
    precio: data.precio,
    descripcionAdicional: data.descripcionAdicional,
    sistemaColor: data.sistemaColor,
    distencion: data.distencion,
    diente: data.diente,
    tonoInferior: data.tonoInferior,
    tonoMedio: data.tonoMedio,
    tonoSuperior: data.tonoSuperior,
  };
}
