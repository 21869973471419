import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import {
  listarFacturacion,
  registraFacturacion,
} from "../../../api/facturacion";
import { listarEstablecimientos } from "../../../api/establecimientos";
import { listarDoctores } from "../../../api/doctores";
import { map } from "lodash";
import regimenFiscalData from "../catalogosJSON/regimenFiscal.json";
import estadosPaisData from "../catalogosJSON/estadosPais.json";
import municipiosEstadoData from "../catalogosJSON/municipiosEstadosPais.json";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { LogsInformativos } from "../../logs/logs";

export default function DatosdeFacturacion({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !usuarioRFC ||
      !razonSocial ||
      !regimenFiscal ||
      !calleDom ||
      !cpDom ||
      !coloniaDom ||
      !municipioSeleccionado ||
      !estadoPais
    ) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);

        const dataTemp = {
          rfc: usuarioRFC,
          razonSocial: razonSocial,
          regimenFiscal: regimenFiscal,
          calle: calleDom,
          numeroExterior: numExtDom,
          numeroInterior: numIntDom,
          codigoPostal: cpDom,
          colonia: coloniaDom,
          municipio: municipioSeleccionado,
          estadoPais: estadoPais,
          idUsuarioDatos:
            tipoUsuario != "administrador"
              ? correoUsuario
              : usuarioReg.split("/")[0],
          observaciones: formData.observaciones,
          estado: "true",
        };
        registraFacturacion(dataTemp).then((response) => {
          const { data } = response;
          console.log(data);
          LogsInformativos("Se ha registrado los datos de facturacion para la razon social " + dataTemp.razonSocial, dataTemp)
          toast.success(data.mensaje);
          setFormData(initialFormValue());
          setLoading(false);
          if (tipoUsuario != "administrador") {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else{
            setShow(false);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const tiposCliente = ["Doctor", "Consultorio"];
  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            setListEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, []);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const [listarFacturaciones, setListFacturacion] = useState([]);

  const obtenerFacturacion = () => {
    try {
      listarFacturacion()
        .then((response) => {
          const { data } = response;
          if (data) {
            const datosFacturacion = formatModelFacturacion(data);
            const idsUsuarioDatos = obtenerIdsUsuarioDatos(datosFacturacion);
            setListFacturacion(idsUsuarioDatos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  // Función auxiliar para obtener los valores de idUsuarioDatos
  const obtenerIdsUsuarioDatos = (datosFacturacion) => {
    return datosFacturacion.map((facturacion) => facturacion.idUsuarioDatos);
  };
  useEffect(() => {
    obtenerFacturacion();
  }, []);

  const [nuevoListadoDoc, setnuevoListadoDoc] = useState([]);
  const [nuevoListadoCons, setnuevoListadoCons] = useState([]);

  useEffect(() => {
    const filterID = listarDoctor.filter(
      (data) => !listarFacturaciones.includes(data.email)
    );
    setnuevoListadoDoc(filterID);
    const filterIDCons = listarEstablecimiento.filter(
      (data) => !listarFacturaciones.includes(data.email)
    );
    setnuevoListadoCons(filterIDCons);
  }, [listarFacturaciones, listarDoctor, listarEstablecimiento]);

  const [regimenFiscal, setRegimenFiscal] = useState("");

  const handleInputChange = (e) => {
    setRegimenFiscal(e.target.value);
  };

  const [estadoPais, setEstadoPais] = useState("");

  const handleInputChangeEstado = (event) => {
    const nuevoEstado = event.target.value;
    setEstadoPais(nuevoEstado);
  };

  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");

  const handleInputChangeMunicipio = (e) => {
    setMunicipioSeleccionado(e.target.value);
  };

  const [usuarioReg, setUsuarioReg] = useState("");
  const [usuarioRFC, setUsuarioRFC] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [calleDom, setcalleDom] = useState("");
  const [numExtDom, setnumExtDom] = useState("");
  const [numIntDom, setnumIntDom] = useState("");
  const [coloniaDom, setcoloniaDom] = useState("");
  const [cpDom, setcpDom] = useState("");

  const selectOptionUsuario = (e) => {
    setUsuarioReg(e.target.value);
  };

  useEffect(() => {
    if (tipoUsuario == "administrador") {
      setRazonSocial(usuarioReg.split("/")[1]);
      const filterID = listarDoctor.find(
        (data) => data.id === usuarioReg.split("/")[0]
      );
      if (filterID) {
        setUsuarioRFC(filterID.rfc);
        setcalleDom(filterID.calle);
        setnumExtDom(filterID.nExterior);
        setnumIntDom(filterID.nInterior);
        setcoloniaDom(filterID.colonia);
        setcpDom(filterID.cPostal);
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  }, [usuarioReg]);

  useEffect(() => {
    if (tipoUsuario == "doctor") {
      const filterID = listarDoctor.find(
        (data) => data.email === correoUsuario
      );
      if (filterID) {
        setRazonSocial(
          filterID.nombre +
            " " +
            filterID.apellidoPaterno +
            " " +
            filterID.apellidoMaterno
        );
        setUsuarioRFC(filterID.rfc);
        setcalleDom(filterID.calle);
        setnumExtDom(filterID.nExterior);
        setnumIntDom(filterID.nInterior);
        setcoloniaDom(filterID.colonia);
        setcpDom(filterID.cPostal);
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  }, [correoUsuario, listarDoctor]);

  useEffect(() => {
    if (tipoUsuario == "consultorio") {
      const filterID = listarEstablecimiento.find(
        (data) => data.email === correoUsuario
      );
      if (filterID) {
        setRazonSocial(filterID.razonSocial);
        setUsuarioRFC(filterID.rfc);
        setcalleDom(filterID.calle);
        setnumExtDom(filterID.numeroExterior);
        setnumIntDom(filterID.numeroInterior);
        setcoloniaDom(filterID.colonia);
        setcpDom(filterID.codigoPostal);
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  }, [correoUsuario, listarEstablecimiento]);

  return (
    <>
      {loading && <Load />}
      <div className="facturacionGeneral">
        <Form
          className="formFacturacion"
          onSubmit={onSubmit}
          onChange={onChange}
        >
          {tipoUsuario == "administrador" && (
            <>
              <Row className="mb-2 mb-md-4 mb-lg-7">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                >
                  <Form.Label hidden>Tipo de cliente: <code>*</code></Form.Label>
                </Col>
                <Col sm={12} md={8} lg={8}>
                  <Form.Control
                    as="select"
                    hidden
                    aria-label="Seleccionar tipo de cliente"
                    value={formData.tipoCliente}
                    name="tipoCliente"
                  >
                    <option>Seleccionar</option>
                    {tiposCliente.map((tipo, index) => (
                      <option key={index} value={tipo}>
                        {tipo}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-2 mb-md-4 mb-lg-7">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                >
                  <Form.Label>
                    {formData.tipoCliente == null
                      ? "Cliente"
                      : formData.tipoCliente}
                    : <code>*</code>
                  </Form.Label>
                </Col>
                <Col sm={12} md={8} lg={8}>
                  <Form.Control
                    as="select"
                    aria-label="Seleccionar cliente"
                    name="clienteVisitar"
                    value={usuarioReg}
                    onChange={selectOptionUsuario}
                  >
                    <option>Elige una opción</option>
                    {formData.tipoCliente == "Consultorio"
                      ? map(nuevoListadoCons, (cat, index) => (
                          <option
                            key={index}
                            value={cat?.email + "/" + cat?.razonSocial}
                          >
                            {cat?.alias}
                          </option>
                        ))
                      : formData.tipoCliente == "Doctor"
                      ? map(nuevoListadoDoc, (cat, index) => (
                          <option
                            key={index}
                            value={
                              cat?.email +
                              "/" +
                              cat?.nombre +
                              " " +
                              cat?.apellidoPaterno +
                              " " +
                              cat?.apellidoMaterno
                            }
                          >
                            {cat?.nombre +
                              " " +
                              cat?.apellidoPaterno +
                              " " +
                              cat?.apellidoMaterno}
                          </option>
                        ))
                      : null}
                  </Form.Control>
                </Col>
              </Row>
            </>
          )}

          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Razón Social: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                className="inpRegimen"
                placeholder="Ingresa Razón Social"
                value={razonSocial}
                onChange={(e) => setRazonSocial(e.target.value)}
                name="razonSocial"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>RFC: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                className="inpRegimen"
                placeholder="Ingresa RFC"
                value={usuarioRFC}
                onChange={(e) => setUsuarioRFC(e.target.value)}
                name="rfc"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Régimen Fiscal: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
                <Form.Control
                  as="select"
                  className="inpRegimen"
                  value={regimenFiscal}
                  onChange={handleInputChange}
                >
                  <option>Selecciona un Régimen Fiscal</option>
                  {regimenFiscalData.map((regimen) => (
                    <option
                      key={regimen.id}
                      value={regimen.id + " - " + regimen.descripcion}
                    >
                      {regimen.id + " - " + regimen.descripcion}
                    </option>
                  ))}
                </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Calle: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Ingresa Calle"
                value={calleDom}
                onChange={(e) => setcalleDom(e.target.value)}
                name="calle"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Número Exterior:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="number"
                placeholder="Ingresa número exterior"
                value={numExtDom}
                onChange={(e) => setnumExtDom(e.target.value)}
                name="numeroExterior"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Número Interior:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="number"
                placeholder="Ingresa número interior"
                value={numIntDom}
                onChange={(e) => setnumIntDom(e.target.value)}
                name="numeroInterior"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Colonia: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Ingresa Colonia"
                value={coloniaDom}
                onChange={(e) => setcoloniaDom(e.target.value)}
                name="colonia"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Estado: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                value={estadoPais}
                onChange={handleInputChangeEstado}
              >
                <option>Selecciona un Estado</option>
                {estadosPaisData.map((estado) => (
                  <option key={estado.clave} value={estado.nombre}>
                    {estado.nombre}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Municipio: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                value={municipioSeleccionado}
                onChange={handleInputChangeMunicipio}
              >
                <option>Selecciona un Municipio</option>
                {municipiosEstadoData[estadoPais] &&
                  municipiosEstadoData[estadoPais].map((municipio) => (
                    <option key={municipio} value={municipio}>
                      {municipio}
                    </option>
                  ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Código Postal: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="number"
                placeholder="Codigo Postal"
                value={cpDom}
                onChange={(e) => setcpDom(e.target.value)}
                name="codigoPostal"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Observaciones:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="textarea"
                rows={3}
                defaultValue={formData.observaciones}
                placeholder="Observaciones"
                name="observaciones"
              />
            </Col>
          </Row>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    regimenFiscal: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "",
    codigoPostal: "",
    colonia: "",
    municipio: "",
    observaciones: "",
    tipoCliente: "Doctor"
  };
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
      calle: data.calle,
      nExterior: data.nExterior,
      nInterior: data.nInterior,
      colonia: data.colonia,
      estadoDom: data.estadoDom,
      municipio: data.municipio,
      cPostal: data.cPostal,
      referencia: data.referencia,
    });
  });
  return dataTemp;
}

const formatModelFacturacion = (data) => {
  return data.map((item) => {
    return {
      idUsuarioDatos: item.idUsuarioDatos,
    };
  });
};

function formatModelFacturacionCompleta(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      rfc: data.rfc,
      razonSocial: data.razonSocial,
      regimenFiscal: data.regimenFiscal,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      colonia: data.colonia,
      municipio: data.municipio,
      estadoPais: data.estadoPais,
      idUsuarioFac: data.idUsuarioDatos,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}
