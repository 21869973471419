import React from 'react'
import { Table } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

export const TablaMovimientos = ({ productos, setProductos }) => {

  // Para eliminar productos del listado
  const removeItem = (producto) => {
    let newArray = productos;
    newArray.splice(newArray.findIndex(a => a.servicio === producto.servicio), 1);
    setProductos([...newArray]);
  }

  return (
    <div>

      <Table striped bordered hover size="sm" >
        <thead>
          <tr>
            <th>#</th>
            <th>Materia prima</th>
            <th>Movimiento</th>
            <th>Departamento/Proveedor</th>
            <th>Cantidad</th>
            <th>UM</th>
            <th>Observaciones</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.producto}</td>
                <td>{producto.tipo}</td>
                <td>{producto.departamento}</td>
                <td>{producto.cantidadMovimiento}</td>
                <td>{producto.unidadMedida}</td>
                <td>{producto.observaciones}</td>
                <td data-title="Eliminar">
                  <Button
                    variant="danger"
                    onClick={() => {
                      removeItem(producto)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  )
}
