import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { TablaServicios } from '../TablaServicios';
import { actualizaDeshabilitarRecepciones } from '../../../api/recepciones';
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { LogsInformativos } from "../../logs/logs";

const CancelarMaterialEntrega = ({ data, history, setShow }) => {
    
    const idDocumental = data[0];

    const dataTemp = {
      folio: data[1],
      materiales: data[2],
      otros: data[4],
    };
  
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);  
  
    const [loading, setLoading] = useState(true);
  
    const onSubmit = (e) => {
      e.preventDefault();
  
        try {
          setLoading(true);
  
          const dataTemp = {
            estado: "false"
          };
          actualizaDeshabilitarRecepciones(idDocumental, dataTemp).then((response) => {
            const { data } = response;
            console.log(data);
            LogsInformativos("Se cancelado la entrega de material " + idDocumental, dataTemp)
            toast.success(data.mensaje);
  
            history({
              search: queryString.stringify(""),
            });
            setLoading(false);
            setShow(false);
          });
        } catch (e) {
          console.log(e);
        }
    };
  
    const [clasificacionMat, setClasificacionMat] = useState(dataTemp.materiales);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <div style={{ padding: "2%" }}>
          <div style={{ marginTop: "2%" }}>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Orden de cliente:</Form.Label>
              </Col>
              <Col sm={12} md={10} lg={10}>
                <Form.Control type='text' value={dataTemp.folio} disabled/>
              </Col>
            </Row>
          </div>
          <TablaServicios
            productos={clasificacionMat}
            setProductos="cancelar"
          />
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Deshabilitar" type="submit" />
          </div>
        </div>
      </Form>
    </>
  )
}

export default CancelarMaterialEntrega