import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarRecepciones,
    ENDPOINTObtenerRecepciones,
    ENDPOINTListarRecepciones,
    ENDPOINTActualizarRecepciones,
    ENDPOINTEliminarRecepciones,
    ENDPOINTDeshabilitarRecepciones
} from "./endpoints";
import axios from 'axios';

export async function listarRecepciones(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRecepciones, config);
}

export async function registraRecepciones(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarRecepciones, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerRecepciones(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerRecepciones + `/${params}`, config);
}

export async function actualizarRecepciones(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarRecepciones + `/${id}`, data, config);
}

export async function eliminarRecepciones(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarRecepciones + `/${id}`, data, config);
}

export async function actualizaDeshabilitarRecepciones(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarRecepciones + `/${id}`, data, config);
}