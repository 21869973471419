import {
  faArrowRight,
  faBars,
  faCancel,
  faHandHolding,
  faKey,
  faMinus,
  faPaperPlane,
  faPlus,
  faRotate,
  faTrash,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { actualizarVales, obtenerVales } from "../../api/vales";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { toast } from "react-toastify";
import {
  buscarAlmacenInsumosIds,
  listarAlmacenProductosLimpiezaNombre,
  totalAlmacenProductosLimpieza,
} from "../../api/almacenProductosLimpieza";
import { registraMovimientosAPL } from "../../api/movimientosAlmacenPL"
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LogsInformativos } from "../logs/logs";

const EntregarInsumosVale = ({ data, setShow }) => {
  const idVale = data[0];

  const [nombre, setNombre] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombre(data.nombre);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listVales, setListVales] = useState([]);
  const [listMateriales, setListMateriales] = useState([]);

  const obtenerVale = (id) => {
    try {
      obtenerVales(id)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listVales && data) {
            setListVales(data);
          } else {
            setListVales(data);
            setListMateriales(data.material);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVale(idVale);
  }, [idVale]);


  const [cantidadesProdAlmacen, setcantidadesProdAlmacen] = useState([]);

  const obtenerAlmacenIds = (arrIds) => {
    try {
      buscarAlmacenInsumosIds(arrIds)
        .then((response) => {
          const { data } = response;
          if (!cantidadesProdAlmacen && data) {
            setcantidadesProdAlmacen(data);
          } else {
            setcantidadesProdAlmacen(data);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    const ids = listMateriales.map((material) => material.id);
    obtenerAlmacenIds(ids);
  }, [listMateriales]);


  const [materialesConExistenciaMP, setMaterialesConExistenciaMP] = useState([])
  useEffect(() => {
    const listMaterialesConExistencia = JSON.parse(
      JSON.stringify(listMateriales)
    );

    listMaterialesConExistencia.forEach((material) => {
      const existenciaObj = cantidadesProdAlmacen.find(
        (obj) => obj._id === material.id
      );

      if (existenciaObj) {
        material.existencia = existenciaObj.existencia;
      }
    });

    setMaterialesConExistenciaMP(listMaterialesConExistencia);
  }, [listMateriales, cantidadesProdAlmacen]);


  console.log(listMateriales)

  const registraMovimientos = (e) => {
    try {
      // Assuming listMateriales is an array of objects with the required properties
      materialesConExistenciaMP.map((producto) => {
        const dataTemp5 = {
          idArticuloAMP: producto.id,
          producto: producto.producto,
          tipo: "Salida",
          cantidadMovimiento: producto.cantidad,
          unidadMedida: producto.unidadMedida,
          departamento: "No disponible",
          observaciones: "No disponibles" // Corrected typo: obsrvaciones -> observaciones
        };
        registraMovimientosAPL(dataTemp5).then((response) => {
          const { data } = response;
          console.log(data);
        });
    })
    } catch (error) {
      console.error(error);
    }
  };

  const [nombreAutoriza, setNombreAutoriza] = useState("");

  const actualizarVale = async () => {
    try {
      const dataTemp = {
        autorizo: nombreAutoriza,
        entrego: nombre,
        material: materialesConExistenciaMP,
        estado: "3",
      };
      actualizarVales(idVale, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        LogsInformativos("Se ha entregado el vale " + dataTemp.entrego, dataTemp)
        registraMovimientos();
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = (index) => {
    // Obtener una copia del arreglo original
    const newArray = [...materialesConExistenciaMP];

    // Disminuir la cantidad del producto en el índice dado
    newArray[index].cantidad--;

    // Si la cantidad llega a cero, eliminar el producto del arreglo
    if (newArray[index].cantidad === 0) {
      newArray.splice(index, 1);
    }

    // Actualizar el estado con el nuevo arreglo
    setMaterialesConExistenciaMP(newArray);
  };

  const handleSum = (index) => {
    // Obtener una copia del arreglo original
    const newArray = [...materialesConExistenciaMP];

    // Disminuir la cantidad del producto en el índice dado
    newArray[index].cantidad++;
    // Actualizar el estado con el nuevo arreglo
    setMaterialesConExistenciaMP(newArray);
  };

  const handleQuit = (index) => {
    const newArray = [...materialesConExistenciaMP];
    newArray.splice(index, 1);
    setMaterialesConExistenciaMP(newArray);
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(false);

  const handleClick = () => {
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const [nombreProd, setNombreProd] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noTotalProductos, setNoTotalProductos] = useState(1);
  const [listarProducto, setListProducto] = useState([]);

  const obtenerProductos = (nombre) => {
    try {
      totalAlmacenProductosLimpieza()
        .then((response) => {
          const { data } = response;
          setNoTotalProductos(data);
        })
        .catch((e) => {
          console.log(e);
        });

      listarAlmacenProductosLimpiezaNombre(nombre, page, rowsPerPage)
        .then((response) => {
          const { data } = response;
          if (!listarProducto && data) {
            setListProducto(formatModelProductos(data));
          } else {
            const datosProducto = formatModelProductos(data);
            setListProducto(datosProducto);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  console.log(listarProducto);

  const handleChangePage = (page) => {
    // console.log("Nueva pagina "+ newPage)
    setPage(page);
  };

  const handleChangeRowsPerPage = (newPerPage) => {
    // console.log("Registros por pagina "+ parseInt(event.target.value, 10))
    setRowsPerPage(newPerPage);
    //setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (nombreProd != "") {
      obtenerProductos(nombreProd);
    }
  }, [nombreProd, page, rowsPerPage]);

  const handleAgregar = (producto) => {
    const producto2 = {
      id: producto[0],
      cantidad: 1,
      clave: producto[1],
      producto: producto[2],
      categoria: producto[3],
      unidadMedida: producto[4],
    };
    setListMateriales([...listMateriales, producto2]);
  };

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "clave",
      label: "CLAVE",
    },
    {
      name: "producto",
      label: "MATERIAL",
    },
    {
      name: "categoria",
      label: "CATEGORIA",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                className="btnAddTables"
                onClick={() => handleAgregar(tableMeta.rowData)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    responsive: isSmOrDown && "standard",
    selectableRows: "none",
    pagination: true,
    serverSide: true,
    search: false, // Oculta la barra de búsqueda
    download: false, // Oculta el botón de descarga
    print: false, // Oculta el botón de impresión
    viewColumns: false,
    filter: false,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onChangePage: handleChangePage,
    count: noTotalProductos,
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        //columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
    },
  };

  return (
    <div style={{ padding: "2%" }}>
      <Row>
        <Form>
          <div>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Nombre del solicitante:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="Nombre solicitante"
                  value={listVales.recibio}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Área:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="Área solicitante"
                  value={
                    listVales.area != "" ? listVales.area : "No especificada"
                  }
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Autoriza:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="Nombre de quien autoriza"
                  value={nombreAutoriza}
                  onChange={(e) => setNombreAutoriza(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Entrega:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="Nombre de quien entrega"
                  disabled
                  value={nombre}
                />
              </Col>
            </Row>
          </div>
          <div className="divButton">
            <Button className="btnAddTables" onClick={handleClick}>
              {!mostrarPrimerDiv ? (
                <><FontAwesomeIcon icon={faPlus} /> Agregar producto</>
              ) : (
                <><FontAwesomeIcon icon={faCancel} /> Cancelar</>
              )}

            </Button>
          </div>
          <div style={{ textAlign: "center" }}>
            {mostrarPrimerDiv && (
              <div>
                <div className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Ingresa Material a buscar 🔍"
                    value={nombreProd}
                    onChange={(e) => setNombreProd(e.target.value)}
                  />
                </div>
                <MUIDataTable
                  data={listarProducto}
                  columns={columns}
                  options={options}
                />
              </div>
            )}
            <h4 className="mt-3">Productos agregados</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Cantidad</th>
                  <th>Clave</th>
                  <th>Material</th>
                  <th>UM</th>
                  <th>Cantidad en almacen</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {materialesConExistenciaMP.map((producto, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{producto.cantidad}</td>
                    <td>{producto.clave}</td>
                    <td>{producto.producto}</td>
                    <td>{producto.unidadMedida}</td>
                    <td>{producto.existencia}</td>
                    <td style={{ textAlign: "center" }}>
                      <>
                        <Button
                          variant="success"
                          onClick={() => handleSum(index)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <Button
                          variant="warning"
                          onClick={() => handleDelete(index)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleQuit(index)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Form>
        <div style={{ textAlign: "center" }}>
          <Button variant="warning" onClick={actualizarVale}>
            <FontAwesomeIcon icon={faHandHolding} /> Entregar
          </Button>
        </div>
      </Row>
    </div>
  );
};

function formatModelProductos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      producto: data.producto,
      categoria: data.categoria,
      unidadMedida: data.unidadMedida,
    });
  });
  return dataTemp;
}

export default EntregarInsumosVale;
