import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import imagenDT from "../../assets/img/DT.jpeg";
import imagen from "../../assets/img/DP.jpeg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actualizaProcesoCancelacionOrdenesServicio } from "../../api/ordenesServicio";
import queryString from "query-string";
import { Load } from "../load/load";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { registraEvento } from "../../api/eventos";
import { LogsInformativos } from "../logs/logs";

export default function ProcesoCancelacionOrdenServicio({ data, history, setShow }) {
  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    fecha: data[2],
    localidad: data[3],
    nombreDoctor: data[4],
    nombrePaciente: data[5],
    tipoDentadura: data[6],
    modeloSuperior: data[7],
    modeloInferior: data[8],
    mordidaCera: data[9],
    mordeidaSilicon: data[10],
    sAntagonista: data[11],
    llenadoCucharilla: data[12],
    otros: data[13],
    recibio: data[14],
    fechaEntrada: data[15],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [fechaNueva, setFechaNueva] = useState(dia + "/" + mes + "/" + year);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  let fecha2 = new Date();
  let dia2 = fecha2.getDate(); // Obtener el día (del 1 al 31)
  let mes2 = fecha2.getMonth() + 1; // Obtener el mes (del 0 al 11, por eso se suma 1)
  let anio2 = fecha2.getFullYear(); // Obtener el año (ej. 2023)
  let hora2 = fecha2.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos2 = fecha2.getMinutes(); // Obtener los minutos

  let datosFecha2 = `${dia2}/${mes2}/${anio2} ${hora2}:${minutos2}`;

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const dataTemp = {
        cancelacion: "2"
      }

      actualizaProcesoCancelacionOrdenesServicio(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        LogsInformativos("Se ha registrado el proceso de cancelacion la orden de cliente " + idDocumental, dataTemp)
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit} onChange={onChange}>
          <Form.Group className="datosGOS">
            <div className="divfolioOS2">
              <Form.Label className="folioOS2">Folio:</Form.Label>
              <Form.Control
                className="inpFolioOS2"
                type="text"
                placeholder="Folio"
                name="folio"
                value={formData.folio}
                disabled
              />
            </div>
            <Form.Control
              name="fecha"
              onChange={handleFechaChange}
              defaultValue={formData.fecha}
              className="inpFechaOS2"
              disabled
            />
            <Form.Control
              type="text"
              className="localidadOS"
              name="localidad"
              defaultValue={formData.localidad}
              placeholder="Localidad"
              disabled
            />
            <Form.Control
              type="text"
              className="nombreDoctorOS2"
              name="nombreDoctor"
              defaultValue={formData.nombreDoctor}
              placeholder="Nombre del Doctor"
              disabled
            />
          </Form.Group>
          <Form.Group>
            <div className="divNombrePaciente">
              <Form.Label className="lblNombrePaciente">
                Nombre del paciente:
              </Form.Label>
              <Form.Control
                className="inpNombrePaciente"
                name="nombrePaciente"
                defaultValue={formData.nombrePaciente}
                placeholder="Nombre del Paciente"
                disabled
              />
            </div>
          </Form.Group>
          <Form.Group>

            <div className="divTipoDentOS">
              <div className="textTipoDentOS">
                <Form.Label>Tipo de dentadura:</Form.Label>
                <Form.Select
                  value={opcion}
                  name="tipoDentadura"
                  defaultValue={formData.tipoDentadura}
                  onChange={cambiaImagen}
                  disabled
                >
                  <option value="DP" selected={formData.tipoDentadura == "DP"}>Dentincion Permanente</option>
                  <option value="DT" selected={formData.tipoDentadura == "DT"}>Destincion Temporal</option>
                </Form.Select>
              </div>
              <div className="imgTipoOS">
                {opcion === "DP" && <img src={imagen} alt="Imagen 1" />}
                {opcion === "DT" && <img src={imagenDT} alt="Imagen 2" />}
              </div>
            </div>
          </Form.Group>

          <Form.Label>Otros:</Form.Label>
          <Form.Control name="otros" defaultValue={formData.otros} as="textarea" rows={3} disabled />

          <div className="recibeOSDiv">
            <Form.Label className="recibeOS2">Recibió:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de quien recibe"
              defaultValue={formData.recibio}
              name="recibio"
              disabled
            />
            <Form.Label className="fechaOS2">Fecha de Entrada:</Form.Label>
            <Form.Control
              type="date"
              placeholder="dd/mm/aaaa"
              className="inpFecha"
              defaultValue={formData.fechaEntrada}
              name="fechaEntrada"
              disabled
            />
          </div>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    fecha: data.fecha,
    localidad: data.localidad,
    nombreDoctor: data.nombreDoctor,
    nombrePaciente: data.nombrePaciente,
    tipoDentadura: data.tipoDentadura,
    modeloSuperior: data.modeloSuperior,
    modeloInferior: data.modeloInferior,
    mordidaCera: data.mordidaCera,
    mordidaSilicon: data.mordeidaSilicon,
    sAntagonista: data.sAntagonista,
    llenadoCucharilla: data.llenadoCucharilla,
    otros: data.otros,
    recibio: data.recibio,
    fechaEntrada: data.fechaEntrada,
  };
}
