import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { eliminarAlmacenProductosLimpieza } from "../../api/almacenProductosLimpieza";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function EliminarAlmacenLP({ data, history, setShow }) {

    const dataTemp = {
        producto: data[1],
        inicial: data[2],
        entrada: data[3],
        salida: data[4],
        existencia: data[5],
        pedidoStock: data[6],
        pedidoMinimo: data[7],
        stockMinimo: data[8],
        stockMaximo: data[9],
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));
    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto
            eliminarAlmacenProductosLimpieza(data[0]).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha eliminado el producto " + data[0] + " en el almacen de productos de limpieza", dataTemp)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className="contact-form">
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <br />
                    <Form.Control
                        placeholder="Producto"
                        type="text"
                        name="producto"
                        defaultValue={formData.producto}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="inicial"
                        type="text"
                        name="inicial"
                        defaultValue={formData.inicial}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="entrada"
                        type="text"
                        name="entrada"
                        defaultValue={formData.entrada}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="salida"
                        type="text"
                        name="salida"
                        defaultValue={formData.salida}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="existencia"
                        type="text"
                        name="existencia"
                        defaultValue={formData.existencia}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="pedidoStock"
                        type="text"
                        name="pedidoStock"
                        defaultValue={formData.pedidoStock}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="pedidoMinimo"
                        type="text"
                        name="pedidoMinimo"
                        defaultValue={formData.pedidoMinimo}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="stockMinimo"
                        type="text"
                        name="stockMinimo"
                        defaultValue={formData.stockMinimo}
                        disabled
                    />
                    <br />
                    <Form.Control
                        placeholder="stockMaximo"
                        type="text"
                        name="stockMaximo"
                        defaultValue={formData.stockMaximo}
                        disabled
                    />
                    <br />
                    <label></label>
                    <input className="submit" value="Enviar" type="submit" />
                </Form>
            </div>
        </>
    );
}
function initialFormValue(data) {
    return {
        producto: data.producto,
            inicial: data.inicial,
            entrada: data.entrada,
            salida: data.salida,
            existencia: data.existencia,
            pedidoStock: data.pedidoStock,
            pedidoMinimo: data.pedidoMinimo,
            stockMinimo: data.stockMinimo,
            stockMaximo: data.stockMaximo,
    };
}
