import { Form, Dropdown, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import {
  obtenerAlmacenProductosLimpieza,
  actualizarAlmacenProductosLimpieza,
  listarAlmacenProductosLimpieza,
} from "../../api/almacenProductosLimpieza";
import queryString from "query-string";
import { registraMovimientosAPL } from "../../api/movimientosAlmacenPL";
import { TablaMovimientos } from "./tablaMovimientos";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { listarDepartamentos } from "../../api/departamentos";
import { listarProveedores } from "../../api/proveedores";
import Select from "react-select";
import { listarUnidadMedida } from "../../api/unidadesMedida";
import { LogsInformativos } from "../logs/logs";

export default function InsetarMovimientosAPL({
  history,
  setShow,
  idArticuloAPL,
}) {
  const [formData, setFormData] = useState(initialFormValue());

  const [listarUnidadesMedida, setListUnidadesMedida] = useState([]);

  const obtenerUnidadesMedida = () => {
    try {
      listarUnidadMedida()
        .then((response) => {
          const { data } = response;

          if (!listarUnidadesMedida && data) {
            setListUnidadesMedida(formatModelUnidadesMedida(data));
          } else {
            const datosUnidadesMedida = formatModelUnidadesMedida(data);
            setListUnidadesMedida(datosUnidadesMedida);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUnidadesMedida();
  }, []);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  const [listarProveedor, setListProveedor] = useState([]);

  const obtenerProveedor = () => {
    try {
      listarProveedores()
        .then((response) => {
          const { data } = response;

          if (!listarProveedor && data) {
            setListDepartamento(formatModelProveedor(data));
          } else {
            const datosProveedor = formatModelProveedor(data);
            setListProveedor(datosProveedor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerProveedor();
  }, []);

  const [listarAlmacen, setListAlmacen] = useState([]);
  const [listProdAlmacen, setListProdAlmacen] = useState([]);

  const obtenerAlmacen = () => {
    try {
      listarAlmacenProductosLimpieza()
        .then((response) => {
          const { data } = response;

          if (data && Array.isArray(data)) {
            let almacen;

            if (formData.tipo === 'Salida') {
              // Filter data when tipo is 'salida' and existenciareal > 0
              almacen = formatModelAlmacen(data.filter(item => Number(item.existencia) > 0));
            } else {
              // Use the complete data when tipo is not 'salida'
              almacen = formatModelAlmacen(data);
            }

            setListAlmacen(almacen);
            funcArrProductos(almacen);
          }
        })
        .catch((e) => {
          // Handle or log the error
          console.error("Error fetching data:", e);
        });
    } catch (e) {
      // Handle or log the error
      console.error("Error in obtenerAlmacen:", e);
    }
  };

  const funcArrProductos = (almacen) => {
    const newArray = almacen.map(({ producto, id }) => ({
      value: id + "/" + producto,
      label: producto,
    }));
    setListProdAlmacen(newArray);
  };

  useEffect(() => {
    obtenerAlmacen();
  }, [formData.tipo]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [nombreProv, setnombreProv] = useState("");

  useEffect(() => {
    const idProdAlmacen = selectedOption.split("/")[0];
    const filterID = listarAlmacen.find((data) => data.id === idProdAlmacen);
    if (filterID) {
      setnombreProv(filterID.proveedor);
    } else {
      console.log("No se encontró ningún elemento con el ID especificado");
    }
  }, [selectedOption]);

  //load
  const [loading, setLoading] = useState(true);

  const [nombreProducto, setNombreProducto] = useState("");
  const [idArticulo, setIdArticulo] = useState("");
  const [cantidadEntrada, setCantidadEntrada] = useState("");
  const [cantidadSalida, setCantidadSalida] = useState("");

  useEffect(() => {
    try {
      obtenerAlmacenProductosLimpieza(selectedOption.split("/")[0])
        .then((response) => {
          const { data } = response;
          setIdArticulo(data._id);
          setNombreProducto(data.producto == undefined ? "" : data.producto);
          setCantidadEntrada(Number(data.entrada));
          setCantidadSalida(Number(data.salida));
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            console.log("No hay internet");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, [selectedOption.split("/")[0]]);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [productosMovimiento, setProductosMovimiento] = useState([]);
  const [productosMovimiento2, setProductosMovimiento2] = useState([]);
  const [departamento, setDepartamento] = useState("");
  const [tipo, setTipo] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const agregarClas = () => {
    const temp = formData.unidadMedida.split("/");
    const nuevaClas = {
      idArticuloAPL: selectedOption.split("/")[0],
      producto: selectedOption.split("/")[1],
      departamento: tipo === "Entrada" ? nombreProv : departamento,
      tipo: tipo,
      cantidadMovimiento: cantidad,
      unidadMedida: temp[0],
      observaciones: observaciones,
    };
    setProductosMovimiento([...productosMovimiento, nuevaClas]);
    setDepartamento("");
    setCantidad("");
    setObservaciones("");
    const nuevaClas2 = {
      entrada:
        tipo == "Entrada"
          ? cantidadEntrada + (Number(formData.cantidad) * Number(temp[1]))
          : cantidadEntrada,
      salida:
        tipo == "Salida" ? cantidadSalida + (Number(formData.cantidad) * Number(temp[1])) : cantidadSalida,
    };
    setProductosMovimiento2([...productosMovimiento2, nuevaClas2]);
  };

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.tipo || !formData.cantidad) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);

        map(productosMovimiento, (registro, index) =>
          registraMovimientosAPL(registro).then((response) => {
            const { data } = response;
            //notificacion
            map(productosMovimiento2, (registro2, index2) =>
              actualizarAlmacenProductosLimpieza(
                registro.idArticuloAPL,
                registro2
              ).then((response) => {
                history({
                  search: queryString.stringify(""),
                });
              })
            );
            toast.success(data.mensaje);
            LogsInformativos("Se ha registrado el movimiento el producto " + registro.idArticuloAPL + " en el almacen de productos de limpieza", registro)
            history({
              search: queryString.stringify(""),
            });
            setLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const palabraEspecifica = "TABLILLA";

  // Convertir ambas la cadena y la palabra a minúsculas (o mayúsculas)
  const cadenaEnMinusculas = nombreProducto.toLowerCase();
  const palabraEnMinusculas = palabraEspecifica.toLowerCase();

  // Utilizar includes() en las cadenas convertidas
  const contienePalabra = cadenaEnMinusculas.includes(palabraEnMinusculas);

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const filteredOptions = listarAlmacen.filter((cat) =>
    cat.producto.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleSelectChange = (selectedValue) => {
    setFormData({
      ...formData,
      materiaPrima: selectedValue,
    });
  };

  return (
    <>
      {loading && <Load />}
      <div className="contact-form">
        <Form onSubmit={onSubmit} onChange={onChange}>
          <br />
          <Form.Label className="lblTonoOS">Movimiento:</Form.Label>
          <Form.Control
            as="select"
            placeholder="Tipo"
            name="tipo"
            defaultValue={formData.tipo}
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
          >
            <option value="">Elige una opcion</option>
            <option value="Entrada">Entrada</option>
            <option value="Salida">Salida</option>
          </Form.Control>
          {formData.tipo !== "" && (
            <>
              <Form.Label className="lblTonoOS">Materia prima:</Form.Label>
              <Select
                options={listProdAlmacen}
                isSearchable={true}
                placeholder="Seleccionar..."
                onChange={(selectedOption) => handleSeleccionChange(selectedOption)}
              />
            </>
          )}
           <br/>
          <Form.Label className="lblTonoOS">Tipo de departamento:</Form.Label>
          <Form.Control
            as="select"
            placeholder="TipoD"
            name="tipoD"
            defaultValue={formData.tipoD}
          >
            <option value="">Elige una opcion</option>
            <option value="proveedor">Proveedor</option>
            <option value="departamento">Departamento</option>
          </Form.Control>
          <br />
          {(formData.tipo === "Salida" && formData.tipoD == "departamento") && (
            <>
              <Form.Label className="lblTonoOS">Departamento:</Form.Label>
              <Form.Control
                name="departamento"
                as="select"
                placeholder="Departamento"
                //onChange={(e) => setTonoSuperior(e.target.value)}
                defaultValue={formData.departamento}
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
              >
                <option>Elige una opción</option>
                <option value="no aplica">No aplica</option>
                {map(listarDepartamento, (cat, index) => (
                  <option key={index} value={cat?.nombre}>
                    {cat?.nombre}
                  </option>
                ))}
              </Form.Control>
            </>
          )}
          {(formData.tipo === "Salida" && formData.tipoD == "proveedor") && (
            <>
              <Form.Label className="lblTonoOS">Departamento:</Form.Label>
              <Form.Control
                name="departamento"
                as="select"
                placeholder="Departamento"
                //onChange={(e) => setTonoSuperior(e.target.value)}
                defaultValue={formData.departamento}
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
              >
                <option>Elige una opción</option>
                <option value="no aplica">No aplica</option>
                {map(listarProveedor, (cat, index) => (
                  <option key={index} value={cat?.nombre}>
                    {cat?.nombre}
                  </option>
                ))}
              </Form.Control>
            </>
          )}
          {formData.tipo === "Entrada" && (
            <>
              <Form.Label className="lblTonoOS">Proveedor:</Form.Label>
              <Form.Control type="text" value={nombreProv} disabled />
            </>
          )}

          <br />
          <Form.Label className="lblTonoOS">Cantidad:</Form.Label>
          <Form.Control
            placeholder="cantidad del movimiento"
            type="text"
            name="cantidad"
            defaultValue={formData.cantidad}
            value={cantidad}
            onChange={(e) => setCantidad(e.target.value)}
          />
          <br />
          <Form.Label className="nombreProdMP">Unidad de medida:</Form.Label>
          <Form.Control
            placeholder="Unidad medida"
            as="select"
            name="unidadMedida"
            defaultValue={formData.unidadMedida}
          >
            <option>Elige una opción</option>
            {map(listarUnidadesMedida, (cat, index) => (
              <option key={index} value={cat?.nombre + "/" + cat?.conversion}>
                {cat?.nombre}
              </option>
            ))}
          </Form.Control>
          <br />
          {contienePalabra && (
            <>
              <Form.Label className="lblTonoOS">Observaciones:</Form.Label>
              <Form.Control
                placeholder="Observaciones"
                type="text"
                as="textarea"
                name="observaciones"
                defaultValue={formData.observaciones}
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              />
              <br />
            </>
          )}
          <div className="btnServiciosTabla">
            <Row>
              <Col md={{ span: 11, offset: 10 }} lg={{ span: 11, offset: 10 }}>
                <Button className="btnAddServiceTable" onClick={agregarClas}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Agregar
                </Button>
              </Col>
            </Row>
          </div>
          <TablaMovimientos
            productos={productosMovimiento}
            setProductos={setProductosMovimiento}
          />
          <label></label>
          <input className="submit" value="Enviar" type="submit" />
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    tipo: "",
    tipoD: "",
    cantidad: "",
    departamento: "",
    observaciones: "",
    materiaPrima: "",
  };
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelAlmacen(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      claveAlterna: data.claveAlterna,
      producto: data.producto,
      minimo: data.minimo,
      maximo: data.maximo,
      existencia: data.existencia,
      entrada: data.entrada,
      salida: data.salida,
      devolucion: data.devolucion,
      proveedor: data.departamento,
      existenciaReal:
        Number(data.existencia) +
        Number(data.entrada) +
        Number(data.devolucion) -
        Number(data.salida),
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelUnidadesMedida(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      conversion: data.conversion,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelProveedor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      estado: data.estado,
    });
  });
  return dataTemp;
}
