import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { registraIncidencias, obtenerNumeroIncidencia } from "../../api/incidencias";
import queryString from "query-string";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { registraEvento } from "../../api/eventos";
import { LogsInformativos } from "../logs/logs";

export default function RegistroIncidencia({ history, setShow }) {

    const [formData, setFormData] = useState(initialFormValue);
    const [signInLoading, setSignInLoading] = useState(false);

    const [departamentoUsuario, setDepartamentoUsuario] = useState("");
    const [nombreUsuario, setNombreUsuario] = useState("");

    const obtenerDatosUsuario = () => {
        try {
            obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
                .then((response) => {
                    const { data } = response;
                    setDepartamentoUsuario(data.departamento);
                    setNombreUsuario(data.nombre);
                })
                .catch((e) => {
                    if (e.message === "Network Error") {
                        //console.log("No hay internet")
                        toast.error("Conexión al servidor no disponible");
                    }
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        obtenerDatosUsuario();
    }, []);

    // Para almacenar el folio actual
    const [folioActual, setFolioActual] = useState("");

    const obtenerFolio = () => {
        try {
            obtenerNumeroIncidencia()
                .then((response) => {
                    const { data } = response;
                    // console.log(data)
                    const { noIncidencia } = data;
                    setFolioActual(noIncidencia);
                })
                .catch((e) => {
                    console.log(e);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        obtenerFolio();
    }, []);

    // Convertir la cadena en un objeto Date
    let fecha = new Date();

    // Obtener el día, mes y año
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
    let year = fecha.getFullYear();
    let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
    let minutos = fecha.getMinutes(); // Obtener los minutos

    let datosFecha = `${dia}/${mes}/${year} ${hora}:${minutos}`;

    const registraEventos = (e) => {
        try {
            setSignInLoading(true);

            const dataTemp5 = {
                fecha: datosFecha,
                folioOrden: folioActual,
                usuario: nombreUsuario,
                tipoEvento: "Advertencia",
                motivoEvento: "Se registro una incidencia en el departamento " + departamentoUsuario,
                estado: "activo",
                leido: "0",
            };
            registraEvento(dataTemp5).then((response) => {
                const { data } = response;
                console.log(data);
                LogsInformativos("Se ha registrado la incidencia " + dataTemp5.folioOrden, dataTemp5)
                history({
                    search: queryString.stringify(""),
                });
                setSignInLoading(false);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fecha || !formData.descripcion) {
            toast.warning("Completa todos los campos del formulario.");
        } else {
            setSignInLoading(true);
            try {
                const dataTemp = {
                    folio: folioActual,
                    fecha: formData.fecha,
                    descripcion: formData.descripcion,
                    departamento: departamentoUsuario,
                    comentarios: "",
                    estado: "1"
                };
                registraIncidencias(dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });

                    setSignInLoading(false);
                    registraEventos();
                    setShow(false);
                    //cancelarRegistro()
                }).catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                        setSignInLoading(false);
                    } else {
                        if (ex.response && ex.response.status === 401) {
                            const { mensaje } = ex.response.data;
                            toast.error(mensaje);
                            setSignInLoading(false);
                        }
                    }
                });
            } catch (ex) {
                toast.error("Error al iniciar sesión");
                setSignInLoading(false);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Folio: </Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="folio"
                        value={folioActual}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Fecha: </Form.Label>
                    <Form.Control
                        type="date"
                        className="emailNewUser"
                        placeholder="fecha"
                        name="fecha"
                        defaultValue={formData.fecha}
                    />
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control
                        type="text"
                        className="passNewUser"
                        placeholder="Ingrese la descripcion"
                        name="descripcion"
                        defaultValue={formData.descripcion}
                    />
                    <Form.Label className="folioOS2">
                        Departamento
                    </Form.Label>
                    <Form.Control
                        type="textt"
                        className="localidadOS"
                        name="departamento"
                        value={departamentoUsuario}
                        disabled
                        placeholder="Departamento"
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue() {
    return {
        fecha: "",
        descripcion: "",
        departamento: ""
    };
}
