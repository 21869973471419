import { Table } from "react-bootstrap";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

export const TablaFaltasTecnicas = ({ data }) => {

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

console.log(data)
  return (
    <div className="generaltablaOrdenes">
      <div className="tablaServiciosOrdenes">
        <Table striped bordered hover size="sm" className="tablaServiciosOrden">
          <thead>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>Descripcion</th>
              <th>Empleado</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody>
            {data[26].map((faltas, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {dayjs(faltas.fecha).format("LL")}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>
                    {faltas.descripcion}
                  </td>
                  <td>{faltas.empleado}</td>
                  <td>{faltas.comentarios}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

