import React from "react";
import { Navbar } from "react-bootstrap";
import Encabezado from "../../../assets/img/encabezado_formulario_2.png";
import "./Alta.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function TerminosYCondiciones() {
  const footerStyle = {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#0c1b30",
    color: "white",
    textAlign: "center",
    padding: "10px 0",
  };

  return (
    <>
      <Navbar
        className="justify-content-center"
        style={{
          backgroundColor: "#0c1b30",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Navbar.Brand>
          <img
            alt="Encabezado"
            src={Encabezado}
            width="100%"
            height="100%"
          />
        </Navbar.Brand>
      </Navbar>
      <div style={{ marginTop: "80px", marginBottom: "80px", padding: "2%" }}>
        <h1>Términos y Condiciones de Uso de la Aplicación "Herfran"</h1>
        <h2>1. Aceptación de los Términos</h2>
        <p>
          Bienvenido a "Herfran" ("nosotros", "nuestro", "la Aplicación"). Al acceder, descargar y utilizar la Aplicación, usted ("usuario", "usted", "su") acepta y se compromete a cumplir con estos Términos y Condiciones. Si no está de acuerdo con estos Términos, por favor no utilice la Aplicación.
        </p>
        <h2>2. Descripción del Servicio</h2>
        <p>
          "Herfran" es una aplicación diseñada para gestionar y optimizar los procesos operativos de laboratorios dentales. A través de la Aplicación, usted podrá realizar, entre otras funciones:
        </p>
        <ul>
          <li>Gestión de Datos del Cliente: Almacenamiento y administración de información personal y dental de los pacientes.</li>
          <li>Alta de Pedidos: Registro y seguimiento de órdenes de trabajo y pedidos para tratamientos dentales.</li>
          <li>Saldos y Facturación: Control de pagos, facturación y saldos pendientes relacionados con los servicios ofrecidos por el laboratorio.</li>
        </ul>
        <h2>3. Registro y Cuenta de Usuario</h2>
        <p>
          Para utilizar "Herfran", es necesario crear una cuenta proporcionando información veraz, completa y actualizada. Usted es responsable de mantener la confidencialidad de sus credenciales de acceso y de todas las actividades realizadas bajo su cuenta. Si detecta un uso no autorizado de su cuenta, debe notificarlo de inmediato para que tomemos las medidas necesarias.
        </p>
        <h2>4. Uso Permitido y Restricciones</h2>
        <p>
          El usuario se compromete a utilizar la Aplicación exclusivamente para fines relacionados con la gestión de laboratorios dentales y a no utilizarla para actividades ilegales, fraudulentas o que infrinjan derechos de terceros. Se prohíbe:
        </p>
        <ul>
          <li>Utilizar la Aplicación para fines diferentes a los establecidos.</li>
          <li>Modificar, reproducir, distribuir o crear trabajos derivados de la Aplicación sin autorización expresa.</li>
          <li>Acceder o intentar acceder a sistemas de la Aplicación de manera no autorizada.</li>
        </ul>
        <p>
          La empresa se reserva el derecho de suspender o cancelar la cuenta de un usuario que infrinja estos Términos.
        </p>
        <h2>5. Propiedad Intelectual</h2>
        <p>
          Todo el contenido, software, diseño y gráficos relacionados con "Herfran" son propiedad de la empresa y están protegidos por derechos de propiedad intelectual. Usted no adquiere ningún derecho sobre la Aplicación, excepto el uso limitado conforme a estos Términos y Condiciones.
        </p>
        <h2>6. Privacidad y Protección de Datos</h2>
        <p>
          "Herfran" se compromete a proteger la privacidad de los datos personales que se gestionan a través de la Aplicación, en cumplimiento con las leyes y regulaciones aplicables. Los datos del cliente y cualquier otra información personal se recopilan, procesan y almacenan únicamente para los fines específicos de la operación del laboratorio dental. Para más información, consulte nuestro Aviso de Privacidad.
        </p>
        <h2>7. Uso de la Información Recopilada</h2>
        <p>
          La información recopilada a través de "Herfran" se utiliza para:
        </p>
        <ul>
          <li>Gestionar las órdenes de trabajo y pedidos de manera eficiente.</li>
          <li>Facilitar la comunicación entre el laboratorio y sus clientes.</li>
          <li>Procesar facturación, pagos y mantener un registro actualizado de los saldos.</li>
          <li>Mejorar la experiencia del usuario mediante la optimización de los servicios.</li>
        </ul>
        <h2>8. Responsabilidad y Limitación de Responsabilidad</h2>
        <p>
          "Herfran" se ofrece "tal cual", sin garantías de ningún tipo, ya sean explícitas o implícitas. La empresa no será responsable por daños, pérdidas o inconvenientes que puedan surgir del uso o la imposibilidad de uso de la Aplicación. Usted utiliza la Aplicación bajo su propio riesgo.
        </p>
        <h2>9. Modificaciones a los Términos</h2>
        <p>
          La empresa se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. Cualquier cambio significativo será notificado a través de la Aplicación o por otros medios adecuados antes de su entrada en vigor. Su uso continuado de "Herfran" después de cualquier modificación constituirá su aceptación de los términos modificados.
        </p>
        <h2>10. Seguridad</h2>
        <p>
          La seguridad de su información es de suma importancia para nosotros. Hemos implementado medidas técnicas y organizativas para proteger sus datos contra el acceso no autorizado, pérdida o alteración. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida a través de la Aplicación.
        </p>
        <h2>11. Terminación</h2>
        <p>
          Usted puede cancelar su cuenta y dejar de utilizar "Herfran" en cualquier momento. La empresa también se reserva el derecho de terminar su cuenta si se detecta un uso indebido o en caso de incumplimiento de estos Términos.
        </p>
        <h2>12. Ley Aplicable y Jurisdicción</h2>
        <p>
          Estos Términos y Condiciones se rigen por las leyes del país o región en que la empresa tiene su sede. Cualquier disputa relacionada con estos Términos se resolverá en los tribunales competentes de dicha jurisdicción.
        </p>
        <h2>13. Acuerdo Completo</h2>
        <p>
          Estos Términos y Condiciones constituyen el acuerdo completo entre usted y la empresa en relación con el uso de "Herfran" y sustituyen cualquier acuerdo previo o contemporáneo entre las partes.
        </p>
        <h2>14. Contacto</h2>
        <p>
          Para cualquier consulta o asistencia relacionada con estos Términos y Condiciones, puede contactarnos en:
        </p>
        <p>
          Herfran Corporativo Dental SA de CV<br />
          Correo Electrónico: <a href="mailto:hcd.oficinaherfran@gmail.com">hcd.oficinaherfran@gmail.com</a><br />
          Dirección: C El Roble 38, Santa Cruz Nieto, San Juan del Rio, Queretaro, México.<br />
          Teléfono: 4272796272
        </p>
      </div>
      <div style={footerStyle}>
        <p style={{ marginBottom: 0 }}>
          <FontAwesomeIcon icon={faLocationDot} /> EL ROBLE #38, COLONIA SANTA CRUZ NIETO, CP 76804, SAN JUAN DEL RÍO, QRO.
        </p>
        <a
          href="mailto:mesadecontrol@grupodentalherfran.com"
          style={{ color: "#fff" }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
    </>
  );
}
