import React, { useEffect, useState } from "react";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Col, Form, Row } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { filtrarPorFechaYArea } from "../../api/vales";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { listarDepartamentos } from "../../api/departamentos";
import { toast } from "react-toastify";
import DynamicChart from "../administrador/Dashboard/grafica/Grafica";

const TablaMaterialesValeFiltro = () => {
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(initialFormValue());

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setDepartamentoUsuario(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);
  const [size, setSizeModal] = useState(null);

  const registroVale = (content) => {
    setTitulosModal("Registar prestamo");
    setContentModal(content);
    setShowModal(true);
  };

  const [listVales, setListVales] = useState([]);

  const obtenerVales = () => {
    try {
      if (formData.tipoFiltro == "hoy") {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        const formattedDate = year + "-" + month + "-" + day;

        filtrarPorFechaYArea(formattedDate, formattedDate, departamentoUsuario)
          .then((response) => {
            const { data } = response;
            setListVales(formatModelVales(data));
          })
          .catch((e) => {});
      } else if (formData.tipoFiltro == "ayer") {
        const hoy = new Date();
        const ayer = new Date(hoy);
        ayer.setDate(hoy.getDate() - 1);
        const año = ayer.getFullYear();
        const mes = String(ayer.getMonth() + 1).padStart(2, "0");
        const día = String(ayer.getDate()).padStart(2, "0");
        const fechaAyer = año + "-" + mes + "-" + día;

        filtrarPorFechaYArea(fechaAyer, fechaAyer, departamentoUsuario).then(
          (response) => {
            const { data } = response;
            setListVales(formatModelVales(data));
          }
        );
      } else if (formData.tipoFiltro == "semanaPasada") {
        const fechaActual = new Date();
        const year = fechaActual.getFullYear();
        const month = String(fechaActual.getMonth() + 1).padStart(2, "0");
        const day = String(fechaActual.getDate()).padStart(2, "0");
        const formattedDate = year + "-" + month + "-" + day;
        fechaActual.setDate(fechaActual.getDate() - 7);
        const año = fechaActual.getFullYear();
        const mes = String(fechaActual.getMonth() + 1).padStart(2, "0");
        const dia = String(fechaActual.getDate()).padStart(2, "0");
        const fechaHaceUnaSemana = año + "-" + mes + "-" + dia;

        filtrarPorFechaYArea(
          fechaHaceUnaSemana,
          formattedDate,
          departamentoUsuario
        ).then((response) => {
          const { data } = response;
          setListVales(formatModelVales(data));
        });
      } else if (formData.tipoFiltro == "mesPasado") {
        const fechaActual = new Date();
        const primerDiaMesPasado = new Date(
          fechaActual.getFullYear(),
          fechaActual.getMonth() - 1,
          1
        );
        const ultimoDiaMesPasado = new Date(
          fechaActual.getFullYear(),
          fechaActual.getMonth(),
          0
        );
        const primerDiaFormatted =
          primerDiaMesPasado.getFullYear() +
          "-" +
          String(primerDiaMesPasado.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(primerDiaMesPasado.getDate()).padStart(2, "0");
        const ultimoDiaFormatted =
          ultimoDiaMesPasado.getFullYear() +
          "-" +
          String(ultimoDiaMesPasado.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(ultimoDiaMesPasado.getDate()).padStart(2, "0");

        filtrarPorFechaYArea(
          primerDiaFormatted,
          ultimoDiaFormatted,
          departamentoUsuario
        ).then((response) => {
          const { data } = response;
          setListVales(formatModelVales(data));
        });
      } else if (formData.tipoFiltro == "esteMes") {
        const fecha_actual = new Date();
        const year = fecha_actual.getFullYear();
        const month = String(fecha_actual.getMonth() + 1).padStart(2, "0");
        const dia = String(fecha_actual.getDate()).padStart(2, "0");
        const formattedDate = year + "-" + month + "-" + dia;
        fecha_actual.setDate(1);
        const day = String(fecha_actual.getDate()).padStart(2, "0");
        const mes = String(fecha_actual.getMonth() + 1).padStart(2, "0");
        const año = fecha_actual.getFullYear();
        const fecha_formateada = `${año}-${mes}-${day}`;

        filtrarPorFechaYArea(
          fecha_formateada,
          formattedDate,
          departamentoUsuario
        ).then((response) => {
          const { data } = response;
          setListVales(formatModelVales(data));
        });
      } else if (formData.tipoFiltro == "ultimoMes") {
        const fechaActual = new Date();
        const year = fechaActual.getFullYear();
        const month = String(fechaActual.getMonth() + 1).padStart(2, "0");
        const dia = String(fechaActual.getDate()).padStart(2, "0");
        const formattedDate = year + "-" + month + "-" + dia;
        const fechaHaceUnMes = new Date();
        fechaHaceUnMes.setMonth(fechaActual.getMonth() - 1);
        const fechaFormateada =
          fechaHaceUnMes.getFullYear() +
          "-" +
          String(fechaHaceUnMes.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fechaHaceUnMes.getDate()).padStart(2, "0");

        filtrarPorFechaYArea(
          fechaFormateada,
          formattedDate,
          departamentoUsuario
        ).then((response) => {
          const { data } = response;
          setListVales(formatModelVales(data));
        });
      } else if (formData.tipoFiltro == "rangoPersonalizado") {
        filtrarPorFechaYArea(
          formData.fechaInicio,
          formData.fechaFin,
          departamentoUsuario
        ).then((response) => {
          const { data } = response;
          setListVales(formatModelVales(data));
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (!showModal) {
      obtenerVales();
    }
  }, [
    showModal,
    formData.tipoFiltro,
    formData.fechaInicio,
    formData.fechaFin,
    departamentoUsuario,
  ]);

  console.log(listVales);

  const entregarMAterialVale = (content) => {
    setTitulosModal("Entregar material vale");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarVale = (content) => {
    setTitulosModal("Cancelar vale");
    setContentModal(content);
    setShowModal(true);
    //setSizeModal("lg");
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "clave",
      label: "CLAVE DEL PRODUCTO",
    },
    {
      name: "producto",
      label: "PRODUCTO",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "total",
      label: "TOTAL",
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  const [total, setTotal] = useState([]);
  const [producto, setProducto] = useState([]);

  useEffect(() => {
    setTotal(listVales.map((item) => item.total));
    setProducto(listVales.map((item) => item.producto));
  }, [listVales]);

  return (
    <>
      <div>
        <div className="divSaldosClientes">
          <div className="divContainerOptions">
            <Form onChange={onChange}>
              <Row>
                <Col sm={12} md={3} lg={3} className="mb-2">
                  <Form.Label>Filtros</Form.Label>
                  <Form.Control
                    name="tipoFiltro"
                    defaultValue={formData.tipoFiltro}
                    as="select"
                    style={{ marginRight: "1%" }}
                  >
                    <option value="">Seleccionar periodo</option>
                    <option value="hoy">Hoy</option>
                    <option value="ayer">Ayer</option>
                    <option value="semanaPasada">Semana pasada</option>
                    <option value="esteMes">Este mes</option>
                    <option value="mesPasado">Mes pasado</option>
                    <option value="rangoPersonalizado">Personalizado</option>
                  </Form.Control>
                </Col>
                {tipoUsuario == "administrador" && (
                  <Col sm={12} md={3} lg={3}>
                    <Form.Label>Cliente</Form.Label>
                    <Form.Control
                      as="select"
                      value={departamentoUsuario}
                      onChange={(e) => setDepartamentoUsuario(e.target.value)}
                    >
                      <option>Seleccionar</option>
                      {listarDepartamento.map((departamento, index) => (
                        <option key={index} value={departamento.nombre}>
                          {departamento.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                )}
                {formData.tipoFiltro == "rangoPersonalizado" && (
                  <>
                    <Col sm={12} md={3} lg={3} className="mb-2">
                      <Form.Label>Fecha inicial</Form.Label>
                      <Form.Control
                        name="fechaInicio"
                        defaultValue={formData.fechaInicio}
                        type="date"
                        style={{ marginRight: "1%" }}
                      />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="mb-2">
                      <Form.Label>Fecha Final</Form.Label>
                      <Form.Control
                        name="fechaFin"
                        defaultValue={formData.fechaFin}
                        type="date"
                        style={{ marginRight: "1%" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de vales de material registrados"}
          data={listVales}
          columns={columns}
          options={options}
        />
        <div style={{textAlign:"center", marginTop:"2%"}}>
          {listVales.length > 0 ? (
            <DynamicChart
            frecuencias={total}
            etiquetas={producto}
            chartType={"bar"}
            titInfo={"Total de material"}
            bgColor={"#9c182d"}
            chartTitle={"GRAFICO DE MATERIALES SOLICITADOS"}
          />
          ):(
            <p>No hay registros para graficar</p>
          )}
          
        </div>
      </div>
      <BasicModal
        show={showModal}
        setShow={setShowModal}
        title={titulosModal}
      >
        {contentModal}
      </BasicModal>
    </>
  );
};

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelVales(data) {
  const dataTemp = [];
  data.forEach((item) => {
    dataTemp.push({
      id: item.id,
      clave: item.clave,
      producto: item.producto,
      categoria: item.categoria,
      unidadMedida: item.unidadMedida,
      total: item.total,
    });
  });
  return dataTemp;
}

function initialFormValue() {
  return {
    tipoFiltro: "",
    fechaInicio: "",
    fechaFin: "",
  };
}

export default TablaMaterialesValeFiltro;
