import React from 'react'
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const TablaMateriales = ({ productos, setProductos }) => {
  // Para eliminar productos del listado
  const removeItem = (producto) => {
    let newArray = productos;
    newArray.splice(newArray.findIndex(a => a.material === producto.material), 1);
    setProductos([...newArray]);
  }

  return (
    <div>
      <Table striped bordered hover size="sm" >
        <thead>
          <tr>
            <th>#</th>
            <th>Material</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.material}</td>
                <td data-title="Eliminar">
                    <Button
                      variant="danger"
                      onClick={() => {
                        removeItem(producto)
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  )
}
