import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { isEmailValid } from "../../utils/validations";
import { registraUsuarios } from "../../api/usuarios";
import queryString from "query-string";
import { listarDepartamentos } from "../../api/departamentos";
import { map } from "lodash";
import { LogsInformativos } from "../logs/logs";

export default function RegistroUsuario({ data, history, setShow }) {

    const dataTemp = {
        nombre: data[2] + " " + data[3] + " " + data[4],
        email: data[7],
        tipo: "doctor",
        departamento: ""
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));
    const [signInLoading, setSignInLoading] = useState(false);

    const [listarDoctor, setListDoctor] = useState([]);

    const obtenerDoctor = () => {
        try {
            listarDepartamentos()
                .then((response) => {
                    const { data } = response;

                    if (!listarDoctor && data) {
                        setListDoctor(formatModelDoctor(data));
                    } else {
                        const datosDoctor = formatModelDoctor(data);
                        setListDoctor(datosDoctor);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDoctor();
    }, []);

    const [password, setPassword] = useState("");

    const generateRandomPassword = () => {
        const charset =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
        const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
        let newPassword = "";

        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            newPassword += charset[randomIndex];
        }

        setPassword(newPassword);
    };

    useEffect(() => {
        generateRandomPassword();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!formData.nombre || !formData.email || !password || !formData.tipo) {
            toast.warning("Completa todos los campos del formulario.");
        } else {
            if (!isEmailValid(formData.email)) {
                toast.warning("Correo no valido");
            } else {
                setSignInLoading(true);
                try {
                    const dataTemp = {
                        nombre: formData.nombre,
                        email: formData.email,
                        password: password,
                        tipo: formData.tipo,
                        departamento: formData.departamento,
                        estado: "true"
                    };
                    registraUsuarios(dataTemp).then((response) => {
                        const { data } = response;
                        console.log(data)

                        toast.success(data.mensaje);

                        history({
                            search: queryString.stringify(""),
                        });
                        LogsInformativos("Se ha registrado el usuario " + dataTemp.nombre, dataTemp)
                        setSignInLoading(false);
                        setShow(false);
                        //cancelarRegistro()
                    }).catch((ex) => {
                        if (ex.message === "Network Error") {
                            toast.error("Conexión al servidor no disponible");
                            setSignInLoading(false);
                        } else {
                            if (ex.response && ex.response.status === 401) {
                                const { mensaje } = ex.response.data;
                                toast.error(mensaje);
                                setSignInLoading(false);
                            }
                        }
                    });
                } catch (ex) {
                    toast.error("Error al iniciar sesión");
                    setSignInLoading(false);
                }
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Nombre (s): <code>*</code></Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="nombre"
                        defaultValue={formData.nombre}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Correo electrónico: <code>*</code></Form.Label>
                    <Form.Control
                        type="email"
                        className="emailNewUser"
                        placeholder="ejemplo@ejemplo.com"
                        name="email"
                        defaultValue={formData.email}
                        disabled
                    />
                    <Form.Label>Contraseña: <code>*</code></Form.Label>
                    <Form.Control
                        type="password"
                        className="passNewUser"
                        placeholder="Ingrese una contraseña"
                        name="password1"
                        value={password}
                        disabled
                    />
                    <Form.Label>Confirmar contraseña: <code>*</code></Form.Label>
                    <Form.Control
                        type="password"
                        className="passConfirmNewUser"
                        placeholder="Confirme contraseña"
                        name="password2"
                        value={password}
                        disabled
                    />
                    <Form.Label>Tipo: <code>*</code></Form.Label>
                    <Form.Control
                        as="select"
                        className="passConfirmNewUser"
                        placeholder="Tipo"
                        name="tipo"
                        defaultValue={formData.tipo}
                        disabled
                    >
                        <option value="">Selecciona una opcion</option>
                        <option value="doctor">Doctor</option>
                        <option value="administrador">Administrador</option>
                        <option value="almacen">Almacen</option>
                        <option value="departamento">Departamento</option>
                        <option value="finanzas">Finanzas</option>
                        <option value="rHumanos">Recursos Humando</option>
                        <option value="cProduccion">Coordinador de Producción</option>
                        <option value="contabilidad">Contabilidad</option>
                        <option value="mesa de control">Mesa de control</option>
                    </Form.Control>
                    {formData.tipo == "departamento" && (
                        <>
                            <Form.Label className="folioOS2">
                                Departamento
                            </Form.Label>
                            <Form.Control
                                as="select"
                                className="localidadOS"
                                name="departamento"
                                defaultValue={formData.departamento}
                                placeholder="Departamento"
                            >
                                <option>Elige una opción</option>
                                {map(listarDoctor, (cat, index) => (
                                    <option
                                        key={index}
                                        value={cat?.nombre}
                                    >
                                        {" "}
                                        {cat?.nombre}
                                    </option>
                                ))}
                            </Form.Control>
                        </>
                    )}
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue(data) {
    return {
        nombre: data.nombre,
        email: data.email,
        password1: "",
        password2: "",
        tipo: data.tipo,
        departamento: ""
    };
}

function formatModelDoctor(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            folio: data.folio,
            nombre: data.nombre,
            email: data.email,
            estado: data.estado,
        });
    });
    return dataTemp;
}
