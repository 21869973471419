import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarColorimetria,
    ENDPOINTObtenerColorimetria,
    ENDPOINTListarColorimetria,
    ENDPOINTActualizarColorimetria,
    ENDPOINTEliminarColorimetria,
    ENDPOINTDeshabilitarColorimetria
} from "./endpoints";
import axios from 'axios';

export async function listarColorimetria(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarColorimetria, config);
}

export async function registraColorimetria(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarColorimetria, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerColorimetria(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerColorimetria + `/${params}`, config);
}

export async function actualizarColorimetria(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarColorimetria + `/${id}`, data, config);
}

export async function eliminarColorimetria(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarColorimetria + `/${id}`, data, config);
}

export async function actualizaDeshabilitarColorimetria(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarColorimetria + `/${id}`, data, config);
}