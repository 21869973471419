import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarCategorias,
    ENDPOINTObtenerCategorias,
    ENDPOINTListarCategorias,
    ENDPOINTActualizarCategorias,
    ENDPOINTEliminarCategorias,
    ENDPOINTDeshabilitarCategorias
} from "./endpoints";
import axios from 'axios';

export async function listarCategorias(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCategorias, config);
}

export async function registraCategorias(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarCategorias, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerCategorias(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerCategorias + `/${params}`, config);
}

export async function actualizarCategorias(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCategorias + `/${id}`, data, config);
}

export async function eliminarCategorias(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarCategorias + `/${id}`, data, config);
}

export async function actualizaDeshabilitarCategorias(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarCategorias + `/${id}`, data, config);
}