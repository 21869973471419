import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actualizaDeshabilitarRecursosHumanos } from "../../api/recursosHumanos";
import queryString from "query-string";
import { Load } from "../load/load";
import { LogsInformativos } from "../logs/logs";

export default function BajaEmpleado({ data, history, setShow }) {
  const idDocumental = data[0];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const dataTemp = {
        estado: data[19] == "false" ? "true" : "false"
      } 

      actualizaDeshabilitarRecursosHumanos(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        LogsInformativos("Se ha eliminado el empleado " + idDocumental)
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit}>
        <div style={{textAlign: "center", marginBottom: "1%"}}>
        {data[19] == "true" && (
          <>
            <h3>¿Seguro en dar de baja al empleado {data[1]}?</h3>
            </>
          )}
          {data[19] == "false" && (
          <>
            <h3>¿Seguro en habilitar al empleado {data[1]}?</h3>
            </>
          )}
          </div>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}
