import {
  faCartPlus,
  faHashtag,
  faTrash,
  faArrowRight,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { listarProveedores } from "../../api/proveedores";
import Select from "react-select";
import { toast } from "react-toastify";
import { listarProductosAgotandose, listarAlmacenMateriasPrimasNombre, totalMateriasPrimas } from "../../api/almacenMateriasPrimas";
import { listarDepartamentos } from "../../api/departamentos";
import {
  obtenerNumeroRequisicionCompras,
  registraRequisicionCompras,
} from "../../api/compras";
import { LogsInformativos } from "../logs/logs";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MUIDataTable from "mui-datatables";

const Registro = ({ setShow }) => {

  const [nombreProd, setNombreProd] = useState("");

  const [listarProducto, setListProducto] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noTotalProductos, setNoTotalProductos] = useState(1);

  const obtenerProductos = (nombre) => {
    try {
      totalMateriasPrimas()
        .then((response) => {
          const { data } = response;
          setNoTotalProductos(data);
        })
        .catch((e) => {
          console.log(e);
        });

      listarAlmacenMateriasPrimasNombre(nombre, page, rowsPerPage)
        .then((response) => {
          const { data } = response;
          console.log(data)
          if (!listarProducto && data) {
            setListProducto(formatModelProductos(data));
            console.log(formatModelProductos(data))
          } else {
            const datosProducto = formatModelProductos(data);
            console.log(formatModelProductos(data))
            setListProducto(datosProducto);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const handleChangePage = (page) => {
    // console.log("Nueva pagina "+ newPage)
    setPage(page);
  };

  const handleChangeRowsPerPage = (newPerPage) => {
    // console.log("Registros por pagina "+ parseInt(event.target.value, 10))
    setRowsPerPage(newPerPage);
    //setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (nombreProd != "") {
      obtenerProductos(nombreProd);
    }
  }, [nombreProd, page, rowsPerPage]);

  const [departamento, setDepartamento] = useState("");
  const [solicita, setSolicita] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [selectedProd, setSelectedProd] = useState(null);
  const handleSeleccionChangeProd = (option) => {
    setSelectedProd(option.value);
  };

  const [cantidadProd, setCantidadProd] = useState("");

  useEffect(() => {
    if (selectedProd != null) {
      setCantidadProd(selectedProd.split("|")[4]);
    } else {
      setCantidadProd("");
    }
  }, [selectedProd]);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listProveedores, setListProveedores] = useState([]);

  const obtenerProveedores = () => {
    try {
      listarProveedores()
        .then((response) => {
          const { data } = response;
          if (!listProveedores && data) {
            setListProveedores(formatModelProveedores(data));
          } else {
            setListProveedores(formatModelProveedores(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarProd, setListarProd] = useState([]);

  const obtenerProd = () => {
    try {
      listarProductosAgotandose()
        .then((response) => {
          const { data } = response;
          if (!listarProd && data) {
            setListarProd(formatModelProds(data));
          } else {
            setListarProd(formatModelProds(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroRequisicionCompras()
        .then((response) => {
          const { data } = response;
          const { noCompra } = data;
          setFolioActual(noCompra);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setDepartamento(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
    obtenerProveedores();
    obtenerProd();
    obtenerDepartamento();
    obtenerDatosUsuario();
  }, []);

  console.log(listarProd)

  const [departamentosUnicos, setDepartamentosUnicos] = useState([]);

  useEffect(() => {
    const departamentos = listarProd.reduce((departamentos, producto) => {
      if (!departamentos.includes(producto.departamento)) {
        departamentos.push(producto.departamento);
      }
      return departamentos;
    }, []);
    console.log("🚀 ~ departamentos ~ listarProd:", listarProd)
    console.log("🚀 ~ departamentos ~ departamentos:", departamentos)
    setDepartamentosUnicos(departamentos);
  }, [listarProd]);

  const [listaProveedoresFil, setListaProveedoresFil] = useState([]);

  useEffect(() => {
    const proveedoresFiltrados = listProveedores.filter((proveedor) =>
      departamentosUnicos.includes(proveedor.nombre)
    );

    console.log("🚀 ~ useEffect ~ listProveedores:", listProveedores);
    console.log("🚀 ~ useEffect ~ proveedoresFiltrados:", proveedoresFiltrados);
    setListaProveedoresFil(proveedoresFiltrados);
  }, [departamentosUnicos, listProveedores]);

  const [arrProductos, setArrProductos] = useState([]);
  const [provSel, setProvSel] = useState("");
  const [listarProductoFil, setListProductoFil] = useState([]);

  /***********LLENADO DE TABLA**********/
  useEffect(() => {
    if (provSel != "") {
      console.log(listarProd);
      const filterProds = listarProducto.filter(
        (data) => data.departamento === provSel.split("/")[1]
      );
      setListProductoFil(formatModelProductos(filterProds));
      console.log(formatModelProductos(filterProds));
    }
  }, [provSel, nombreProd]);
  /***********FIN LLENADO**********/

  /***********TABLA PROD**********/
  const handleQuit = (index) => {
    const newArray = [...productosAgregados];
    newArray.splice(index, 1);
    setProductosAgregados(newArray);
  };

  /***********TABLA PROD**********/

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;

    return `${year}-${mes}-${dia}`;
  };

  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    obtenerFechaActual()
  );

  const registraReq = (e) => {
    e.preventDefault();
    if (!solicita) {
      toast.warning("Completa los campos");
    } else {
      if (productosAgregados.length === 0) {
        toast.warning("No se han agregado productos");
      } else {
        try {
          const dataTemp = {
            folio: folioActual,
            fecha: fechaSeleccionada,
            solicito: solicita,
            departamento: departamento,
            proveedor: provSel.split("/")[1],
            folioProveedor: provSel.split("/")[0],
            productos: productosAgregados,
            observaciones: observaciones,
            estado: "true",
          };
          registraRequisicionCompras(dataTemp).then((response) => {
            const { data } = response;
            LogsInformativos(
              "Se ha registrado la requisicion " + dataTemp.folio,
              dataTemp
            );
            toast.success(data.mensaje);
            setShow(false);
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  /************************************************ */
  const [showModal, setShowModal] = useState(false);
  const [cantidadTemp, setCantidadTemp] = useState(0);
  const [productoIndex, setProductoIndex] = useState(-1);
  const [productoNom, setProductoNom] = useState("");

  const handleOpenModal = (cantidad, index, nombre) => {
    setCantidadTemp(cantidad);
    setProductoIndex(index);
    setProductoNom(nombre);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModificarCantidad = () => {
    // Actualizar la cantidad en el arreglo arrProductos
    const updatedProductos = [...productosAgregados]; // Clonar el arreglo original
    updatedProductos[productoIndex].cantidad = cantidadTemp; // Actualizar la cantidad en el producto específico
    // Aquí puedes hacer una llamada a una función para enviar los datos actualizados al servidor, si es necesario
    // Luego, actualiza el estado y cierra el modal
    setProductosAgregados(updatedProductos);
    handleCloseModal();
  };
  /************************************************ */

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "clave",
      label: "CLAVE",
    },
    {
      name: "producto",
      label: "MATERIAL",
    },
    {
      name: "categoria",
      label: "CATEGORIA",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "existencia",
      label: "STOCK ACTUAL",
    },
    {
      name: "minimo",
      label: "CANTIDAD",
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                className="btnAddTables"
                onClick={() => handleAgregar(tableMeta.rowData)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    responsive: isSmOrDown && "standard",
    selectableRows: "none",
    pagination: true,
    serverSide: true,
    search: false, // Oculta la barra de búsqueda
    download: false, // Oculta el botón de descarga
    print: false, // Oculta el botón de impresión
    viewColumns: false,
    filter: false,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onChangePage: handleChangePage,
    count: listarProductoFil.length,
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        //columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
    },
  };

  const [productosAgregados, setProductosAgregados] = useState([]);

  const handleAgregar = (producto) => {
    const producto2 = {
      id: producto[0],
      clave: producto[1],
      producto: producto[2],
      categoria: producto[3],
      unidadMedida: producto[4],
      existencia: producto[5],
      cantidad: producto[6]
    };
    setProductosAgregados([...productosAgregados, producto2]);
  };

  return (
    <>
      <div style={{ padding: "2%" }}>
        <Row>
          {/* Parte superior: formulario y tabla */}
          <Col sm={12} md={6}>
            <div style={{ padding: "1%" }}>
              <Form>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Folio: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Folio de OC"
                      value={folioActual}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Proveedor:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Select
                      options={listProveedores}
                      isSearchable={true}
                      placeholder="Seleccionar..."
                      onChange={(selectedOption) =>
                        handleSeleccionChange(selectedOption)
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Solicita: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nombre de quien solicita"
                      value={solicita}
                      onChange={(e) => setSolicita(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Departamento:</Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      value={departamento}
                      placeholder="Departamento del usuario"
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Proveedor: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      as="select"
                      value={provSel}
                      onChange={(e) => setProvSel(e.target.value)}
                    >
                      <option value="">Seleccionar</option>
                      {listProveedores.map((proveedor, index) => (
                        <option
                          key={index}
                          value={proveedor.folio + "/" + proveedor.nombre}
                        >
                          {proveedor.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Observaciones:</Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Observaciones de requisición"
                      value={observaciones}
                      onChange={(e) => setObservaciones(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Material: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa Material a buscar 🔍"
                      value={nombreProd}
                      onChange={(e) => setNombreProd(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Cantidad:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <div className="divCantidadButtonVale">
                      <Form.Control
                        type="text"
                        placeholder="Cantidad a solicitar"
                      />
                      &nbsp;
                      <Button variant="success">
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>

          <Col sm={12} md={6}>
            <div style={{ padding: "1%" }}>
              <h4 style={{ textAlign: "center" }}>Productos agregados</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Producto</th>
                    <th>Clave</th>
                    <th>Unidad de medida</th>
                    <th>Stock actual</th>
                    <th>Cantidad</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {productosAgregados.map((producto, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{producto.producto}</td>
                      <td>{producto.clave}</td>
                      <td>{producto.unidadMedida}</td>
                      <td>{producto.existencia}</td>
                      <td>{producto.cantidad}</td>
                      <td>
                        <Button
                          variant="success"
                          onClick={() =>
                            handleOpenModal(
                              producto.cantidad,
                              index,
                              producto.producto
                            )
                          }
                          title="Modificar cantidad"
                        >
                          <FontAwesomeIcon icon={faHashtag} />
                        </Button>
                        &nbsp;
                        <Button variant="danger" onClick={() => handleQuit(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        {/* Parte inferior: productos agregados */}
        <Row>
          <Col sm={12}>
            <div style={{ padding: "1%" }}>
              <h4 style={{ textAlign: "center" }}>Productos encontrados</h4>
              <MUIDataTable
                data={listarProductoFil}
                columns={columns}
                options={options}
              />
            </div>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registraReq}>
            <FontAwesomeIcon icon={faCartPlus} />
            &nbsp;Registrar
          </Button>
        </div>
        {/* Modal */}
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Modificar Cantidad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formCantidad">
              <h4 style={{ textAlign: "center", fontStyle: "italic" }}>
                {productoNom}
              </h4>
              <Form.Label>Nueva Cantidad:</Form.Label>
              <Form.Control
                type="number"
                value={cantidadTemp}
                onChange={(e) => setCantidadTemp(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleModificarCantidad}>
              Actualizar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

function formatModelProveedores(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}
function formatModelUM(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelProds(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      departamento: data.departamento,
      existencia: data.existencia,
      maximo: data.maximo,
      minimo: data.minimo,
      producto: data.producto,
      unidadMedida: data.unidadMedida,
      estado: data.estado,
      ubicacion: data.ubicacion,
    });
  });
  return dataTemp;
}

function formatModelProdsFil(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data.id,
      clave: data.clave,
      departamento: data.departamento,
      existencia: data.existencia,
      minimo: data.minimo,
      producto: data.producto,
      unidadMedida: data.unidadMedida,
      categoria: data.categoria
    });
  });
  return dataTemp;
}

function formatModelProductos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      producto: data.producto,
      categoria: data.categoria,
      unidadMedida: data.unidadMedida,
      minimo: data.minimo,
      existencia: data.existencia,
      departamento: data.departamento,
    });
  });
  return dataTemp;
}

export default Registro;
