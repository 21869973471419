import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { listarCursos } from "../../api/cursos";
import { registraCapacitacionesEmpleados } from "../../api/capacitacionesEmpleados";
import queryString from "query-string";
import { map } from "lodash";
import { LogsInformativos } from "../logs/logs";

export default function RegistroCapacitacion({ data, history, setShow }) {

    const [formData, setFormData] = useState(initialFormValue);
    const [signInLoading, setSignInLoading] = useState(false);

    const [listarCurso, setListCurso] = useState([]);

    const obtenerCurso = () => {
        try {
            listarCursos()
                .then((response) => {
                    const { data } = response;

                    if (!listarCurso && data) {
                        setListCurso(formatModelCursos(data));
                    } else {
                        const datosCursos = formatModelCursos(data);
                        setListCurso(datosCursos);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerCurso();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!formData.tipo || !formData.fecha) {
            toast.warning("Completa todos los campos del formulario.");
        } else {
            setSignInLoading(true);
            try {
                const dataTemp = {
                    idEmpleado: data[0],
                    empleado: data[1],
                    idCurso: formData.tipo.split("/")[0],
                    curso: formData.tipo.split("/")[1],
                    estado: "true"
                };
                registraCapacitacionesEmpleados(dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha registrado la capacitacion para el curso " + dataTemp.curso + "al empleado " + dataTemp.empleado, dataTemp)
                    setSignInLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                }).catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                        setSignInLoading(false);
                    } else {
                        if (ex.response && ex.response.status === 401) {
                            const { mensaje } = ex.response.data;
                            toast.error(mensaje);
                            setSignInLoading(false);
                        }
                    }
                });
            } catch (ex) {
                toast.error("Error al iniciar sesión");
                setSignInLoading(false);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosAcceso">
                    <Form.Label>Tipo: </Form.Label>
                    <Form.Control
                        as="select"
                        className="tipo"
                        placeholder="tipo"
                        name="tipo"
                        defaultValue={formData.tipo}
                    >
                        <option>Elige una opción</option>
                        {map(listarCurso, (cat, index) => (
                            <option key={index} value={cat?.id + "/" + cat?.nombre}>
                                {cat?.nombre}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Label>Fecha</Form.Label>
                    <Form.Control
                        type="date"
                        className="fecha"
                        placeholder="Fecha"
                        name="fecha"
                        defaultValue={formData.fecha}
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue() {
    return {
        nombre: "",
        tipo: "",
        descripcion: "",
        fecha: "",
    };
}

function formatModelCursos(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            nombre: data.nombre,
            tipo: data.tipo,
            descripcion: data.descripcion,
            fecha: data.fecha,
            estado: data.estado,
        });
    });
    return dataTemp;
}

