import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { Load } from "../load/load";
import { actualizarServicios } from "../../api/servicios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from "../logs/logs";

export default function ActualizarPrioridad({ data, history, setShow }) {
  console.log(data);
  const idDocumental = data[0];

  const [formData, setFormData] = useState(initialFormValue());
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [fechaNueva, setFechaNueva] = useState(dia + "/" + mes + "/" + year);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.prioridad == "") {
      toast.warning("Selecciona una opción válida");
    } else {
      try {
        setLoading(true);

        const dataTemp = {
          prioridad: formData.prioridad,
        };

        actualizarServicios(idDocumental, dataTemp).then((response) => {
          const { data } = response;
          console.log(data);
          LogsInformativos("Se ha actualizado la prioridad de la orden de trabajo " + idDocumental, dataTemp)
          toast.success(data.mensaje);

          history({
            search: queryString.stringify(""),
          });
          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit} onChange={onChange}>
          <div className="divSubmit">
            <Row>
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Prioridad:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  name="prioridad"
                  defaultValue={formData.prioridad}
                  as="select"
                >
                  <option value="">Selecciona una opcion</option>
                  <option value="alta">Alta</option>
                  <option value="media">Media</option>
                  <option value="baja">Baja</option>
                </Form.Control>
              </Col>
            </Row>
            <div style={{ marginTop: "2%" }}>
              <Button variant="warning" onClick={onSubmit}>
                <FontAwesomeIcon icon={faRefresh} /> Actualizar
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    prioridad: "",
  };
}
