import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  listarAsistenciaDia,
  listarDepartamentosAsistencia,
} from "../../api/asistencia";
import { Badge, Form, Col, Row, Button } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RegistrarAsistencia from "./RegistroAsistencia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

function TblAsistencia(props) {
  const { location, history } = props;

  dayjs.locale("es");
  dayjs.extend(localizedFormat);

  // Obtener la fecha de hoy
  const today = new Date();

  // Obtener el año, mes y día por separado
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // El mes comienza desde 0
  const day = String(today.getDate()).padStart(2, "0");

  // Crear la cadena de fecha en el formato deseado
  const formattedDate = `${year}-${month}-${day}`;

  const [fecha, setFecha] = useState(formattedDate);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setDepartamentoUsuario(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      if (tipoUsuario == "administrador") {
        listarAsistenciaDia(fecha)
          .then((response) => {
            const { data } = response;

            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
          })
          .catch((e) => {});
      } else if (tipoUsuario == "departamento") {
        listarDepartamentosAsistencia(departamentoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
          })
          .catch((e) => {});
      }
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location, departamentoUsuario, fecha]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroAsistencia = (content) => {
    setTitulosModal("Registrar asistencia");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "empleado",
      label: "NOMBRE DEL EMPLEADO",
    },
    {
      name: "usuario",
      label: "DEPARTAMENTO",
    },
    {
      name: "fecha",
      label: "HORA DE REGISTRO",
      options: {
        customBodyRender: (value) => {
          const fecha = value;

          return (
            <>
                {dayjs(fecha).format('h:mm A')}
            </>
          );
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "1" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Asistencia de empleados {dayjs(fecha).format("LL")}</h1>
      <div className="mr-3 ml-3">
        <Row>
          <Col sm={12} md={{ span: 4, offset: 2 }} lg={{ span: 4, offset: 2 }}>
            <Form.Label>Selecciona un dia:</Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Button
              className="btnAddTables"
              onClick={() =>
                registroAsistencia(
                  <RegistrarAsistencia
                    history={history}
                    setShowModal={setShowModal}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
          </Col>
        </Row>
      </div>

      <div className="divTabla">
        <MUIDataTable
          title={"Listado de asistencias de empleados registrados"}
          data={listarOrdenes}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      empleado: data.empleado,
      usuario: data.usuario,
      tipo: data.tipo,
      estado: data.estado,
      fecha: data.createdAt,
    });
  });
  return dataTemp;
}

export default withRouter(TblAsistencia);
