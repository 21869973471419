import React, { useEffect, useState } from "react";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import RegistroMantenimiento from "./RegistroMantenimiento";
import { Button } from "react-bootstrap";
import { Load } from "../../load/load";
import Calendario from '../../calendario/calendario';
import { eventsMantenimiento } from "../../../api/mantenimiento";
import { withRouter } from '../../../utils/withRouter';

function ProgramaMantenimiento(props) {
  const { location, history } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [eventosIniciales, setEventosIniciales] = useState([]);

  const obtenerEventos = () => {
    try {
      eventsMantenimiento()
        .then((response) => {
          const { data } = response;

          setEventosIniciales(data);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEventos();
  }, [location]);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroMantenimiento = (content) => {
    setTitulosModal("Registar mantenimiento de máquina");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <>
      {loading && <Load />}
      <h1>Programa de mantenimiento de máquinas</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroMantenimiento(
                <RegistroMantenimiento
                  setShow={setShowModal}
                  history={history}
                />
              )
            }
          >
            <FontAwesomeIcon icon={faCalendarPlus} /> Registrar
          </Button>
        </div>
      </div>
      <Calendario
        eventosIniciales={eventosIniciales}
      />
      <div className="divTabla"></div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
};

export default withRouter(ProgramaMantenimiento);