import React, { useEffect, useState } from "react";
import {
  actualizaEstadoPermisos,
  listarPermisos,
} from "../../../../api/permisos";
import { Form, Table } from "react-bootstrap";
import { LogsInformativos } from "../../../logs/logs";
import { toast } from "react-toastify";

const Permisos = ({ setShow }) => {
  const [listaPermisos, setListaPermisos] = useState([]);

  const consultaPermisos = () => {
    try {
      listarPermisos()
        .then((response) => {
          const { data } = response;
          if (!listaPermisos && data) {
            setListaPermisos([]);
          } else {
            setListaPermisos(data);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    consultaPermisos();
  }, []);

  const handleSwitchChange = (id, status) => {
    try {
      const dataTemp = {
        estado: status ? false : true,
      };
      actualizaEstadoPermisos(id, dataTemp).then((response) => {
        const { data } = response;
        LogsInformativos("Se ha concedido el acceso a un modulo ", dataTemp);
        if (status) {
          toast.warning("Se ha revocado el permiso al módulo");
        } else {
          toast.success("Se ha otorgado el permiso al módulo");
        }
        consultaPermisos();
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Table>
        <thead>
          <th>No.</th>
          <th>Módulo</th>
          <th>Tipo de usuario</th>
          <th>Status</th>
        </thead>
        <tbody>
          {listaPermisos.map((permiso, index) => (
            <tr>
              <td>{index + 1}</td>
              <td style={{ textTransform: "capitalize" }}>{permiso.modulo}</td>
              <td style={{ textTransform: "capitalize" }}>
                {permiso.tipoUsuarios}
              </td>
              <td>
                <Form.Check
                  type="switch"
                  id={`custom-switch-${permiso._id}`} // Asigna un ID único para cada switch
                  label={permiso.estado ? "Concedido" : "Negado"}
                  checked={permiso.estado}
                  onChange={() =>
                    handleSwitchChange(permiso._id, permiso.estado)
                  } // Agrega una función para manejar el cambio
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Permisos;
