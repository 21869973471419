import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarCargos,
    ENDPOINTObtenerCargos,
    ENDPOINTListarCargos,
    ENDPOINTActualizarCargos,
    ENDPOINTEliminarCargos,
    ENDPOINTDeshabilitarCargos
} from "./endpoints";
import axios from 'axios';

export async function listarCargos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCargos, config);
}

export async function registraCargos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarCargos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerCargos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerCargos + `/${params}`, config);
}

export async function actualizarCargos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCargos + `/${id}`, data, config);
}

export async function eliminarCargos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarCargos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarCargos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarCargos + `/${id}`, data, config);
}