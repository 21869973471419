import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { listarEvaluaciones360 } from "../../api/evaluaciones360";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { Badge } from "react-bootstrap";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblVerificacion(props) {
    const { location, history } = props;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const [listarDoctor, setListDoctor] = useState([]);

    const obtenerDoctor = () => {
        try {
            listarEvaluaciones360()
                .then((response) => {
                    const { data } = response;

                    if (!listarDoctor && data) {
                        setListDoctor(formatModelDoctor(data));
                    } else {
                        const datosDoctor = formatModelDoctor(data);
                        setListDoctor(datosDoctor);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDoctor();
    }, [location]);

    // Configurando animacion de carga
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);

    const cargarDatos = () => {
        const timeout = setTimeout(() => {
            setRows(listarDoctor);
            setPending(false);
        }, 0);
        return () => clearTimeout(timeout);
    };

    useEffect(() => {
        cargarDatos();
    }, []);

    const columns = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "presion",
            label: "PRESION LLANTAS",
        },
        {
            name: "condicion",
            label: "CONDICION LLANTAS",
        },
        {
            name: "aceiteMotor",
            label: "ACEITE DEL MOTOR",
        },
        {
            name: "liquidoFrenos",
            label: "LIQUIDO DE LOS FRENOS",
        },
        {
            name: "refrigeranteMotor",
            label: "REFRIGERANTE DEL MOTOR",
        },
        {
            name: "liquidoTransmision",
            label: "LIQUIDO TRANSMISION",
        },
        {
            name: "estadoPastillas",
            label: "ESTADOD DE LAS PASTILLAS DEL FRENO",
        },
        {
            name: "estadoDiscos",
            label: "ESTADO DE LOS DISCOS DEL FRENO",
        },
        {
            name: "estadoLucesFrenos",
            label: "ESTADO DE LAS LUCES DEL FRENO",
        },
        {
            name: "estadoLucesAtras",
            label: "ESTADO DE LAS LUCES DE ATRAS",
        },
        {
            name: "estadoLucesTraseras",
            label: "ESTADO DE LAS LUCES TRASERAS",
        },
        {
            name: "estadoBateria",
            label: "ESTADO DE LA BATERIA",
        },
        {
            name: "licenciaConducir",
            label: "LICENCIA DE CONDUCIR",
        },
        {
            name: "tarjetaRegistro",
            label: "TARJETA DE REGISTRO",
        },
        {
            name: "tarjetaSeguro",
            label: "TARJETA DE SEGURO",
        },
        {
            name: "gato",
            label: "GATO HIDRAULICO",
        },
        {
            name: "llave",
            label: "LLAVE PARA LLANTAS",
        },
        {
            name: "llantaRepuesto",
            label: "LLANTA DE REPUESTO",
        },
        {
            name: "botiquin",
            label: "BOTIQUIN DE EMERGENCIAS",
        },
        {
            name: "nivelCombustible",
            label: "NIVEL DEL COMBUSTIBLE",
        },
        {
            name: "estadoGPS",
            label: "ESTADO DEL GPS",
        },
        {
            name: "estadoLimpiaparabrisas",
            label: "ESTADO DEL LIMPIAPARABRISAS",
        },
        {
            name: "nivelDeposito",
            label: "NIVEL DEL DEPOSITO DEL LIMPIAPARABRISAS",
        },
        {
            name: "estado",
            label: "STATUS",
            options: {
                customBodyRender: (value) => {
                    const estado = value;

                    let estiloTexto = "";
                    let estadoTexto = "";

                    if (estado == "true") {
                        estiloTexto = "activo";
                        estadoTexto = "Activo";
                    } else {
                        estiloTexto = "inhabilitado";
                        estadoTexto = "Inhabilitado";
                    }

                    return <>
                        <Badge
                            bg={estado == "true" ? "success" : "danger"}
                        //className="estado"
                        >
                            {estiloTexto}
                        </Badge>
                    </>;
                },
            },
        },
    ];

    const theme = useTheme();
    const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

    const options = {
        filterType: "checkbox",
        responsive: isSmOrDown && 'standard',
        selectableRows: isSmOrDown ? 'none' : 'multiple',
        customBodyRender: (value) => <div style={{ textAlign: 'center', textTransform:"capitalize" }}>{value}</div>,
        textLabels: {
            body: {
                noMatch: "No se encontraron registros",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `ordenar por ${column.label}`
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtros",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "RESTAURAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Show/Hide Table Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
            },
        }
    };

    return (
        <>
            {loading && <Load />}
            <h1>Evaluaciones 360</h1>
            <div className="divTabla">
                <MUIDataTable
                    title={"Lista de evaluaciones 360 registrados"}
                    data={listarDoctor}
                    columns={columns}
                    options={options}
                />
            </div>
        </>
    );
}

function formatModelDoctor(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            presion: data.neumaticos.presion,
            condicion: data.neumaticos.condicion,
            aceiteMotor: data.fluidos.aceiteMotor,
            liquidoFrenos: data.fluidos.liquidoFrenos,
            refrigeranteMotor: data.fluidos.refrigeranteMotor,
            liquidoTransmision: data.fluidos.liquidoTransmision,
            estadoPastillas: data.frenos.estadoPastillas,
            estadoDiscos: data.frenos.estadoDiscos,
            estadoLucesFrenos: data.luces.estadoLucesFrenos,
            estadoLucesAtras: data.luces.estadoLucesAtras,
            estadoLucesTraseras: data.luces.estadoLucesTraseras,
            estadoBateria: data.bateria.estadoBateria,
            licenciaConducir: data.documentacion.licenciaConducir,
            tarjetaRegistro: data.documentacion.tarjetaRegistro,
            tarjetaSeguro: data.documentacion.tarjetaSeguro,
            gato: data.equipoEmergencia.gato,
            llave: data.equipoEmergencia.llave,
            llantaRepuesto: data.equipoEmergencia.llantaRepuesto,
            botiquin: data.equipoEmergencia.botiquin,
            nivelCombustible: data.combustible.nivelCombustible,
            estadoGPS: data.gps.estado,
            estadoLimpiaparabrisas: data.limpiaparabrisas.estadoLimpiaparabrisas,
            nivelDeposito: data.limpiaparabrisas.nivelDeposito,
            estado: data.estado,
        });
    });
    return dataTemp;
}

export default withRouter(TblVerificacion);
