// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/upload-logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archivo {
  display: block;
  top: -40px;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: #f0f0f0;
  cursor: pointer;
}
  
  .archivo img {
    width: 100%;
    height: 100px;
    object-fit: fill;
    border-radius: 50%;
  }
`, "",{"version":3,"sources":["webpack://./src/components/dropzone/Dropzone.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,yDAAyD;EACzD,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;AACjB;;EAEE;IACE,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":[".archivo {\n  display: block;\n  top: -40px;\n  margin: auto;\n  align-items: center;\n  justify-content: center;\n  background-image: url(\"../../assets/img/upload-logo.png\");\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  overflow: hidden;\n  background-color: #f0f0f0;\n  cursor: pointer;\n}\n  \n  .archivo img {\n    width: 100%;\n    height: 100px;\n    object-fit: fill;\n    border-radius: 50%;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
