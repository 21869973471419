import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarCoordenadas,
    ENDPOINTObtenerCoordenadas,
    ENDPOINTListarCoordenadas,
    ENDPOINTActualizarCoordenadas,
    ENDPOINTEliminarCoordenadas,
    ENDPOINTDeshabilitarCoordenadas
} from "./endpoints";
import axios from 'axios';

export async function listarCoordenadas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCoordenadas, config);
}

export async function registraCoordenadas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarCoordenadas, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerCoordenadas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerCoordenadas + `/${params}`, config);
}

export async function actualizarCoordenadas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCoordenadas + `/${id}`, data, config);
}

export async function eliminarCoordenadas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarCoordenadas + `/${id}`, data, config);
}

export async function actualizaDeshabilitarCoordenadas(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarCoordenadas + `/${id}`, data, config);
}