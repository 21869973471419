import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row, Image } from "react-bootstrap";
import { registraGastosMensajero } from "../../../api/gastosMensajero";
import Dropzone from "../../dropzone/Dropzone";
import { toast } from "react-toastify";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import queryString from "query-string";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { Load } from "../../load/load";
import { LogsInformativos } from "../../logs/logs";

export default function Gastos({ history, setShow }) {
  const [fechaDia, setfechaDia] = useState("");

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [formData, setFormData] = useState(initialFormValue());

  const obtenerFechaActual = () => {
    let fecha = new Date();

    // Obtener el día, mes y año
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
    let year = fecha.getFullYear();
    let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds(); // Obtener los minutos
    let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia}`;
  

    return datosFecha;
  };

  useEffect(() => {
    setfechaDia(obtenerFechaActual());
  }, []);

  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!imagenSeleccionada || !formData.concepto || !formData.cantidad || !formData.comentarios) {
      toast.warning("Completa el formulario");
    } else {
      try {
        subeArchivosCloudinary(imagenSeleccionada, "mensajeros").then(response => {
          const { data } = response;
          setLoading(true);

          const dataTemp = {
            fecha: fechaDia,
            concepto: formData.concepto,
            cantidad: formData.cantidad,
            comentarios: formData.comentarios,
            imagen: data.secure_url,
            correoMensajero: correoUsuario,
            estado: "true",
          };
          registraGastosMensajero(dataTemp).then((response) => {
            const { data } = response;
            LogsInformativos("Se ha registrado el gasto del mensajero con correo " + dataTemp.correoMensajero, dataTemp)
            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });

            setLoading(false);
            setShow(false);
            //cancelarRegistro()
          });
        }).then(e => {
          console.log(e)
        })
      } catch (e) {
        console.log(e);
      }

    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
    {loading && <Load />}
      <div style={{ padding: "1rem" }}>
        <div className="imagenPrincipal">
          <h4 className="textoImagenPrincipal">Sube tu imagen <code>*</code></h4>
          <div title="Seleccionar imagen de la categoría" className="imagenProducto">
            <Dropzone
              setImagenFile={setImagenSeleccionada}
            />
          </div>
        </div>
        <h2 style={{ textAlign: "center", marginBottom: "2%", fontWeight: "bold", fontStyle: "italic" }}>Gastos</h2>
        <Form onSubmit={onSubmit} onChange={onChange}>
          <Row className="mt-2">
            <Col sm={12} md={12} lg={12}>
              <Form.Label>Fecha: <code>*</code></Form.Label>
              <Form.Control type="text" value={fechaDia} disabled />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={12} md={4} lg={4}>
              <Form.Label>Cantidad: <code>*</code></Form.Label>
              <Form.Control type="text" name="cantidad" defaultValue={formData.cantidad} />
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Label>Concepto del gasto: <code>*</code></Form.Label>
              <Form.Control type="text" name="concepto" defaultValue={formData.concepto} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={12} md={12} lg={12}>
              <Form.Label>Comentarios:</Form.Label>
              <Form.Control type="text" name="comentarios" defaultValue={formData.comentarios} />
            </Col>
          </Row>
          <div style={{ textAlign: "center", marginTop: "3%" }}>
            <label></label>
            <div className="divSubmit">
              <input className="submit" value="Enviar" type="submit" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

function initialFormValue() {
  return {
    concepto: "",
    cantidad: "",
    comentarios: "",
  };
}
