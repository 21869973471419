import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarRecursosHumanos,
    ENDPOINTObtenerRecursosHumanos,
    ENDPOINTListarRecursosHumanos,
    ENDPOINTListarDepartamentosRecursosHumanos,
    ENDPOINTActualizarRecursosHumanos,
    ENDPOINTEliminarRecursosHumanos,
    ENDPOINTDeshabilitarRecursosHumanos,
    ENDPOINTEventsRecursosHumanos,
    ENDPOINTObtenerRecursosHumanosByNombre
} from "./endpoints";
import axios from 'axios';

// Obtener empleados por nombre
export async function obtenerRecursosHumanosPorNombre(nombre) {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    return axios.get(
      API_HOST + ENDPOINTObtenerRecursosHumanosByNombre + `/?nombre=${nombre}`,
      config
    )
  }

export async function listarRecursosHumanos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRecursosHumanos, config);
}

export async function listarDepartamentosRecursosHumanos(departamento){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDepartamentosRecursosHumanos + `?departamento=${departamento}`, config);
}

export async function eventsRecursosHumanos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTEventsRecursosHumanos, config);
}

export async function registraRecursosHumanos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarRecursosHumanos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerRecursosHumanos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerRecursosHumanos + `/${params}`, config);
}

export async function actualizarRecursosHumanos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarRecursosHumanos + `/${id}`, data, config);
}

export async function eliminarRecursosHumanos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarRecursosHumanos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarRecursosHumanos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarRecursosHumanos + `/${id}`, data, config);
}