import React, { useEffect, useState } from "react";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import RegistroDescuento from "./RegistroDescuento";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Col, Row } from "react-bootstrap";
import { Load } from "../../load/load";
import { listarAsistenciasEntreFechas } from "../../../api/asistencia";
import dayjs from "dayjs";
import { listarRecursosHumanos } from "../../../api/recursosHumanos";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarDescuentosEmpEntreFechas } from "../../../api/sueldosGenerales";

const DiasTrabajados = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroDescuento = (content) => {
    setTitulosModal("Registrar descuento a empleado");
    setContentModal(content);
    setShowModal(true);
  };

  const [listEmpleados, setListEmpleados] = useState([]);

  const obtenerEmpleados = () => {
    try {
      listarRecursosHumanos()
        .then((response) => {
          const { data } = response;
          if (!listEmpleados && data) {
            const empleadosConEstadoTrue = data.filter(empleado => empleado.estado === true);
            setListEmpleados(formatModelEmpleados(empleadosConEstadoTrue));
        } else {
            const empleadosConEstadoTrue = data.filter(empleado => empleado.estado === true);
            const datosOrdenes = formatModelEmpleados(empleadosConEstadoTrue);
            setListEmpleados(datosOrdenes);
        }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerEmpleados();
  }, []);

  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const [listAsistencias, setListAsistencias] = useState([]);

  const obtenerAsistencias = (fecha1, fecha2) => {
    try {
      listarAsistenciasEntreFechas(fecha1, fecha2)
        .then((response) => {
          const { data } = response;
          if (!listAsistencias && data) {
            setListAsistencias(formatModelAsistencias(data));
          } else {
            setListAsistencias(formatModelAsistencias(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [listDescuentos, setListDescuentos] = useState([]);

  const obtenerDescuentos = (fecha1, fecha2) => {
    try {
      listarDescuentosEmpEntreFechas(fecha1, fecha2)
        .then((response) => {
          const { data } = response;
          if (!listDescuentos && data) {
            setListDescuentos(data);
          } else {
            setListDescuentos(data);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    const date1 =
      dayjs(fechaInicio).format("YYYY") +
      "-" +
      dayjs(fechaInicio).format("M") +
      "-" +
      dayjs(fechaInicio).format("D");
    const date2 =
      dayjs(fechaFin).format("YYYY") +
      "-" +
      dayjs(fechaFin).format("M") +
      "-" +
      dayjs(fechaFin).format("D");
    obtenerAsistencias(date1, date2);
    obtenerDescuentos(date1, date2);
  }, [fechaInicio, fechaFin]);

  useEffect(() => {
    console.log(listDescuentos);
  }, [listDescuentos]);

  const [listEmpAsis, setlistEmpAsis] = useState([]);

  useEffect(() => {
    // Copia profunda de listEmpleados para evitar mutaciones no deseadas
    let empleadosCompletos = JSON.parse(JSON.stringify(listEmpleados));

    // Itera sobre cada empleado en listEmpleados
    empleadosCompletos.forEach((empleado) => {
      // Busca la entrada correspondiente en listAsistencias usando el ID del empleado
      const asistencia = listAsistencias.find(
        (asistencia) => asistencia.id === empleado.id
      );

      // Si se encuentra una entrada de asistencia, agrega el número de inasistencias al empleado
      if (asistencia) {
        empleado.inasistencias = asistencia.inasistencias;
      } else {
        // Si no se encuentra una entrada de asistencia para el empleado, establece inasistencias en 0
        empleado.inasistencias = 0;
      }

      // Busca la entrada correspondiente en listDescuentos usando el ID del empleado
      const descuento = listDescuentos.find(
        (descuento) => descuento.idEmpleado === empleado.id
      );

      // Si se encuentra una entrada de descuento, agrega la cantidad al empleado
      if (descuento) {
        empleado.cantidadDescuento = descuento.totalCantidad;
      } else {
        // Si no se encuentra una entrada de descuento para el empleado, establece cantidadDescuento en 0
        empleado.cantidadDescuento = 0;
      }
    });

    console.log(empleadosCompletos);

    setlistEmpAsis(empleadosCompletos);
  }, [listEmpleados, listAsistencias, listDescuentos]);

  /*************************************************************** */

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreCompleto",
      label: "NOMBRE",
      options: {
        customBodyRender: (value) => {
          // Capitalizar la primera letra de cada palabra
          const capitalizedWords = value.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
          return capitalizedWords;
        },
      },
    },
    {
      name: "email",
      label: "EMAIL",
    },
    {
      name: "cargo",
      label: "CARGO",
    },
    {
      name: "formaPago",
      label: "FORMA DE PAGO",
    },
    {
      name: "salario",
      label: "SALARIO",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "inasistencias",
      label: "FALTAS",
    },
    {
      name: "cantidadDescuento",
      label: "DESCUENTOS",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  /*************************************************************** */

  return (
    <div>
      {loading && <Load />}
      <h1>Sueldos de empleados</h1>
      <div>
        <Row>
          <Col sm={12} md={3} lg={3} className="mb-2">
            <Form.Label>Fecha inicial</Form.Label>
            <Form.Control
              name="fechaInicio"
              value={fechaInicio}
              onChange={(e) => setFechaInicio(e.target.value)}
              type="date"
              style={{ marginRight: "1%" }}
            />
          </Col>
          <Col sm={12} md={3} lg={3} className="mb-2">
            <Form.Label>Fecha Final</Form.Label>
            <Form.Control
              name="fechaFin"
              value={fechaFin}
              onChange={(e) => setFechaFin(e.target.value)}
              type="date"
              style={{ marginRight: "1%" }}
            />
          </Col>
        </Row>
      </div>
      <div className="divButton">
        <Button
          className="btnAddTables"
          onClick={() =>
            registroDescuento(<RegistroDescuento setShow={setShowModal} />)
          }
        >
          <FontAwesomeIcon icon={faPlus} /> Agregar
        </Button>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Listado de sueldos de empleados registrados"}
          data={listEmpAsis}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </div>
  );
};

function formatModelAsistencias(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      asitencias: data.asitencias,
      inasistencias: data.inasistencias,
    });
  });
  return dataTemp;
}

function formatModelEmpleados(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombreCompleto: data.nombreCompleto,
      email: data.email,
      cargo: data.cargo,
      salario: data.salario,
      formaPago: data.formaPago,
    });
  });
  return dataTemp;
}

export default DiasTrabajados;
