// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barraProgreso {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
  }
  
  .step1 {
    position: relative;
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .circle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #3498db;
    color: #ffffff;
    border-radius: 50%;
    font-size: 1em;
    margin-bottom: 5px;
  }
  
  .step1.active .circle1 {
    background-color: #2ecc71;
  }
  
  .textocontaineret1 {
    font-weight: bold;
    font-size: 1em;
    margin-top: 5px;
  }
  
  .textoCardStep1 {
    font-size: 0.8em;
    color: #555;
  }

  .contenidoVerificacion{
    margin: 2vw;
    text-align: center;
  }
  
  /* Reglas de medios para pantallas más pequeñas (móviles) */
  @media screen and (max-width: 768px) {
    .barraProgreso {
      display: none;
    }

    .radioVerif{
      font-size: 28px;
    }
  
    .step1 {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/rutas/estilos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,SAAO;IACP,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA,2DAA2D;EAC3D;IACE;MACE,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,WAAW;MACX,mBAAmB;IACrB;EACF","sourcesContent":[".barraProgreso {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    width: 100%;\n    margin: 0 auto;\n  }\n  \n  .step1 {\n    position: relative;\n    flex: 1;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .circle1 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 40px;\n    height: 40px;\n    background-color: #3498db;\n    color: #ffffff;\n    border-radius: 50%;\n    font-size: 1em;\n    margin-bottom: 5px;\n  }\n  \n  .step1.active .circle1 {\n    background-color: #2ecc71;\n  }\n  \n  .textocontaineret1 {\n    font-weight: bold;\n    font-size: 1em;\n    margin-top: 5px;\n  }\n  \n  .textoCardStep1 {\n    font-size: 0.8em;\n    color: #555;\n  }\n\n  .contenidoVerificacion{\n    margin: 2vw;\n    text-align: center;\n  }\n  \n  /* Reglas de medios para pantallas más pequeñas (móviles) */\n  @media screen and (max-width: 768px) {\n    .barraProgreso {\n      display: none;\n    }\n\n    .radioVerif{\n      font-size: 28px;\n    }\n  \n    .step1 {\n      width: 100%;\n      margin-bottom: 20px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
