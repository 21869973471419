import { useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { listarDepartamentosODT } from '../../../api/departamentosODT';

Chart.register(...registerables);

function GraficasDepartamentos() {
  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerServiciosRuta = async () => {
    try {
      const response = await listarDepartamentosODT();
      const { data } = response;
      if (data) {
        setListOrdenes(formatModelOrdenes(data));
      }
    } catch (e) {
      toast.error(e.message === "Network Error" ? "Conexión al servidor no disponible" : "Error al obtener los datos");
    }
  };

  useEffect(() => {
    obtenerServiciosRuta();
  }, []);

  const calcularDiferenciaMinutos = (fecha1, fecha2) => {
    const diferencia = Math.abs(new Date(fecha2) - new Date(fecha1));
    return Math.ceil(diferencia / (1000 * 60 * 60));
  };

  const datosAgrupadosPorDepartamento = listarOrdenes.reduce((acc, item) => {
    const { departamento, fechaEntrada, fechaSalida } = item;
    if (!acc[departamento]) {
      acc[departamento] = { tiempoMuerto: 0, tiempoProductivo: 0 };
    }
    acc[departamento].tiempoMuerto += calcularDiferenciaMinutos(fechaEntrada, fechaSalida);
    acc[departamento].tiempoProductivo += calcularDiferenciaMinutos(item.fechaInicio, item.fechaTermino);
    return acc;
  }, {});

  const departamentos = Object.keys(datosAgrupadosPorDepartamento);

  const data = {
    labels: departamentos,
    datasets: [
      {
        label: 'Tiempo Muerto',
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 1,
        data: departamentos.map(dep => datosAgrupadosPorDepartamento[dep].tiempoMuerto),
      },
      {
        label: 'Tiempo Productivo',
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
        data: departamentos.map(dep => datosAgrupadosPorDepartamento[dep].tiempoProductivo),
      },
    ],
  };

  const opciones = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div className='App' style={{ width: "100%", height: "500px" }}>
      <h2>Tabla de tiempo de proceso por departamento</h2>
      {listarOrdenes.length > 0 ? (
        <Bar data={data} options={opciones} />
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
}

function formatModelOrdenes(data) {
  const formattedData = data.map((item) => {
    const fechaEntrada = new Date(item.fechaEntrada);
    const fechaInicio = new Date(item.fechaInicio);
    const fechaTermino = new Date(item.fechaTermino);
    const fechaSalida = new Date(item.fechaSalida);

    const diferenciaMinutos = (fecha1, fecha2) => Math.abs((new Date(fecha2) - new Date(fecha1)) / (1000 * 60 * 60));

    const tiempoMuerto = diferenciaMinutos(fechaEntrada, fechaInicio) + diferenciaMinutos(fechaTermino, fechaSalida);
    const tiempoProductivo = diferenciaMinutos(fechaInicio, fechaTermino);

    return {
      id: item._id,
      folioODT: item.folioODT,
      departamento: item.departamento,
      fechaEntrada: item.fechaEntrada,
      fechaInicio: item.fechaInicio,
      fechaTermino: item.fechaTermino,
      fechaSalida: item.fechaSalida,
      estado: item.estado,
      tiempoMuerto,
      tiempoProductivo,
    };
  });

  return formattedData;
}

export default GraficasDepartamentos;
