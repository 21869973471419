import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { eliminarMensajeros } from "../../../api/mensajeros";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function EliminarMensajeros({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        nombre: data[1],
        telefono: data[2],
        email: data[3],
        modeloVehiculo: data[4],
        placa: data[5],
        economico: data[6],
        fecha: data[7]
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            eliminarMensajeros(idDocumental).then((response) => {
                const { data } = response;
                //notificacion

                toast.success(data.mensaje);
                LogsInformativos("Se ha eliminado el mensajero " + idDocumental)
                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='facturacionGeneral'>
          <Form className="formMensajero" onSubmit={onSubmit} onChange={onChange}>
            <Form.Label className="lblNomMensajero">
              Nombre completo:{" "}
            </Form.Label>
            <Form.Control
              type="text"
              className="nombreMensajero"
              placeholder="Nombre completo"
              defaultValue={formData.nombre}
              name="nombre"
              disabled
            />
            <Form.Label className="lblTelMensajero">Teléfono: </Form.Label>
            <Form.Control
              type="tel"
              className="telefonoMensajero"
              placeholder="Teléfono"
              defaultValue={formData.telefono}
              name="telefono"
              disabled
            />
            <Form.Label className="lblNomMensajero">
              Correo electronico:{" "}
            </Form.Label>
            <Form.Control
              type="email"
              className="emailMensajero"
              placeholder="ejemplo@ejemplo.com"
              defaultValue={formData.email}
              name="email"
              disabled
            />
            <Form.Label className="lblmodeloMensajero">
              Modelo de vehículo:{" "}
            </Form.Label>
            <Form.Control
              type="text"
              className="modeloMensajero"
              placeholder="Modelo de vehículo"
              defaultValue={formData.modeloVehiculo}
              name="modeloVehiculo"
              disabled
            />
            <Form.Label className="lblPlacaMensajero">
              Placa:{" "}
            </Form.Label>
            <Form.Control
              type="text"
              className="placaMensajero"
              placeholder="Placa del vehículo"
              defaultValue={formData.placa}
              name="placa"
              disabled
            />
            <Form.Label className="lblNomMensajero">
              # Económico:{" "}
            </Form.Label>
            <Form.Control
              type="text"
              className="economicoMensajero"
              placeholder="# Económico"
              defaultValue={formData.economico}
              name="economico"
              disabled
            />
            <Form.Label className="lblFechaMensajero">
              Fecha de registro:{" "}
            </Form.Label>
            <Form.Control
              type="text"
              className="fechaRegMensajero"
              defaultValue={formData.fecha}
              disabled
            />
            <br />
            <label></label>
            <div className="divSubmit">
              <input className="submit" value="Enviar" type="submit" />
            </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        nombre: data.nombre,
        telefono: data.telefono,
        email: data.email,
        modeloVehiculo: data.modeloVehiculo,
        placa: data.placa,
        economico: data.economico,
        fecha: data.fecha
    };
}
