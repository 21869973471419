import React, { useEffect, useState } from "react";
import { listarFechasyEstadoPago } from "../../../api/ordenesServicio";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Badge, Dropdown } from "react-bootstrap";
import { Load } from "../../load/load";
import { faBars, faMoneyCheckDollar, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EnviaRecordatorio from "./EnviaRecordatorio";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import PagosOrdenesM from "../../ordenServicio/pagosOrdenesM/PagosOrdenesM";
import { withRouter } from "../../../utils/withRouter";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaPOS = ({history, location}) => {
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listOS, setListOS] = useState([]);

  const obtenerOS = () => {
    try {
      listarFechasyEstadoPago()
        .then((response) => {
          const { data } = response;
          console.log(data)
          if (!listOS && data) {
            setListOS(formatModelOrdenes(data));
          } else {
            setListOS(formatModelOrdenes(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOS();
  }, [location]);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const enviarRecordatorio = (content) => {
    setTitulosModal("Enviar recordatorio de pago");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirPago = (content) => {
    setTitulosModal("Pago de orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  /****************************************************************/

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "localidad",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreDoctor",
      label: "DOCTOR",
    },
    {
      name: "cargoOrden",
      label: "CARGO",
    },
    {
      name: "precioTotal",
      label: "TOTAL ORDEN",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "totalAbonos",
      label: "TOTAL ABONADO",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "diasDesdeFecha",
      label: "DÍAS TRANSURRIDOS PARA PAGO",
      options: {
        customBodyRender: (value) => {
          const dias = value;

          return (
            <>
              <Badge
                bg={
                  dias < 10
                    ? "success"
                    : dias < 20
                    ? "info"
                    : dias <= 25
                    ? "warning"
                    : "danger"
                }
              >
                {dias}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "estadoPago",
      label: "PAGO",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let texto = "";

          if (estado == "0") {
            texto = "Pendiente";
          }

          return (
            <>
              <Badge bg="warning">{texto}</Badge>
            </>
          );
        },
      },
    },
    {
      name: "estado",
      label: "ESTADO DEL PEDIDO",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Orden nueva";
            estadoTexto = "Orden nueva";
          } else if (estado == "2") {
            estiloTexto = "Recoleccion asignada";
            estadoTexto = "Recoleccion asignada";
          } else if (estado == "3") {
            estiloTexto = "Orden entregada";
            estadoTexto = "Orden entregada";
          } else if (estado == "4") {
            estiloTexto = "Material recibido";
            estadoTexto = "Material recibido";
          } else if (estado == "5") {
            estiloTexto = "Orden finalizada";
            estadoTexto = "Orden finalizada";
          } else if (estado == "0") {
            estiloTexto = "Orden cancelada";
            estadoTexto = "Orden cancelada";
          } else if (estado == "6") {
            estiloTexto = "En proceso";
            estadoTexto = "En proceso";
          } else if (estado == "7") {
            estiloTexto = "En ruta de entrega";
            estadoTexto = "En ruta de entrega";
          } else if (estado == "8") {
            estiloTexto = "Entregado";
            estadoTexto = "Entregado";
          }
          return (
            <>
              <Badge
                bg={
                  estado == "1"
                    ? "info"
                    : estado == "2"
                    ? "warning"
                    : estado == "3"
                    ? "primary"
                    : estado == "4"
                    ? "secondary"
                    : estado == "5"
                    ? "dark"
                    : estado == "6"
                    ? "primary"
                    : estado == "7"
                    ? "light"
                    : estado == "8"
                    ? "success"
                    : "danger"
                }
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Dropdown>
              <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                <FontAwesomeIcon icon={faBars} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    recibirPago(
                      <PagosOrdenesM
                        setShow={setShowModal}
                        data={tableMeta.rowData}
                        history={history}
                        origen="tablaPagos"
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faMoneyCheckDollar}
                    style={{ color: "#28a745" }}
                  />
                  &nbsp;Recibir pago
                </Dropdown.Item>
                {tableMeta.rowData[8] >= 25 && (
                  <Dropdown.Item
                    onClick={() =>
                      enviarRecordatorio(
                        <EnviaRecordatorio
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Enviar recordatorio
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    },
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  /****************************************************************/

  return (
    <>
      {loading && <Load />}
      <h1>Ordenes de cliente pendientes de pago</h1>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de ordenes de cliente pendientes de pago"}
          data={listOS}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      fechaEntrada: data.fechaEntrada,
      correoConsultorio: data.correo,
      cargoOrden: data.cargoOrden,
      estado: data.estado,
      diasDesdeFecha: data.diasDesdeFecha,
      totalAbonos: data.totalAbonos,
      precioTotal: data.precioTotal,
      estadoPago: data.estadoPago,
    });
  });
  return dataTemp;
}

export default withRouter(TablaPOS);
