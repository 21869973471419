import { Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarDepartamentos } from "../../api/departamentos";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function ModificarDepartamentos({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    nombre: data[2],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [tipo, setTipo] = useState("");
  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto

      const dataTemp = {
        nombre: formData.nombre,
        tipo: tipo
      };

      actualizarDepartamentos(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        LogsInformativos(
          "Se ha modificado el departamento " + dataTemp.nombre,
          dataTemp
        );
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalDoctores">
        <Form className="formDoctores" onSubmit={onSubmit} onChange={onChange}>
          <Form.Group>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblpApellido">Folio: </Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  className="inpPrimerADoc"
                  placeholder="Ingresa primer apellido"
                  value={formData.folio}
                  name="folio"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Nombre: </Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  className="inpSegundoA"
                  placeholder="Ingresa el nombre del departamento"
                  defaultValue={formData.nombre}
                  name="nombre"
                />
              </Col>
            </Row>

            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Tipo: </Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  as="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="">Seleccionar</option>
                  <option value="Produccion">Producción</option>
                  <option value="Administrativo">Administrativo</option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    nombre: data.nombre,
  };
}
