import React, { useEffect, useState } from "react";
import { listarRutas } from "../../api/rutas";
import { Button, Table } from "react-bootstrap";
import TablaOrdenesRuta from "./tablaOrdenesRuta/TablaOrdenesRuta";
import BasicModal from "../Modal/BasicModal/BasicModal";

const RutasConOrdenes = ({ fecha }) => {
  console.log("fecha", fecha);

  const [listRoutesOrdenes, setlistRoutesOrdenes] = useState([]);
  const obtenerRutasPorFecha = (dia) => {
    try {
      listarRutas(dia)
        .then((response) => {
          const { data } = response;
          console.log("dataRutas con ordenes", data);
          if (data.length > 0) {
            setlistRoutesOrdenes(formatModelRutas(data));
          } else {
            setlistRoutesOrdenes([]);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerRutasPorFecha(fecha);
  }, [fecha]);

    //Para el modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

  const verOrdenes = (content) => {
    setTitulosModal("Registrar ruta");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>No.</th>
            <th>Día</th>
            <th>Fecha</th>
            <th>Ruta</th>
            <th>Mensajero</th>
            <th>Vehículo</th>
            <th>Ordenes</th>
          </tr>
        </thead>
        <tbody>
          {listRoutesOrdenes.map((ruta, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textTransform: "capitalize" }}>
                {ruta.observaciones}
              </td>
              <td>{ruta.fecha}</td>
              <td>{ruta.ruta}</td>
              <td style={{ textTransform: "capitalize" }}>
                {ruta.nombreMensajero}
              </td>
              <td>{ruta.vehiculoAsignado + " - " + ruta.numeroEconomico}</td>
              <td>
                <Button
                  onClick={() =>
                    verOrdenes(
                      <TablaOrdenesRuta
                        setShow={setShowModal}
                        data={ruta}
                      />
                    )
                  }
                >
                  Ver ordenes
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <BasicModal
          show={showModal}
          setShow={setShowModal}
          title={titulosModal}
        >
          {contentModal}
        </BasicModal>
    </div>
  );
};

function formatModelRutas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ruta: data.ruta.split("/")[0],
      observaciones: data.observaciones,
      nombreMensajero: data.nombreMensajero,
      fecha: data.fecha,
      vehiculoAsignado: data.vehiculoAsignado,
      numeroEconomico: data.numeroEconomico,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default RutasConOrdenes;
