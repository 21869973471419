// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divSaldosClientes{
    display: flex;
    width: 100%;
}

.divContainerOptions{
    width: 100%;
    padding: 0 1% 0 1%;
}

.btnSaldosDiv{
    padding: 1%;
    text-align: right;
}

.btnSaldosDivOc{
    display: none;
}

@media screen and (max-width: 770px) {
    .btnSearchTableSaldos{
        padding-right: 6%;
        justify-content: right;
    }
    
  }

`, "",{"version":3,"sources":["webpack://./src/components/finanzas/saldosClientes/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,iBAAiB;QACjB,sBAAsB;IAC1B;;EAEF","sourcesContent":[".divSaldosClientes{\n    display: flex;\n    width: 100%;\n}\n\n.divContainerOptions{\n    width: 100%;\n    padding: 0 1% 0 1%;\n}\n\n.btnSaldosDiv{\n    padding: 1%;\n    text-align: right;\n}\n\n.btnSaldosDivOc{\n    display: none;\n}\n\n@media screen and (max-width: 770px) {\n    .btnSearchTableSaldos{\n        padding-right: 6%;\n        justify-content: right;\n    }\n    \n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
