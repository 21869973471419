import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { enviaEmailRecordatorio } from "../../../api/ordenesServicio";
import { toast } from "react-toastify";

const EnviaRecordatorio = ({ data, setShow }) => {
  const emailCliente = data[5];
  const folioOS = data[2];

  const enviaEmail = () => {
    try {
      const dataTemp = {
        email: emailCliente,
        folioOrden: folioOS,
      };
      enviaEmailRecordatorio(dataTemp).then((response) => {
        const { data } = response;
        console.log(data)
        toast.info("Email enviado");
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Folio:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Folio de OC"
              value={folioOS}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Email:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien elabora"
              value={emailCliente}
              disabled
            />
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={enviaEmail}>
            <FontAwesomeIcon icon={faPaperPlane} />
            &nbsp; Enviar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EnviaRecordatorio;
