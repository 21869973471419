import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { eliminarRecursosHumanos } from "../../api/recursosHumanos";
import queryString from "query-string";
import { Load } from "../load/load";
import { LogsInformativos } from "../logs/logs";

export default function EliminarEmpleado({ data, history, setShow }) {
  const idDocumental = data[0];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      eliminarRecursosHumanos(idDocumental).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        LogsInformativos("Se ha eliminado el empleado " + idDocumental)
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit}>
        <div style={{textAlign: "center", marginBottom: "1%"}}>
            <h3>¿Seguro de eliminar al usuario {data[1]}? Esta acción no se podra deshacer</h3>
          </div>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}
