import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  listarDoctores,
  listarDoctoresPorCorreo,
  listarDoctoresPorCorreoDoctor,
} from "../../api/doctores";
import { obtenerUsuarioEmail, listarUsuarios } from "../../api/usuarios";
import Modal from "react-bootstrap/Modal";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import EliminarDoctores from "./Eliminar";
import ModificarDoctores from "./Modificar";
import DatosDoctores from "./DatosDoctores";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RegistroUsuario from "./Registro";

function TblDoctores(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarUsuariosI, setListUsuariosI] = useState([]);

  const obtenerUsuariosInternos = () => {
    listarUsuarios()
      .then((response) => {
        const { data } = response;

        if (!listarUsuariosI && data) {
          setListUsuariosI(formatModelOrdenes(data));
        } else {
          const datosDoctor = formatModelOrdenes(data);
          const filteredUsers = datosDoctor.filter(
            (user) =>
              user.tipo === "doctor" ||
              user.tipo === "consultorio"
          );

          if (filteredUsers.length > 0) {
            setListUsuariosI(filteredUsers);
          } else {
            console.log(
              "No se encontró ningún elemento con el tipo especificado"
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
      });
  };

  const [listUsuarios, setListUsuarios] = useState([]);

  const obtenerUsuarios = () => {
    listarUsuarios()
      .then((response) => {
        const { data } = response;

        if (!listUsuarios && data) {
          setListUsuarios(formatModelOrdenes(data));
        } else {
          const datosDoctor = formatModelOrdenes(data);
          setListUsuarios(datosDoctor)
        }
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
      });
  };

  useEffect(() => {
    obtenerUsuarios();
  }, [location]);

  console.log(listUsuarios)

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    if (tipoUsuario == "administrador") {
      try {
        listarDoctores()
          .then((response) => {
            const { data } = response;

            if (!listarDoctor && data) {
              setListDoctor(formatModelDoctor(data));
            } else {
              const datosDoctor = formatModelDoctor(data);
              setListDoctor(datosDoctor);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    } else if (tipoUsuario == "consultorio") {
      try {
        listarDoctoresPorCorreo(correoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarDoctor && data) {
              setListDoctor(formatModelDoctor(data));
            } else {
              const datosDoctor = formatModelDoctor(data);
              setListDoctor(datosDoctor);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    } else if (tipoUsuario == "doctor") {
      try {
        listarDoctoresPorCorreoDoctor(correoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarDoctor && data) {
              setListDoctor(formatModelDoctor(data));
            } else {
              const datosDoctor = formatModelDoctor(data);
              setListDoctor(datosDoctor);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    }
  };

  useEffect(() => {
    obtenerDoctor();
  }, [location, correoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarDoctor);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroDoctor = (content) => {
    setTitulosModal("Registrar doctor");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarDoctor = (content) => {
    setTitulosModal("Modificar doctor");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarDoctor = (content) => {
    setTitulosModal("Eliminar doctor");
    setContentModal(content);
    setShowModal(true);
  };

  const crearUsuario = (content) => {
    setTitulosModal("Crear usuario");
    setContentModal(content);
    setShowModal(true);
  };


  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "nombre",
      label: "NOMBRE",
    },
    {
      name: "apellidoPaterno",
      label: "APELLIDO PATERNO",
    },
    {
      name: "apellidoMaterno",
      label: "APELLIDO MATERNO",
    },
    {
      name: "fechaNacimiento",
      label: "FECHA NACIMIENTO",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "telefonoCelular",
      label: "TELEFONO CELULAR",
    },
    {
      name: "email",
      label: "EMAIL",
    },
    {
      name: "rfc",
      label: "RFC",
    },
    {
      name: "observaciones",
      label: "OBSERVACIONES",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return <>
            <Badge
              bg={estado == "true" ? "success" : "danger"}
            //className="estado"
            >
              {estiloTexto}
            </Badge>
          </>;
        },
      },
    },
    {
      name: "consultorio",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreConsultorio",
      label: "NOMBRE DEL CONSULTORIO",
    },
    {
      name: "constancia",
      label: "CONSTANCIA",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                ver constancia
              </a>
            );
          } else {
            return (
              <Badge bg="secondary">
                no disponible
              </Badge>
            );
          }
        },
      },
    },
    {
      name: "direcciones",
      label: "DIRECCIONES",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const emailDoctor = tableMeta.rowData[7];
          const isEmailInListUsuarios = listUsuarios.some(user => user.email === emailDoctor);
          console.log(isEmailInListUsuarios)
          if (tipoUsuario !== "doctor") {
            return (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    className="botonDropdown"
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {!isEmailInListUsuarios && (
                    <Dropdown.Item
                    onClick={() =>
                      crearUsuario(
                        <RegistroUsuario
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: "#252440" }}
                    />
                    &nbsp; Crear usuario
                  </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() =>
                        modificarDoctor(
                          <ModificarDoctores
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ color: "#ffc107" }}
                      />
                      &nbsp; Modificar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        eliminarDoctor(
                          <EliminarDoctores
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp; Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            );
          } else {
            return (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    className="botonDropdown"
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      variant="success"
                      onClick={() =>
                        modificarDoctor(
                          <ModificarDoctores
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp; Modificar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            );
          }
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Doctores</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroDoctor(
                <DatosDoctores history={history} setShow={setShowModal} />
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista doctores registrados"}
          data={listarDoctor}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
      constancia: data.constancia,
      direcciones: data.direcciones || []
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      email: data.email,
      password: data.password,
      tipo: data.tipo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblDoctores);
