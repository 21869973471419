import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarEstablecimientos } from "../../../api/establecimientos";
import { toast } from "react-toastify";
import queryString from "query-string";
import estadosPaisData from '../catalogosJSON/estadosPais.json';
import municipiosEstadoData from '../catalogosJSON/municipiosEstadosPais.json'
import { LogsInformativos } from "../../logs/logs";

export default function ModificarCliente({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    razonSocial: data[2],
    estadoEstablecimiento: data[3],
    municipio: data[4],
    colonia: data[5],
    calle: data[6],
    numeroExterior: data[7],
    numeroInterior: data[8],
    codigoPostal: data[9],
    telefono: data[10],
    haceEsquina: data[11],
    email: data[12],
    horario: data[13],
    observaciones: data[14],
    alias: data[16],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto

      const dataTemp = {
        razonSocial: formData.razonSocial,
        estadoEstablecimiento: estadoPais,
        municipio: municipioSeleccionado,
        colonia: formData.colonia,
        calle: formData.calle,
        numeroExterior: formData.numeroExterior,
        numeroInterior: formData.numeroInterior,
        codigoPostal: formData.codigoPostal,
        telefono: formData.telefono,
        haceEsquina: formData.haceEsquina,
        email: formData.email,
        observaciones: formData.observaciones,
        horario: formData.horario,
        alias: formData.alias
      };

      actualizarEstablecimientos(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        LogsInformativos("Se ha modificado el consultorio con razon social " + dataTemp.razonSocial, dataTemp)
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
        <Form.Label className='lblNombreEst'>Folio: </Form.Label>
          <Form.Control
            type='text'
            className='inpNombreEst'
            placeholder='Ingresa nombre comercial o razon social'
            value={formData.folio}
            name="folio"
            disabled
          />
          <Form.Label className='lblNombreEst'>Nombre o Razón Social: </Form.Label>
          <Form.Control
            type='text'
            className='inpNombreEst'
            placeholder='Ingresa nombre comercial o razon social'
            defaultValue={formData.razonSocial}
            name="razonSocial"
          />
          <Form.Label className='lblNombreEst'>Alias: </Form.Label>
          <Form.Control
            type='text'
            className='inpNombreEst'
            placeholder='Ingresa Alias'
            defaultValue={formData.alias}
            name="alias"
          />
          <Form.Group className='contactoCli'>
            <Form.Label className='lblTelefono'>Telefono: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa telefono'
              defaultValue={formData.telefono}
              name="telefono"
            />
            <Form.Label className='lblEmail'>Correo Electrónico: </Form.Label>
            <Form.Control
              type='email'
              className='inpEmail'
              placeholder='ejemplo@ejemplo.com'
              defaultValue={formData.email}
              name="email"
            />
          </Form.Group>
          <Form.Group className='dom1Cli'>
            <Form.Label className='lblCalle'>Calle: </Form.Label>
            <Form.Control
              type='text'
              className='inpCalle'
              placeholder='Ingresa calle'
              defaultValue={formData.calle}
              name="calle"
            />
            <Form.Label className='lblnExt'>Num Ext: </Form.Label>
            <Form.Control
              type='number'
              className='inpNumExt'
              placeholder='Ingresa numero exterior'
              defaultValue={formData.numeroExterior}
              name="numeroExterior"
            />
            <Form.Label className='lblnInt'>Num Int: </Form.Label>
            <Form.Control
              type='number'
              className='inpNumInt'
              placeholder='Ingresa numero interior'
              defaultValue={formData.numeroInterior}
              name="numeroInterior"
            />

          </Form.Group>
          <Form.Group className='dom2Cli'>
            <div className="colonia">
              <Form.Label className='lblColonia'>Colonia: </Form.Label>
              <Form.Control
                type='text'
                className='inpColonia'
                placeholder='Ingresa colonia'
                defaultValue={formData.colonia}
                name="colonia"
              />
            </div>
            <div className="estadoCli">
              <Form.Label className='lblEstadoEst'>Estado</Form.Label>
              <Form.Control
                as="select"
                value={estadoPais}
                onChange={(e) => setEstadoPais(e.target.value)}
              >
                <option>Selecciona un Estado</option>
                {estadosPaisData.map((estado) => (
                  <option key={estado.clave} value={estado.nombre}>
                    {estado.nombre}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="municipioCli">
              <Form.Label className='lblMunicipio'>Municipio</Form.Label>
              <Form.Control
                as="select"
                value={municipioSeleccionado}
                onChange={(e) => setMunicipioSeleccionado(e.target.value)}
              >
                <option>Selecciona un Municipio</option>
                {municipiosEstadoData[estadoPais] &&
                  municipiosEstadoData[estadoPais].map((municipio) => (
                    <option key={municipio} value={municipio}>
                      {municipio}
                    </option>
                  ))}
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group className='dom3Cli'>
            <Form.Label className='lblCP'>Codigo Postal: </Form.Label>
            <Form.Control
              type='number'
              className='inpCP'
              placeholder='Ingresa Codigo Postal'
              defaultValue={formData.codigoPostal}
              name="codigoPostal"
            />
            <Form.Label className='lblEsquina'>Hace esquina con: </Form.Label>
            <Form.Control
              type='text'
              className='inpEsquina'
              placeholder='Ingresa Referencia'
              defaultValue={formData.haceEsquina}
              name="haceEsquina"
            />
          </Form.Group>
          <Form.Label className='lblHorario'>Horario: </Form.Label>
          <Form.Control
            type='text'
            className='inpHorario'
            placeholder='Ingresa horario'
            defaultValue={formData.horario}
            name="horario"
          />
          <Form.Group className='bloque6Est'>
            <Form.Label>Observaciones:</Form.Label>
            <Form.Control
              placeholder="Observaciones"
              className='txtObservaciones'
              as="textarea" rows={2}
              defaultValue={formData.observaciones}
              name="observaciones"
            />
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    razonSocial: data.razonSocial,
    estadoEstablecimiento: data.estadoEstablecimiento,
    municipio: data.municipio,
    colonia: data.colonia,
    calle: data.calle,
    numeroExterior: data.numeroExterior,
    numeroInterior: data.numeroInterior,
    codigoPostal: data.codigoPostal,
    telefono: data.telefono,
    haceEsquina: data.haceEsquina,
    email: data.email,
    horario: data.horario,
    observaciones: data.observaciones,
    alias: data.alias
  };
}
