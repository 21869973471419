import { useState, useEffect } from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { registraDoctores, obtenerNumeroDoctor, listarDoctoresPorCorreoDoctor, actualizarDoctores } from "../../api/doctores";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { obtenerEstablecimientosPorFolio } from "../../api/establecimientos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faBan, faPen } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from "../logs/logs";
import estadosPaisData from '../administrador/catalogosJSON/estadosPais.json';
import municipiosEstadoData from '../administrador/catalogosJSON/municipiosEstadosPais.json'

const DatosDocInicio = () => {
  const [inputEnabled, setInputEnabled] = useState(false);
  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");
  const [calle, setCalle] = useState("");
  const [numeroExterior, setNumeroExterior] = useState("");
  const [numeroInterior, setNumeroInterior] = useState("");
  const [colonia, setColonia] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [haceEsquina, setHaceEsquina] = useState("");

  const toggleInput = () => {
    setInputEnabled(!inputEnabled);
  };

  const [formData3, setFormData3] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [idDoc, setidDoc] = useState("")
  const [folioDoc, setFolioDoc] = useState("")
  const [nombreDoc, setnombreDoc] = useState("")
  const [primApDoc, setprimApDoc] = useState("")
  const [segApDoc, setsegApDoc] = useState("")
  const [fechaNacimientoDoc, setfechaNacimientoDoc] = useState("")
  const [telCelDoc, settelCelDoc] = useState("")
  const [folioCDoc, setfolioCDoc] = useState("")
  const [observacionesDoc, setObservacionesDoc] = useState("")
  const [consultorio, setConsultorio] = useState("")
  const [addresses, setAddresses] = useState()

  const consultaDoc = () => {
    try {
      listarDoctoresPorCorreoDoctor(correoUsuario)
        .then((response) => {
          const data = response.data;
          if (data && data.length > 0) {
            const primerDoctor = data[0];
            console.log(primerDoctor)
            setidDoc(primerDoctor._id)
            setFolioDoc(primerDoctor.folio);
            setnombreDoc(primerDoctor.nombre);
            setprimApDoc(primerDoctor.apellidoPaterno);
            setsegApDoc(primerDoctor.apellidoMaterno);
            setfechaNacimientoDoc(primerDoctor.fechaNacimiento);
            settelCelDoc(primerDoctor.telefonoCelular);
            setfolioCDoc(primerDoctor.consultorio);
            setObservacionesDoc(primerDoctor.observaciones);
            setConsultorio(primerDoctor.nombreConsultorio);
            setAddresses(primerDoctor.direcciones || []);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    consultaDoc();
  }, [correoUsuario]);

  console.log(addresses)

  // Function to add address to array
  const handleAddAddress = (e) => {
    e.preventDefault();
    const newAddress = {
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      estado: estadoPais,
      municipio: municipioSeleccionado,
      codigoPostal,
      haceEsquina,
    };

    setAddresses([...addresses, newAddress]);

    // Limpiar campos después de agregar la dirección
    setCalle("");
    setNumeroExterior("");
    setNumeroInterior("");
    setColonia("");
    setEstadoPais("");
    setMunicipioSeleccionado("");
    setCodigoPostal("");
    setHaceEsquina("");
  };

  const [nombreConsultorio, setNombreConsultorio] = useState("");
  const [correoConsultorio, setCorreoConsultorio] = useState("");

  const cargarDatosConsultorio = () => {
    obtenerEstablecimientosPorFolio(folioCDoc)
      .then((response) => {
        const { data } = response;

        //setNombreConsultorio(data.razonSocial);
        setCorreoConsultorio(data.email);
      })
      .catch((e) => {
      });
  };

  useEffect(() => {
    cargarDatosConsultorio()
  }, [folioCDoc])


  const onSubmit = (e) => {
    e.preventDefault();

    try {
      const dataTemp = {
        idUsuario: idDoc,
        telefonoCelular: telCelDoc,
        email: correoUsuario,
        observaciones: observacionesDoc,
        nombreConsultorio: nombreConsultorio,
        direcciones: addresses
      };
      actualizarDoctores(idDoc, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        LogsInformativos("Se ha registrado el doctor " + dataTemp.idUsuario, dataTemp)
        toast.success(data.mensaje);
        consultaDoc();
        setInputEnabled(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ marginBottom: "4%" }}>
      <div style={{ textAlign: "center", marginBottom: "2%" }}>
        <h2 style={{ fontWeight: "bold", fontStyle: "italic" }}>Mis Datos</h2>
      </div>
      <div>
        <Form>
          <div>
            <Row className="mb-2">
              <Col>
                <Button
                  onClick={toggleInput}
                  className="float-right"
                  variant={inputEnabled ? "danger" : "warning"}
                >
                  {inputEnabled ? (
                    <>
                      <FontAwesomeIcon icon={faBan} /> Cancelar
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPen} /> Editar
                    </>
                  )}
                </Button>
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Folio:</Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="text"
                  className="inpNombreEst"
                  defaultValue={folioDoc}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Nombre(s):</Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="text"
                  className="inpNombreEst"
                  placeholder="Ingresa nombre"
                  name="nombreDoc"
                  defaultValue={nombreDoc}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Primer Apellido: </Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="text"
                  className="inpPrimerADoc"
                  placeholder="Ingresa primer apellido"
                  defaultValue={primApDoc}
                  name="apellidoPaternoDoc"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Segundo Apellido: </Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="text"
                  className="inpSegundoA"
                  placeholder="Ingresa segundo apellido"
                  defaultValue={segApDoc}
                  name="apellidoMaternoDoc"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Fecha de nacimiento: (Opcional)</Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="text"
                  className="inpFechaDoc"
                  defaultValue={fechaNacimientoDoc}
                  name="fechaNacimientoDoc"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Telefono celular: </Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="tel"
                  className="inpTelefonoDoc"
                  placeholder="Ingresa telefono a 10 digitos"
                  defaultValue={telCelDoc}
                  name="telefonoCelularDoc"
                  disabled={!inputEnabled}
                  onChange={(e) => settelCelDoc(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Correo Electrónico: </Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="email"
                  className="inpCorreoDoc"
                  placeholder="ejemplo@ejemplo.com"
                  defaultValue={correoUsuario}
                  name="emailDoc"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Nombre consultorio: </Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  type="text"
                  placeholder="Nombre de consultorio"
                  defaultValue={consultorio}
                  name="nombreConsultorio"
                  onChange={(e) => setNombreConsultorio(e.target.value.toUpperCase())}
                  disabled={!inputEnabled}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={3}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblColonia">Observaciones: </Form.Label>
              </Col>
              <Col sm={12} md={9}>
                <Form.Control
                  className="txtObservacionesDoc "
                  as="textarea"
                  rows={3}
                  placeholder="Observaciones"
                  defaultValue={observacionesDoc}
                  name="observacionesDoc"
                  disabled={!inputEnabled}
                  onChange={(e) => setObservacionesDoc(e.target.value)}
                />
              </Col>
              <br />
              <h4>Domicilio (opcional)</h4>
              <hr />
              <Form.Group className="dom1Cli">
                <Form.Label className="lblCalle">Calle: </Form.Label>
                <Form.Control
                  type="text"
                  className="inpCalle"
                  placeholder="Ingresa calle"
                  value={calle}
                  onChange={(e) => setCalle(e.target.value)}
                  disabled={!inputEnabled}
                />
                <Form.Label className="lblnExt">Num Ext: </Form.Label>
                <Form.Control
                  type="number"
                  className="inpNumExt"
                  placeholder="Ingresa numero exterior"
                  value={numeroExterior}
                  onChange={(e) => setNumeroExterior(e.target.value)}
                  disabled={!inputEnabled}
                />
                <Form.Label className="lblnInt">Num Int: </Form.Label>
                <Form.Control
                  type="number"
                  className="inpNumInt"
                  placeholder="Ingresa numero interior"
                  value={numeroInterior}
                  onChange={(e) => setNumeroInterior(e.target.value)}
                  disabled={!inputEnabled}
                />
              </Form.Group>
              <Form.Group className="dom2Cli">
                <div className="colonia">
                  <Form.Label className="lblColonia">Colonia: </Form.Label>
                  <Form.Control
                    type="text"
                    className="inpColonia"
                    placeholder="Ingresa colonia"
                    value={colonia}
                    onChange={(e) => setColonia(e.target.value)}
                    disabled={!inputEnabled}
                  />
                </div>
                <div className="estadoCli">
                  <Form.Label className="lblEstadoEst">Estado</Form.Label>
                  <Form.Control
                    as="select"
                    value={estadoPais}
                    onChange={(e) => setEstadoPais(e.target.value)}
                    disabled={!inputEnabled}
                  >
                    <option>Selecciona un Estado</option>
                    {estadosPaisData.map((estado) => (
                      <option key={estado.clave} value={estado.nombre}>
                        {estado.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div className="municipioCli">
                  <Form.Label className="lblMunicipio">Municipio</Form.Label>
                  <Form.Control
                    as="select"
                    value={municipioSeleccionado}
                    onChange={(e) => setMunicipioSeleccionado(e.target.value)}
                    disabled={!inputEnabled}
                  >
                    <option>Selecciona un Municipio</option>
                    {municipiosEstadoData[estadoPais] &&
                      municipiosEstadoData[estadoPais].map((municipio) => (
                        <option key={municipio} value={municipio}>
                          {municipio}
                        </option>
                      ))}
                  </Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="dom3Cli">
                <Form.Label className="lblCP">Codigo Postal: </Form.Label>
                <Form.Control
                  type="number"
                  className="inpCP"
                  placeholder="Ingresa Codigo Postal"
                  value={codigoPostal}
                  onChange={(e) => setCodigoPostal(e.target.value)}
                  disabled={!inputEnabled}
                />
                <Form.Label className="lblEsquina">Referencia: </Form.Label>
                <Form.Control
                  type="text"
                  className="inpEsquina"
                  placeholder="Ingresa Referencia"
                  value={haceEsquina}
                  onChange={(e) => setHaceEsquina(e.target.value)}
                  disabled={!inputEnabled}
                />
              </Form.Group>
              <br />
              {/* Botón para agregar dirección */}
              {inputEnabled && (
                <div>
                  <br />
                  <button type="button" onClick={handleAddAddress}>Agregar Dirección</button>
                </div>
              )}
              <br />
              {/* Tabla para mostrar las direcciones */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Calle</th>
                    <th>Num Ext</th>
                    <th>Num Int</th>
                    <th>Colonia</th>
                    <th>Estado</th>
                    <th>Municipio</th>
                    <th>Codigo Postal</th>
                    <th>Referencia</th>
                  </tr>
                </thead>
                <tbody>
                  {addresses?.map((address, index) => (
                    <tr key={index}>
                      <td>{address.calle}</td>
                      <td>{address.numeroExterior}</td>
                      <td>{address.numeroInterior}</td>
                      <td>{address.colonia}</td>
                      <td>{address.estado}</td>
                      <td>{address.municipio}</td>
                      <td>{address.codigoPostal}</td>
                      <td>{address.haceEsquina}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <br />
            </Row>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button variant="success" onClick={onSubmit}>
              <FontAwesomeIcon icon={faFloppyDisk} />
              &nbsp; Guardar Datos
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DatosDocInicio;
