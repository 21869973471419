import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { eliminarEstablecimientos } from "../../../api/establecimientos";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function EliminarCliente({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        razonSocial: data[1],
        estadoEstablecimiento: data[2],
        municipio: data[3],
        colonia: data[4],
        calle: data[5],
        numeroExterior: data[6],
        numeroInterior: data[7],
        codigoPostal: data[8],
        telefono: data[9],
        haceEsquina: data[10],
        email: data[11],
        horario: data[12],
        observaciones: data[13],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            eliminarEstablecimientos(idDocumental).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha eliminado el consultorio " + idDocumental, dataTemp)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalEstablecimiento'>
            <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
                <Form.Label className='lblNombreEst'>Nombre o Razón Social: </Form.Label>
                <Form.Control
                  type='text'
                  className='inpNombreEst'
                  placeholder='Ingresa nombre'
                  defaultValue={formData.razonSocial}
                  name="razonSocial"
                  disabled
                />
                <Form.Group className='contactoCli'>
                <Form.Label className='lblTelefono'>Telefono: </Form.Label>
                <Form.Control
                  type='number'
                  className='inpTelefono'
                  placeholder='Ingresa telefono'
                  defaultValue={formData.telefono}
                  name="telefono"
                  disabled
                />
                <Form.Label className='lblEmail'>Correo Electrónico: </Form.Label>
                <Form.Control
                  type='email'
                  className='inpEmail'
                  placeholder='ejemplo@ejemplo.com'
                  defaultValue={formData.email}
                  name="email"
                  disabled
                />
              </Form.Group>
              <Form.Group className='dom1Cli'>
                <Form.Label className='lblCalle'>Calle: </Form.Label>
                <Form.Control
                  type='text'
                  className='inpCalle'
                  placeholder='Ingresa calle'
                  defaultValue={formData.calle}
                  name="calle"
                  disabled
                />
                <Form.Label className='lblnExt'>Num Ext: </Form.Label>
                <Form.Control
                  type='number'
                  className='inpNumExt'
                  placeholder='Ingresa numero exterior'
                  defaultValue={formData.numeroExterior}
                  name="numeroExterior"
                  disabled
                />
                <Form.Label className='lblnInt'>Num Int: </Form.Label>
                <Form.Control
                  type='number'
                  className='inpNumInt'
                  placeholder='Ingresa numero interior'
                  defaultValue={formData.numeroInterior}
                  name="numeroInterior"
                  disabled
                />
              
              </Form.Group>
              <Form.Group className='dom2Cli'>
              <div className="colonia">
              <Form.Label className='lblColonia'>Colonia: </Form.Label>
                <Form.Control
                  type='text'
                  className='inpColonia'
                  placeholder='Ingresa colonia'
                  defaultValue={formData.colonia}
                  name="colonia"
                  disabled
                />
                </div>
              </Form.Group>
              <Form.Group className='dom3Cli'>
                <Form.Label className='lblCP'>Codigo Postal: </Form.Label>
                <Form.Control
                  type='number'
                  className='inpCP'
                  placeholder='Ingresa Codigo Postal'
                  defaultValue={formData.codigoPostal}
                  name="codigoPostal"
                  disabled
                />                
                <Form.Label className='lblEsquina'>Hace esquina con: </Form.Label>
                <Form.Control
                  type='text'
                  className='inpEsquina'
                  placeholder='Ingresa Referencia'
                  defaultValue={formData.haceEsquina}
                  name="haceEsquina"
                  disabled
                />
              </Form.Group>
                <Form.Label className='lblHorario'>Horario: </Form.Label>
                <Form.Control
                  type='text'
                  className='inpHorario'
                  placeholder='Ingresa horario'
                  defaultValue={formData.horario}
                  name="horario"
                  disabled
                />
              <Form.Group className='bloque6Est'>
                <Form.Label>Observaciones:</Form.Label>
                <Form.Control
                  placeholder="Observaciones"
                  className='txtObservaciones'
                  as="textarea" rows={2}
                  defaultValue={formData.observaciones}
                  name="observaciones"
                  disabled
                />
              </Form.Group>
              <br />
              <label></label>
              <div className="divSubmit">
                <input className="submit" value="Enviar" type="submit" />
              </div>
            </Form>
          </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        razonSocial: data.razonSocial,
        estadoEstablecimiento: data.estadoEstablecimiento,
        municipio: data.municipio,
        colonia: data.colonia,
        calle: data.calle,
        numeroExterior: data.numeroExterior,
        numeroInterior: data.numeroInterior,
        codigoPostal: data.codigoPostal,
        telefono: data.telefono,
        haceEsquina: data.haceEsquina,
        email: data.email,
        horario: data.horario,
        observaciones: data.observaciones,
    };
}
