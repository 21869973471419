import {
  faCommentMedical,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Badge } from "react-bootstrap";
import { ChatList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import MensajesChat from "./MensajesChat";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import {
  listarChats,
  obtenerChatsIdChat,
  registraMensajeria,
  marcarMensajesComoLeidos,
} from "../../../api/mensajeria";
import { toast } from "react-toastify";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import NuevoMensaje from "./NuevoMensaje";
import profilePhoto from "../../../assets/img/profile.jpg";
import { Load } from "../../load/load";

const PrincipalChat = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [chatsList, setChatsList] = useState([]);

  const obtenerChats = () => {
    try {
      listarChats()
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          setChatsList(formatModelChats(data));
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerChatsUsuario = (email) => {
    try {
      obtenerChatsIdChat(email)
        .then((response) => {
          const { data } = response;
          setChatsList(formatModelChats(data));
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    const fetchChats = () => {
      obtenerChatsUsuario(correoUsuario);
      // if (!showModal) {
      //   if (tipoUsuario === "mesa de control") {
      //     obtenerChats();
      //   } else if (tipoUsuario === "doctor" || tipoUsuario === "consultorio") {
      //     obtenerChatsUsuario(correoUsuario);
      //   }
      // }
    };

    fetchChats(); // Ejecuta la función al inicio

    const intervalId = setInterval(fetchChats, 60000); // Ejecuta la función cada minuto

    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonte
  }, [tipoUsuario, correoUsuario, showModal]);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    hora = hora < 10 ? `0${hora}` : hora;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };

  const [mensajeText, setMensajeText] = useState("");
  const [flag, setFlag] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!mensajeText) {
      toast.warning("Completa el formulario");
    } else {
      try {
        const dataTemp2 = {
          idChat: userMessage.email,
          nombreUsuario:
            tipoUsuario !== "mesa de control"
              ? nombreUsuario
              : "Mesa de control",
          correoUsuario: correoUsuario,
          tipoMensaje: "text",
          fechaHora: obtenerFechaActual(),
          mensaje: mensajeText,
          nombreIdChat: userMessage.nombreChat,
          estado: "0",
        };
        registraMensajeria(dataTemp2).then((response) => {
          const { data } = response;
          setMensajeText("");
          setFlag(true);
          obtenerChatsUsuario(correoUsuario);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [userMessage, setUserMessage] = useState(null);

  const handleChatClick = (chat) => {
    setUserMessage(chat);

    // Marcar los mensajes como leídos
    marcarMensajesComoLeidos(chat.email)
      .then((response) => {
        // Actualiza el estado de chatsList para establecer estado en "1" (leído)
        setChatsList((prevChatsList) =>
          prevChatsList.map((c) =>
            c.idChat === chat.email ? { ...c, estado: "1" } : c
          )
        );
      })
      .catch((error) => {
        console.error("Error al marcar los mensajes como leídos:", error);
      });
  };

  const registroOC = (content) => {
    setTitulosModal("Nuevo mensaje");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <>
      {loading && <Load />}
      <div style={{ margin: "1%", minHeight: "90vh", maxHeight: "90vh" }}>
        <Row style={{ minHeight: "90vh", maxHeight: "90vh" }}>
          <Col sm={4} md={4} lg={4}>
            <div
              className="card card-row card-primary"
              style={{ minHeight: "100%", maxHeight: "100%" }}
            >
              <div
                className="card-header d-flex justify-content-between"
                style={{ backgroundColor: "#9c182d" }}
              >
                <h3 className="card-title">Chats</h3>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="link"
                    style={{ color: "#0c1b30" }}
                    title="Nuevo chat"
                    onClick={() =>
                      registroOC(
                        <NuevoMensaje
                          setShow={setShowModal}
                          nombre={nombreUsuario}
                          email={correoUsuario}
                          tipo={tipoUsuario}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCommentMedical} />
                  </Button>
                </div>
              </div>
              <div className="card-body">
                {chatsList.length > 0 ? (
                  <ChatList
                    className="chat-list"
                    dataSource={chatsList.map((message) => ({
                      avatar: profilePhoto,
                      alt: message.nombreUsuario,
                      title: (
                        <>
                          {tipoUsuario != "doctor"
                            ? message.nombreIdChat != nombreUsuario
                              ? message.nombreIdChat
                              : message.nombreUsuario
                            : "Mesa de control"}
                          {message.estado === "0" && (
                            <Badge bg="danger" style={{ marginLeft: "10px" }}>
                              Nuevo
                            </Badge>
                          )}
                        </>
                      ),
                      subtitle: message.nombreUsuario + ": " + message.mensaje,
                      date: new Date(message.fechaHora),
                      email: message.idChat,
                      nombreChat: message.nombreIdChat,
                      estado: message.estado,
                      unreadCount: message.estado === "0" ? 1 : 0, // Agregar esta línea
                    }))}
                    onClick={handleChatClick}
                  />
                ) : (
                  <h4>No hay historial de mensajes</h4>
                )}
              </div>
            </div>
          </Col>
          <Col
            style={{
              minHeight: "100%",
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {userMessage ? (
              <>
                <div style={{ flex: 1 }}>
                  <MensajesChat
                    email={userMessage.email}
                    identifica={flag}
                    setFlag={setFlag}
                    usuario={correoUsuario}
                    nombre={userMessage.title}
                  />
                </div>
                <div style={{ display: "flex", width: "100%" }} className="mt-2">
                  <div style={{ width: "95%" }}>
                    <Form.Control
                      as="textarea"
                      placeholder="Escribe tu mensaje"
                      value={mensajeText}
                      onChange={(e) => setMensajeText(e.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center ml-2">
                    <Button
                      style={{
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                      }}
                      onClick={sendMessage}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <p>Pulsa una conversación para ver los mensajes</p>
            )}
          </Col>
        </Row>
        <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
          {contentModal}
        </BasicModal>
      </div>
    </>
  );
};

function formatModelChats(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      correoUsuario: data.correoUsuario,
      fechaHora: data.fechaHora,
      idChat: data.idChat,
      mensaje: data.mensaje,
      nombreUsuario: data.nombreUsuario,
      tipoMensaje: data.tipoMensaje,
      nombreIdChat: data.nombreIdChat,
      estado: data.estado || "1", // Se asume que el estado es "1" por defecto (leído)
    });
  });
  return dataTemp;
}

export default PrincipalChat;
