import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { registraCursos } from "../../api/cursos";
import queryString from "query-string";
import { map } from "lodash";
import { LogsInformativos } from "../logs/logs";

export default function RegistroCursos({ history, setShow }) {

    const [formData, setFormData] = useState(initialFormValue);
    const [signInLoading, setSignInLoading] = useState(false);

    const tipoArr = [
        "Curso",
        "Capacitacion",
    ];

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!formData.nombre || !formData.tipo || !formData.descripcion) {
            toast.warning("Completa todos los campos del formulario.");
        } else {
            setSignInLoading(true);
            try {
                const dataTemp = {
                    nombre: formData.nombre,
                    tipo: formData.tipo,
                    descripcion: formData.descripcion,
                    estado: "true"
                };
                registraCursos(dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha registrado el curso " + dataTemp.nombre, dataTemp)
                    setSignInLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                }).catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                        setSignInLoading(false);
                    } else {
                        if (ex.response && ex.response.status === 401) {
                            const { mensaje } = ex.response.data;
                            toast.error(mensaje);
                            setSignInLoading(false);
                        }
                    }
                });
            } catch (ex) {
                toast.error("Error al iniciar sesión");
                setSignInLoading(false);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Nombre: <code>*</code></Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="nombre"
                        defaultValue={formData.nombre}
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Tipo: <code>*</code></Form.Label>
                    <Form.Control
                        as="select"
                        className="tipo"
                        placeholder="tipo"
                        name="tipo"
                        defaultValue={formData.tipo}
                    >
                        <option value="">Seleccionar</option>
                        {tipoArr.map((tipo, index) => (
                            <option value={tipo} key={index}>
                                {tipo}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Label>Descripción: <code>*</code></Form.Label>
                    <Form.Control
                        type="text"
                        className="descripcion"
                        placeholder="Ingrese una descripcion"
                        name="descripcion"
                        defaultValue={formData.descripcion}
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue() {
    return {
        nombre: "",
        tipo: "",
        descripcion: "",
        fecha: "",
    };
}

