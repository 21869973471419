import { useState, useEffect } from "react";
import queryString from "query-string";
import { Button, Col, Form, Row, Spinner, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTrashCan, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import BusquedaEmpleados from "./BuscarEmpleados";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { toast } from "react-toastify";
import { registraAsistencia } from "../../api/asistencia";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

function RegistrarAsistencia(props) {
  const { setShowModal, location, history } = props;

  // Importa el complemento de zona horaria
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  const [fechayHora, setFechayHora] = useState("");
  const [fechayHoraSinFormato, setFechayHoraSinFormato] = useState("");

  useEffect(() => {
    const hoy = new Date();
    const adjustedDate = dayjs(hoy).utc().utcOffset(-360).format(); // Ajusta la hora a CST (UTC -6)

    setFechayHora(dayjs(adjustedDate).locale('es').format('dddd, LL hh:mm A'));
    setFechayHoraSinFormato(adjustedDate);
  }, []);

  // Para almacenar los datos del formulario
  const [formData, setFormData] = useState(initialFormData());

  // Para controlar el modal de busqueda de socios
  const [showModalBusqueda, setShowModalBusqueda] = useState(false);
  const [contentModalBusqueda, setContentModalBusqueda] = useState(null);
  const [titulosModalBusqueda, setTitulosModalBusqueda] = useState(null);

  // Para el modal de busqueda
  const busquedaSocios = (content) => {
    setTitulosModalBusqueda("Búsqueda de socio");
    setContentModalBusqueda(content);
    setShowModalBusqueda(true);
  };

  // Para controlar la animación
  const [loading, setLoading] = useState(false);

  // Para cancelar el registro
  const cancelarRegistro = () => {
    setShowModal(false);
  };

  // Para almacenar el id, ficha y nombre del socio elegido
  const [idSocioElegido, setIdSocioElegido] = useState("");
  const [departamentoSocioElegido, setDepartamentoSocioElegido] = useState("");
  const [nombreSocioElegido, setNombreSocioElegido] = useState("");

  const hoy = new Date();
  // const fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear() + " " + hora;
  const fecha =
    hoy.getDate() < 10
      ? hoy.getFullYear() +
      "-" +
      (hoy.getMonth() + 1) +
      "-" +
      "0" +
      hoy.getDate()
      : hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();

  const hora =
    hoy.getHours() < 10
      ? "0" + hoy.getHours() + ":" + hoy.getMinutes()
      : hoy.getMinutes() < 10
        ? hoy.getHours() + ":" + "0" + hoy.getMinutes()
        : hoy.getHours() < 10 && hoy.getMinutes() < 10
          ? "0" + hoy.getHours() + ":" + "0" + hoy.getMinutes()
          : hoy.getHours() + ":" + hoy.getMinutes();

  const [fechaActual, setFechaActual] = useState(fecha + "T" + hora);

  console.log(dayjs(fechayHoraSinFormato).tz('America/Mexico_City').format('YYYY-MM-DDTHH:mm:ss.SSSZ'))

  const onSubmit = (e) => {
    e.preventDefault();
    const formattedDate = dayjs(fechayHoraSinFormato).tz('America/Mexico_City').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    if (!nombreSocioElegido) {
      toast.warning("Debe elegir un socio");
    } else {
      setLoading(true);

      const dataTemp = {
        empleado: nombreSocioElegido,
        usuario: departamentoSocioElegido,
        estado: "1",
        fecha: formattedDate,
        createdAt: formattedDate
      };

      registraAsistencia(dataTemp)
        .then((response) => {
          const { data } = response;

          toast.success(data.mensaje);
          setTimeout(() => {
            setLoading(false);
            history({
              search: queryString.stringify(""),
            });
            setShowModal(false);
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const eliminaBusqueda = () => {
    setDepartamentoSocioElegido("");
    setNombreSocioElegido("");
    setIdSocioElegido("");
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="contenidoFormularioPrincipal">
        <Form onSubmit={onSubmit} onChange={onChange}>
          {/* Ficha, nombre */}
          <Row className="mb-3">
            {nombreSocioElegido ? (
              <>
                <Form.Group as={Col} controlId="formGridFicha">
                  <Form.Label>
                    Nombre{" "}
                    <FontAwesomeIcon
                      className="eliminaBusqueda"
                      icon={faTrashCan}
                      onClick={() => {
                        eliminaBusqueda();
                      }}
                    />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="nombre del socio"
                    name="nombre"
                    defaultValue={nombreSocioElegido}
                    disabled
                  />
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridNombre">
                    <Form.Label>
                      Departamento{" "}
                      <FontAwesomeIcon
                        className="eliminaBusqueda"
                        icon={faTrashCan}
                        onClick={() => {
                          eliminaBusqueda();
                        }}
                      />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Departamento del socio"
                      name="departamento"
                      defaultValue={departamentoSocioElegido}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </>
            ) : (
              <>
                <Form.Group
                  controlId="formGridBusqueda"
                  className="d-flex justify-content-end"
                >
                  <Button
                    type="button"
                    className="btnAddTables"
                    onClick={() => {
                      busquedaSocios(
                        <BusquedaEmpleados
                          setShowModal={setShowModalBusqueda}
                          setIdSocioElegido={setIdSocioElegido}
                          setDepartamentoSocioElegido={
                            setDepartamentoSocioElegido
                          }
                          setNombreSocioElegido={setNombreSocioElegido}
                          idSocioElegido={idSocioElegido}
                          departamentoSocioElegido={departamentoSocioElegido}
                          nombreSocioElegido={nombreSocioElegido}
                        />
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Busca el socio
                  </Button>
                </Form.Group>
              </>
            )}
          </Row>

          <Form.Group as={Row} className="botones">
            <Col className="d-flex justify-content-center">
              <Button type="submit" variant="success" className="registrar">
                {!loading ? (<><FontAwesomeIcon icon={faUserCheck} /> Registrar</>) : <Spinner animation="border" />}
              </Button>
            </Col>
            <Col style={{ display: "none" }}>
              <Button
                variant="danger"
                className="cancelar"
                onClick={() => {
                  cancelarRegistro();
                }}
              >
                Cancelar
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>

      <BasicModal
        show={showModalBusqueda}
        setShow={setShowModalBusqueda}
        title={titulosModalBusqueda}
      >
        {contentModalBusqueda}
      </BasicModal>
    </>
  );
}

function initialFormData() {
  return {
    fecha: "",
  };
}

export default RegistrarAsistencia;
