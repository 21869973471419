import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarPreciosOrden } from "../../../api/preciosOrden";
import { Load } from "../../load/load";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaPreciosOrden = (props) => {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarCargosyDescuentos, setListarCargosyDescuentos] = useState([]);

  const obtenerCyD = () => {
    try {
      listarPreciosOrden()
        .then((response) => {
          const { data } = response;
          console.log(data);

          if (!listarCargosyDescuentos && data) {
            setListarCargosyDescuentos(formatModelCargosyDescuentos(data));
          } else {
            const datosViaje = formatModelCargosyDescuentos(data);
            setListarCargosyDescuentos(datosViaje);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerCyD();
  }, [location]);

  const columns = [
    {
      name: "id",
      label: "ID TRANSACCIÓN",
    },
    {
      name: "ordenServicio",
      label: "ORDEN DE CLIENTE",
    },
    {
      name: "tipoOperacion",
      label: "TIPO DE OPERACIÓN",
    },
    {
      name: "concepto",
      label: "CONCEPTO",
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        customBodyRender: (value) => <span>{formatCurrency(value)}</span>,
      },
    },
    {
      name: "correoRegistro",
      label: "REGISTRÓ",
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <div style={{ marginTop: "1%" }}>
      {loading && <Load />}
      <h1>Cargos y descuentos registrados</h1>
      <div className="divTabla" style={{ textTransform: "capitalize" }}>
        <MUIDataTable
          title={"Lista de cargos y descuentos registrados"}
          data={listarCargosyDescuentos}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

function formatModelCargosyDescuentos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      tipoOperacion: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
      correoRegistro: data.correoRegistro,
      fecha: data.fecha,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default TablaPreciosOrden;
