import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarUnidadMedida,
    ENDPOINTObtenerUnidadMedida,
    ENDPOINTListarUnidadMedida,
    ENDPOINTActualizarUnidadMedida,
    ENDPOINTEliminarUnidadMedida,
    ENDPOINTDeshabilitarUnidadMedida,
    ENDPOINTObtenerFolioUnidadMedida
} from "./endpoints";
import axios from 'axios';

export async function listarUnidadMedida(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarUnidadMedida, config);
}

export async function registraUnidadMedida(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarUnidadMedida, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerUnidadMedida(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUnidadMedida + `/${params}`, config);
}

export async function actualizarUnidadMedida(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarUnidadMedida + `/${id}`, data, config);
}

export async function eliminarUnidadMedida(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarUnidadMedida + `/${id}`, data, config);
}

export async function actualizaDeshabilitarUnidadMedida(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarUnidadMedida + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroUnidadMedida() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioUnidadMedida, config);
}