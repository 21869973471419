import { Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarRecursosHumanos } from "../../api/recursosHumanos";
import { toast } from "react-toastify";
import { LogsInformativos } from "../logs/logs";
import queryString from "query-string";

export default function RenovarContrato({ data, history, setShow }) {
  // Ensure data exists and has the required format
  const idDocumental = data && data.length > 0 ? data[0] : null;

  const [formData, setFormData] = useState(initialFormValue());
  const [loading, setLoading] = useState(true);
  const [indefinido, setIndefinido] = useState(false);

  useEffect(() => {
    // Simulate a data load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!idDocumental) {
      toast.error("Invalid data provided.");
      return;
    }

    try {
      setLoading(true);

      const dataTemp = {
        fechaTermino: indefinido ? "indefinido" : formData.fechaTermino,
      };

      const response = await actualizarRecursosHumanos(idDocumental, dataTemp);
      const { data } = response;

      // Log the update
      LogsInformativos("Se ha actualizado el contrato del empleado " + idDocumental, dataTemp);

      toast.success(data.mensaje);
      history({
        search: queryString.stringify(""),
      });
      setShow(false);
    } catch (error) {
      toast.error("Error updating contract. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = () => {
    setIndefinido(!indefinido);
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalEstablecimiento">
        <Form className="formEstablecimiento" onSubmit={onSubmit} onChange={onChange}>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col sm={12} md={4} lg={4} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
              <Form.Check
                type="checkbox"
                label="Contrato indefinido"
                checked={indefinido}
                onChange={onCheckboxChange}
              />
            </Col>
          </Row>
          {!indefinido && (
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col sm={12} md={4} lg={4} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
                <Form.Label>Nueva fecha de vencimiento del contrato: </Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
                <Form.Control
                  type="date"
                  placeholder="Folio"
                  defaultValue={formData.fechaTermino}
                  name="fechaTermino"
                />
              </Col>
            </Row>
          )}
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    fechaTermino: "",
  };
}
