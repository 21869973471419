// Constante para desarrollo
// export const API_HOST = "http://localhost:5050";

// Constantes para puesta en produccion
// export const API_HOST = "https://api.grupodentalherfran.com"

// constantes para las pruebas
export const API_HOST = "https://demoapilab.grupodentalherfran.com"

// API para la consulta de la IP pública
export const API_IP = "https://api.ipify.org";

export const API_CLOUDINARY = "https://api.cloudinary.com/v1_1/omarlestrella/image/upload";

export const TOKEN = "token";

