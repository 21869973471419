import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { actualizarIncidencias } from "../../api/incidencias";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function ModificarIncidencias({ data, history, setShow }) {
    console.log(data);
    const idDocumental = data[0];

    const dataTemp = {
        folio: data[1],
        fecha: data[2],
        descripcion: data[3],
        departamento: data[4]
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));
    const [signInLoading, setSignInLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fecha || !formData.descripcion) {
            toast.warning("Completa todos los campos del formulario.");
        } else {
            setSignInLoading(true);
            try {
                const dataTemp = {
                    fecha: formData.fecha,
                    descripcion: formData.descripcion,
                    departamento: formData.departamento,
                };
                actualizarIncidencias(idDocumental, dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha modificado la incidencia " + dataTemp.descripcion, dataTemp)
                    setSignInLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                }).catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                        setSignInLoading(false);
                    } else {
                        if (ex.response && ex.response.status === 401) {
                            const { mensaje } = ex.response.data;
                            toast.error(mensaje);
                            setSignInLoading(false);
                        }
                    }
                });
            } catch (ex) {
                toast.error("Error al iniciar sesión");
                setSignInLoading(false);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Folio: </Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="folio"
                        value={formData.folio}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Fecha: </Form.Label>
                    <Form.Control
                        type="date"
                        className="emailNewUser"
                        placeholder="fecha"
                        name="fecha"
                        defaultValue={formData.fecha}
                    />
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control
                        type="text"
                        className="passNewUser"
                        placeholder="Ingrese la descripcion"
                        name="descripcion"
                        defaultValue={formData.descripcion}
                    />
                    <Form.Label className="folioOS2">
                        Departamento
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="localidadOS"
                        name="departamento"
                        defaultValue={formData.departamento}
                        placeholder="Departamento"
                        disabled
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue(data) {
    return {
        folio: data.folio,
        fecha: data.fecha,
        descripcion: data.descripcion,
        departamento: data.departamento
    };
}

