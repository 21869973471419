import React, { useState } from "react";

function OpenpayPaymentPagos() {
  const [transactionUrl, setTransactionUrl] = useState("");
  const [paymentData, setPaymentData] = useState({
    customer: {
      name: 'Juan',
      last_name: 'Vazquez Juarez',
      phone_number: '7731440261',
      email: 'albertoalmarazmeza@gmail.com'
    },
    amount: 10.00,
    method: "bank_account",
    due_date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // Set due date to 24 hours in the future
    description: "pago",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const YOUR_API_KEY = "sk_bf32c8b3b2d342baace620c67b7f10c2"; // Replace with your API Key
      const YOUR_API_SECRET = "pk_2b13389c063a4b789e8e81e4040557e1"; // Replace with your Secret Key

      console.log("Payment Data:", paymentData); // Log payment data

      const response = await fetch(
        "https://sandbox-api.openpay.mx/v1/mh3qxtumcdo4u0fuvbg3/charges/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Basic ${btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`)}`,
          },
          body: JSON.stringify(paymentData),
        }
      );

      const responseBody = await response.json(); // Get response body

      if (response.ok) {
        console.log("Pago realizado:", responseBody);
        const urlSPEI = responseBody.payment_method.url_spei;
        console.log("URL SPEI:", urlSPEI);
        setTransactionUrl(urlSPEI);
      } else {
        console.error("Error al realizar el pago:", responseBody);
      }
    } catch (error) {
      console.error("Error de red:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="amount"
          placeholder="Monto"
          value={paymentData.amount}
          onChange={handleChange}
        />
        <input
          type="text"
          name="description"
          placeholder="Descripción"
          value={paymentData.description}
          onChange={handleChange}
        />
        <button type="submit">Realizar Cargo</button>
      </form>

      {transactionUrl && (
        <div>
          <p>Transacción en progreso. Haz clic en el siguiente enlace para confirmar:</p>
          <a href={transactionUrl} target="_blank" rel="noopener noreferrer">
            Confirmar transacción
          </a>
        </div>
      )}
    </>
  );
}

export default OpenpayPaymentPagos;
