import { useState, useEffect } from 'react';
import { listarConfiguracionRutas } from "../../api/configuracionRutas";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale("es"); // use Spanish locale globally
dayjs.extend(localizedFormat);

function FechaEntrega(props) {
    const { id, fecha, ruta, listarOrdenes } = props;
    const [listarRutas, setListRutas] = useState([]);

    console.log(ruta, fecha)

    const obtenerFechaDelDia = (dia) => {
        const diasDeLaSemana = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
        const hoy = new Date();
        const diaIndex = diasDeLaSemana.indexOf(dia.toLowerCase());

        if (diaIndex === -1) return null;

        const diaActual = hoy.getDay();
        const diferenciaDias = (diaIndex - diaActual + 7) % 7;
        const fechaDeseada = new Date(hoy);
        fechaDeseada.setDate(hoy.getDate() + diferenciaDias);

        return fechaDeseada.toISOString().split('T')[0];
    };

    const compararFechas = (fecha1, fecha2) => {
        const fecha1Date = new Date(fecha1);
        const fecha2Date = new Date(fecha2);
        return (fecha2Date - fecha1Date) / (1000 * 60 * 60 * 24); // Diferencia en días
    };

    const sumarUnaSemana = (fecha) => {
        const nuevaFecha = new Date(fecha);
        nuevaFecha.setDate(nuevaFecha.getDate() + 7);
        return nuevaFecha.toISOString().split('T')[0];
    };

    const formatModelRutas = (data) => {
        const formattedFecha = fecha.split(" ")[0]; // Fecha de entrega en formato YYYY-MM-DD

        return data.map((item) => {
            const fechaDelDia = obtenerFechaDelDia(item.dia);
            if (fechaDelDia) {
                const diferenciaDias = compararFechas(formattedFecha, fechaDelDia);

                // Crear una nueva variable para almacenar la fecha ajustada
                let fechaAjustada = fechaDelDia;

                // Si fechaDelDia es menos de 3 días mayor a formattedFecha, sumar una semana
                if (diferenciaDias < 3) {
                    fechaAjustada = sumarUnaSemana(fechaDelDia);
                }

                return {
                    id: item._id,
                    folio: item.folio,
                    nombre: item.nombre, // Assuming nombre is an array
                    descripcion: item.descripcion,
                    coordenadas: item.coordenadas,
                    dia: item.dia,
                    datosMensajero: item.datosMensajero,
                    datosVehiculo: item.datosVehiculo,
                    estado: item.estado,
                    fechaDelDia: fechaAjustada, // Agregado al objeto de ruta
                    hora: item.hora
                };
            }
            return null; // O manejar el caso en que no se pueda calcular fechaDelDia
        }).filter(item => item !== null); // Filtrar valores nulos
    };

    const obtenerRutas = async () => {
        try {
            const response = await listarConfiguracionRutas();
            const { data } = response;

            if (Array.isArray(data)) {
                let datosRutas = formatModelRutas(data);
                const rutaLowerCase = ruta.toLowerCase();

                // Filtrar las rutas que cumplen con la condición del nombre
                datosRutas = datosRutas.filter((r) =>
                    r.nombre.some(n => n.toLowerCase().includes(rutaLowerCase))
                );

                // Validar si fechaDelDia existe en listarOrdenes
                datosRutas = datosRutas.map(r => {
                    if (listarOrdenes.some(o => o.fecha === r.fechaDelDia)) {
                        r.fechaDelDia = sumarUnaSemana(r.fechaDelDia);
                    }
                    return r;
                });

                // Seleccionar la ruta con la menor diferencia de días, si cumple con la condición de los 3 días
                let rutaSeleccionada = null;
                let menorDiferencia = Infinity;

                for (const r of datosRutas) {
                    const diferenciaDias = compararFechas(fecha.split(" ")[0], r.fechaDelDia);
                    if (diferenciaDias >= 3 && diferenciaDias < menorDiferencia) {
                        menorDiferencia = diferenciaDias;
                        rutaSeleccionada = r;
                    }
                }

                // Si no hay ninguna ruta válida, sumar una semana a fechaDelDia y volver a comprobar
                if (!rutaSeleccionada) {
                    datosRutas = formatModelRutas(data); // Volver a calcular las fechas con una semana añadida
                    datosRutas = datosRutas.filter((r) =>
                        r.nombre.some(n => n.toLowerCase().includes(rutaLowerCase))
                    );

                    datosRutas = datosRutas.map(r => {
                        if (listarOrdenes.some(o => o.fecha === r.fechaDelDia)) {
                            r.fechaDelDia = sumarUnaSemana(r.fechaDelDia);
                        }
                        return r;
                    });

                    menorDiferencia = Infinity;
                    for (const r of datosRutas) {
                        const diferenciaDias = compararFechas(fecha.split(" ")[0], r.fechaDelDia);
                        if (diferenciaDias >= 3 && diferenciaDias < menorDiferencia) {
                            menorDiferencia = diferenciaDias;
                            rutaSeleccionada = r;
                        }
                    }
                }

                setListRutas(rutaSeleccionada ? [rutaSeleccionada] : []);
            } else {
                console.warn('Data is not an array:', data);
            }
        } catch (e) {
            console.error('Error al obtener las rutas:', e);
        }
    };

    useEffect(() => {
        obtenerRutas();
    }, [id, ruta]);

    return (
        <>
            {listarRutas.length > 0 ? dayjs(listarRutas[0].fechaDelDia).format("LL") : "No hay rutas disponibles"}
        </>
    );
}

export default FechaEntrega;
