import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../load/load";
import { eliminarConfiguracionRutas } from "../../api/configuracionRutas";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function EliminarConfiguracionRutas({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data.id;

    const dataTemp = {
        folio: data.folio,
        nombre: data.ruta,
        descripcion: data.descripcion,
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            eliminarConfiguracionRutas(idDocumental).then((response) => {
                const { data } = response;
                //notificacion

                toast.success(data.mensaje);
                LogsInformativos("Se ha eliminado la configuracion de la ruta " + idDocumental)
                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Label className='lblNombreDoc'>Folio: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreEst'
                        placeholder='Ingresa nombre'
                        name="nombre"
                        value={formData.folio}
                        disabled
                    />
                    <Form.Label className='lblNombreDoc'>Nombre: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreEst'
                        placeholder='Ingresa nombre'
                        name="nombre"
                        defaultValue={formData.nombre}
                        disabled
                    />
                    <Form.Group className='formObservacionesDoc'>
                        <Form.Label>Descripcion:</Form.Label>
                        <Form.Control
                            className='txtObservacionesDoc '
                            as="textarea"
                            rows={3}
                            placeholder='Descripcion'
                            defaultValue={formData.descripcion}
                            name="descripcion"
                            disabled
                        />
                    </Form.Group>


                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        folio: data.folio,
        nombre: data.nombre,
        descripcion: data.descripcion
    };
}
