// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divCantidadButtonVale{
    display: flex;
}

.divTicket{
    border: 1px solid black;
    height: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/components/material/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".divCantidadButtonVale{\n    display: flex;\n}\n\n.divTicket{\n    border: 1px solid black;\n    height: 20rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
