import { useState, useEffect } from "react";
import { Button, Form, Tab, Table, Tabs } from "react-bootstrap";
import BasicModal from "../Modal/BasicModal/BasicModal";
import AgregaRuta from "../configuracionRutas/ConfiguracionRutas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCar, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TablaRutas } from "./TablaInformativa";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import { withRouter } from "../../utils/withRouter";
import RutasConOrdenes from "./RutasConOrdenes";
import { Load } from "../load/load";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function Rutas({ history, location }) {
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [formData, setFormData] = useState(initialFormValue());

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [actualizarTabla, setActualizarTabla] = useState(false);

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroOrden = (content) => {
    setTitulosModal("Registrar ruta");
    setContentModal(content);
    setShowModal(true);
  };

  useEffect(() => {
    if (!showModal) {
      setActualizarTabla(true);
    }
  }, [showModal]);

  const formatDate = (date) => {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  console.log(formData.fecha);

  const hoy = new Date();

  const year = hoy.getFullYear();
  let month = hoy.getMonth() + 1;
  let day = hoy.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  const fechah = year + "-" + month + "-" + day;

  const [fechaHoy, setFechaHoy] = useState(fechah);

  const [selectedDate, setSelectedDate] = useState(dayjs(fechaHoy).format("LL"));

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setSelectedDate(dayjs(dateValue).format("LL"));
  };


  return (
    <>
      {loading && <Load />}
      <div className="generalRutas">
        <Form id="routeForm" onChange={onChange}>
          <h1>Asignacion de rutas del dia {selectedDate}</h1>
          <h3>En caso de ser necesario agregar nuevas ubicaciones a la ruta, eliminar el registro y volver a agregarlo</h3>
          <div className="formularioRutas">
            <Form.Control
              type="date"
              defaultValue={formData.fecha == "" ? fechaHoy : formData.fecha}
              name="fecha"
              className="fechaRuta"
              onChange={handleDateChange}
            />
          </div>

          <div className="divButton">
            {tipoUsuario == "administrador" && (
              <>
                <Button
                  variant="primary"
                  onClick={() =>
                    registroOrden(
                      <AgregaRuta
                        setShow={setShowModal}
                        fecha={formData.fecha == "" ? fechaHoy : formData.fecha}
                        history={history}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> Agregar
                </Button>
              </>
            )}
          </div>

          <div className="tablaRequisicionCompras">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
              justify
              style={{
                fontSize: "larger",
                fontWeight: "bold",
                marginTop: "1%",
              }}
            >
              <Tab
                eventKey="home"
                title={
                  <span style={{ textTransform: "capitalize" }}>
                    <FontAwesomeIcon icon={faCalendar} /> Rutas por día
                  </span>
                }
              >
                <TablaRutas
                  history={history}
                  location={location}
                  fecha={formData.fecha == "" ? fechaHoy : formData.fecha}
                  actualizarTabla={actualizarTabla}
                  setActualizarTabla={setActualizarTabla}
                  striped
                  bordered
                  hover
                  size="sm"
                />
              </Tab>
              <Tab
                eventKey="profile"
                title={
                  <span style={{ textTransform: "capitalize" }}>
                    <FontAwesomeIcon icon={faCar} /> Rutas con ordenes asignadas
                  </span>
                }
              >
                <RutasConOrdenes fecha={formData.fecha == "" ? fechaHoy : formData.fecha} />
              </Tab>
            </Tabs>
          </div>

          <BasicModal
            show={showModal}
            setShow={setShowModal}
            title={titulosModal}
          >
            {contentModal}
          </BasicModal>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    fecha: "",
  };
}

export default withRouter(Rutas);
