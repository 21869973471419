import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarDepartamentos,
    ENDPOINTObtenerDepartamentos,
    ENDPOINTListarDepartamentos,
    ENDPOINTListarDepartamentosProduccion,
    ENDPOINTActualizarDepartamentos,
    ENDPOINTEliminarDepartamentos,
    ENDPOINTDeshabilitarDepartamentos,
    ENDPOINTObtenerFolioDepartamentos,
    ENDPOINTListarDepartamentosTipo,
    ENDPOINTObtenerNombreDepartamento
} from "./endpoints";
import axios from 'axios';

// Obtener los datos de un socio por ficha
export async function obtenerNombreDepartamento(folio) {
    // console.log("Ficha ", ficha)
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //Authorization: `Bearer ${getTokenApi()}`,
      },
    }
    return axios.get(
      API_HOST + ENDPOINTObtenerNombreDepartamento + `/${folio}`,
      config
    )
  }

export async function listarDepartamentos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDepartamentos, config);
}

export async function listarDepartamentosProduccion(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDepartamentos, config);
}

export async function registraDepartamentos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarDepartamentos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerDepartamentos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerDepartamentos + `/${params}`, config);
}

export async function actualizarDepartamentos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarDepartamentos + `/${id}`, data, config);
}

export async function eliminarDepartamentos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarDepartamentos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarDepartamentos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarDepartamentos + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroDepartamentos() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioDepartamentos, config);
}

    export async function listarPorTipoDepartamento(tipo){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
               
            }
        };
        return await axios.get(API_HOST + ENDPOINTListarDepartamentosTipo + `?tipo=${tipo}`, config);
    }