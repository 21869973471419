// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-notification-container {
    position: fixed;
    top: 85%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 9999;
  }
  
  .floating-notification-button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 9999;
  }
  
  .notification-popup {
    position: absolute;
    color: black;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: none;
  }
  
  .floating-notification-button:hover .notification-popup {
    display: block;
  }
  
  .icon-move {
    animation: moveIcon 0.2s alternate infinite;
  }
  
  @keyframes moveIcon {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-5px); /* Ajusta la cantidad de movimiento según tus preferencias */
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/administrador/Dashboard/FloatingNotificationButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,WAAW;IACX,2BAA2B;IAC3B,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,0CAA0C;IAC1C,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,2CAA2C;EAC7C;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B,EAAE,4DAA4D;IAC3F;EACF","sourcesContent":[".floating-notification-container {\n    position: fixed;\n    top: 85%;\n    right: 20px;\n    transform: translateY(-50%);\n    z-index: 9999;\n  }\n  \n  .floating-notification-button {\n    padding: 10px 20px;\n    color: #fff;\n    border: none;\n    border-radius: 50%;\n    cursor: pointer;\n    position: relative;\n    z-index: 9999;\n  }\n  \n  .notification-popup {\n    position: absolute;\n    color: black;\n    top: 100%;\n    right: 0;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 10px;\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);\n    display: none;\n  }\n  \n  .floating-notification-button:hover .notification-popup {\n    display: block;\n  }\n  \n  .icon-move {\n    animation: moveIcon 0.2s alternate infinite;\n  }\n  \n  @keyframes moveIcon {\n    from {\n      transform: translateY(0);\n    }\n    to {\n      transform: translateY(-5px); /* Ajusta la cantidad de movimiento según tus preferencias */\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
