import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarGastosMensajero,
    ENDPOINTObtenerGastosMensajero,
    ENDPOINTListarGastosMensajero,
    ENDPOINTActualizarGastosMensajero,
    ENDPOINTEliminarGastosMensajero,
    ENDPOINTDeshabilitarGastosMensajero,
    ENDPOINTConsultaGastosMensajero,
    ENDPOINTListarGastosMensajeroGral,
    ENDPOINTListarMensajerosGastosGral,
} from "./endpoints";
import axios from 'axios';

// Para obtener todos los datos del usuario
export async function consultarSaldosMensajero(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerGastosMensajero + `/${params}`, config);
}

export async function listarGastosMensajeroGral(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMensajerosGastosGral, config);
}

export async function listarGastosMensajero(correoMensajero){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarGastosMensajero + `?correoMensajero=${correoMensajero}`, config);
}

export async function registraGastosMensajero(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarGastosMensajero, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerGastosMensajero(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerGastosMensajero + `/${params}`, config);
}

export async function actualizarGastosMensajero(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarGastosMensajero + `/${id}`, data, config);
}

export async function eliminarGastosMensajero(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarGastosMensajero + `/${id}`, data, config);
}

export async function actualizaDeshabilitarGastosMensajero(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarGastosMensajero + `/${id}`, data, config);
}

export async function listarSaldosMensajero(){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
    
            }
        };
        return await axios.get(API_HOST + ENDPOINTConsultaGastosMensajero, config);
    }


