import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarSaldosCliente,
    ENDPOINTObtenerSaldosCliente,
    ENDPOINTListarSaldosCliente,
    ENDPOINTActualizarSaldosCliente,
    ENDPOINTEliminarSaldosCliente,
    ENDPOINTDeshabilitarSaldosCliente,
    ENDPOINTActualizarSaldosPorCorreo,
    ENDPOINTListarSaldoPorCliente
} from "./endpoints";
import axios from 'axios';

export async function listarSaldosCliente(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarSaldosCliente, config);
}

export async function registraSaldosCliente(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarSaldosCliente, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerSaldosCliente(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerSaldosCliente + `/${params}`, config);
}

export async function actualizarSaldosCliente(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarSaldosCliente + `/${id}`, data, config);
}

export async function actualizarSaldosCorreo(correoCliente, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarSaldosPorCorreo + `/${correoCliente}`, data, config);
}

export async function listarSaldosCorreo(correoCliente) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.get(API_HOST + ENDPOINTListarSaldoPorCliente + `/${correoCliente}`, config);
}

export async function eliminarSaldosCliente(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarSaldosCliente + `/${id}`, data, config);
}

export async function actualizaDeshabilitarSaldosCliente(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarSaldosCliente + `/${id}`, data, config);
}