import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { registraCoordenadas } from "../../../api/coordenadas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";

const Ubicacion = ({ setCenter, setMaker }) => {
  
  const showToast = () => {
    toast.success("¡Coordenadas registradas éxitosamente!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const findMe = async () => {
    if (!navigator.geolocation) {
      toast.error("El navegador no soporta geolocalización", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        // Obtener la dirección usando MapQuest
        const apiKey = "2eei5oDr1QNMmUGDCY8115cQclIbiAq3"; // Reemplaza con tu clave de API de MapQuest
        const response = await fetch(
          `https://www.mapquestapi.com/geocoding/v1/reverse?key=${apiKey}&location=${latitude},${longitude}`
        );
        const data = await response.json();

        if (setCenter) {
          setCenter(latitude, longitude);
        }
        if (setMaker) {
          setMaker(latitude, longitude, "Mi ubicacion");
        }

        const addressData = data.results[0].locations[0];

        // Extraer información específica
        const street = addressData.street;
        const city = addressData.adminArea5;
        const state = addressData.adminArea3;
        const country = addressData.adminArea1;

        const completeAddress = `${street}, ${city}, ${state}, ${country}`;

        const dataTemp = {
          latitud: latitude,
          longitud: longitude,
          ubicacion: completeAddress,
          usuario: nombreUsuario,
          estado: "true",
        };

        // Registrar las coordenadas junto con la dirección
        registraCoordenadas(dataTemp);
        showToast();
      },
      (error) => {
        alert("Error al obtener ubicación");
      }
    );
  };

  return (
    <div style={{ textAlign: "center", marginTop: "2%", marginBottom: "2%" }}>
      <Button onClick={findMe} variant="danger">
        <FontAwesomeIcon icon={faLocationCrosshairs} />
        &nbsp; Ubicar
      </Button>
    </div>
  );
};

export default Ubicacion;
