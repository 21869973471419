// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generalMesas{
    padding: 1%;
}

.divButtonMesas{
    text-align: right;
    margin-bottom: 1%;
}

.divMesasView {
  display: flex;
  flex-wrap: wrap;
}

.divMesasView > div {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(25% - 10px); /* 25% width for 4 items per row with 10px spacing */
  margin: 5px; /* Adjust margin as needed for spacing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.descMesa{
    font-size: x-small;
    text-transform: uppercase;
}

.titMesa{
    text-transform: uppercase;
}

.mesaDisponible{
    background-color:rgb(47, 255, 92);
}

.sillasMesa{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px; /* Ajusta el valor según tus preferencias */
}

.sillaOcupada{
    color: red;
}

.sillaDisponible{
    color: rgb(47, 255, 92);
}

.simbologiaDiv{
    font-style: italic;
}

@media (max-width: 900px) {
    .divMesasView > div {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(50% - 10px); /* 50% width for 2 items per row with 10px spacing on smaller screens */
    }
}

@media (max-width: 767px) {
    .divMesasView > div {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(100% - 10px); /* 50% width for 2 items per row with 10px spacing on smaller screens */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/comedor/disponibilidad/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B,EAAE,oDAAoD;EAChF,WAAW,EAAE,wCAAwC;EACrD,sBAAsB,EAAE,uEAAuE;AACjG;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,eAAe;IACf,2BAA2B;IAC3B,SAAS,EAAE,2CAA2C;AAC1D;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,YAA0B;QAA1B,cAA0B;QAA1B,4BAA0B,EAAE,uEAAuE;IACvG;AACJ;;AAEA;IACI;QACI,YAA2B;QAA3B,cAA2B;QAA3B,6BAA2B,EAAE,uEAAuE;IACxG;AACJ","sourcesContent":[".generalMesas{\n    padding: 1%;\n}\n\n.divButtonMesas{\n    text-align: right;\n    margin-bottom: 1%;\n}\n\n.divMesasView {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.divMesasView > div {\n  flex: 1 0 calc(25% - 10px); /* 25% width for 4 items per row with 10px spacing */\n  margin: 5px; /* Adjust margin as needed for spacing */\n  box-sizing: border-box; /* Include padding and border in the element's total width and height */\n}\n\n.descMesa{\n    font-size: x-small;\n    text-transform: uppercase;\n}\n\n.titMesa{\n    text-transform: uppercase;\n}\n\n.mesaDisponible{\n    background-color:rgb(47, 255, 92);\n}\n\n.sillasMesa{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    gap: 10px; /* Ajusta el valor según tus preferencias */\n}\n\n.sillaOcupada{\n    color: red;\n}\n\n.sillaDisponible{\n    color: rgb(47, 255, 92);\n}\n\n.simbologiaDiv{\n    font-style: italic;\n}\n\n@media (max-width: 900px) {\n    .divMesasView > div {\n        flex: 1 0 calc(50% - 10px); /* 50% width for 2 items per row with 10px spacing on smaller screens */\n    }\n}\n\n@media (max-width: 767px) {\n    .divMesasView > div {\n        flex: 1 0 calc(100% - 10px); /* 50% width for 2 items per row with 10px spacing on smaller screens */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
