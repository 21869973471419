import React, { useState, useEffect } from "react";
import { withRouter } from "../../utils/withRouter";
import { listarCursos } from "../../api/cursos";
import { listarAsistenciaSemana } from "../../api/asistencia";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { Button, Table, Col, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function MatrizAsistencia(props) {
  const { location } = props;

  // Function to calculate the dates of a given week number in the current year
  function getDatesOfWeek(weekNumber) {
    const today = new Date();
    const year = today.getFullYear();
    const januaryFirst = new Date(year, 0, 1);
    const daysOffset = januaryFirst.getDay() - 1; // Monday as the first day of the week

    const firstWeekDate = new Date(year, 0, 1 + (7 - januaryFirst.getDay()));
    const targetWeekDate = new Date(firstWeekDate);
    targetWeekDate.setDate(firstWeekDate.getDate() + (weekNumber - 1) * 7);

    const mondayDate = new Date(targetWeekDate);
    mondayDate.setDate(targetWeekDate.getDate() - daysOffset + 1);

    const sundayDate = new Date(mondayDate);
    sundayDate.setDate(mondayDate.getDate() + 13); // Add 13 days to get the Sunday of the next week

    return { year, monday: mondayDate, sunday: sundayDate };
  }

  // Calculate current week number
  const currentDate = new Date();
  const dayOfYear = Math.floor(
    (currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 86400000
  );
  const weekNumber2 = Math.ceil(((dayOfYear + 1) / 7) - 1);
  const [semana, setSemana] = useState(weekNumber2);

  // Get dates for the selected week
  const { year, monday, sunday } = getDatesOfWeek(semana);

  // Define days of the week
  const diasDeLaSemana = [
    { id: 1, nombre: "Lunes" },
    { id: 2, nombre: "Martes" },
    { id: 3, nombre: "Miércoles" },
    { id: 4, nombre: "Jueves" },
    { id: 5, nombre: "Viernes" },
    { id: 6, nombre: "Sábado" },
    { id: 7, nombre: "Domingo" },
  ];

  // State for loading indicator
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listCursos, setListCursos] = useState([]);
  const [listCapacitaciones, setListCapacitaciones] = useState([]);

  useEffect(() => {
    obtenerCursos();
    obtenerCapacitaciones();
  }, [location, semana]);

  // Fetch courses data
  const obtenerCursos = () => {
    try {
      listarCursos()
        .then((response) => {
          const { data } = response;
          setListCursos(formatModelCursos(data));
        })
        .catch((e) => { });
    } catch (e) { }
  };

  // Fetch attendance data for the selected week
  const obtenerCapacitaciones = () => {
    try {
      listarAsistenciaSemana(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
        .then((response) => {
          const { data } = response;
          setListCapacitaciones(formatModelCapacitaciones(data));
        })
        .catch((e) => { });
    } catch (e) { }
  };

  console.log(listCapacitaciones)

  // Modal state management
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroAsistencia = (content) => {
    setTitulosModal("Registar capacitaciones");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <>
      {loading && <Load />}

      <Col sm={12} md={6} lg={6}>
        <Row>
          <Col sm={12} md={3} lg={3}>
            <Form.Label>Selecciona una quincena:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              as="select"
              value={semana}
              onChange={(e) => setSemana(e.target.value)}
            >
              <option value="">Selecciona</option>
              {[...Array(52).keys()].map((num) => {
                const { monday, sunday } = getDatesOfWeek(num + 1);
                return (
                  <option key={num + 1} value={num + 1}>
                    {`${monday.toISOString().split('T')[0]} - ${sunday.toISOString().split('T')[0]}`}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        </Row>
      </Col>

      <div>
        <h1>Matriz de Asistencia de Empleados</h1>
        <div className="divTabla">
          <Table responsive>
            <thead>
              <tr>
                <th style={{ backgroundColor: "#9c182d", color: "#FFF" }}>
                  Empleado
                </th>
                {diasDeLaSemana.map((course) => (
                  <th
                    key={course.id}
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "#9c182d",
                      color: "#FFF",
                    }}
                  >
                    {course.nombre}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listCapacitaciones.map(({ empleado, asistenciaPorDia }) => (
                <tr key={empleado}>
                  <td style={{ textTransform: "capitalize" }}>{empleado}</td>
                  {diasDeLaSemana.map((course) => (
                    <td key={course.id}>
                      {asistenciaPorDia.find((cap) => cap.diaSemana === course.id)
                        ? asistenciaPorDia.find((cap) => cap.diaSemana === course.id).asistencia
                        : 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

// Helper functions to format fetched data
function formatModelCursos(data) {
  return data.map((course) => ({
    id: course._id,
    nombre: course.nombre,
    tipo: course.tipo,
    descripcion: course.descripcion,
    fecha: course.fecha,
    estado: course.estado,
  }));
}

function formatModelCapacitaciones(data) {
  return data.map((item) => ({
    empleado: item.empleado,
    asistenciaPorDia: item.asistenciaPorDia.map((cap) => ({
      asistencia: cap.asistencia,
      diaSemana: cap.diaSemana,
    })),
  }));
}

export default withRouter(MatrizAsistencia);
