import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { eliminarComedor } from "../../api/comedor";
import { LogsInformativos } from "../logs/logs";

export default function EliminarComedor({ data, history, setShow }) {

  const idDocumental = data[0];

  const dataTemp = {
    fecha: data[1],
    monto: data[2],
    cotizacion: data[3],
    tipo: data[4],
  };

  const tiposGastos = ["Hoy", "Semana"];

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const [signInLoading, setSignInLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.monto) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      try {
        eliminarComedor(idDocumental)
          .then((response) => {
            const { data } = response;
            console.log(data);

            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });
            LogsInformativos("Se ha modificado el comedor " + idDocumental, dataTemp)
            setSignInLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
          .catch((ex) => {
            if (ex.message === "Network Error") {
              toast.error("Conexión al servidor no disponible");
              setSignInLoading(false);
            } else {
              if (ex.response && ex.response.status === 401) {
                const { mensaje } = ex.response.data;
                toast.error(mensaje);
                setSignInLoading(false);
              }
            }
          });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                defaultValue={formData.fecha}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Monto:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Monto"
                name="monto"
                style={{ textTransform: "capitalize" }}
                defaultValue={formData.monto}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Tipo:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de cliente"
                defaultValue={formData.tipo}
                name="tipo"
                disabled
              >
                <option>Seleccionar</option>
                {tiposGastos.map((tipo, index) => (
                  <option key={index} value={tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue(data) {
  return {
    fecha: data.fecha,
    cotizacion: data.cotizacion,
    monto: data.monto,
    tipo: data.tipo,
  };
}