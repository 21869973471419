import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { eliminarAlmacenMateriasPrimas } from "../../api/almacenMateriasPrimas";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function VaciarTabla({ datos, history, setShow }) {
    // Load state
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate data load
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    // Handle form submission
    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            // Map over the data array and remove each item
            await Promise.all(datos?.map(async (item) => {
                const response = await eliminarAlmacenMateriasPrimas(item?.id);
                const { data: responseData } = response;
                toast.success(responseData.mensaje);
                LogsInformativos(`Se ha eliminado el movimiento del producto ${item.id} en el almacen de materias primas`, item);
            }));

            history({
                search: queryString.stringify(""),
              });
            //toast.success("Los movimientos del producto se han eliminado correctamente.");
        } catch (e) {
            console.error("Error details:", e); // Log more details about the error
            //toast.error("Ocurrió un error al eliminar los movimientos del producto. Por favor, intente nuevamente.");
        } finally {
            setLoading(false);
            setShow(false); // Ensure the modal closes regardless of success or error
        }
    };

    return (
        <>
            {loading && <Load />}
            <div className="contact-form">
                <Form onSubmit={onSubmit}>
                    <br />
                    <h1 style={{ color: 'red' }}>
                        Esta acción vaciará la información de la tabla, esta acción no se podrá deshacer después.
                    </h1>
                    <label></label>
                    <input className="submit" value="Enviar" type="submit" />
                </Form>
            </div>
        </>
    );
}
