import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarPrecios,
    ENDPOINTObtenerPrecios,
    ENDPOINTListarPrecios,
    ENDPOINTActualizarPrecios,
    ENDPOINTEliminarPrecios,
    ENDPOINTDeshabilitarPrecios,
    ENDPOINTObtenerFolioPrecios,
    ENDPOINTObtenerPiezasDetalles,
    ENDPOINTObtenerPiezasPorMaterial,
    ENDPOINTObtenerPiezasPorDepartamento
} from "./endpoints";
import axios from 'axios';

export async function listarPrecios(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPrecios, config);
}

export async function listarPiezasDetalles(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPiezasDetalles, config);
}

export async function registraPrecios(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarPrecios, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerPrecios(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPrecios + `/${params}`, config);
}

export async function actualizarPrecios(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarPrecios + `/${id}`, data, config);
}

export async function eliminarPrecios(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarPrecios + `/${id}`, data, config);
}

export async function actualizaDeshabilitarPrecios(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarPrecios + `/${id}`, data, config);
}

export async function obtenerNumeroPieza() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioPrecios, config);
}

export async function listarPiezasPorMaterial(material){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPiezasPorMaterial + `?material=${material}`, config);
}

export async function listarPiezasPorDepartamento(departamento){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPiezasPorDepartamento + `?nombreDepartamento=${departamento}`, config);
}