// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width-image {
    width: 100%;
    height: auto; /* Mantiene la proporción de la imagen */
  }`, "",{"version":3,"sources":["webpack://./src/components/administrador/cliente/Alta.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY,EAAE,wCAAwC;EACxD","sourcesContent":[".full-width-image {\n    width: 100%;\n    height: auto; /* Mantiene la proporción de la imagen */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
