import React from "react";
import { useEffect } from "react";

const MapQuest = ({ height, width, center, tileLayer, zoom, apiKey }) => {
  useEffect(() => {
    //apykey
    window.L.mapquest.key = apiKey;
    //inicializar mapa
    const map = window.L.mapquest.map("map", {
      center,
      layers: window.L.mapquest.tileLayer(tileLayer),
      zoom,
    });
    map.addControl(window.L.mapquest.control())
  }, []);

  return (
    <div id="map" style={{ width, height }}>
      <p>Cargando mapa...</p>
    </div>
  );
};

export default MapQuest;
