import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarIncidencias,
    ENDPOINTObtenerIncidencias,
    ENDPOINTListarIncidencias,
    ENDPOINTListarIncidenciasDepartamento,
    ENDPOINTActualizarIncidencias,
    ENDPOINTEliminarIncidencias,
    ENDPOINTDeshabilitarIncidencias,
    ENDPOINTObtenerFolioIncidencias,
    ENDPOINTIncidenciasTotalNA,
} from "./endpoints";
import axios from 'axios';

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroIncidencia() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioIncidencias, config);
}

export async function listarIncidencias() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarIncidencias, config);
}

export async function listarIncidenciasDepartamento(departamento) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarIncidenciasDepartamento + `?departamento=${departamento}`, config);
}

export async function registraIncidencias(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarIncidencias, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerIncidencias(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerIncidencias + `/${params}`, config);
}

export async function actualizarIncidencias(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarIncidencias + `/${id}`, data, config);
}

export async function eliminarIncidencias(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarIncidencias + `/${id}`, data, config);
}

export async function actualizaDeshabilitarIncidencias(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarIncidencias + `/${id}`, data, config);
}

export async function obtenerNumeroIncidenciasNA() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTIncidenciasTotalNA, config);
}