import React, { useEffect, useState } from "react";
import { MapaMarcaUbicacion } from "./MapaMarcaUbicacion";
import { listarConfiguracionRutas } from "../../../api/configuracionRutas";

const RutaMensProv = ({ data1 }) => {
  const idRuta = data1.ruta.split("/")[1];
  const [listarRutas, setListRutas] = useState([]);
  const [arrLocations, setarrLocations] = useState([]);


    const obtenerRutas = async () => {
      try {
        const response = await listarConfiguracionRutas();
        const { data } = response;

        if (!listarRutas && data) {
          setListRutas(formatModelRutas(data));
        } else {
          const rutaConId = formatModelRutas(data).find(
            (ruta) => ruta.id == idRuta
          );

          if (rutaConId) {
            setListRutas([rutaConId]);
            const arrayCoordenadas = rutaConId.coordenadas;
            setarrLocations(arrayCoordenadas)
          } else {
            setListRutas([]);
          }
        }
      } catch (error) {
        console.error("Error al obtener rutas", error);
      }
    };


useEffect(() => {
obtenerRutas();
}, [idRuta])


  return (
    <div>
      <MapaMarcaUbicacion markersData={arrLocations} />
    </div>
  );
};

function formatModelRutas(data) {
  return data.map((ruta) => ({
    id: ruta._id,
    coordenadas: ruta.coordenadas,
  }));
}

export default RutaMensProv;
