import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { registraColorimetria } from "../../../api/colorimetria";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";
import Dropzone from "../../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../../api/cloudinary";

export default function SistemasColorimetria({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!imagenProducto) {
      if (!formData.nombre || !formData.tonos) {
        toast.warning("Completa el formulario");
      } else {
        try {
          setLoading(true);

          const dataTemp = {
            nombre: formData.nombre,
            tonos: formData.tonos,
            estado: "true"
          };
          registraColorimetria(dataTemp).then((response) => {
            const { data } = response;
            console.log(data)
            LogsInformativos("Se ha registrado el sistema de colorimetria " + dataTemp.nombre, dataTemp)
            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });

            setFormData(initialFormValue())
            setLoading(false);
            setShow(false);
            //cancelarRegistro()
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      if (!formData.nombre || !formData.tonos) {
        toast.warning("Completa el formulario");
      } else {
        try {
          if (imagenProducto) {
            subeArchivosCloudinary(imagenProducto, "colorimetria")
              .then((response) => {
                const { data } = response;
                setLoading(true);
                const dataTemp = {
                  nombre: formData.nombre,
                  tonos: formData.tonos,
                  imagen: data.secure_url,
                  estado: "true"
                };
                registraColorimetria(dataTemp).then((response) => {
                  const { data } = response;
                  console.log(data)
                  LogsInformativos("Se ha registrado el sistema de colorimetria " + dataTemp.nombre, dataTemp)
                  toast.success(data.mensaje);

                  history({
                    search: queryString.stringify(""),
                  });

                  setFormData(initialFormValue())
                  setLoading(false);
                  setShow(false);
                  //cancelarRegistro()
                });
              })
              .then((e) => {
                console.log(e);
              });
          } else {
            setLoading(true);
            const dataTemp = {
              nombre: formData.nombre,
              tonos: formData.tonos,
              imagen: "",
              estado: "true"
            };
            registraColorimetria(dataTemp).then((response) => {
              const { data } = response;
              console.log(data)
              LogsInformativos("Se ha registrado el sistema de colorimetria " + dataTemp.nombre, dataTemp)
              toast.success(data.mensaje);

              history({
                search: queryString.stringify(""),
              });

              setFormData(initialFormValue())
              setLoading(false);
              setShow(false);
              //cancelarRegistro()
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalSerDentales'>
        <Form onSubmit={onSubmit} onChange={onChange}>
          <div className="imagenPrincipal">
            <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
            <div
              title="Seleccionar imagen de la categoría"
              className="imagenProducto"
            >
              <Dropzone setImagenFile={setImagenProducto} />
            </div>
          </div>
          <Form.Label className='lblNombreSis'>Nombre del sistema de colorimetria: <code>*</code></Form.Label>
          <Form.Control
            type='text'
            className='inpNombreSis'
            placeholder='Nombre'
            name="nombre"
            defaultValue={formData.nombre}
          />
          <Form.Label className='lblTonos'>Tonos: <code>*</code></Form.Label>
          <Form.Control
            type='text'
            className='inpTonos'
            placeholder='Introduzca escalas separadas por comas. Por ejemplo: escala1, escala2, escala3'
            name="tonos"
            defaultValue={formData.tonos}
          />
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  )
}

function initialFormValue() {
  return {
    nombre: "",
    tonos: "",
  };
}