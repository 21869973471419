import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarConfiguracionRutas,
    ENDPOINTObtenerConfiguracionRutas,
    ENDPOINTListarConfiguracionRutas,
    ENDPOINTActualizarConfiguracionRutas,
    ENDPOINTEliminarConfiguracionRutas,
    ENDPOINTDeshabilitarConfiguracionRutas,
    ENDPOINTObtenerFolioConfiguracionRutas,
    ENDPOINTListarPorDiaConfiguracionRutas,
    ENDPOINTListarPorCorreoConfiguracionRutas,
    ENDPOINTEnviarEmailRecordatorioConfiguracionRutas
} from "./endpoints";
import axios from 'axios';

export async function enviaEmailRecordatorioConfiguracionRutas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTEnviarEmailRecordatorioConfiguracionRutas, data, config);
}

export async function listarConfiguracionRutas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarConfiguracionRutas, config);
}

export async function registraConfiguracionRutas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarConfiguracionRutas, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerConfiguracionRutas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerConfiguracionRutas + `/${params}`, config);
}

export async function actualizarConfiguracionRutas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarConfiguracionRutas + `/${id}`, data, config);
}

export async function eliminarConfiguracionRutas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarConfiguracionRutas + `/${id}`, data, config);
}

export async function actualizaDeshabilitarConfiguracionRutas(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarConfiguracionRutas + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerFolioConfiguracionRutas() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioConfiguracionRutas, config);
}

export async function listarRutasPorDia(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPorDiaConfiguracionRutas + `/${params}`, config);
}

export async function listarRutasPorCorreo(correo, dia) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPorCorreoConfiguracionRutas + `/?email=${correo}&&dia=${dia}`, config);
}