import { Admin } from '../pages/administrador/Admin';
import AltaCliente from '../components/administrador/cliente/AltaCliente';
import DatosDoctores from '../components/doctores/DatosDoctores';
import DatosdeFacturacion from '../components/administrador/datosFacturacion/DatosdeFacturacion';
import SistemasColorimetria from '../components/administrador/colorimetria/SistemasColorimetria';
import AltaPrecios from '../components/administrador/precios/AltaPrecios';
import ServiciosDentales from '../components/administrador/serviciosDentales/ServiciosDentales';
import AlmacenPL from '../components/almacenProductosLimpieza/insertar';
import AlmacenMP from '../components/almacenMateriasPrimas/insertar';
import { Login } from '../components/login/Login';
import { Principal } from '../components/principal/Principal';
import { ErrorPantalla } from '../components/error/Error';
import Mensajeros from '../components/administrador/mensajeros/Mensajeros';
import DetalleOrden from '../components/ordenServicio/DetalleOrden';
import OrdenServicioNueva from '../components/ordenServicio/OrdenServicioNueva';
import AltaCliente3 from '../components/administrador/cliente/AltaCliente3';
import MovimientosAMP from '../components/movimientosAlmacenMP/tabla';
import MovimientosAPL from '../components/movimientosAlmacenPL/tabla';
import { errorSesion } from '../components/error/errorSesion';
import EliminarUsuarios from '../components/administrador/cliente/EliminarUsuarios';
import TerminosYCondiciones from '../components/administrador/cliente/TerminosCondiciones';

export default [
    {
        path: "/dashboard",
        page: Admin
    },
    {
        path: "/terminosycondiciones",
        page: TerminosYCondiciones
    },
    {
        path: "/eliminarUsuarios",
        page: EliminarUsuarios
    },
    {
        path: "/clientes",
        page: AltaCliente
    },
    {
        path: "/registroCliente",
        page: AltaCliente3
    },
    {
        path: "/doctores",
        page: DatosDoctores
    },
    {
        path: "/facturacion",
        page: DatosdeFacturacion
    },
    {
        path: "/detalleOrden",
        page: DetalleOrden
    },
    {
        path: "/colorimetria",
        page: SistemasColorimetria
    },
    {
        path: "/precios",
        page: AltaPrecios
    },
    {
        path: "/serviciosDentales",
        page: ServiciosDentales
    },
    {
        path: "/mensajeros",
        page: Mensajeros
    },
    {
        path: "/login",
        page: Login
    },
    {
        path: "/AlmacenPL",
        page: AlmacenPL
    },
    {
        path: "/MovimientosAPL/:idArticuloAPL",
        page: MovimientosAPL
    },
    {
        path: "/AlmacenMP",
        page: AlmacenMP
    },
    {
        path: "/MovimientosAMP/:idArticuloAMP",
        page: MovimientosAMP
    },
    {
        path: "/",
        page: Principal
    },
    {
        path: "error",
        page: ErrorPantalla
    },
    {
        path: "ordenprueba",
        page: OrdenServicioNueva
    },
    {
        path: "error404",
        page: errorSesion
    }
]
