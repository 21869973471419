import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { registraCargos } from "../../api/cargos";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import { listarDepartamentos } from "../../api/departamentos";
import { map } from "lodash";

export default function Cargos({ history, setShow }) {
    const [formData, setFormData] = useState(initialFormValue());

    const [listarDepartamento, setListDepartamento] = useState([]);

    const obtenerDepartamento = () => {
        try {
            listarDepartamentos()
                .then((response) => {
                    const { data } = response;

                    if (!listarDepartamento && data) {
                        setListDepartamento(formatModelDepartamento(data));
                    } else {
                        const datosDepartamento = formatModelDepartamento(data);
                        setListDepartamento(datosDepartamento);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDepartamento();
    }, []);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.nombre) {
            toast.warning("Completa el formulario");
        } else {
            try {
                setLoading(true);

                const dataTemp = {
                    nombre: formData.nombre,
                    descripcion: formData.descripcion,
                    departamento: formData.departamento,
                    estado: "true"
                };
                registraCargos(dataTemp).then((response) => {
                    const { data } = response;
                    LogsInformativos("Se ha registrado el cargo " + dataTemp.nombre, dataTemp)
                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });

                    //setFormData(initialFormValue())
                    setLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Group className='datosPersonalesDoc'>
                        <Form.Label className='lblpApellido'>Nombre: <code>*</code> </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpPrimerADoc'
                            placeholder='Ingresa Nombre del puesto'
                            value={formData.nombre}
                            name="nombre"
                        />
                        <Form.Label className='lblsApellido'>Descripcion: <code>*</code> </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpSegundoA'
                            placeholder='Ingresa la descripcion del puesto'
                            defaultValue={formData.descripcion}
                            name="descripcion"
                        />
                        <Form.Label className='lblDepartamento'>Departamento: <code>*</code> </Form.Label>
                        <Form.Control
                            as="select"
                            className='inpTercerADoc'
                            placeholder='Ingresa Nombre del puesto'
                            value={formData.departamento}
                            name="departamento"
                        >
                            <option value="">Selecciona una opcion</option>
                            {map(listarDepartamento, (cat, index) => (
                                <option
                                    key={index}
                                    value={cat?.nombre}
                                >
                                    {cat?.nombre}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    )
}

function initialFormValue() {
    return {
        nombre: "",
        descripcion: "",
        departamento: "",
    };
}

function formatModelDepartamento(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            folio: data.folio,
            nombre: data.nombre,
            tipo: data.tipo,
            estado: data.estado,
        });
    });
    return dataTemp;
}
