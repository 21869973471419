import { useState } from 'react';
import { Button, Col, Form, Row, Container, Spinner } from "react-bootstrap";
import PropTypes from 'prop-types';
import { LogsInformativos } from "../logs/logs";

function ModificacionMateriales(props) {
    const { datos, setShowModal, listProductosCargados, setListProductosCargados } = props;

    // Para guardar los datos del formulario
    const [formData, setFormData] = useState(initialFormData(datos));

    // Cancelar y cerrar el formulario
    const cancelarRegistro = () => {
        setShowModal(false);
    };

    // Para controlar la animacion
    const [loading, setLoading] = useState(false);

    function cambiarValor(id, valorABuscar, valorNuevo) {
        // Crear una copia del array original
        const updatedProductos = listProductosCargados.map((elemento) => {
            if (elemento.id === id) {
                return {
                    ...elemento,
                    [valorABuscar]: valorNuevo
                };
            }
            return elemento;
        });

        // Actualiza el estado de productos cargados
        setListProductosCargados(updatedProductos);
    }

    const addItems = () => {
        setLoading(true);

        // Actualizar el array con los nuevos valores
        cambiarValor(datos.id, "servicio", formData.servicio);
        cambiarValor(datos.id, "cantidad", formData.cantidad);

        LogsInformativos("Se ha modificado el material en la orden de cliente");
        cancelarRegistro();
    };

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Container fluid>
                <div className="formularioDatos">
                    <Form onChange={onChange}>
                        <Row>
                            <Form.Group as={Col} controlId="formGridCliente">
                                <Form.Label>
                                    Material a enviar
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    id="servicio"
                                    type="text"
                                    value={formData.servicio}
                                    name="servicio"
                                    disabled
                                >
                                    <option>Elige una opción</option>
                                    <option value="Modelo superior">
                                        Modelo superior
                                    </option>
                                    <option value="Modelo inferior">
                                        Modelo inferior
                                    </option>
                                    <option value="Mordida de cera">
                                        Mordida de cera
                                    </option>
                                    <option value="Mordida en silicon">
                                        Mordida en silicon
                                    </option>
                                    <option value="Sin antagonista">
                                        Sin antagonista
                                    </option>
                                    <option value="Cobrar llenado de cucharilla">
                                        Cobrar llenado de cucharilla
                                    </option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridCliente">
                                <Form.Label>
                                    Cantidad
                                </Form.Label>
                                <Form.Control
                                    id="cantidad"
                                    type="text"
                                    value={formData.cantidad}
                                    name="cantidad"
                                />
                            </Form.Group>
                        </Row>
                        <br />
                        <div className="divSubmit">
                            <input className="submit" value="Enviar" type="button" onClick={addItems} />
                        </div>
                    </Form>
                </div>
            </Container>
        </>
    );
}

function initialFormData(data) {
    return {
        servicio: data.servicio,
        cantidad: data.cantidad,
    };
}

ModificacionMateriales.propTypes = {
    datos: PropTypes.object.isRequired,
    setShowModal: PropTypes.func.isRequired,
    listProductosCargados: PropTypes.array.isRequired,
    setListProductosCargados: PropTypes.func.isRequired,
};

export default ModificacionMateriales;
