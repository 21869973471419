import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { listarMensajeros } from "../../../api/mensajeros";
import { map } from "lodash";
import { registraMensajerosGastos } from "../../../api/mensajerosGastos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LogsInformativos } from "../../logs/logs";

const SaldosMensajero = ({ history, setShow }) => {
  const [concepto, setConcepto] = useState("");
  const [saldo, setSaldo] = useState("");
  const [mensajero, setMensajero] = useState("");

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos
  let fechaFormat = `${dia}/${mes}/${year}`;
  let datosFecha = `${dia}/${mes}/${year} ${hora}:${minutos}`;

  const registraSaldo = (e) => {
    try {
      if (!saldo || !mensajero || !fechaFormat || !datosFecha) {
        toast.warning("Completa el formulario");
      } else {
        const dataTemp = {
          nombreCliente: mensajero.split("/")[0],
          correoCliente: mensajero.split("/")[1],
          fecha: fechaFormat,
          fechayHora: datosFecha,
          saldo: saldo,
          estado: "activo"
        };
        registraMensajerosGastos(dataTemp).then((response) => {
          const { data } = response;
          console.log(data);
          LogsInformativos("Se ha registrado el saldo del mensajero con correo " + dataTemp.correoCliente, dataTemp)
          toast.success("Saldo agregado al mensajero");
          setShow(false);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Mensajero: <code>*</code></Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              as="select"
              aria-label="Seleccionar mensajero"
              value={mensajero}
              onChange={(e) => setMensajero(e.target.value)}
              name="mensajero"
            >
              <option>Elige una opción</option>
              {map(listarMensajero, (cat, index) => (
                <option key={index} value={cat?.nombre + "/" + cat?.email}>
                  {cat?.nombre}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row style={{ display: "none" }} className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad de saldo actual:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control type="number" placeholder="Saldo actual" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Concepto: <code>*</code></Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              value={concepto}
              onChange={(e) => setConcepto(e.target.value)}
              placeholder={"Ingresa el concepto"}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad: <code>*</code></Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={saldo}
                onChange={(e) => setSaldo(e.target.value)}
                type="number"
                min={1}
              />
            </InputGroup>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registraSaldo}>
            <FontAwesomeIcon icon={faCashRegister} />
            &nbsp;Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      nombre: data.nombre,
      email: data.email,
    });
  });
  return dataTemp;
}

export default SaldosMensajero;
