import {
  Document,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "../../assets/img/encabezado.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    marginLeft: 30,
    marginRight: 30,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textTransform: "capitalize",
  },
  headerCell: {
    padding: 8,
    fontSize: 10,
  },
  firstColumn: {
    width: "8%",
    borderColor: "black",
  },
  secondColumn: {
    width: "40%",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderColor: "black",
  },
  thirdColumn: {
    width: "16%",
    borderRightWidth: 1,
    borderColor: "black",
  },
  fourthColumn: {
    width: "20%",
    borderColor: "black",
  },
  fiveColumn: {
    width: "20%",
    borderColor: "black",
    borderLeftWidth: 1,
  },
});
const PDFRutasView = ({ rutas, dia }) => {
  console.log(rutas);
  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#0c1b30",
            height: 55,
          }}
        >
          <Image src={logo} style={{ width: 350, height: 50 }} />
        </View>
        <View style={{ textAlign: "center", marginBottom: 10, marginTop: 30 }}>
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            LISTADO DE RUTAS DEL DÍA {dia}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.firstColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
              }}
            >
              <Text>FOLIO</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.secondColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
              }}
            >
              <Text>RUTA</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.thirdColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
              }}
            >
              <Text>DESCRIPCIÓN</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.fourthColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Text>MENSAJERO</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.fourthColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Text>VEHICULO</Text>
            </View>
          </View>
          {rutas.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={{ ...styles.tableCell, ...styles.firstColumn }}>
                <Text>{item.folio}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.secondColumn }}>
                <Text>{item.ruta}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.thirdColumn }}>
                <Text>
                  {item.descripcion ? item.descripcion : "No hay descripcion"}
                </Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.fourthColumn }}>
                <Text>
                  {item.datosMensajero
                    ? item.datosMensajero.nombre
                    : "No Asignado"}
                </Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.fiveColumn }}>
                <Text>
                  {item.datosVehiculo
                    ? `${item.datosVehiculo.modeloVehiculo || ""} ${
                        item.datosVehiculo.placa || ""
                      }`
                    : "No asignado"}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "#0c1b30",
            padding: 10,
            height: 65,
          }}
        >
          <Text style={{ fontSize: 12, color: "white", textAlign: "center" }}>
            EL ROBLE, SANTA CRUZ NIETO, 76804 SAN JUAN DEL RÍO, QRO.
          </Text>
          <Text style={{ fontSize: 12, color: "white", textAlign: "center" }}>
            grupodentalherfran.com
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const RutasPDF = ({ data, dia }) => {
  return (

      <PDFDownloadLink
        document={<PDFRutasView rutas={data} dia={dia} />}
        fileName={"Rutas " + dia + ".pdf"}
        style={{textDecoration:"none", color:"white"}}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : <Button variant="danger"><FontAwesomeIcon icon={faFilePdf} /> Descargar</Button>
        }
      </PDFDownloadLink>
  );
};

export default RutasPDF;
 