import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import queryString from "query-string";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { LogsInformativos } from "../logs/logs";
import { actualizarRecursosHumanos } from "../../api/recursosHumanos";
import { Document, Page, Text, View, StyleSheet, BlobProvider } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ReactDOM from 'react-dom'; // Importación de ReactDOM

dayjs.extend(utc);
dayjs.extend(timezone);

export default function RegistroFaltasTecnicas({ history, setShow, data }) {
    const styles = StyleSheet.create({
        page: {
            padding: 30,
        },
        section: {
            margin: 10,
            padding: 10,
        },
        text: {
            fontSize: 12,
            marginBottom: 12,
        },
        signature: {
            marginTop: 30,
            textAlign: 'center',
        },
        signatureLine: {
            marginTop: 50,
            textAlign: 'center',
            fontSize: 10,
        }
    });

    const GeneratePDF = ({ newFaltaTecnica }) => (
        <Document>
            <Page style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.text} align="center">
                        ACTA ADMINISTRATIVA
                    </Text>
                    <Text style={styles.text}>
                        En la Ciudad de San Juan del Río, Qro., a {dayjs(newFaltaTecnica.fechaIncidencia).format("DD")} de {dayjs(newFaltaTecnica.fechaIncidencia).format("MMMM")} del {dayjs(newFaltaTecnica.fechaIncidencia).format("YYYY")} siendo las {dayjs(newFaltaTecnica.fechaIncidencia).format("HH")} horas con {dayjs(newFaltaTecnica.fechaIncidencia).format("mm")} minutos y encontrándonos en el interior de las instalaciones de la fuente de trabajo ubicada en calle Roble 38, Santa Cruz Nieto de esta Ciudad de San Juan Del Rio, Qro, domicilio donde se encuentra ubicado el centro de trabajo denominado HERFRAN CORPORATIVO DENTAL S.A. DE C.V. cuyo giro comercial consiste en la ELABORACION DE PROTESIS DENTALES, y en este acto encontrándonos presentes Francisco Javier Hernández Mondragón en mi calidad de propietario de la fuente de trabajo, Anahí Guadalupe Álvarez Reséndiz y José Luis Ruiz Rodríguez en su calidad de jefe de producción, ambos en su calidad de testigos para la elaboración de la presente acta, así como también estando presente la (el) trabajador (a) C. {newFaltaTecnica.empleado} quien desempeña el puesto de {newFaltaTecnica.cargo} y de manera verbal se le hace saber que el jefe de producción José Luis Ruiz Rodríguez nos ha informado que siendo las {dayjs(newFaltaTecnica.fechaAclaracion).format("HH")} horas con {dayjs(newFaltaTecnica.fechaAclaracion).format("mm")} minutos del dia {dayjs(newFaltaTecnica.fechaAclaracion).format("DD")} del mes {dayjs(newFaltaTecnica.fechaAclaracion).format("MM")} del {dayjs(newFaltaTecnica.fechaAclaracion).format("YYYY")} y encontrándose en las instalaciones de la fuente de trabajo la cual se encuentra ubicada en calle Roble 38 CP 76804, Santa Cruz Nieto de esta Ciudad de San Juan Del Rio, Qro, domicilio al cual se encuentra asignada C {newFaltaTecnica.empleado}.
                    </Text>
                    <Text style={styles.text}>
                        Por lo que en este acto y estando presentes las personas anteriormente señaladas se le solicita al C. {newFaltaTecnica.empleado} de su versión sobre los hechos que anteriormente se le hicieron saber y que en este acto manifiesta lo siguiente; y siendo las {dayjs(newFaltaTecnica.fechaAclaracion).format("HH")} horas con {dayjs(newFaltaTecnica.fechaAclaracion).format("mm")} minutos del dia {dayjs(newFaltaTecnica.fechaAclaracion).format("DD")} del mes {dayjs(newFaltaTecnica.fechaAclaracion).format("MM")} del {dayjs(newFaltaTecnica.fechaAclaracion).format("YYYY")} .
                    </Text>
                    <Text style={styles.text}>
                        {formData.descripcion}
                    </Text>
                    <Text style={styles.text}>
                        Visto lo manifestado por el trabajador C. {newFaltaTecnica.empleado} y siendo las {dayjs(newFaltaTecnica.fechaCierre).format("HH")} horas con {dayjs(newFaltaTecnica.fechaCierre).format("mm")} minutos del dia {dayjs(newFaltaTecnica.fechaCierre).format("DD")} del mes {dayjs(newFaltaTecnica.fechaCierre).format("MM")} del {dayjs(newFaltaTecnica.fechaCierre).format("YYYY")}  se cierra y se levanta la presente acta administrativa para todos los efectos legales, firmando los que intervinieron.
                    </Text>
                    <Text style={styles.text}>
                        __________________________________________
                    </Text>
                    <Text style={styles.text}>
                        Ratifico y acepto todo lo manifestado con anterioridad, así como también estoy de acuerdo en todo lo que se ha establecido en la presente acta. {dayjs(newFaltaTecnica.fecha).format("DD")} de {dayjs(newFaltaTecnica.fecha).format("MMMM")} del {dayjs(newFaltaTecnica.fecha).format("YYYY")}.
                    </Text>
                    <Text style={styles.text}>
                        Por todo lo anterior y tomando en cuenta los hechos aquí narrados, solicito que la presente acta sea tomada en cuenta para todos los efectos legales a que haya lugar.
                    </Text>
                    <Text style={styles.text}>
                        FRANCISCO JAVIER HERNANDEZ MONDRAGON
                    </Text>
                    <Text style={styles.text}>
                        __________________________________________
                    </Text>
                    <Text style={styles.text}>
                        JOSE LUIS RUIZ RODRIGUEZ                                ANAHI GUADALUPE ALVAREZ RESENDIZ
                    </Text>
                </View>
            </Page>
        </Document>
    );

    const [formData, setFormData] = useState(initialFormValue());
    const [signInLoading, setSignInLoading] = useState(false);
    const [fechayHora, setFechayHora] = useState("");
    const [fechayHoraSinFormato, setFechayHoraSinFormato] = useState("");
    const [faltasTecnicas, setFaltasTecnicas] = useState(data ? data[26] : []);

    console.log(faltasTecnicas)

    useEffect(() => {
        const hoy = new Date();
        const adjustedDate = dayjs(hoy).utc().utcOffset(-360).format(); // Ajusta la hora a CST (UTC -6)

        setFechayHora(dayjs(adjustedDate).locale('es').format('dddd, LL hh:mm A'));
        setFechayHoraSinFormato(adjustedDate);
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        setSignInLoading(true);
        try {
            const newFaltaTecnica = {
                fecha: fechayHoraSinFormato,
                descripcion: formData.descripcion,
                empleado: data[1],
                cargo: data[7],
                fechaIncidencia: formData.fechaIncidencia,
                fechaCierre: formData.fechaCierre,
                fechaAclaracion: formData.fechaAclaracion,
                comentarios: formData.comentarios,
            };

            const updatedFaltasTecnicas = [...faltasTecnicas, newFaltaTecnica];

            const dataTemp = {
                faltasAdministrativas: updatedFaltasTecnicas,
            };

            await actualizarRecursosHumanos(data[0], dataTemp)
                .then((response) => {
                    const { data } = response;
                    toast.success(data.mensaje);
                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha registrado una falta técnica", newFaltaTecnica);
                    setSignInLoading(false);
                    setShow(false);

                    // Generar y descargar el PDF
                    const pdfBlobProvider = (
                        <BlobProvider document={<GeneratePDF newFaltaTecnica={newFaltaTecnica} />}>
                            {({ blob }) => {
                                if (blob) {
                                    saveAs(blob, 'Acta_Administrativa.pdf');
                                }
                                return null;
                            }}
                        </BlobProvider>
                    );
                    ReactDOM.render(pdfBlobProvider, document.getElementById('pdfContainer'));
                })
                .catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                    } else if (ex.response && ex.response.status === 401) {
                        toast.error(ex.response.data.mensaje);
                    }
                    setSignInLoading(false);
                });
        } catch (ex) {
            toast.error("Error al registrar la falta técnica");
            setSignInLoading(false);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Empleado: <code>*</code></Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Fecha"
                        name="fechaIncidencia"
                        value={data[1]}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Cargo: <code>*</code></Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Fecha"
                        name="fechaIncidencia"
                        value={data[7]}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Fecha de incidencia: <code>*</code></Form.Label>
                    <Form.Control
                        type="datetime-local"
                        className="nomNewUser"
                        placeholder="Fecha"
                        name="fechaIncidencia"
                        defaultValue={formData.fechaIncidencia}
                    />
                </Form.Group>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Fecha de aclaracion: <code>*</code></Form.Label>
                    <Form.Control
                        type="datetime-local"
                        className="nomNewUser"
                        placeholder="Fecha"
                        name="fechaAclaracion"
                        defaultValue={formData.fechaAclaracion}
                    />
                </Form.Group>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Descripcion: <code>*</code></Form.Label>
                    <Form.Control
                        as="textarea"
                        type="text"
                        className="nomNewUser"
                        placeholder="Descripcion"
                        name="descripcion"
                        wrap="soft"
                        defaultValue={formData.descripcion}
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Fecha de cierre: <code>*</code></Form.Label>
                    <Form.Control
                        type="datetime-local"
                        className="emailNewUser"
                        placeholder="Fecha de cierre"
                        name="fechaCierre"
                        defaultValue={formData.fechaCierre}
                    />
                </Form.Group>
                <br />
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
            <div id="pdfContainer" style={{ display: 'none' }}></div>
        </div>
    );
};

function initialFormValue() {
    return {
        fechaCierre: "",
        fechaIncidencia: "",
        fechaAclaracion: "",
        descripcion: "",
        comentarios: ""
    };
}
