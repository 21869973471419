import React, { useEffect, useState } from "react";
import { obtenerMensajeriaIdChat } from "../../../api/mensajeria";
import { MessageBox, MessageList } from "react-chat-elements";

const MensajesChat = ({ email, identifica, setFlag, usuario, nombre }) => {
  const [mensajesList, setmensajesList] = useState([]);

  const obtenerMensajesCorreo = (correo) => {
    try {
      obtenerMensajeriaIdChat(correo)
        .then((response) => {
          const { data } = response;
          console.log("data", data);

          if (!mensajesList && data) {
            setmensajesList(formatModelMensaje(data));
            setFlag(false);
          } else {
            setmensajesList(formatModelMensaje(data));
            setFlag(false);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerMensajesCorreo(email);
  }, [email]);

  useEffect(() => {
    if (identifica) {
      obtenerMensajesCorreo(email);
    }
  }, [identifica]);

  return (
    <div>
      <div className="card card-row card-primary" style={{minHeight:"80vh", maxHeight:"80vh"}}>
        <div className="card-header" style={{ backgroundColor:"#9c182d" }}>
          <h3 className="card-title">{nombre}</h3>
        </div>
        <div className="card-body" style={{overflow:"auto"}}>
          {mensajesList.length > 0 ? (
            mensajesList.map((mensaje) => (
              <MessageBox
                position={usuario == mensaje.correoUsuario ? "right" : "left"}
                title={mensaje.nombreUsuario}
                type={mensaje.tipoMensaje}
                text={mensaje.mensaje}
                date={new Date(mensaje.fechaHora)}
              />
            ))
          ) : (
            <p>no hay mensajes</p>
          )}
        </div>
      </div>
    </div>
  );
};

function formatModelMensaje(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      correoUsuario: data.correoUsuario,
      fechaHora: data.fechaHora,
      idChat: data.idChat,
      mensaje: data.mensaje,
      nombreUsuario: data.nombreUsuario,
      tipoMensaje: data.tipoMensaje,
    });
  });
  return dataTemp;
}

export default MensajesChat;
