import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { eliminarMaterialCat } from "../../../api/materialesCat";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function EliminarMaterial({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        nombreMaterial: data[2],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            eliminarMaterialCat(idDocumental).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha eliminado el material" + idDocumental)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalPrecios'>
                <Form className='formPrecios' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Group>
                        <Form.Label className='lblClasificacionPre'>Nombre del material: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpNombreSis'
                            placeholder='Nombre'
                            name="nombreMaterial"
                            defaultValue={formData.nombreMaterial}
                            disabled
                        />
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        nombreMaterial: data.nombreMaterial,
    };
}
