export function FooterDash() {
    return (
      <>
        <footer className="main-footer">
          <strong>
            Copyright © 2023 <a href=""></a>.
          </strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.0
          </div>
        </footer>
      </>
    );
  }
  