import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Container,
  Image,
} from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faArrowDownLong,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { obtenerRecursosHumanosPorNombre } from "../../api/recursosHumanos";
import DataTable from "react-data-table-component";
import { estilos } from "../../utils/tableStyled";

function BusquedaEmpleados(props) {
  const {
    setShowModal,
    setNombreSocioElegido,
    setIdSocioElegido,
    setDepartamentoSocioElegido,
  } = props;

  // Para controlar la animación
  const [loading, setLoading] = useState(false);

  // Para almacenar los datos de la busqueda
  const [formData, setFormData] = useState(initialFormValue());

  // Almacenar el listado de socios encontrados
  const [listSociosEncontrados, setListSociosEncontrados] = useState(null);

  // Para cancelar la búsqueda
  const cancelarBusqueda = () => {
    setShowModal(false);
  };

  // Para eliminar los datos del socio encontrado
  const eliminaDatosBusqueda = () => {
    setListSociosEncontrados(null);
    setNombreSocioElegido("");
    setDepartamentoSocioElegido("");
  };

  // Gestionar el socio seleccionado
  const socioSeleccionado = ({ id, nombreCompleto, departamento, correo }) => {
    // Almacena id, ficha y nombre del socio elegido
    setIdSocioElegido(id);
    setNombreSocioElegido(nombreCompleto);
    setListSociosEncontrados(null);
    setDepartamentoSocioElegido(departamento);
    cancelarBusqueda();
  };

  console.log(listSociosEncontrados);

  // Validar listado de socios encontrados
  const validarListadoSocios = (listSocios) => {
    if (listSocios.length === 0) {
      toast.error("No se encontraron socios con los datos ingresados");
    }
    setListSociosEncontrados(formatModelSocios(listSocios));
  };

  // Obtener empleado por nombre
  const obtenerEmpleadoPorNombre = async (nombre) => {
    try {
      setLoading(true);
      const { data } = await obtenerRecursosHumanosPorNombre(nombre);

      validarListadoSocios(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const loadListadoEmpleados = () => {
    const { nombre } = formData;

    // Busqueda por nombre
    if (nombre === "") {
      toast.warning("Debe especificar el nombre del empleado");
    } else if (nombre !== "") {
      obtenerEmpleadoPorNombre(nombre);
    }
  };

  // Busqueda y Gestión del socio encontrado
  const onSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);
    loadListadoEmpleados();
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombreCompleto,
      sortable: false,
      center: true,
      reorder: false,
    },
    {
      name: "Imagen",
      sortable: false,
      center: true,
      reorder: false,
      selector: (row) => (
        <>
          <Image
            src={row.foto}
            alt={`Imagen de ${row.foto}`}
            style={{ maxWidth: "100px" }}
          />
        </>
      ),
    },
    {
      name: "Seleccionar",
      selector: (row) => (
        <>
          <FontAwesomeIcon
            className="eleccionSocio"
            icon={faCircleCheck}
            onClick={() => {
              socioSeleccionado(row);
            }}
          />
        </>
      ),
      sortable: false,
      center: true,
      reorder: false,
    },
  ];

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(listSociosEncontrados);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
  };

  const [resetPaginationToogle, setResetPaginationToogle] = useState(false);

  return (
    <>
      {listSociosEncontrados ? (
        <>
          <div className="listadoSociosEncontrados">
            <Row>
              <h3>Socios encontrados</h3>
              <Container fluid>
                <DataTable
                  columns={columns}
                  noDataComponent="No hay registros para mostrar"
                  data={listSociosEncontrados}
                  progressPending={pending}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationResetDefaultPage={resetPaginationToogle}
                  customStyles={estilos}
                  sortIcon={<FontAwesomeIcon icon={faArrowDownLong} />}
                  pagination
                />
              </Container>
            </Row>
            <Row>
              <Button
                variant="danger"
                className="BuscarOtraVez"
                onClick={() => {
                  eliminaDatosBusqueda();
                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} /> Realizar otra
                búsqueda
              </Button>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className="contenidoFormularioPrincipal">
            <Form onChange={onChange} onSubmit={onSubmit}>
              {/* Selección de información */}
              {/* Ficha o Nombre */}
              <Row className="mb-3">
                <div className="datosBusqueda">
                  <>
                    <Form.Group as={Col} controlId="formGridFicha">
                      <Form.Label>Nombre: <code>*</code></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Escribe el nombre"
                        autoComplete="off"
                        name="nombre"
                        defaultValue={formData.nombre}
                      />
                    </Form.Group>
                  </>
                </div>
              </Row>

              <Form.Group as={Row} className="botonesBusqueda">
                <Col className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    variant="success"
                    className="busqueda"
                    disabled={loading}
                  >
                    {!loading ? (
                      <>
                        <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar
                      </>
                    ) : (
                      <Spinner animation="border" />
                    )}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        </>
      )}
    </>
  );
}

function formatModelSocios(data) {
  return data.map(
    ({
      _id: id,
      nombreCompleto,
      foto,
      departamento,
      createdAt: fechaCreacion,
      updatedAt: fechaActualizacion,
    }) => ({
      id,
      nombreCompleto,
      foto,
      departamento,
      fechaCreacion,
      fechaActualizacion,
    })
  );
}

function initialFormValue() {
  return {
    nombre: "",
    tipo: "",
    ficha: "",
  };
}

export default BusquedaEmpleados;
