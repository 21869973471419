import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import regimenFiscalData from "../catalogosJSON/regimenFiscal.json";
import estadosPaisData from "../catalogosJSON/estadosPais.json";
import municipiosEstadoData from "../catalogosJSON/municipiosEstadosPais.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faFloppyDisk,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { actualizarFacturacion } from "../../../api/facturacion";

const FacturacionPerfil = ({ datos }) => {
  const [inputDisabled, setInputDisabled] = useState(true);

  const toggleInput = () => {
    setInputDisabled(!inputDisabled);
  };

  const [estadoPais, setEstadoPais] = useState(datos[0].estadoPais);

  const handleInputChangeEstado = (event) => {
    const nuevoEstado = event.target.value;
    setEstadoPais(nuevoEstado);
  };

  const [municipioSeleccionado, setMunicipioSeleccionado] = useState(
    datos[0].municipio
  );

  const handleInputChangeMunicipio = (e) => {
    setMunicipioSeleccionado(e.target.value);
  };

  const usuarioReg = datos[0].id;
  const [usuarioRFC, setUsuarioRFC] = useState(datos[0].rfc);
  const [razonSocial, setRazonSocial] = useState(datos[0].razonSocial);
  const [regimenFiscal, setRegimenFiscal] = useState(datos[0].regimenFiscal);
  const [calleDom, setcalleDom] = useState(datos[0].calle);
  const [numExtDom, setnumExtDom] = useState(datos[0].numeroExterior);
  const [numIntDom, setnumIntDom] = useState(datos[0].numeroInterior);
  const [coloniaDom, setcoloniaDom] = useState(datos[0].colonia);
  const [cpDom, setcpDom] = useState(datos[0].codigoPostal);
  const [observacionesFact, setobservacionesFact] = useState(
    datos[0].observaciones
  );

  const actualizarRegistro = () => {
    try {
      const dataTemp = {
        regimenFiscal: regimenFiscal,
        calle: calleDom,
        numeroExterior: numExtDom,
        numeroInterior: numIntDom,
        codigoPostal: cpDom,
        colonia: coloniaDom,
        municipio: municipioSeleccionado,
        estadoPais: estadoPais,
        observaciones: observacionesFact,
      };

      actualizarFacturacion(usuarioReg, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        setInputDisabled(!inputDisabled);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="facturacionGeneral">
      <Form className="formFacturacion">
        <div style={{ textAlign: "right", marginBottom: "2%" }}>
          <Button
            variant={inputDisabled ? "warning" : "danger"}
            onClick={toggleInput}
          >
            {inputDisabled ? (
              <>
                <FontAwesomeIcon icon={faPen} /> Editar
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCancel} /> Cancelar
              </>
            )}
          </Button>
        </div>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Razón Social:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              className="inpRegimen"
              placeholder="Ingresa Razón Social"
              value={razonSocial}
              onChange={(e) => setRazonSocial(e.target.value)}
              name="razonSocial"
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>RFC:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              className="inpRegimen"
              placeholder="Ingresa RFC"
              value={usuarioRFC}
              onChange={(e) => setUsuarioRFC(e.target.value)}
              name="rfc"
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Régimen Fiscal:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {inputDisabled === false ? (
              <Form.Control
                as="select"
                className="inpRegimen"
                value={regimenFiscal}
                onChange={(e) => setRegimenFiscal(e.target.value)}
              >
                <option>Selecciona un Régimen Fiscal</option>
                {regimenFiscalData.map((regimen) => (
                  <option
                    key={regimen.id}
                    value={regimen.id + " - " + regimen.descripcion}
                  >
                    {regimen.id + " - " + regimen.descripcion}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                className="inpRegimen"
                value={regimenFiscal}
                disabled={inputDisabled}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Calle:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Ingresa Calle"
              value={calleDom}
              onChange={(e) => setcalleDom(e.target.value)}
              name="calle"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Número Exterior: </Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              placeholder="Ingresa número exterior"
              value={numExtDom}
              onChange={(e) => setnumExtDom(e.target.value)}
              name="numeroExterior"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Número Interior:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              placeholder="Ingresa número interior"
              value={numIntDom}
              onChange={(e) => setnumIntDom(e.target.value)}
              name="numeroInterior"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Colonia:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Ingresa Colonia"
              value={coloniaDom}
              onChange={(e) => setcoloniaDom(e.target.value)}
              name="colonia"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Estado:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {inputDisabled === false ? (
              <Form.Control
                as="select"
                value={estadoPais}
                onChange={handleInputChangeEstado}
              >
                <option>Selecciona un Estado</option>
                {estadosPaisData.map((estado) => (
                  <option key={estado.clave} value={estado.nombre}>
                    {estado.nombre}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                className="inpRegimen"
                style={{ textTransform: "capitalize", wordWrap: "normal" }}
                value={estadoPais}
                disabled={inputDisabled}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Municipio: </Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {inputDisabled === false ? (
              <Form.Control
                as="select"
                value={municipioSeleccionado}
                onChange={(e) => setMunicipioSeleccionado(e.target.value)}
              >
                <option>Selecciona un Municipio</option>
                {municipiosEstadoData[estadoPais] &&
                  municipiosEstadoData[estadoPais].map((municipio) => (
                    <option key={municipio} value={municipio}>
                      {municipio}
                    </option>
                  ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                className="inpRegimen"
                value={municipioSeleccionado}
                disabled={inputDisabled}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Código Postal:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              placeholder="Codigo Postal"
              value={cpDom}
              onChange={(e) => setcpDom(e.target.value)}
              name="codigoPostal"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Observaciones:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="textarea"
              rows={3}
              value={observacionesFact}
              placeholder="Observaciones"
              onChange={(e) => setobservacionesFact(e.target.value)}
              name="observaciones"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        {inputDisabled === false && (
          <div className="divSubmit">
            <Button variant="success" onClick={actualizarRegistro}>
              <FontAwesomeIcon icon={faFloppyDisk} /> Guardar Cambios
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default FacturacionPerfil;
