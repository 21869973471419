import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarEstablecimientos,
    ENDPOINTObtenerEstablecimientos,
    ENDPOINTObtenerEstablecimientosPorFolio,
    ENDPOINTObtenerEstablecimientosPorCorreo,
    ENDPOINTListarEstablecimientos,
    ENDPOINTListarEstablecimientosPorCorreo,
    ENDPOINTActualizarEstablecimientos,
    ENDPOINTEliminarEstablecimientos,
    ENDPOINTDeshabilitarEstablecimientos,
    ENDPOINTObtenerUltimoEstablecimiento,
    ENDPOINTObtenerFolioEstablecimiento,
    ENDPOINTTotalEstablecimiento
} from "./endpoints";
import axios from 'axios';

export async function listarEstablecimientos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarEstablecimientos, config);
}

export async function listarUltimo(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUltimoEstablecimiento, config);
}

export async function listarEstablecimientosPorCorreo(email){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarEstablecimientosPorCorreo + `?email=${email}`, config);
}

export async function registraEstablecimientos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarEstablecimientos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerEstablecimientos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerEstablecimientos + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerEstablecimientosPorFolio(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerEstablecimientosPorFolio + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerEstablecimientosPorCorreo(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerEstablecimientosPorCorreo + `/${params}`, config);
}

export async function actualizarEstablecimientos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarEstablecimientos + `/${id}`, data, config);
}

export async function eliminarEstablecimientos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarEstablecimientos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarEstablecimientos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarEstablecimientos + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroEstablecimiento() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioEstablecimiento, config);
}

export async function totalEstablecimientos() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalEstablecimiento, config);
}