import { useMemo, useState, useEffect } from 'react';
import { Form, Button } from "react-bootstrap";
import { useTable } from 'react-table';
import { listarRecursosHumanos } from "../../api/recursosHumanos";
import { listarCursos } from "../../api/cursos";
import { registraCapacitacionesEmpleados } from "../../api/capacitacionesEmpleados";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from '../logs/logs';
import { toast } from 'react-toastify';
import queryString from 'query-string';

function AltaCapacitacion({ history }) {
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedChecks, setSelectedChecks] = useState([]);
    const [filterCourse, setFilterCourse] = useState();
    const [listarEmpleados, setListEmpleados] = useState([]);
    const [listarCurso, setListCurso] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState();

    useEffect(() => {
        obtenerOrdenesServicio();
        obtenerCurso();
    }, []);

    const obtenerOrdenesServicio = () => {
        try {
            listarRecursosHumanos()
                .then((response) => {
                    const { data } = response;
                    if (!listarEmpleados && data) {
                        const empleadosConEstadoTrue = data.filter(empleado => empleado.estado === true);
                        setListEmpleados(formatModelEmpleados(empleadosConEstadoTrue));
                    } else {
                        const empleadosConEstadoTrue = data.filter(empleado => empleado.estado === true);
                        const datosOrdenes = formatModelEmpleados(empleadosConEstadoTrue);
                        setListEmpleados(datosOrdenes);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    const obtenerCurso = () => {
        try {
            listarCursos()
                .then((response) => {
                    const { data } = response;
                    if (!listarCurso && data) {
                        setListCurso(formatModelCurso(data));
                    } else {
                        const datosCurso = formatModelCurso(data);
                        setListCurso(datosCurso);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    const formatModelEmpleados = (data) => {
        const dataTemp = [];
        data.forEach((data) => {
            dataTemp.push({
                id: data._id,
                name: data.nombreCompleto,
                fechaNacimiento: data.fechaNacimiento,
                direccion: data.direccion,
                telefono: data.telefono,
                email: data.email,
                estadoCivil: data.estadoCivil,
                cargo: data.cargo,
                fechaContratacion: data.fechaContratacion,
                salario: data.salario,
                formaPago: data.formaPago,
                horarioTrabajo: data.horarioLaboral.horaEntrada + "/" + data.horarioLaboral.horaSalida,
                diasDescanso: data.diasDescanso,
                vacaciones: data.vacaciones,
                contactoEmergencia: data.contactoEmergencia,
                seguroMedico: data.seguroMedico,
                nivelEducacion: data.nivelEducacion,
                foto: data.foto,
                estado: data.estado,
                curp: data.curp,
                rfc: data.rfc,
                regimenFiscal: data.regimenFiscal
            });
        });
        return dataTemp;
    };

    const formatModelCurso = (data) => {
        const dataTemp = [];
        data.forEach((data) => {
            dataTemp.push({
                id: data._id,
                nombre: data.nombre,
                tipo: data.tipo,
                descripcion: data.descripcion,
                fecha: data.fecha,
                estado: data.estado,
            });
        });
        return dataTemp;
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Empleado',
                accessor: 'name',
            },
            {
                Header: 'Select',
                accessor: 'checkbox',
                Cell: ({ cell }) => (
                    <td
                        {...cell.getCellProps()}
                        style={{ padding: '10px', border: 'solid 1px gray', textAlign: 'center' }}
                    >
                        <Form.Check
                            type="checkbox"
                            checked={selectedChecks.includes(cell.row.original.id)}
                            onChange={() => toggleEmployeeSelection(cell.row.original.id, cell.row.original.name)}
                            style={{ margin: 'auto' }}
                        />
                    </td>
                ),
            },

        ],
        [selectedChecks]
    );

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilterCourse(value);
        setSelectedCourse(value);
        setSelectedChecks([]);
    };

    const toggleEmployeeSelection = (id, name) => {
        setSelectedEmployees(prevSelectedEmployees => {
            const isEmployeeSelected = prevSelectedEmployees.includes(name);
            let updatedEmployees = [];
            if (isEmployeeSelected) {
                updatedEmployees = prevSelectedEmployees.filter(employee => employee !== name);
            } else {
                updatedEmployees = [...prevSelectedEmployees, name];
            }
            return updatedEmployees;
        });

        setSelectedChecks(prevSelectedChecks => {
            const isCheckSelected = prevSelectedChecks.includes(id);
            let updatedChecks = [];
            if (isCheckSelected) {
                updatedChecks = prevSelectedChecks.filter(check => check !== id);
            } else {
                updatedChecks = [...prevSelectedChecks, id];
            }
            return updatedChecks;
        });
    };

    const data = useMemo(() => listarEmpleados, [listarEmpleados]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const registrarCapacitacion = () => {
        if (!selectedCourse) {
            toast.warning("Selecciona un curso");
        } else {
            selectedEmployees.map((empleado, index) => {
                try {
                    const dataTemp = {
                        empleado: empleado,
                        curso: selectedCourse,
                        estado: "true",
                    };
                    registraCapacitacionesEmpleados(dataTemp).then((response) => {
                        const { data } = response;
                        LogsInformativos("Se ha registrado la capacitacion para el curso " + dataTemp.curso + "al empleado " + dataTemp.empleado, dataTemp);
                        history({
                            search: queryString.stringify(""),
                        });
                    });
                } catch (e) {
                    console.log(e);
                }
            });
            // Limpiar el array de empleados seleccionados después de registrar la capacitación
            setSelectedEmployees([]);
            setSelectedChecks([]);
        }
    };

    return (
        <div>
            <Form.Control as="select" value={selectedCourse} onChange={handleFilterChange}>
                <option value="">Selecciona una opcion</option>
                {listarCurso.map(curso => (
                    <option key={curso.id} value={curso.nombre}>{curso.nombre}</option>
                ))}
            </Form.Control>
            <table {...getTableProps()} style={{ width: '100%', border: 'solid 1px blue' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} style={{ borderBottom: 'solid 3px red' }}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{ padding: '10px', border: 'solid 1px gray' }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="mb-3 d-flex justify-content-center">
                <Button variant="success" onClick={registrarCapacitacion}>
                    <FontAwesomeIcon icon={faCartPlus} />
                    &nbsp;Registrar capacitacion de los empleados a este curso
                </Button>
            </div>
        </div>
    );
}

export default AltaCapacitacion;
