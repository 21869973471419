import React, { useEffect, useState } from "react";
import { actualizarOrdenCompra, obtenerOrdenCompra } from "../../../api/ordenCompra";
import { Button, Col, Form, InputGroup, Row, Table, Modal } from "react-bootstrap";
import Select from "react-select";
import { listarUnidadMedida } from "../../../api/unidadesMedida";
import { listarProveedores } from "../../../api/proveedores";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faTrash, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from "../../logs/logs";

const ActualizarOC = ({ data, setShow }) => {
  const idOC = data;

  const [ordenC, setOrdenC] = useState([]);
  const [folioActual, setFolioActual] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [autoriza, setAutoriza] = useState("");
  const [elabora, setElabora] = useState("");
  const [productosOrdenC, setProductosOrdenC] = useState([]);

  const obtenerOC = (idOC) => {
    try {
      obtenerOrdenCompra(idOC)
        .then((response) => {
          const { data } = response;
          if (!ordenC && data) {
            setOrdenC(data);
          } else {
            setOrdenC(data);
            setFolioActual(data.folio);
            setProveedor(data.datosProveedor.razonSocial);
            setElabora(data.elaboro);
            setAutoriza(data.autorizo);
            setProductosOrdenC(data.productos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOC(idOC);
  }, []);

  const [listarUM, setListarUM] = useState([]);

  const obtenerUM = () => {
    try {
      listarUnidadMedida()
        .then((response) => {
          const { data } = response;
          if (!listarUM && data) {
            setListarUM(formatModelUM(data));
          } else {
            setListarUM(formatModelUM(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUM();
  }, []);

  /***********TABLA PROD**********/
  const [arrProductos, setArrProductos] = useState([]);
  const [producto, setProducto] = useState("");
  const [uMedida, setUMedida] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [precioUnitario, setPrecioUnitario] = useState("");

  const agregarProducto = () => {
    if (!producto || !uMedida || !cantidad || !precioUnitario) {
      toast.warning("Datos incompletos");
    } else {
      const nuevoProducto = {
        producto: producto,
        um: uMedida,
        cantidad: cantidad,
        pUnitario: precioUnitario,
        totalProducto: parseFloat(precioUnitario) * parseFloat(cantidad),
      };

      setProductosOrdenC([...productosOrdenC, nuevoProducto]);
      setProducto("");
      setCantidad("");
      setUMedida("");
      setPrecioUnitario("");
    }
  };

  const handleQuit = (index) => {
    const newArray = [...productosOrdenC];
    newArray.splice(index, 1);
    setProductosOrdenC(newArray);
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const [subtotal, setSubtotal] = useState(0);
  const [iva, setiva] = useState(0);
  const [totalOC, settotalOC] = useState(0);

  useEffect(() => {
    const sumaTotalOC = productosOrdenC.reduce(
      (totalProducto, venta) => totalProducto + parseFloat(venta.totalProducto),
      0
    );

    const ivaCalc = sumaTotalOC * 0.16;
    const totalCalc = sumaTotalOC + ivaCalc;

    setSubtotal(sumaTotalOC);
    setiva(ivaCalc);
    settotalOC(totalCalc);
  }, [productosOrdenC]);

  /**************************** */

  const actualizarOC = () => {
    try {
      const dataTemp = {
        productos: productosOrdenC,
        subtotal: subtotal,
        iva: iva,
        total: totalOC,
        elaboro: elabora,
        autorizo: autoriza,
        estado: "activo",
      };
      actualizarOrdenCompra(idOC, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        LogsInformativos("Se ha actualizado la orden de compra " + dataTemp.elaboro, dataTemp)
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  /************************************************ */
  const [showModal, setShowModal] = useState(false);
  const [cantidadTemp, setCantidadTemp] = useState(0);
  const [productoIndex, setProductoIndex] = useState(-1);
  const [productoNom, setProductoNom] = useState("");

  const handleOpenModal = (cantidad, index, nombre) => {
    setCantidadTemp(cantidad);
    setProductoIndex(index);
    setProductoNom(nombre)
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModificarCantidad = () => {
    // Actualizar la cantidad en el arreglo arrProductos
    const updatedProductos = [...productosOrdenC]; // Clonar el arreglo original
    updatedProductos[productoIndex].cantidad = cantidadTemp; // Actualizar la cantidad en el producto específico
    // Aquí puedes hacer una llamada a una función para enviar los datos actualizados al servidor, si es necesario
    // Luego, actualiza el estado y cierra el modal
    setArrProductos(updatedProductos);
    handleCloseModal();
  };
  /************************************************ */

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Folio:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Folio de OC"
              value={folioActual}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Proveedor:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control type="text" disabled value={proveedor} />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Elabora:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien elabora"
              value={elabora}
              onChange={(e) => setElabora(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Autoriza:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien autoriza"
              value={autoriza}
              onChange={(e) => setAutoriza(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col sm={12} md={2} lg={2}>
            <Form.Label>Producto:</Form.Label>
            <Form.Control
              type="text"
              value={producto}
              onChange={(e) => setProducto(e.target.value)}
            />
          </Col>
          <Col sm={12} md={3} lg={3}>
            <Form.Label>Unidad de medida:</Form.Label>
            <Form.Control
              as="select"
              value={uMedida}
              onChange={(e) => setUMedida(e.target.value)}
            >
              <option value="">Seleccionar</option>
              {listarUM.map((um, index) => (
                <option value={um.nombre}>{um.nombre}</option>
              ))}
            </Form.Control>
          </Col>
          <Col sm={12} md={2} lg={2}>
            <Form.Label>Cantidad:</Form.Label>
            <Form.Control
              type="text"
              value={cantidad}
              onChange={(e) => {
                let inputValue = e.target.value;
                if (/^\d*\.?\d*$/.test(inputValue)) {
                  setCantidad(inputValue);
                }
              }}
            />
          </Col>
          <Col sm={12} md={3} lg={3}>
            <Form.Label>Precio unitario:</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="text"
                value={precioUnitario}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    setPrecioUnitario(inputValue);
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col sm={12} md={1} lg={1} className="d-flex align-items-end">
            <Button onClick={agregarProducto}>Agregar</Button>
          </Col>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Producto</th>
                <th>Unidad de medida</th>
                <th>Cantidad</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {productosOrdenC.map((producto, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{producto.producto}</td>
                  <td>{producto.unidadMedida}</td>
                  <td>{producto.cantidad}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleOpenModal(producto.cantidad, index, producto.producto)}
                      title="Modificar cantidad"
                    >
                      <FontAwesomeIcon icon={faHashtag} />
                    </Button>
                    &nbsp;
                    <Button variant="danger" onClick={() => handleQuit(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Modificar Cantidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formCantidad">
                <h4 style={{ textAlign: "center", fontStyle: "italic" }}>{productoNom}</h4>
                <Form.Label>Nueva Cantidad:</Form.Label>
                <Form.Control
                  type="number"
                  value={cantidadTemp}
                  onChange={(e) => setCantidadTemp(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleModificarCantidad}>
                Actualizar
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={actualizarOC}>
            <FontAwesomeIcon icon={faCartPlus} />
            &nbsp;Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

function formatModelUM(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

const formatModelProveedores = (data) => {
  return data.map((proveedor) => ({
    value: proveedor.nombre,
    label: proveedor.nombre, // Puedes personalizar el formato del label según tus necesidades
  }));
};

export default ActualizarOC;
