import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarVehiculos,
    ENDPOINTObtenerVehiculos,
    ENDPOINTListarVehiculos,
    ENDPOINTActualizarVehiculos,
    ENDPOINTEliminarVehiculos,
    ENDPOINTDeshabilitarVehiculos,
    ENDPOINTActualizarVehiculosKm
} from "./endpoints";
import axios from 'axios';

export async function listarVehiculos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarVehiculos, config);
}

export async function registraVehiculos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarVehiculos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerVehiculos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerVehiculos + `/${params}`, config);
}

export async function actualizarVehiculos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarVehiculos + `/${id}`, data, config);
}

export async function actualizarVehiculosKm(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarVehiculosKm + `/${id}`, data, config);
}

export async function eliminarVehiculos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarVehiculos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarVehiculos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarVehiculos + `/${id}`, data, config);
}