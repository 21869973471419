import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarCursos,
    ENDPOINTObtenerCursos,
    ENDPOINTListarCursos,
    ENDPOINTActualizarCursos,
    ENDPOINTEliminarCursos,
    ENDPOINTDeshabilitarCursos
} from "./endpoints";
import axios from 'axios';

export async function listarCursos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCursos, config);
}

export async function registraCursos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarCursos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerCursos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerCursos + `/${params}`, config);
}

export async function actualizarCursos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCursos + `/${id}`, data, config);
}

export async function eliminarCursos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarCursos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarCursos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarCursos + `/${id}`, data, config);
}