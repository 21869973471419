import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { eliminarDiasFestivos } from "../../api/diasFestivos";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function ElimnaDiasFestivos({ data, history, setShow }) {

    const idDiaFestivo = data[0];

    const dataTemp = {
        fecha: data[2],
        descripcion: data[3]
    }

    const [formData, setFormData] = useState(initialFormValue(dataTemp));
    const [signInLoading, setSignInLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fecha || !formData.festividad) {
            toast.warning("Completa todos los campos del formulario.");
        } else {
            setSignInLoading(true);
            try {
                const dataTemp = {
                    fecha: formData.fecha,
                    descripcion: formData.festividad
                };
                eliminarDiasFestivos(idDiaFestivo, dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha modificado el dia festivo " + dataTemp.festividad, dataTemp)
                    setSignInLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                }).catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                        setSignInLoading(false);
                    } else {
                        if (ex.response && ex.response.status === 401) {
                            const { mensaje } = ex.response.data;
                            toast.error(mensaje);
                            setSignInLoading(false);
                        }
                    }
                });
            } catch (ex) {
                toast.error("Error al iniciar sesión");
                setSignInLoading(false);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Fecha: <code>*</code></Form.Label>
                    <Form.Control
                        type="date"
                        className="nomNewUser"
                        placeholder="Fecha"
                        name="fecha"
                        defaultValue={formData.fecha}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Nombre de la festividad: <code>*</code></Form.Label>
                    <Form.Control
                        type="text"
                        className="emailNewUser"
                        placeholder="Ingresa el nombre de la festividad"
                        name="festividad"
                        defaultValue={formData.festividad}
                        disabled
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue(data) {
    return {
        fecha: data.fecha,
        festividad: data.descripcion
    };
}
