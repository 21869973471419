import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import {
  registraUnidadMedida,
  obtenerNumeroUnidadMedida,
} from "../../api/unidadesMedida";
import { LogsInformativos } from "../logs/logs";

export default function RegistrarUnidadesMedida({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());
  const [signInLoading, setSignInLoading] = useState(false);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroUnidadMedida()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noUnidad } = data;
          setFolioActual(noUnidad);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setSignInLoading(true);
    try {
      const precio = 1;
      const dataTemp = {
        folio: folioActual,
        nombre:
          formData.umAdquisicion == "No aplica"
            ? formData.umPrimaria
            : formData.umAdquisicion + formData.umPrimaria.toLocaleLowerCase(),
        conversion:
          formData.umAdquisicion === "Decá"
            ? parseFloat(precio) * 100
            : formData.umAdquisicion === "Hectó"
            ? parseFloat(formData.umAdquisicion) * 10
            : formData.umAdquisicion === "Kiló"
            ? parseFloat(precio) * 1000
            : formData.umAdquisicion === "Decí"
            ? parseFloat(precio) / 10
            : formData.umAdquisicion === "Centí"
            ? parseFloat(precio) / 100
            : formData.umAdquisicion === "Milí"
            ? parseFloat(precio) / 1000
            : formData.umAdquisicion == "No aplica"
            ? precio
            : "",
        estado: "true",
      };
      registraUnidadMedida(dataTemp)
        .then((response) => {
          const { data } = response;
          console.log(data);

          toast.success(data.mensaje);

          history({
            search: queryString.stringify(""),
          });
          LogsInformativos(
            "Se ha registrado la unidad de medida " + dataTemp.nombre,
            dataTemp
          );
          setSignInLoading(false);
          setShow(false);
          //cancelarRegistro()
        })
        .catch((ex) => {
          if (ex.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
            setSignInLoading(false);
          } else {
            if (ex.response && ex.response.status === 401) {
              const { mensaje } = ex.response.data;
              toast.error(mensaje);
              setSignInLoading(false);
            }
          }
        });
    } catch (ex) {
      toast.error("Error al iniciar sesión");
      setSignInLoading(false);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>
                Folio: <code>*</code>
              </Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control type="text" value={folioActual} disabled />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>
                Nombre de la unidad de medida: <code>*</code>
              </Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Nombre de la unidad de medida"
                name="umPrimaria"
                defaultValue={formData.umPrimaria}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>
                Multiplo/Submultiplo: <code>*</code>
              </Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                name="umAdquisicion"
                defaultValue={formData.umAdquisicion}
              >
                <option>Elige una opción</option>
                <option value="No aplica">No aplica</option>
                <option value="Decá">
                  Decá{formData.umPrimaria.toLowerCase()}
                </option>
                <option value="Hectó">
                  Hectó{formData.umPrimaria.toLowerCase()}
                </option>
                <option value="Kiló">
                  Kiló{formData.umPrimaria.toLowerCase()}
                </option>
                <option value="Decí">
                  Decí{formData.umPrimaria.toLowerCase()}
                </option>
                <option value="Centí">
                  Centí{formData.umPrimaria.toLowerCase()}
                </option>
                <option value="Milí">
                  Milí{formData.umPrimaria.toLowerCase()}
                </option>
              </Form.Control>
            </Col>
          </Row>
        </div>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue() {
  return {
    nombre: "",
    umPrimaria: "",
    umAdquisicion: "",
  };
}
