import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deshabilitarMesas } from "../../../api/mesas";
import queryString from "query-string";
import { Load } from "../../load/load";
import { LogsInformativos } from "../../logs/logs";

export default function DeshabilitarComedor({ data, history, setShow }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            deshabilitarMesas().then((response) => {
                const { data } = response;
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                LogsInformativos("Se ha actualizado el estado del comedor ")
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {loading && <Load />}
            <div className="generalOrden2">
                <br />
                <Form className="formOrden2" onSubmit={onSubmit}>
                    <div style={{ textAlign: "center", marginBottom: "1%" }}>
                        <h3>¿Actualizar el estado del comedor?</h3>
                    </div>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}
