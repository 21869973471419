import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarDepartamentoServicios } from "../../api/servicios";
import { listarDepartamentos } from "../../api/departamentos";
import { toast } from "react-toastify";
import { map } from "lodash";
import queryString from "query-string";
import { registraEvento } from "../../api/eventos";
import { LogsInformativos } from "../logs/logs";

export default function TransferirDepartamento({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data[0];

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto
      const temp = formData.departamento.split("/");

      const dataTemp = {
        folioDepartamento: temp[0],
        departamento: temp[1],
        email: temp[2],
      }

      actualizarDepartamentoServicios(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        LogsInformativos("Se ha transferido la orden de cliente " + dataTemp.folioDepartamento, dataTemp)
        toast.success(data.mensaje);
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
          <Form.Group>
            <Form.Label className='lblTelefono'>Departamento: </Form.Label>
            <Form.Control
              as="select"
              className='inpTelefono'
              placeholder='Ingresa modelo'
              defaultValue={formData.departamento}
              name="departamento"
            >
              <option>Elige una opción</option>
              {map(listarDoctor, (cat, index) => (
                <option key={index} value={cat?.folio + "/" + cat?.nombre + "/" + cat?.email}>
                  {" "}
                  {cat?.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    departamento: "",
  };
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      email: data.email,
      estado: data.estado,
    });
  });
  return dataTemp;
}
