import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarAlmacenMateriasPrimas,
    ENDPOINTObtenerAlmacenMateriasPrimas,
    ENDPOINTListarAlmacenMateriasPrimas,
    ENDPOINTListarAlmacenMateriasPrimasProveedor,
    ENDPOINTListarPaginandoAlmacenMateriasPrimas,
    ENDPOINTTotalAlmacenMateriasPrimas,
    ENDPOINTActualizarAlmacenMateriasPrimas,
    ENDPOINTEliminarAlmacenMateriasPrimas,
    ENDPOINTDeshabilitarAlmacenMateriasPrimas,
    ENDPOINTObtenerFolioAlmacenMateriasPrimas,
    ENDPOINTObtenerProductosPorNombre,
    ENDPOINTListarProductosAgotandose,
    ENDPOINTBuscarPorIdsAMP
} from "./endpoints";
import axios from 'axios';

export async function listarAlmacenMateriasPrimas() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAlmacenMateriasPrimas, config);
}

export async function listarAlmacenMateriasPrimasProveedores(proveedor) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAlmacenMateriasPrimasProveedor + `?departamento=${proveedor}`, config);
}

export async function listarProductosAgotandose() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarProductosAgotandose, config);
}

export async function listarPaginandoAlmacenMateriasPrimas(pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPaginandoAlmacenMateriasPrimas +
        `/?pagina=${pagina}&&limite=${limite}`
        , config);
}

export async function totalMateriasPrimas() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalAlmacenMateriasPrimas, config);
}

export async function registraAlmacenMateriasPrimas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarAlmacenMateriasPrimas, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerAlmacenMateriasPrimas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerAlmacenMateriasPrimas + `/${params}`, config);
}

export async function actualizaAlmacenMateriasPrimas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarAlmacenMateriasPrimas + `/${id}`, data, config);
}

export async function eliminarAlmacenMateriasPrimas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarAlmacenMateriasPrimas + `/${id}`, data, config);
}

export async function actualizaDeshabilitarAlmacenMateriasPrimas(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarAlmacenMateriasPrimas + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroAlmacenMateriasPrimas() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioAlmacenMateriasPrimas, config);
}

export async function listarAlmacenMateriasPrimasNombre(searchTerm, pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    };

    return await axios.get(API_HOST + ENDPOINTObtenerProductosPorNombre + `/?searchTerm=${searchTerm}&&pagina=${pagina}&&limite=${limite}`, config);
}

export async function buscarAlmacenMateriasPrimasIds(arrayIds) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTBuscarPorIdsAMP + `?ids=${arrayIds}`, config);
}