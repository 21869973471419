import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { actualizarEntregas } from "../../api/entregas";
import { listarOrdenesTrabajo } from "../../api/ordenesTrabajo";
import { listarMensajeros } from "../../api/mensajeros";
import { map } from "lodash";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { actualizaDeshabilitarOrdenesServicioFolio, obtenerOrdenesServicioPorFolio } from "../../api/ordenesServicio";
import { obtenerUsuario } from "../../api/usuarios";
import { obtenerUsuarioEmail } from "../../api/usuarios";
import { registraEvento } from "../../api/eventos"; 
import { LogsInformativos } from "../logs/logs";

export default function ModificarEntregas({ data, history, setShow }) {

  const [contraseñaDoctorInput, setContraseñaDoctorInput] = useState("");

  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    mensajero: data[2],
    fechaEntrega: data[4],
    ordenTrabajo: data[3],
    motivoEntrega: data[5],
    comentarios: data[6],
  };

  console.log(dataTemp);

  const tiposViaje = ["Trabajo final", "Prueba"];

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const [signInLoading, setSignInLoading] = useState(false);

  const obtenerFechaYHoraActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let horas = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    horas = horas < 10 ? `0${horas}` : horas;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${dia}-${mes}-${year} ${horas}:${minutos}`;
  };

  // Estado para almacenar la fecha seleccionada
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");

  useEffect(() => {
    setFechaSeleccionada(obtenerFechaYHoraActual());
  }, []);

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setCorreoUsuario(data.email);
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [correoDoctor, setCorreoDoctor] = useState("");

  const obtenerDatosODS = () => {
    try {
      obtenerOrdenesServicioPorFolio(data[3])
        .then((response) => {
          const { data } = response;
          console.log(data)
          setCorreoDoctor(data[0].correoDoctor);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosODS();
  }, [correoUsuario]);

  const [contraseñaDoctor, setContraseñaDoctor] = useState("");

  const obtenerDatosUsuarioDoctor = () => {
    try {
      obtenerUsuarioEmail(correoDoctor)
        .then((response) => {
          const { data } = response;
          console.log(data)
          setContraseñaDoctor(data.password);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuarioDoctor();
  }, [correoDoctor]);

  console.log(contraseñaDoctor)

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesTrabajo = () => {
    try {
      listarOrdenesTrabajo()
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesTrabajo();
  }, []);

console.log(listarOrdenes)

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const [recibioEntrega, setrecibioEntrega] = useState("")
  const [ubicacionUsuario, setubicacionUsuario] = useState("")

  const findMe = async () => {
    if (!contraseñaDoctorInput || contraseñaDoctorInput != contraseñaDoctor) {
      toast.error("La contraseña del doctor no coincide o está vacía");
      return;
    }
    if (!navigator.geolocation) {
      toast.error("El navegador no soporta geolocalización", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        // Obtener la dirección usando MapQuest
        const apiKey = "2eei5oDr1QNMmUGDCY8115cQclIbiAq3"; // Reemplaza con tu clave de API de MapQuest
        const response = await fetch(
          `https://www.mapquestapi.com/geocoding/v1/reverse?key=${apiKey}&location=${latitude},${longitude}`
        );
        const data = await response.json();
        const addressData = data.results[0].locations[0];

        // Extraer información específica
        const street = addressData.street;
        const city = addressData.adminArea5;
        const state = addressData.adminArea3;
        const country = addressData.adminArea1;

        const completeAddress = `${street}, ${city}, ${state}, ${country}`;
        console.log(completeAddress)
        setubicacionUsuario(completeAddress)
      },
      (error) => {
        alert("Error al obtener ubicación");
      }
    );
  };

  const guardarEvento = async () => {
    try {
      const fechaActual = obtenerFechaYHoraActual();
      const dataTemp2 = {
        fecha: fechaActual,
        folioOrden: dataTemp.fechaEntrega,
        usuario: nombreUsuario,
        tipoEvento: "Entregada",
        motivoEvento: "Entrega realizada",
        estado: "activo",
        leido: 0,
      };
      registraEvento(dataTemp2).then((response) => {
        const { data } = response;
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizarEstadoOS = async () => {
    try {
      const dataTemp2 = {
        estado: "8",
      };
      actualizaDeshabilitarOrdenesServicioFolio(data[3], dataTemp2).then((response) => {
        const { data } = response;
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {

    try {

      const fechayHoraActual = obtenerFechaYHoraActual();
      const dataTemp = {
        mensajero: formData.mensajero.split('/')[0],
        correoMensajero: tipoUsuario == "mensajero" ? correoUsuario : formData.mensajero.split('/')[1],
        fechayHoraEntrega: fechayHoraActual,
        motivoEntrega: formData.motivoEntrega,
        comentarios: formData.comentarios,
        ubicacion: ubicacionUsuario,
        recibio: recibioEntrega,
        estado: "1"
      };
      actualizarEntregas(idDocumental, dataTemp)
        .then((response) => {
          const { data } = response;
          console.log(data);
          history({
            search: queryString.stringify(""),
        });
          LogsInformativos("Se ha modificado la entrega del mensajero " + dataTemp.mensajero, dataTemp)
          toast.success("Entrega exitosa");
          guardarEvento();
          actualizarEstadoOS();
          setShow(false);
          //cancelarRegistro()
        })
        .catch((ex) => {
          if (ex.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          } else {
            if (ex.response && ex.response.status === 401) {
              const { mensaje } = ex.response.data;
              toast.error(mensaje);
            }
          }
        });
    } catch (ex) {
      toast.error("Error al iniciar sesión");
      setSignInLoading(false);
    }
  };

  useEffect(() => {
    if (ubicacionUsuario != "") {
      onSubmit();
    }
  }, [ubicacionUsuario])


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Folio:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                name="direccion"
                style={{ textTransform: "capitalize" }}
                value={formData.folio}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha de entrega:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                value={fechaSeleccionada}
                onChange={(e) => setFechaSeleccionada(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Mensajero:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                aria-label="Seleccionar mensajero"
                defaultValue={formData.mensajero}
                name="mensajero"
                disabled
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Orden trabajo:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control type="text" value={formData.ordenTrabajo} disabled />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Motivo de la entrega:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de viaje"
                defaultValue={formData.motivoEntrega}
                name="motivoEntrega"
              >
                <option>Seleccionar</option>
                {tiposViaje.map((tipo, index) => (
                  <option
                    key={index}
                    value={tipo}
                    selected={formData.motivoEntrega == tipo}
                  >
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Recibió:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Nombre de quien recibe"
                value={recibioEntrega}
                onChange={(e) => setrecibioEntrega(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Comentarios:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="textarea"
                placeholder="Comentarios"
                defaultValue={formData.comentarios}
                name="comentarios"
              />
            </Col>
          </Row>

          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Contraseña del doctor (para confirmar entrega)</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                name="contraseñaDoctorInput"
                value={contraseñaDoctorInput}
                onChange={(e) => setContraseñaDoctorInput(e.target.value)}
                placeholder="Contraseña del doctor"
              />
            </Col>
          </Row>

        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <Button onClick={findMe}>Guardar</Button>
        </div>
      </Form>
    </div>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    mensajero: data.mensajero,
    fechaEntrega: data.fechaEntrega,
    ordenTrabajo: data.ordenTrabajo,
    motivoEntrega: data.motivoEntrega,
    comentarios: data.comentarios,
  };
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
      calle: data.calle,
      nExterior: data.nExterior,
      nInterior: data.nInterior,
      colonia: data.colonia,
      estadoDom: data.estadoDom,
      municipio: data.municipio,
      cPostal: data.cPostal,
      referencia: data.referencia,
    });
  });
  return dataTemp;
}

const formatModelOrdenes = (data) => {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
    });
  });
  return dataTemp;
};
