import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarVacaciones,
    ENDPOINTObtenerVacaciones,
    ENDPOINTListarVacaciones,
    ENDPOINTActualizarVacaciones,
    ENDPOINTEliminarVacaciones,
    ENDPOINTDeshabilitarVacaciones,
    ENDPOINTEventsVacaciones,
    ENDPOINTVacacionesProximas,
} from "./endpoints";
import axios from 'axios';

export async function listarVacaciones(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarVacaciones, config);
}

export async function eventsVacaciones(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTEventsVacaciones, config);
}

export async function registraVacaciones(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarVacaciones, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerVacaciones(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerVacaciones + `/${params}`, config);
}

export async function actualizarVacaciones(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarVacaciones + `/${id}`, data, config);
}

export async function eliminarVacaciones(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarVacaciones + `/${id}`, data, config);
}

export async function actualizaDeshabilitarVacaciones(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarVacaciones + `/${id}`, data, config);
}

export async function listarVacacionesProximas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTVacacionesProximas, config);
}