import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { obtenerOrdenesRutas } from "../../../api/rutas";

const TablaOrdenesRuta = ({ data }) => {
  console.log(data);
  const idRuta = data.id;
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [listarViaje, setListViaje] = useState([]);

  const obtenerOR = (id) => {
    console.log(id)
    try {
      obtenerOrdenesRutas(id)
        .then((response) => {
          const { data } = response;
          console.log(data)

         if (!listarViaje && data) {
            setListViaje(formatModelViaje(data));
          } else {
            const datosViaje = formatModelViaje(data);
            setListViaje(datosViaje);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOR(idRuta);
  }, [idRuta]);

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "consultorio",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "direccionConsultorio",
      label: "DIRECCION",
    },
    {
      name: "estado",
      label: "ESTADO",
      options: {
        customBodyRender: (value) => {
          const estado = value;
          let estadoTexto = "";
          if (estado == "2") {
            estadoTexto = "Recolección";
          } else {
            estadoTexto = "Entrega";
          }
          return <div>{estadoTexto}</div>;
        },
      },
    },
  ];

  return (
    <div>
      <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
        <h4>Ordenes de la ruta </h4>&nbsp;
        <h4 style={{ textTransform: "capitalize" }}>
          {data.ruta.split("/")[0]}
        </h4>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de ordenes asignadas"}
          data={listarViaje}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

function formatModelViaje(data) {
    const dataTemp = [];
    data.forEach((data) => {
      dataTemp.push({
        id: data._id,
        folio: data.folio,
        consultorio: data.localidad,
        nombreDoctor: data.nombreDoctor,
        direccionConsultorio: data.direccionConsultorio,
        estado: data.estado,
      });
    });
    return dataTemp;
  }

export default TablaOrdenesRuta;
