import React, { useEffect, useState } from "react";
import { editarLugarMesa, obtenerMesas } from "../../../api/mesas";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faChair, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import RegistroMesas from "./RegistroMesas";
import { Button } from "react-bootstrap";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import DeshabilitarComedor from "./DeshabilitarComedor";
import { withRouter } from "../../../utils/withRouter";
import { Load } from "../../load/load";

const Mesas = ({ history }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroViajes = (content) => {
    setTitulosModal("Registar mesa");
    setContentModal(content);
    setShowModal(true);
  };

  const deshabilitarComedor = (content) => {
    setTitulosModal("Actualizar estado comedor");
    setContentModal(content);
    setShowModal(true);
  };

  const [listMesas, setListMesas] = useState([]);

  const obtenerListaMesas = () => {
    try {
      obtenerMesas()
        .then((response) => {
          const { data } = response;
          if (!listMesas && data) {
            setListMesas(formatModelMesas(data));
          } else {
            setListMesas(formatModelMesas(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (!showModal) {
      obtenerListaMesas();
    }
  }, [showModal]);

  const onSubmit = (silla, mesa) => {
    console.log("mesa", mesa);
    console.log("silla", silla);
    try {
      const dataTemp = {
        silla: silla.silla,
        nuevoEstado: silla.estado == "1" ? "0" : "1",
      };
      editarLugarMesa(mesa, dataTemp).then((response) => {
        const { data } = response;
        toast.success("Estado de la silla actualizado");
        obtenerListaMesas();
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalMesas">
        <h1>Mesas</h1>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroViajes(<RegistroMesas setShow={setShowModal} />)
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>

          <Button
            className="btnAddTables"
            onClick={() =>
              deshabilitarComedor(<DeshabilitarComedor history={history} setShow={setShowModal} />)
            }
          >
            <FontAwesomeIcon icon={faCancel} /> Actualizar estado
          </Button>
        </div>
        <div className="divPrincipalMesas">
          <div className="card-body">
            <div className="divMesasView">
              {listMesas.map((mesa, index) => (
                <div class="info-box">
                  <span
                    class={
                      "info-box-icon " +
                      (mesa.estado == "1" ? "mesaDisponible" : "bg-secondary")
                    }
                  >
                    <i class="fas fa-utensils"></i>
                  </span>
                  <div class="info-box-content">
                    <span class="info-box-number titMesa">
                      N. Mesa: {mesa.numeroMesa}
                    </span>
                    <span class="info-box-text">
                      N. Personas: {mesa.numeroPersonas}
                    </span>
                    <span class="info-box-text descMesa">{mesa.descripcion}</span>
                    <div className="sillasMesa">
                      {mesa.lugares.map((silla, indexS) => (
                        <div
                          key={indexS}
                          style={{ fontSize: "x-large" }}
                          onClick={() => onSubmit(silla, mesa.id)}
                        >
                          <FontAwesomeIcon
                            className={
                              silla.estado == "0"
                                ? "sillaDisponible"
                                : "sillaOcupada"
                            }
                            icon={faChair}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ textAlign: "left", fontSize: "small" }}>
          Simbología <br />
          <div className="simbologiaDiv">
            <FontAwesomeIcon icon={faChair} style={{ color: "red" }} /> Silla
            ocupada
            <br />
            <FontAwesomeIcon
              icon={faChair}
              style={{ color: "rgb(47, 255, 92)" }}
            />{" "}
            Silla desocupada
          </div>
        </div>
        <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
          {contentModal}
        </BasicModal>
      </div>
    </>
  );
};

function formatModelMesas(mesas) {
  const tempmesas = [];
  mesas.forEach((mesas) => {
    tempmesas.push({
      id: mesas._id,
      numeroMesa: mesas.numeroMesa,
      descripcion: mesas.descripcion,
      numeroPersonas: mesas.numeroPersonas,
      lugares: mesas.lugares,
      estado: mesas.estado,
    });
  });
  return tempmesas;
}

export default withRouter(Mesas);
