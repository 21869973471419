import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarDiasFestivos,
    ENDPOINTObtenerDiasFestivos,
    ENDPOINTListarDiasFestivos,
    ENDPOINTActualizarDiasFestivos,
    ENDPOINTEliminarDiasFestivos,
    ENDPOINTDeshabilitarDiasFestivos
} from "./endpoints";
import axios from 'axios';

export async function listarDiasFestivos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDiasFestivos, config);
}

export async function registraDiasFestivos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarDiasFestivos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerDiasFestivos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerDiasFestivos + `/${params}`, config);
}

export async function actualizarDiasFestivos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarDiasFestivos + `/${id}`, data, config);
}

export async function eliminarDiasFestivos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarDiasFestivos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarDiasFestivos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarDiasFestivos + `/${id}`, data, config);
}