import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarAsistencia,
    ENDPOINTObtenerAsistencia,
    ENDPOINTListarAsistencia,
    ENDPOINTListarAsistenciaDia,
    ENDPOINTListarAsistenciaSemana,
    ENDPOINTListarDepartamentosAsistencia,
    ENDPOINTActualizarAsistencia,
    ENDPOINTEliminarAsistencia,
    ENDPOINTDeshabilitarAsistencia,
    ENDPOINTListarFechasAsistencia
} from "./endpoints";
import axios from 'axios';

export async function listarAsistencia(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAsistencia, config);
}

export async function listarAsistenciaDia(fecha){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAsistenciaDia + `?fecha=${fecha}`, config);
}

export async function listarAsistenciaSemana(inicio, fin){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAsistenciaSemana + `?inicio=${inicio}&&fin=${fin}`, config);
}

export async function listarDepartamentosAsistencia(usuario){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDepartamentosAsistencia + `?usuario=${usuario}`, config);
}

export async function registraAsistencia(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarAsistencia, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerAsistencia(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerAsistencia + `/${params}`, config);
}

export async function actualizarAsistencia(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarAsistencia + `/${id}`, data, config);
}

export async function eliminarAsistencia(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarAsistencia + `/${id}`, data, config);
}

export async function actualizaDeshabilitarAsistencia(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarAsistencia + `/${id}`, data, config);
}

export async function listarAsistenciasEntreFechas(fechaInicial, fechaFinal){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarFechasAsistencia + `?fecha1=${fechaInicial}&&fecha2=${fechaFinal}`, config);
}