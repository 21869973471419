import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { registraViajes } from "../../api/viajes";
import { listarEstablecimientos } from "../../api/establecimientos";
import { listarMensajeros } from "../../api/mensajeros";
import { listarDoctores } from "../../api/doctores";
import { listarVehiculos } from "../../api/vehiculos";
import { map } from "lodash";
import Select from "react-select";
import { listarOrdenesServicio, asignarMensajeroOrdenesServicio } from "../../api/ordenesServicio";
import { LogsInformativos } from "../logs/logs";
import { registraEntregas, obtenerNumeroEntregas } from "../../api/entregas";

export default function RegistroViajes({ history, setShow }) {
  const tiposViaje = [
    "Viaje Especial",
    "Viaje Responsabilidad",
    "Asistencia Clínica Especial",
    "Asistencia Clínica Responsabilidad",
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [folioActualEntrega, setFolioActualEntrega] = useState("");

  const obtenerFolioEntrega = () => {
    try {
      obtenerNumeroEntregas()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noEntrega } = data;
          setFolioActualEntrega(noEntrega);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolioEntrega();
  }, []);

  const registrarEntregas = async (folioOrden) => {
    try {
      const temp = formData.nombreMensajeroRuta.split("/");
      const dataTemp = {
        folio: folioActualEntrega,
        mensajero: temp[0],
        correoMensajero: temp[1],
        fechayHoraEntrega: "Pendiente",
        ordenTrabajo: folioOrden,
        motivoEntrega: "Pendiente de entrega",
        recibio: "",
        ubicacion: "",
        comentarios: "",
        tipo: "Viaje especial",
        estado: "0",
      };

      const response = await registraEntregas(dataTemp);
      const { data } = response;
      console.log(data.mensaje, "de folio ", folioOrden);
    } catch (error) {
      console.error(
        `Error al registrar entregas para el folioOrden ${folioOrden}:`,
        error
      );
      setLoading(false);
    }
  };

  const actualizarOrdenMensajero = async (idDocumental) => {
    try {
      const temp = formData.nombreMensajeroRuta.split("/");
      const dataTemp = {
        mensajero: temp[0],
        correoMensajero: temp[1],
        telefonoMensajero: temp[2],
      }

      const response = await asignarMensajeroOrdenesServicio(
        idDocumental,
        dataTemp
      );
      const { data } = response;
      toast.success(data.mensaje);
    } catch (error) {
      console.error(
        `Error al actualizar la orden del mensajero para el id ${idDocumental}:`,
        error
      );
      setLoading(false);
    }
  };

  const [listOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicios = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;

          if (!listOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicios();
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);

  const tiposCliente = ["Doctor", "Consultorio"];

  const [formData, setFormData] = useState(initialFormValue());
  const [signInLoading, setSignInLoading] = useState(false);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;

    return `${year}-${mes}-${dia}`;
  };

  // Estado para almacenar la fecha seleccionada
  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    obtenerFechaActual()
  );

  useEffect(() => {
    setFechaSeleccionada(obtenerFechaActual());
  }, []);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            setListEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, []);

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.nombreMensajeroRuta) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      setSignInLoading(true);
      try {
        const temp = formData.nombreMensajeroRuta.split("/");
        const dataTemp = {
          fecha: fechaSeleccionada,
          tipoCliente: formData.tipoCliente,
          clienteVisitar: formData.clienteVisitar,
          direccion: domSel,
          ubicacion: formData.ubicacion,
          motivoViaje: formData.motivoViaje,
          descripcionViaje: formData.descripcionViaje,
          ordenS: selectedOption,
          cargoAñadido: formData.cargoAñadido,
          mensajero: temp[0],
          doctor: formData.doctor,
          estado: "true",
        };
        registraViajes(dataTemp)
          .then((response) => {
            const { data } = response;
            console.log(data);
            const filteredOrdenes = listOrdenes.filter((orden) => orden.value === selectedOption);
            registrarEntregas(selectedOption);
            actualizarOrdenMensajero(filteredOrdenes[0].id)
            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });
            LogsInformativos("Se ha registrado el viaje " + dataTemp.direccion, dataTemp)
            setSignInLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
          .catch((ex) => {
            if (ex.message === "Network Error") {
              toast.error("Conexión al servidor no disponible");
              setSignInLoading(false);
            } else {
              if (ex.response && ex.response.status === 401) {
                const { mensaje } = ex.response.data;
                toast.error(mensaje);
                setSignInLoading(false);
              }
            }
          });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [idClienteVisita, setidClienteVisita] = useState("");

  useEffect(() => {
    setidClienteVisita(formData.clienteVisitar.split("/")[1]);
  }, [formData.clienteVisitar]);

  const [domSel, setdomSel] = useState([]);

  useEffect(() => {
    if (formData.tipoCliente == "Doctor") {
      const filterID = listarDoctor.find((data) => data.id === idClienteVisita);
      if (filterID) {
        setdomSel(
          "C. " +
          filterID.calle +
          ", Ext. " +
          (filterID.nExterior !== undefined ? filterID.nExterior : "SN") +
          ", Int. " +
          (filterID.nInterior !== undefined ? filterID.nInterior : "SN") +
          ", Col. " +
          filterID.colonia +
          ", CP. " +
          filterID.cPostal +
          ", " +
          filterID.municipio +
          ", " +
          filterID.estadoDom
        );
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    } else if (formData.tipoCliente == "Consultorio") {
      const filterID = listarEstablecimiento.find(
        (data) => data.id === idClienteVisita
      );
      if (filterID) {
        setdomSel(
          "C. " +
          filterID.calle +
          ", Ext. " +
          (filterID.numeroExterior !== undefined
            ? filterID.numeroExterior
            : "SN") +
          ", Int. " +
          (filterID.numeroInterior !== undefined
            ? filterID.numeroInterior
            : "SN") +
          ", Col. " +
          filterID.colonia +
          ", CP. " +
          filterID.codigoPostal +
          ", " +
          filterID.municipio +
          ", " +
          filterID.estadoEstablecimiento
        );
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  }, [idClienteVisita]);

  const [checked, setChecked] = useState(false);
  const [folioODS, setFolioODS] = useState("");

  const handleCheckboxChange = () => {
    setChecked(!checked);
    setSelectedOption("Sin Orden Asignada")
  };

  const [listarVehiculo, setListVehiculo] = useState([]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVehiculo();
  }, []);

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                value={fechaSeleccionada}
                onChange={(e) => setFechaSeleccionada(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label hidden >Tipo de cliente: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de cliente"
                value={formData.tipoCliente}
                name="tipoCliente"
                disabled
                hidden
              >
                <option>Seleccionar</option>
                {tiposCliente.map((tipo, index) => (
                  <option key={index} value={tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          {formData.tipoCliente == "Consultorio" && (
            <>
              <Row className="mb-2 mb-md-4 mb-lg-7">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                >
                  <Form.Label>Cliente a visitar: <code>*</code></Form.Label>
                </Col>
                <Col sm={12} md={8} lg={8}>
                  <Form.Control
                    as="select"
                    aria-label="Seleccionar cliente"
                    name="clienteVisitar"
                    defaultValue={formData.clienteVisitar}
                  >
                    <option>Elige una opción</option>
                    {map(listarEstablecimiento, (cat, index) => (
                      <option key={index} value={cat?.alias + "/" + cat?.id}>
                        {cat?.alias}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </>
          )}
          {formData.tipoCliente == "Doctor" && (
            <>
              <Row className="mb-2 mb-md-4 mb-lg-7">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                >
                  <Form.Label>Cliente a visitar: <code>*</code></Form.Label>
                </Col>
                <Col sm={12} md={8} lg={8}>
                  <Form.Control
                    as="select"
                    aria-label="Seleccionar cliente"
                    name="clienteVisitar"
                    defaultValue={formData.clienteVisitar}
                  >
                    <option>Elige una opción</option>
                    {map(listarDoctor, (cat, index) => (
                      <option
                        key={index}
                        value={
                          cat?.nombre +
                          " " +
                          cat?.apellidoPaterno +
                          " " +
                          cat?.apellidoMaterno +
                          "/" +
                          cat?.id
                        }
                      >
                        {cat?.nombre +
                          " " +
                          cat?.apellidoPaterno +
                          " " +
                          cat?.apellidoMaterno}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </>
          )}
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Dirección: </Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="direccion"
                style={{ textTransform: "capitalize" }}
                defaultValue={domSel}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Motivo del Viaje: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de viaje"
                defaultValue={formData.motivoViaje}
                name="motivoViaje"
              >
                <option>Seleccionar</option>
                {tiposViaje.map((tipo, index) => (
                  <option key={index} value={tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Descripción del Viaje: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="textarea"
                placeholder="Ingresa una descripción"
                defaultValue={formData.descripcionViaje}
                name="descripcionViaje"
              />
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Orden de cliente:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Select
                options={listOrdenes}
                isSearchable={true}
                placeholder={!checked ? "Seleccionar..." : "Sin Orden de Cliente Asignada"}
                onChange={(selectedOption) =>
                  handleSeleccionChange(selectedOption)
                }
                isDisabled={checked}
              />
            </Col>
          </Row>
          <Row>

          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            ></Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Check
                type="checkbox"
                id="toggle-check"
                label="No Aplica"
                checked={checked}
                onChange={handleCheckboxChange}
              />
            </Col>
          </Row>

          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Mensajero: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar mensajero"
                defaultValue={formData.nombreMensajeroRuta}
                name="nombreMensajeroRuta"
              >
                <option value>Seleccionar</option>
                {map(listarMensajero, (cat, index) => (
                  <option
                    key={index}
                    value={cat?.nombre + "/" + cat?.email + "/" + cat?.telefono}
                  >
                    {" "}
                    {cat?.nombre}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          {formData.motivoViaje != "Viaje Especial" && (
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Doctor:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="Nombre del doctor"
                  aria-label="Seleccionar doctor"
                  defaultValue={formData.doctor}
                  name="doctor"
                />
              </Col>
            </Row>
          )}
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue() {
  return {
    clienteVisitar: "",
    tipoCliente: "Doctor",
    direccion: "",
    ubicacion: "",
    motivoViaje: "",
    descripcionViaje: "",
    cargoAñadido: "",
    mensajero: "",
    doctor: "",
  };
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
      calle: data.calle,
      nExterior: data.nExterior,
      nInterior: data.nInterior,
      colonia: data.colonia,
      estadoDom: data.estadoDom,
      municipio: data.municipio,
      cPostal: data.cPostal,
      referencia: data.referencia,
    });
  });
  return dataTemp;
}

const formatModelOrdenes = (data) => {
  // Aquí debes implementar la lógica para formatear los datos según tus necesidades
  // Asumiendo que el folio está en la propiedad 'folio' de cada objeto
  return data.map((orden) => ({
    id: orden._id,
    value: orden.folio,
    label: `Orden ${orden.folio}`, // Puedes personalizar el formato del label según tus necesidades
  }));
};

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      estado: data.estado
    });
  });
  return dataTemp;
}
