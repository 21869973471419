import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { eliminarVehiculos } from "../../../api/vehiculos";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function EliminarVehiculo({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data[0];

  const dataTemp = {
    modelo: data[1],
    placa: data[2],
    numEconomico: data[3],
  };

  console.log(data)

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto

      eliminarVehiculos(idDocumental).then((response) => {
        const { data } = response;
        //notificacion
        LogsInformativos("Se ha eliminado el vehiculo " + idDocumental, dataTemp)
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
          <Form.Group>
            <Form.Label className='lblTelefono'>Modelo: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa modelo'
              defaultValue={formData.modelo}
              name="modelo"
              disabled
            />
          </Form.Group>
          <Form.Group className='lblTelefono'>
            <Form.Label className='lblEmail'>Placa: </Form.Label>
            <Form.Control
              type='text'
              className='inpNombreEst'
              placeholder='placa'
              defaultValue={formData.placa}
              name="placa"
              disabled
            />
            <Form.Label className='lblTelefono'># Economico: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa economico'
              defaultValue={formData.numEconomico}
              name="numEconomico"
              disabled
            />
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    conductor: data.conductor,
    modelo: data.modelo,
    placa: data.placa,
    numEconomico: data.numEconomico,
  };
}
