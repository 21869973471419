import React, { useRef, useEffect } from "react";

const MapQuest = ({ height, width, center, tileLayer, zoom, apiKey, onClick }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      // Clave API
      window.L.mapquest.key = apiKey;

      // Inicializar mapa
      const map = window.L.mapquest.map("map", {
        center,
        layers: window.L.mapquest.tileLayer(tileLayer),
        zoom,
      });

      // Agregar control de MapQuest
      map.addControl(window.L.mapquest.control());

      // Manejar eventos de clic en el mapa
      if (onClick) {
        map.on("click", onClick);
      }

      // Guardar referencia al mapa en el ref
      mapRef.current = map;
    }

    return () => {
      // Limpiar el mapa al desmontar el componente
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [center, tileLayer, zoom, apiKey, onClick]);

  return (
    <div id="map" style={{ width, height }}>
      <p>Cargando mapa...</p>
    </div>
  );
};

export default MapQuest;
