import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarRecepcionCompra,
    ENDPOINTObtenerRecepcionCompra,
    ENDPOINTListarRecepcionCompra,
    ENDPOINTActualizarRecepcionCompra,
    ENDPOINTEliminarRecepcionCompra,
    ENDPOINTDeshabilitarRecepcionCompra,
    ENDPOINTObtenerFolioRecepcionCompra,
} from "./endpoints";
import axios from 'axios';

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroRecepcionCompra() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioRecepcionCompra, config);
}

export async function listarRecepcionCompra(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRecepcionCompra, config);
}

export async function registraRecepcionCompra(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarRecepcionCompra, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerRecepcionCompra(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerRecepcionCompra + `/${params}`, config);
}

export async function actualizarRecepcionCompra(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarRecepcionCompra + `/${id}`, data, config);
}

export async function eliminarRecepcionCompra(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarRecepcionCompra + `/${id}`, data, config);
}

export async function actualizaDeshabilitarRecepcionCompra(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarRecepcionCompra + `/${id}`, data, config);
}