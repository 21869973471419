import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { registraCajaChica } from "../../../api/cajaChica";
import { toast } from "react-toastify";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { subeArchivosCloudinary } from "../../../api/cloudinary";

const RegistroES = ({ setShow, balance, origen }) => {
  const [concepto, setConcepto] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [tipo, setTipo] = useState("");
  const [tipoGasto, setTipoGasto] = useState("");
  const [archivo, setArchivo] = useState(null);

  const handleFileChange = (e) => {
    setArchivo(e.target.files[0]);
  };

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre + "/" + data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    hora = hora < 10 ? `0${hora}` : hora;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };

  useEffect(() => {
    if (origen == "comedor") {
      let fecha = obtenerFechaActual();

      setTipo("Salida");
      setConcepto("Comedor");
    }
  }, [origen]);

  function obtenerNumeroDeSemana(fecha) {
    // Copiamos la fecha para no modificar la original
    let fechaCopia = new Date(
      Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate())
    );
    // Establecemos la fecha al primer día de la semana (Domingo)
    fechaCopia.setUTCDate(
      fechaCopia.getUTCDate() + 4 - (fechaCopia.getUTCDay() || 7)
    );
    // Obtenemos el primer día del año
    let primerDiaDelAno = new Date(Date.UTC(fechaCopia.getUTCFullYear(), 0, 1));
    // Calculamos el número de la semana
    let numeroDeSemana = Math.ceil(
      ((fechaCopia - primerDiaDelAno) / 86400000 + 1) / 7
    );
    return numeroDeSemana;
  }

  const registro = () => {
    if (!concepto || !cantidad || !tipo) {
      toast.warning("Datos incompletos");
    } else {
      if (tipo === "Salida") {
        if (parseFloat(cantidad) <= balance) {
          if (archivo) {
            subeArchivosCloudinary(archivo, "laboratorio")
              .then((response) => {
                const { data } = response;
                const { secure_url } = data;
                registraSalida(secure_url);
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            registraSalida("");
          }
        } else {
          toast.warning(
            "La cantidad de salida excede el balance disponible en caja"
          );
        }
      } else {
        if (archivo) {
          subeArchivosCloudinary(archivo, "laboratorio")
            .then((response) => {
              const { data } = response;
              const { secure_url } = data;
              registraSalida(secure_url);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          registraSalida("");
        }
      }
    }
  };

  const registraSalida = (linkArchivo) => {
    try {
      const fechaActual = obtenerFechaActual();
      const semana = obtenerNumeroDeSemana(new Date)
      const dataTempIns = {
        idUsuario: nombreUsuario,
        fechayHora: fechaActual,
        concepto: concepto,
        cantidad: cantidad,
        tipo: tipo,
        semana: semana + "-"+ fechaActual.split('-')[0],
        tipoGasto: tipoGasto,
        fecha: fechaActual.split(' ')[0],
        comprobante: linkArchivo,
        estado: "1",
      };
      registraCajaChica(dataTempIns).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Tipo: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {origen == "comedor" ? (
              <Form.Control
                type="text"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                disabled
              />
            ) : (
              <Form.Control
                as="select"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              >
                <option value="">Seleccionar</option>
                <option value="Entrada">Entrada</option>
                <option value="Salida">Salida</option>
              </Form.Control>
            )}
          </Col>
        </Row>

        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Concepto: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Ingresa el concepto de la operación"
              value={concepto}
              onChange={(e) => setConcepto(e.target.value)}
              disabled= {origen != "comedor" ? false : true}
            />
          </Col>
        </Row>

        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={cantidad}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    setCantidad(inputValue);
                  }
                }}
                placeholder="Ingresa la cantidad de la operación"
              />
            </InputGroup>
          </Col>
        </Row>
        {origen == "comedor" && (
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Tipo de gasto: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
            <Form.Control
                as="select"
                value={tipoGasto}
                onChange={(e) => setTipoGasto(e.target.value)}
              >
                <option value="">Seleccionar</option>
                <option value="dia">Día</option>
                <option value="semana">Semana</option>
              </Form.Control>
            </Col>
          </Row>
        )}

        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Comprobante:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              accept="image/png, image/jpeg, .pdf"
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-center">
        <Button variant="success" onClick={registro}>
          <FontAwesomeIcon icon={faCashRegister} /> Registrar
        </Button>
      </div>
    </div>
  );
};

export default RegistroES;
