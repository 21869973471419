import { useState } from 'react'
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ModificacionMateriales from './ModificacionMateriales';
import BasicModal from '../Modal/BasicModal/BasicModal';

export const TablaServicios = ({ productos, setProductos }) => {

  // Para eliminar productos del listado
  const removeItem = (producto) => {
    let newArray = productos;
    newArray.splice(newArray.findIndex(a => a.servicio === producto.servicio), 1);
    setProductos([...newArray]);
  }

  // Para hacer uso del modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  // Para la eliminacion fisica de usuarios
  const modificaMaterial = (content) => {
    setTitulosModal("Modificando el material");
    setContentModal(content);
    setShowModal(true);
  }

  return (
    <div>
      <Table striped bordered hover size="sm" >
        <thead>
          <tr>
            <th>#</th>
            <th>Material a enviar</th>
            <th>Cantidad</th>
            <th>Ajustar cantidad</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.servicio}</td>
                <td>{producto.cantidad}</td>
                <td data-title="Eliminar">
                  <Button
                    variant="success"
                    onClick={() => {
                      modificaMaterial(
                        <ModificacionMateriales
                          datos={producto}
                          setShowModal={setShowModal}
                          listProductosCargados={productos}
                          setListProductosCargados={setProductos}
                        />)
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} className="text-lg" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </div>
  )
}
