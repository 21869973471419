import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPaperPlane,
  faPlus,
  faTrash,
  faArrowsLeftRight,
  faArrowLeft,
  faPenAlt
} from "@fortawesome/free-solid-svg-icons";
import {
  listarAlmacenProductosLimpiezaNombre,
  totalAlmacenProductosLimpieza,
} from "../../api/almacenProductosLimpieza";
import { registraVales, totalVales, obtenerNumeroVales } from "../../api/vales";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LogsInformativos } from "../logs/logs";
import { listarServiciosODT } from "../../api/servicios";
import { map } from "lodash";
import ModificacionProductos from "./ModificarCantidadMaterial";
import BasicModal from "../Modal/BasicModal/BasicModal";

const ValeInsumos = ({ setShow }) => {
  const [nombreProd, setNombreProd] = useState("");
  const [areaVale, setAreaVale] = useState("");
  const [nombre, setNombre] = useState("");
  const [ordenTrabajo, setOrdenTrabajo] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");

  const [folioActual, setFolioActual] = useState("");

  // Para hacer uso del modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const obtenerFolio = () => {
    try {
      obtenerNumeroVales()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noVale } = data;
          setFolioActual(noVale);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;

    return `${year}-${mes}-${dia}`;
  };

  // Estado para almacenar la fecha seleccionada
  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    obtenerFechaActual()
  );

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerServciosRuta = () => {
    try {
      listarServiciosODT()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerServciosRuta();
  }, []);

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setNombre(data.nombre);
          setAreaVale(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    if (tipoUsuario == "administrador") {
      setFilterList(listarOrdenes);
    } else {
      const ordenesFilter = listarOrdenes.filter(
        (ordenes) => ordenes.departamento === areaVale
      );
      setFilterList(ordenesFilter);
    }
  }, [areaVale, listarOrdenes, tipoUsuario]);

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarProducto, setListProducto] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noTotalProductos, setNoTotalProductos] = useState(1);

  const modificaProducto = (content) => {
    setTitulosModal("Modificando el producto");
    setContentModal(content);
    setShowModal(true);
  }

  const obtenerProductos = (nombre) => {
    try {
      totalAlmacenProductosLimpieza()
        .then((response) => {
          const { data } = response;
          setNoTotalProductos(data);
        })
        .catch((e) => {
          console.log(e);
        });

      listarAlmacenProductosLimpiezaNombre(nombre, page, rowsPerPage)
        .then((response) => {
          const { data } = response;
          if (!listarProducto && data) {
            setListProducto(formatModelProductos(data));
          } else {
            const datosProducto = formatModelProductos(data);
            setListProducto(datosProducto);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const handleChangePage = (page) => {
    // console.log("Nueva pagina "+ newPage)
    setPage(page);
  };

  const handleChangeRowsPerPage = (newPerPage) => {
    // console.log("Registros por pagina "+ parseInt(event.target.value, 10))
    setRowsPerPage(newPerPage);
    //setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (nombreProd != "") {
      obtenerProductos(nombreProd);
    }
  }, [nombreProd, page, rowsPerPage]);

  const [productosAgregados, setProductosAgregados] = useState([]);

  const handleAgregar = (producto) => {
    const producto2 = {
      id: producto[0],
      clave: producto[1],
      producto: producto[2],
      categoria: producto[3],
      unidadMedida: producto[4],
    };
    setProductosAgregados([...productosAgregados, producto2]);
  };

  const handleDelete = (index) => {
    // Obtener una copia del arreglo original
    const newArray = [...arregloAgrupado];

    // Disminuir la cantidad del producto en el índice dado
    newArray[index].cantidad--;

    // Si la cantidad llega a cero, eliminar el producto del arreglo
    if (newArray[index].cantidad === 0) {
      newArray.splice(index, 1);
    }

    // Actualizar el estado con el nuevo arreglo
    setArregloAgrupado(newArray);
  };

  function agregarCantidadYAgruparPorId(arreglo) {
    // Crear un objeto para almacenar los grupos de productos por ID
    const gruposPorId = {};

    // Recorrer el arreglo de objetos y agrupar por ID
    arreglo.forEach((producto) => {
      const id = producto.id;
      if (gruposPorId[id]) {
        // Si el grupo ya existe, incrementar la cantidad
        gruposPorId[id].cantidadd++;
      } else {
        // Si el grupo no existe, crearlo con cantidad 1
        gruposPorId[id] = {
          ...producto,
          cantidad: 1,
        };
      }
    });

    // Devolver los objetos agrupados con el campo "cantidad" añadido
    return Object.values(gruposPorId);
  }

  const [arregloAgrupado, setArregloAgrupado] = useState([]);

  useEffect(() => {
    const arregloConCantidad = agregarCantidadYAgruparPorId(productosAgregados);
    setArregloAgrupado(arregloConCantidad);
  }, [productosAgregados]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (productosAgregados.length === 0) {
      toast.warning("No se han agregado productos");
    } else {
      try {
        const dataTemp = {
          folio: folioActual,
          area: !areaVale ? "" : areaVale,
          fecha: fechaSeleccionada,
          material: arregloAgrupado,
          autorizo: "Pendiente",
          entrego: "Pendiente",
          recibio: nombre,
          estado: "1",
          tipo: "insumos"
        };
        registraVales(dataTemp).then((response) => {
          const { data } = response;
          LogsInformativos("Se ha registrado el vale " + dataTemp.recibio, dataTemp)
          toast.success("Material solicitado a almacen");
          setShow(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "clave",
      label: "CLAVE",
    },
    {
      name: "producto",
      label: "MATERIAL",
    },
    {
      name: "categoria",
      label: "CATEGORIA",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                className="btnAddTables"
                onClick={() => handleAgregar(tableMeta.rowData)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    responsive: isSmOrDown && "standard",
    selectableRows: "none",
    pagination: true,
    serverSide: true,
    search: false, // Oculta la barra de búsqueda
    download: false, // Oculta el botón de descarga
    print: false, // Oculta el botón de impresión
    viewColumns: false,
    filter: false,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onChangePage: handleChangePage,
    count: noTotalProductos,
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        //columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
    },
  };

  return (
    <>
      <div style={{ padding: "2%" }}>
        <Row>
          {/* Parte superior: formulario y tabla */}
          <Col sm={12} md={7} lg={7}>
            <Form>
              <div>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Fecha: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="date"
                      value={fechaSeleccionada}
                      onChange={(e) => setFechaSeleccionada(e.target.value)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Nombre del solicitante: </Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Nombre solicitante"
                      value={nombre}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Área: </Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Área solicitante"
                      value={areaVale}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Orden de trabajo:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      placeholder="Nombre de quien recibe"
                      value={ordenTrabajo}
                      onChange={(e) => setOrdenTrabajo(e.target.value)}
                    >
                      <option value="">Elige una opción</option>
                      {map(filterList, (cat, index) => (
                        <option key={index} value={cat?.folioODS}>
                          {cat?.folioODS}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Material: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa Material a buscar 🔍"
                      value={nombreProd}
                      onChange={(e) => setNombreProd(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Cantidad:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <div className="divCantidadButtonVale">
                      <Form.Control
                        type="text"
                        placeholder="Cantidad a solicitar"
                      />
                      &nbsp;
                      <Button variant="success">
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </Col>
  
          <Col sm={12} md={5} lg={5}>
            <div>
              <h4 style={{ textAlign: "center" }}>Productos agregados</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Cantidad</th>
                    <th>Clave</th>
                    <th>Material</th>
                    <th>UM</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {arregloAgrupado.length === 0 ? (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Aún no se han agregado productos
                      </td>
                    </tr>
                  ) : (
                    arregloAgrupado.map((producto, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{producto.cantidad}</td>
                        <td>{producto.clave}</td>
                        <td>{producto.producto}</td>
                        <td>{producto.unidadMedida}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                          <Button
                            variant="success"
                            onClick={() => {
                              modificaProducto(
                                <ModificacionProductos
                                  datos={producto}
                                  setShowModal={setShowModal}
                                  listProductosCargados={arregloAgrupado}
                                  setListProductosCargados={setArregloAgrupado}
                                />
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faPenAlt} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
  
        {/* Parte inferior: MUIDataTable */}
        <Row>
          <Col sm={12}>
            <div style={{ padding: "1%" }}>
              <MUIDataTable
                data={listarProducto}
                columns={columns}
                options={options}
              />
            </div>
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
                <Button variant="success" onClick={onSubmit}>
                  <FontAwesomeIcon icon={faPaperPlane} /> Solicitar
                </Button>
              </div>
  
        {/* Modal */}
        <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
          {contentModal}
        </BasicModal>
      </div>
    </>
  );  
};

function formatModelProductos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      producto: data.producto,
      categoria: data.categoria,
      unidadMedida: data.unidadMedida,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      folioODS: data.ODT,
      fecha: data.fechaHora,
      pieza: data.pieza,
      sistemaColor: data.sistemaColor,
      tipoDentadura: data.distencion,
      tonos:
        data.tonoInferior + ", " + data.tonoMedio + ", " + data.tonoSuperior,
      dientes: data.diente,
      departamento: data.departamento,
      imagenDiente: data.imagenDiente,
      imagenEsquema: data.imagenEsquema,
      prioridad: data.prioridad,
      estado: data.estado,
      direccionConsultorio: data.direccionConsultorio,
      dia: data.dia,
      numeroProceso: data.numeroProceso,
      nombreProceso: data.nombreProceso
    });
  });
  return dataTemp;
}

export default ValeInsumos;
