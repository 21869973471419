import React, { useEffect, useState } from "react";
import DynamicChart from "../../administrador/Dashboard/grafica/Grafica";
import { calcularHorasTranscurridasOrdenesTrabajoSer } from "../../../api/servicios";

const GraficasProd = () => {
  const [listarTrabajos, setListTrabajos] = useState([]);

  const obtenerOTrabajo = () => {
    try {
      calcularHorasTranscurridasOrdenesTrabajoSer()
        .then((response) => {
          const { data } = response;
          const datosServicios = formatModelOrdenes(data);
          setListTrabajos(datosServicios);
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOTrabajo();
  }, []);

  const [folios, setfolios] = useState([]);
  const [horas, sethoras] = useState([]);

  useEffect(() => {
    // Extraer los campos folio y horasTranscurridas
    const folios = listarTrabajos.map((trabajo) => trabajo.folio);
    const horasTranscurridas = listarTrabajos.map(
      (trabajo) => trabajo.horasTranscurridas
    );
    setfolios(folios);
    sethoras(horasTranscurridas);
  }, [listarTrabajos]);

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2>Progreso de ordenes de trabajo</h2>
      </div>
      <div style={{textAlign:"center"}}>
      <DynamicChart
        frecuencias={horas}
        etiquetas={folios}
        chartType={"bar"}
        titInfo={"Progreso de ordenes"}
        bgColor={"#D6912B"}
        chartTitle={"HORAS TRANSCURRIDAS"}
      />
      </div>
    </div>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.horasTranscurridas.forEach((ordenTrabajo) => {
    dataTemp.push({
      id: ordenTrabajo._id,
      folio: ordenTrabajo.folio,
      folioODS: ordenTrabajo.folioODS,
      fecha: ordenTrabajo.fecha,
      localidad: ordenTrabajo.localidad,
      nombreDoctor: ordenTrabajo.nombreDoctor,
      nombrePaciente: ordenTrabajo.nombrePaciente,
      tipoDentadura: ordenTrabajo.tipoDentadura,
      servicios: ordenTrabajo.servicios,
      fechaEntrada: ordenTrabajo.fechaEntrada,
      correoDoctor: ordenTrabajo.correoDoctor,
      correoConsultorio: ordenTrabajo.correoConsultorio,
      prioridad: ordenTrabajo.prioridad,
      horasTranscurridas: ordenTrabajo.horasTranscurridas,
      estado: ordenTrabajo.estado,
    });
  });
  return dataTemp;
}

export default GraficasProd;
