import React, { useEffect, useState } from "react";
import { listarPagos } from "../../../api/pagos";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Load } from "../../load/load";
import MUIDataTable from "mui-datatables";
import BasicModal from "../../Modal/BasicModal/BasicModal";

const TablaPagos = ({ data, history, setShow }) => {
    console.log(data)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarPrecio, setListPrecio] = useState([]);

  const obtenerPrecios = () => {
    try {
        const correo = data[0]
      listarPagos()
        .then((response) => {
          const { data } = response;
          console.log(data);
  
          if (!listarPrecio && data) {
            setListPrecio(formatModelPagos(data));
          } else {
            const datosPrecio = formatModelPagos(data);
            const registrosFiltrados = datosPrecio.filter(item => item.correoCliente === correo);
  
            setListPrecio(registrosFiltrados);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };
  

  useEffect(() => {
    obtenerPrecios();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID DE OPERACIÓN",
    },
    {
      name: "concepto",
      label: "CONCEPTO DE PAGO",
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "formaPago",
      label: "FORMA DE PAGO",
    },
    {
      name: "fechayHora",
      label: "FECHA Y HORA DE OPERACION",
      options: {
        customBodyRender: (value) => convertToCdmxTime(value).split('.')[0],
      },
    },
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const convertToCdmxTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    const cdmxTimeZoneOffset = -6;
  
    const cdmxDateTime = new Date(utcDateTime.getTime() + cdmxTimeZoneOffset * 60 * 60 * 1000);
  
    const formattedDateTime = cdmxDateTime.toISOString().replace('T', ' ').replace('Z', '');
  
    return formattedDateTime;
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows:  "none" ,
    textLabels: {
      body: {
        noMatch: "No se encontraron pagos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de pagos realizados de "+data[1]}
          data={listarPrecio}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
};

function formatModelPagos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      correoCliente: data.correoCliente,
      concepto: data.concepto,
      cantidad: data.cantidad,
      formaPago: data.formaPago,
      fechayHora: data.createdAt
    });
  });
  return dataTemp;
}

export default TablaPagos;
