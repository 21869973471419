import {
  faCashRegister,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { listarEstablecimientosPorCorreo } from "../../../api/establecimientos";
import { registraPagos } from "../../../api/pagos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import {
  actualizarSaldosCliente,
  actualizarSaldosCorreo,
  registraSaldosCliente,
} from "../../../api/saldosCliente";
import { LogsInformativos } from "../../logs/logs";

const PagosClientes = ({ data, saldoPagado, saldoPendiente, pagosOrdenes, setShow }) => {
  console.log(data);
  const [concepto, setConcepto] = useState("");
  const [cargo, setCargo] = useState("");
  const [formaPago, setFormaPago] = useState("efectivo");

  const nombreCliente =
    data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno;

  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const registrarSaldoCliente = () => {
    try {
      const dataTemp = {
        nombreCliente: nombreCliente,
        correoCliente: data.email,
        saldo: cargo,
        estado: "activo",
      };
      registraSaldosCliente(dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizaSaldo = () => {
    try {
      const correo = data.email;
      let nuevoSaldo = parseFloat(saldoPagado) + parseFloat(cargo);
      const dataTemp = {
        saldo: nuevoSaldo,
      };
      actualizarSaldosCorreo(correo, dataTemp).then((response) => {
        const { data } = response;
        //LogsInformativos("Se ha registrado el pago del cliente con correo " + correo, dataTemp)
        toast.success(data.mensaje);
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos
  let fechaFormat = `${year}-${mes <10 ? ("0"+mes) : mes}-${dia <10 ? ("0"+dia) : dia}`;

  const registrarPagoCliente = () => {
    try {
      const verifica = saldoPagado;
      const dataTemp2 = {
        correoCliente: data.email,
        nombreCliente: nombreCliente,
        concepto: concepto,
        cantidad: cargo,
        formaPago: formaPago,
        realizaOperacion: correoUsuario,
        fecha: fechaFormat,
        estado: "activo",
      };
      registraPagos(dataTemp2).then((response) => {
        const { data } = response;
        if (verifica >0 ) {
          actualizaSaldo();
        } else {
          registrarSaldoCliente();
        }
        toast.success(data.mensaje);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Cliente: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control type="text" value={data.email} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Razon Social: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control type="text" value={nombreCliente} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Saldo pagado:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={saldoPagado} type="number" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Saldo pendiente:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={parseFloat(saldoPendiente)} type="number" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Forma de pago: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              as="select"
              value={formaPago}
              onChange={(e) => setFormaPago(e.target.value)}
              placeholder={"Ingresa forma de pago"}
            >
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
              <option value="cheques">Cheques</option>
              <option value="depositos">Depositos</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Concepto: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              value={concepto}
              onChange={(e) => setConcepto(e.target.value)}
              placeholder={"Ingresa el concepto de pago"}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Cantidad: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={cargo}
                onChange={(e) => setCargo(e.target.value)}
                type="number"
                min={1}
              />
            </InputGroup>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registrarPagoCliente}>
            <FontAwesomeIcon icon={faCashRegister} />
            &nbsp;Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PagosClientes;
