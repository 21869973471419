import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import {
  actualizaDeshabilitarServicios,
  actualizarEstadosyOrden,
  actualizarGarantiaServicios
} from "../../api/servicios";
import { LogsInformativos } from "../logs/logs";
import queryString from "query-string";

const Garantia = ({ data, setShow, history }) => {
  const idOT = data[0];
  const folioOT = data[2];

  const [garantia, setGarantia] = useState("");

  const handleSelectChange = (e) => {
    setGarantia(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      const dataTemp = {
        garantia: garantia
      };
      actualizarGarantiaServicios(data[0], dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        history({
          search: queryString.stringify(""),
      });
        LogsInformativos(
          "Se ha finalizado la orden de trabajo " + idOT,
          dataTemp
        );

        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit}>
          <h4 style={{ textAlign: "center" }}>¿Finalizar orden {folioOT}?</h4>
          <Form.Group controlId="garantiaSelect" style={{ textAlign: "center", marginTop: "2%" }}>
            <Form.Label>Garantía</Form.Label>
            <Form.Control as="select" value={garantia} onChange={handleSelectChange}>
              <option value="">Seleccione una opción</option>
              <option value="1">En garantía</option>
              <option value="2">Sin garantía</option>
            </Form.Control>
          </Form.Group>
          <div style={{ textAlign: "center", marginTop: "2%" }}>
            <Button variant="success" type="submit">
              <FontAwesomeIcon icon={faFlagCheckered} /> Actualizar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Garantia;
