import { Col, Form, Navbar, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { LogsInformativos } from "../../logs/logs";
import Encabezado from "../../../assets/img/encabezado_formulario_2.png";
import "./Alta.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { obtenerUsuarioEmail, eliminarUsuario } from "../../../api/usuarios";

export default function EliminarUsuarios() {
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [tipo, setTipo] = useState("");
  const [loading, setLoading] = useState(false);

  const obtenerDatosUsuario = async () => {
    if (!email) return; // Return early if email is empty
    try {
      const response = await obtenerUsuarioEmail(email);
      const { _id, nombre, tipo } = response.data;
      setId(_id);
      setNombre(nombre);
      setTipo(tipo);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, [email]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!id) return; // Return early if id is not available
    try {
      setLoading(true);
      const response = await eliminarUsuario(id);
      LogsInformativos("Se ha eliminado el usuario " + id);
      toast.success(response.data.mensaje);
      setEmail("");
      setNombre("");
      setTipo("");
    } catch (ex) {
      if (ex.message === "Network Error") {
        toast.error("Conexión al servidor no disponible");
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.mensaje);
      } else {
        toast.error("Error inesperado");
      }
    } finally {
      setLoading(false);
    }
  };

  const footerStyle = {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#0c1b30",
    color: "white",
    textAlign: "center",
    padding: "10px 0",
  };

  return (
    <>
      {loading && <Load />}
      <div>
        <Navbar
          className="justify-content-center"
          style={{
            backgroundColor: "#0c1b30",
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Navbar.Brand>
            <img
              alt=""
              src={Encabezado}
              width="100%"
              height="100%"
            />
          </Navbar.Brand>
        </Navbar>
        <div style={{ marginTop: "80px", marginBottom: "80px" }}>
          <Form onSubmit={onSubmit} style={{ padding: "2%" }}>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div>
                <h4>Eliminación de usuario:</h4>
              </div>
            </div>
            <hr />
            <Row className="mb-2">
              <Col>
                <Form.Label>
                  Email: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingresa email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={4} lg={6}>
                <Form.Control
                  type="text"
                  placeholder="Nombre del usuario"
                  value={nombre}
                  disabled
                />
              </Col>
              <Col sm={12} md={4} lg={6}>
                <Form.Control
                  type="text"
                  value={tipo}
                  placeholder="Tipo de usuario"
                  disabled
                />
              </Col>
            </Row>
            <br />
            <div className="divSubmit">
              <input disabled={!id} className="submit" value="Enviar" type="submit" />
            </div>
          </Form>
        </div>
        <div style={footerStyle}>
          <p style={{ marginBottom: 0 }}>
            <FontAwesomeIcon icon={faLocationDot} /> EL ROBLE #38, COLONIA SANTA CRUZ NIETO, CP 76804, SAN JUAN DEL RÍO, QRO.
          </p>
          <a
            href="mailto:mesadecontrol@grupodentalherfran.com"
            style={{ color: "#fff" }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </>
  );
}
