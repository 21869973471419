import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarViajes,
    ENDPOINTObtenerViajes,
    ENDPOINTListarViajes,
    ENDPOINTActualizarViajes,
    ENDPOINTEliminarViajes,
    ENDPOINTDeshabilitarViajes
} from "./endpoints";
import axios from 'axios';

export async function listarViajes(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarViajes, config);
}

export async function registraViajes(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarViajes, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerViajes(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerViajes + `/${params}`, config);
}

export async function actualizarViajes(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarViajes + `/${id}`, data, config);
}

export async function eliminarViajes(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarViajes + `/${id}`, data, config);
}

export async function actualizaDeshabilitarViajes(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarViajes + `/${id}`, data, config);
}