import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { registraVehiculos } from "../../../api/vehiculos";
import queryString from "query-string";
import Dropzone from "../../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import { LogsInformativos } from "../../logs/logs";

export default function Vehiculos({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());

    //Para almacenar la imagen del producto que se guardara a la bd
    const [imagenProducto, setImagenProducto] = useState(null);

  //load
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.modelo || !formData.placa || !formData.numEconomico) {
      toast.warning("Completa el formulario");
    } else {
      try {
        subeArchivosCloudinary(imagenProducto, "vehiculos").then(response => {
          const { data } = response;
          setLoading(true);

          const dataTemp = {
            conductor: formData.conductor,
            modelo: formData.modelo,
            placa: formData.placa,
            numEconomico: formData.numEconomico,
            color: formData.color,
            kilometrajeActual: formData.kilometrajeActual,
            kilometrajeRecorrido: "0",
            imagen: data.secure_url,
            estado: "true"
          };
          registraVehiculos(dataTemp).then((response) => {
            const { data } = response;
            console.log(data)
            LogsInformativos("Se ha registrado el vehiculo con placa " + dataTemp.placa, dataTemp)
            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });

            setLoading(false);
            setShow(false);
            //cancelarRegistro()
          });
        }).then(e => {
          console.log(e)
        })
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
          <div className="imagenPrincipal">
            <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
            <div title="Seleccionar imagen de la categoría" className="imagenProducto">
              <Dropzone
                setImagenFile={setImagenProducto}
              />
            </div>
          </div>
          <Form.Group>
            <Form.Label //className='lblTelefono'
            >
              Modelo: <code>*</code>
            </Form.Label>
            <Form.Control
              type='text'
              //className='inpTelefono'
              placeholder='Ingresa modelo'
              defaultValue={formData.modelo}
              name="modelo"
            />
            <Form.Label className='lblEmail'>Placa: <code>*</code></Form.Label>
            <Form.Control
              type='text'
              className='inpNombreEst'
              placeholder='placa'
              defaultValue={formData.placa}
              name="placa"
            />
          </Form.Group>
          <Form.Group className='lblTelefono'>
            <Form.Label className='lblTelefono'># Economico: <code>*</code></Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa economico'
              defaultValue={formData.numEconomico}
              name="numEconomico"
            />
            <Form.Label className='lblTelefono'>Color: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa color'
              defaultValue={formData.color}
              name="color"
            />
            <Form.Label className='lblTelefono'>Kilometraje actual: <code>*</code></Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa kilometraje actual'
              defaultValue={formData.kilometrajeActual}
              name="kilometrajeActual"
            />
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  )
}

function initialFormValue() {
  return {
    conductor: "",
    modelo: "",
    placa: "",
    numEconomico: "",
    color: "",
    kilometrajeActual: ""
  };
}
