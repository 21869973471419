import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarServiciosDentales } from "../../../api/serviciosDentales";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function ModificarServiciosDentales({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        clasificacion: data[1],
        tipo: data[2],
        servicio: data[3],
        precio: data[4],
        colorimetria: data[5],
        tono1: data[6],
        tono2: data[7],
        tono3: data[8],
        tono4: data[9],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            const dataTemp = {
                clasificacion: formData.clasificacion,
                tipo: formData.tipo,
                servicio: formData.servicio,
                precio: formData.precio,
                colorimetria: formData.colorimetria,
                tonos: formData.tono1 + "," + formData.tono2 + "," + formData.tono3 + "," + formData.tono4,
                estado: "true"
            };

            actualizarServiciosDentales(idDocumental, dataTemp).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha modificado el servicio dental " + dataTemp.servicio, dataTemp)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalSerDentales'>
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <Form.Label className='lblClaSer'>Clasificación de Servicio: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpClasifSer'
                        defaultValue={formData.clasificacion}
                        name="clasificacion"
                        placeholder='Escribe la clasificacion del material. Ejemplo: Zirconia'
                    />
                    <Form.Label className='lblTipoSer'>Tipo de Servicio: </Form.Label>
                    <Form.Control
                        defaultValue={formData.tipo}
                        name="tipo"
                        type='text'
                        className='inpTipoSer'
                        placeholder='Tipo de servicio que se dará de alta. Ejemplo: Prostodoncia'
                    />
                    <Form.Label className='lblSerSer'>Servicio: </Form.Label>
                    <Form.Control
                        defaultValue={formData.servicio}
                        name="servicio"
                        type='text'
                        className='inpServiciosSer'
                        placeholder='Nombre del servicio. Ejemplo: Endoposte'
                    />

                    <Form.Label className='lblPrecioSer'>Precio: </Form.Label>
                    <Form.Control
                        defaultValue={formData.precio}
                        name="precio"
                        type='text'
                        className='inpPrecioSer'
                        placeholder='Precio'
                    />
                    <div className='checksDiv'>
                        <Form.Label className='lblColSer'>Selección del sistema de colorimetria: </Form.Label>
                        {['radio'].map((type) => (
                            <div className="selColorimetria">
                                <Form.Check
                                    type={type}
                                    id={`VC`}
                                    label={`VITA CLASSICAL`}
                                    value="VITA CLASSICAL"
                                    name="colorimetria"
                                    defaultValue={formData.colorimetria}
                                />
                                <Form.Check
                                    type={type}
                                    id={`V3M`}
                                    name="colorimetria"
                                    label={`VITAPAN 3D MASTER`}
                                    value="VITAPAN 3D MASTER"
                                    defaultValue={formData.colorimetria}
                                />
                            </div>
                        ))}

                        <Form.Label className='lblTonosSer'>Tonos: </Form.Label>
                        {['checkbox'].map((type) => (
                            <div className="selTonos">
                                <Form.Check
                                    type={type}
                                    id={`A1`}
                                    label={`A1`}
                                    value="A1"
                                    defaultValue={formData.tono1}
                                    name="tono1"
                                />
                                <Form.Check
                                    type={type}
                                    id={`A2`}
                                    label={`A2`}
                                    value="A2"
                                    defaultValue={formData.tono2}
                                    name="tono2"
                                />
                                <Form.Check
                                    type={type}
                                    id={`A3`}
                                    label={`A3`}
                                    value="A3"
                                    defaultValue={formData.tono3}
                                    name="tono3"
                                />
                                <Form.Check
                                    type={type}
                                    id={`A4`}
                                    label={`A4`}
                                    value="A4"
                                    defaultValue={formData.tono4}
                                    name="tono4"
                                />
                            </div>
                        ))}
                    </div>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        clasificacion: data.clasificacion,
        tipo: data.tipo,
        servicio: data.servicio,
        precio: data.precio,
        colorimetria: data.colorimetria,
        tono1: data.tono1,
        tono2: data.tono2,
        tono3: data.tono3,
        tono4: data.tono4,
    };
}
