import { faCancel, faPause, faRepeat, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { actualizaDeshabilitarServicios } from "../../api/servicios";
import { toast } from "react-toastify";
import { LogsInformativos } from "../logs/logs";

const IniciarOT = ({ setShow, data }) => {
  const [motivo, setMotivo] = useState(""); // Reason for pausing
  const [descripcion, setDescripcion] = useState(""); // Description if "Otros" is selected
  const datos = data;

  const handleMotivoChange = (e) => {
    setMotivo(e.target.value);
    if (e.target.value !== "Otros") {
      setDescripcion(""); // Clear description if not "Otros"
    }
  };

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds(); // Obtener los minutos
  let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;

  const actualizarEstadoS = async () => {
    try {
      const dataTemp = {
        estado: "2", // Toggle state
        fechaInicio: datosFecha
      };
      const response = await actualizaDeshabilitarServicios(datos[0], dataTemp);
      const { data } = response;
      toast.success(data.mensaje);
      LogsInformativos(`Se ha pausado la orden de trabajo ${datos[0]}`, dataTemp);
      setShow(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Iniciar ODT</h3>
      <div style={{ marginTop: "2%", marginBottom: "2%" }}>
        <Row>
          <Col className="text-center">
            <Button variant="danger" onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faCancel} /> Cancelar
            </Button>
          </Col>
          <Col className="text-center">
            <Button
              variant={"success"}
              onClick={actualizarEstadoS}
            >
              <FontAwesomeIcon icon={faStarHalfAlt} /> Inicio
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default IniciarOT;
