import React, { useEffect, useState } from "react";
import { listarOrdenesServicioPorCargoOrden } from "../../../api/ordenesServicio";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MUIDataTable from "mui-datatables";
import { Badge } from "react-bootstrap";

const OrdenesCliienteSaldo = ({ data }) => {
  const [listOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenes = (email) => {
    try {
      listarOrdenesServicioPorCargoOrden(email)
        .then((response) => {
          const { data } = response;
          if (!listOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosMovimientos = formatModelOrdenes(data);
            setListOrdenes(datosMovimientos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOrdenes(data[0]);
  }, [data]);

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros coincidentes con los filtros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "fecha",
      label: "FECHA",
    },
    {
      name: "localidad",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreDoctor",
      label: "DOCTOR",
      options: {
        customBodyRender: (value) => (
            <span style={{textTransform:"capitalize"}}>{value}</span>
        ),
      },
    },
    {
      name: "cargoOrden",
      label: "EMAIL CARGO",
    },
    {
      name: "domicilioConsultorio",
      label: "DOMICILIO",
      options: {
        customBodyRender: (value) => {
          if (value != ", , ") {
            return (<span style={{textTransform:"capitalize"}}>{value}</span>);
          } else {
            return "No especificado";
          }
        },
      },
    },
    
    {
        name: "estado",
        label: "ESTADO DEL PEDIDO",
        options: {
          customBodyRender: (value) => {
            const estado = value;
  
            let estiloTexto = "";
            let estadoTexto = "";
  
            if (estado == "1") {
              estiloTexto = "Orden nueva";
              estadoTexto = "Orden nueva";
            } else if (estado == "2") {
              estiloTexto = "Recoleccion asignada";
              estadoTexto = "Recoleccion asignada";
            } else if (estado == "3") {
              estiloTexto = "Orden entregada";
              estadoTexto = "Orden entregada";
            } else if (estado == "4") {
              estiloTexto = "Fabricando";
              estadoTexto = "Fabricando";
            } else if (estado == "5") {
              estiloTexto = "Orden finalizada";
              estadoTexto = "Orden finalizada";
            } else if (estado == "0") {
              estiloTexto = "Orden cancelada";
              estadoTexto = "Orden cancelada";
            } else if (estado == "6") {
              estiloTexto = "En proceso";
              estadoTexto = "En proceso";
            } else if (estado == "7") {
              estiloTexto = "En ruta de entrega";
              estadoTexto = "En ruta de entrega";
            } else if (estado == "8") {
              estiloTexto = "Entregado";
              estadoTexto = "Entregado";
            } else if (estado == "9") {
              estiloTexto = "Retrabajo";
              estadoTexto = "Retrabajo";
            }
            return (
              <>
                <Badge
                  bg={
                    estado == "1"
                      ? "info"
                      : estado == "2"
                        ? "warning"
                        : estado == "3"
                          ? "primary"
                          : estado == "4"
                            ? "secondary"
                            : estado == "5"
                              ? "dark"
                              : estado == "6"
                                ? "primary"
                                : estado == "7"
                                  ? "light"
                                  : estado == "8"
                                    ? "success"
                                    : estado == "9" ?
                                      "secondary"
                                      : "danger"
                  }
                //className="estado"
                >
                  {estiloTexto}
                </Badge>
              </>
            );
          },
        },
      },
  ];

  return (
    <div>
      <MUIDataTable
        title={"Lista de ordenes con cargo al cliente " + data[1]}
        data={listOrdenes}
        columns={columns}
        options={options}
      />
    </div>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    let municipioConsultorio = "";
    let cpConsultorio = "";
    let localidadConsultorio = "";

    if (typeof data.direccionConsultorio === "object") {
      municipioConsultorio = data.direccionConsultorio.municipio
        ? data.direccionConsultorio.municipio
        : "";
      cpConsultorio = data.direccionConsultorio.CP
        ? data.direccionConsultorio.CP
        : "";
      localidadConsultorio = data.direccionConsultorio.localidad
        ? data.direccionConsultorio.localidad
        : "";
    }

    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      cargoOrden: data.cargoOrden,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
      domicilioConsultorio:
        localidadConsultorio +
        ", " +
        cpConsultorio +
        ", " +
        municipioConsultorio,
    });
  });
  return dataTemp;
}

export default OrdenesCliienteSaldo;
