import React, { useState } from "react";

const OpenpayPayment = () => {
  const [paymentData, setPaymentData] = useState({
    amount: 50,
    card_number: "",
    card_holder_name: "",
    card_expiration_month: "",
    card_expiration_year: "",
    card_cvv: "",
    customer: {
      name: "Juan Pérez",
      email: "juan@example.com",
    },
  });

  // Función para generar un order_id único
  const generateOrderId = () => {
    const timestamp = new Date().getTime(); // Obtén el tiempo actual en milisegundos
    const randomString = Math.random().toString(36).substring(2, 15); // Genera una cadena aleatoria
    return `${timestamp}-${randomString}`; // Combina ambos para crear un ID único
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const createCard = async (cardData) => {
    try {
      const YOUR_API_KEY = "sk_bf32c8b3b2d342baace620c67b7f10c2"; // Reemplaza con tu propia API Key
      const YOUR_API_SECRET = "pk_2b13389c063a4b789e8e81e4040557e1"; // Reemplaza con tu propia Secret Key
      const response = await fetch(
        "https://sandbox-api.openpay.mx/v1/mh3qxtumcdo4u0fuvbg3/cards",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Basic ${btoa(
              `${YOUR_API_KEY}:${YOUR_API_SECRET}`
            )}`,
          },
          body: JSON.stringify(cardData),
        }
      );
  
      if (response.ok) {
        const newCard = await response.json();
        console.log("Tarjeta creada:", newCard);
        return newCard;
      } else {
        const errorDetails = await response.json();
        throw new Error(`Error al crear la tarjeta: ${errorDetails.description || response.statusText}`);
      }
    } catch (error) {
      console.error("Error de red:", error);
      // Puedes agregar un mensaje de error en la UI aquí
      throw error;
    }
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const YOUR_API_KEY = "sk_bf32c8b3b2d342baace620c67b7f10c2"; // Reemplaza con tu propia API Key
      const YOUR_API_SECRET = "pk_2b13389c063a4b789e8e81e4040557e1"; // Reemplaza con tu propia Secret Key

      const cardData = {
        card_number: paymentData.card_number,
        holder_name: paymentData.card_holder_name,
        expiration_month: paymentData.card_expiration_month,
        expiration_year: paymentData.card_expiration_year,
        cvv2: paymentData.card_cvv,
        device_session_id: 'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f' // Asegúrate de tener el device_session_id correcto
      };

      const newCard = await createCard(cardData);

      const chargeRequest = {
        source_id: newCard.id,
        method: "card",
        currency: "MXN",
        description: "cargo inicial",
        order_id: generateOrderId(), // Usa el ID único generado aquí
        device_session_id: 'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f',
        amount: paymentData.amount,
        customer: paymentData.customer,
      };

      const response = await fetch(
        "https://sandbox-api.openpay.mx/v1/mh3qxtumcdo4u0fuvbg3/charges/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Basic ${btoa(
              `${YOUR_API_KEY}:${YOUR_API_SECRET}`
            )}`,
          },
          body: JSON.stringify(chargeRequest),
        }
      );

      if (response.ok) {
        console.log("Pago realizado con éxito");
      } else {
        const errorDetails = await response.json();
        console.error("Error al realizar el pago:", errorDetails);
      }
    } catch (error) {
      console.error("Error en el proceso de pago:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="card_number"
        placeholder="Número de tarjeta"
        value={paymentData.card_number}
        onChange={handleChange}
      />
      <input
        type="text"
        name="card_holder_name"
        placeholder="Nombre del titular"
        value={paymentData.card_holder_name}
        onChange={handleChange}
      />
      <input
        type="text"
        name="card_expiration_month"
        placeholder="Mes de expiración"
        value={paymentData.card_expiration_month}
        onChange={handleChange}
      />
      <input
        type="text"
        name="card_expiration_year"
        placeholder="Año de expiración"
        value={paymentData.card_expiration_year}
        onChange={handleChange}
      />
      <input
        type="text"
        name="card_cvv"
        placeholder="CVV"
        value={paymentData.card_cvv}
        onChange={handleChange}
      />
      <button type="submit">Realizar Pago</button>
    </form>
  );
};

export default OpenpayPayment;
