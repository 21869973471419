import { useState, useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faArrowRight,
  faArrowLeft,
  faPlus,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import queryString from "query-string";
import { registraEstablecimientos } from "../../../api/establecimientos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Load } from "../../load/load";
import {
  listarUltimoFact,
  registraFacturacion,
} from "../../../api/facturacion";
import {
  listarUltimoDoc,
  registraDoctores,
  obtenerNumeroDoctor,
} from "../../../api/doctores";
import {
  listarUltimo,
  obtenerNumeroEstablecimiento,
} from "../../../api/establecimientos";
import { registraUsuarios } from "../../../api/usuarios";
import estadosPaisData from "../catalogosJSON/estadosPais.json";
import municipiosEstadoData from "../catalogosJSON/municipiosEstadosPais.json";
import { LogsInformativos } from "../../logs/logs";

export default function ConsultoriosRegistro(props) {
  const { location } = props;
  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState("");
  const [formData2, setFormData2] = useState({
    regimenFiscalDF: "", // Inicializa con los valores por defecto deseados para cada campo
    calleDF: "",
    numeroExteriorDF: "",
    numeroInteriorDF: "",
    codigoPostalDF: "",
    coloniaDF: "",
    municipioDF: "",
    observacionesDF: "",
  });
  const [formData3, setFormData3] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    setFormData2({ ...formData2, [e.target.name]: e.target.value });
  };
  const onChange3 = (e) => {
    setFormData3({ ...formData3, [e.target.name]: e.target.value });
  };

  const [password, setPassword] = useState("");

  const generateRandomPassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
    let newPassword = "";

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
  };

  useEffect(() => {
    generateRandomPassword();
  }, []);

  const [password2, setPassword2] = useState("");

  const generateRandomPassword2 = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
    let newPassword = "";

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword2(newPassword);
  };

  useEffect(() => {
    generateRandomPassword2();
  }, []);

  // Para almacenar el folio actual
  const [folioDoctor, setFolioDoctor] = useState("");

  const obtenerFolioDoctor = () => {
    try {
      obtenerNumeroDoctor()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noDoctor } = data;
          setFolioDoctor(noDoctor);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolioDoctor();
  }, []);

  // Para almacenar el folio actual
  const [folioConsultorio, setFolioConsultorio] = useState("");

  const obtenerFolioConsultorio = () => {
    try {
      obtenerNumeroEstablecimiento()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noConsultorio } = data;
          setFolioConsultorio(noConsultorio);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolioConsultorio();
  }, []);

  //INSERT CONSULTORIO
  const registraConsultorio = async(e) => {
    e.preventDefault();

    if (
      !formData.razonSocial ||
      !estadoPais ||
      !municipioSeleccionado ||
      !formData.colonia ||
      !formData.calle ||
      !formData.numeroExterior ||
      !formData.codigoPostal ||
      !formData.telefono ||
      !formData.haceEsquina ||
      !formData.email ||
      !formData.horario
    ) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);

        const dataTemp = {
          folio: folioConsultorio,
          razonSocial: formData.razonSocial,
          estadoEstablecimiento: estadoPais,
          municipio: municipioSeleccionado,
          colonia: formData.colonia,
          calle: formData.calle,
          numeroExterior: formData.numeroExterior,
          numeroInterior: formData.numeroInterior,
          codigoPostal: formData.codigoPostal,
          telefono: formData.telefono,
          haceEsquina: formData.haceEsquina,
          email: formData.email,
          observaciones: formData.observaciones,
          horario: formData.horario,
          estado: "true",
          alias: formData.alias,
        };

        const dataTemp2 = {
          nombre: formData.razonSocial,
          email: formData.email,
          password: password,
          tipo: "consultorio",
          estado: "true",
        };

        await registraUsuarios(dataTemp2)
        .then(() => {
          // Register the messenger
          return registraConsultorio(dataTemp);
        })
        .then((messengerResponse) => {
          const messengerData = messengerResponse.data;

          console.log(messengerData);
          LogsInformativos(`Se ha registrado el consultorio ${dataTemp.nombre}`, dataTemp);

          toast.success(messengerData.mensaje);

          setLoading(false);
          window.location.reload();
          nextStep();
        })
        .catch((ex) => {
          if (ex.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          } else if (ex.response && ex.response.status === 401) {
            toast.warning("Ya existe un usuario con este correo");
          } else {
            console.log(ex);
          }
          setLoading(false);
        }); 

      } catch (e) {
        console.log(e);
      }
    }
  };

  //INSERT DATOS FISCALES
  const insertDatosFiscales = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const dataTemp2 = {
        regimenFiscal: formData2.regimenFiscalDF,
        calle: formData2.calleDF,
        numeroExterior: formData2.numeroExteriorDF,
        numeroInterior: formData2.numeroInteriorDF,
        codigoPostal: formData2.codigoPostalDF,
        colonia: formData2.coloniaDF,
        municipio: formData2.municipioDF,
        observaciones: formData2.observacionesDF,
        estado: "true",
      };
      registraFacturacion(dataTemp2).then((response) => {
        const { data } = response;
        console.log(data);

        toast.success(data.mensaje);

        setLoading(false);
        nextStep();
      });
    } catch (e) {
      console.log(e);
    }
  };

  //INSERTA DOCTOR
  const insertDoctor = (e) => {
    e.preventDefault();

    if (
      !formData3.nombreDoc ||
      !formData3.apellidoPaternoDoc ||
      !formData3.apellidoMaternoDoc ||
      !formData3.telefonoCelularDoc ||
      !formData3.emailDoc ||
      !formData3.observacionesDoc
    ) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);

        const dataTemp3 = {
          folio: folioDoctor,
          nombre: formData3.nombre,
          apellidoPaterno: formData3.apellidoPaternoDoc,
          apellidoMaterno: formData3.apellidoMaternoDoc,
          fechaNacimiento: formData3.fechaNacimientoDoc,
          telefonoCelular: formData3.telefonoCelularDoc,
          email: formData3.emailDoc,
          rfc: "",
          observaciones: formData3.observacionesDoc,
          consultorio: folioConsultorio,
          nombreConsultorio: formData.razonSocial,
          correoConsultorio: formData.email,
          estado: "true",
        };
        registraDoctores(dataTemp3).then((response) => {
          const { data } = response;

          const dataTemp2 = {
            nombre: formData.nombre,
            email: formData.email,
            password: password,
            tipo: "doctor",
            estado: "true",
          };
          registraUsuarios(dataTemp2)
            .then((response) => {
              const { data } = response;
              console.log(data);
              //cancelarRegistro()
            })
            .catch((ex) => {
              if (ex.message === "Network Error") {
                toast.error("Conexión al servidor no disponible");
              } else {
                if (ex.response && ex.response.status === 401) {
                }
              }
            });

          console.log(data);

          toast.success(data.mensaje);

          setLoading(false);
          nextStep();
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [listarUltimoEstablecimiento, setListUltimoEstablecimiento] = useState(
    []
  );

  const obtenerUltimoEstablecimiento = () => {
    try {
      listarUltimo()
        .then((response) => {
          const { data } = response;

          if (!listarUltimoEstablecimiento && data) {
            setListUltimoEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            setListUltimoEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerUltimoEstablecimiento();
  }, [location]);

  const [listarUltimoFactura, setListUltimoFactura] = useState([]);

  const obtenerFacturacionUltimo = () => {
    try {
      listarUltimoFact()
        .then((response) => {
          const { data } = response;

          if (!listarUltimoFactura && data) {
            setListUltimoFactura(formatModelFacturacion(data));
          } else {
            const datosFacturacionUltimo = formatModelFacturacion(data);
            setListUltimoFactura(datosFacturacionUltimo);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerFacturacionUltimo();
  }, [location]);

  const [listarDoctorUltimo, setListDoctorUltimo] = useState([]);

  const obtenerDoctorUltimo = () => {
    try {
      listarUltimoDoc()
        .then((response) => {
          const { data } = response;

          if (!listarDoctorUltimo && data) {
            setListDoctorUltimo(formatModelDoctor(data));
          } else {
            const datosDoctorUltimo = formatModelDoctor(data);
            setListDoctorUltimo(datosDoctorUltimo);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerDoctorUltimo();
  }, [location]);

  return (
    <>
      {loading && <Load />}
      <div className="contenedorPrincipalOrden">
        <div className="containerOrden">
          <div className="containerDatosOS">
            <h1 className="ordenTituloP">Registrar Consultorio</h1>
            <div className="barraProgreso">
              {[1, 2, 3, 4].map((stepNum) => (
                <div
                  key={stepNum}
                  className={`step ${stepNum <= currentStep ? "active" : ""} `}
                >
                  {stepNum}
                  <div>
                    {stepNum === 1 ? (
                      <>
                        <span className="textocontaineret">
                          Datos del Consultorio
                        </span>
                      </>
                    ) : stepNum === 2 ? (
                      <>
                        <span className="textocontaineret">
                          Datos Fiscales del Consultorio
                        </span>
                      </>
                    ) : stepNum === 3 ? (
                      <>
                        <span className="textocontaineret">
                          Datos del Doctor a cargo
                        </span>
                      </>
                    ) : stepNum === 4 ? (
                      <>
                        <span className="textocontaineret">
                          Verificar Información
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            {currentStep === 1 && (
              <div>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "2vw",
                    fontStyle: "italic",
                  }}
                >
                  Datos del consultorio
                </h4>
                <hr />
                <Form className="formEstablecimiento" onChange={onChange}>
                  <div>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblNombreEst">
                          Nombre o Razón Social:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpNombreEst"
                          placeholder="Ingresa nombre comercial o razon social"
                          defaultValue={formData.razonSocial}
                          name="razonSocial"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblNombreEst">
                          Alias:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpNombreEst"
                          placeholder="Ingresa Alias"
                          defaultValue={formData.alias}
                          name="alias"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Telefono: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="tel"
                          className="inpTelefono"
                          placeholder="Ingresa telefono"
                          defaultValue={formData.telefono}
                          name="telefono"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Correo Electrónico: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="email"
                          className="inpEmail"
                          placeholder="ejemplo@ejemplo.com"
                          defaultValue={formData.email}
                          name="email"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Calle: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpCalle"
                          placeholder="Ingresa calle"
                          defaultValue={formData.calle}
                          name="calle"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Num Ext: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpNumExt"
                          placeholder="Ingresa numero exterior"
                          defaultValue={formData.numeroExterior}
                          name="numeroExterior"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Num Int: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpNumInt"
                          placeholder="Ingresa numero interior"
                          defaultValue={formData.numeroInterior}
                          name="numeroInterior"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblColonia">
                          Colonia:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpColonia"
                          placeholder="Ingresa colonia"
                          defaultValue={formData.colonia}
                          name="colonia"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblEstadoEst">Estado</Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          as="select"
                          value={estadoPais}
                          onChange={(e) => setEstadoPais(e.target.value)}
                        >
                          <option>Selecciona un Estado</option>
                          {estadosPaisData.map((estado) => (
                            <option key={estado.clave} value={estado.nombre}>
                              {estado.nombre}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Municipio</Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          as="select"
                          value={municipioSeleccionado}
                          onChange={(e) =>
                            setMunicipioSeleccionado(e.target.value)
                          }
                        >
                          <option>Selecciona un Municipio</option>
                          {municipiosEstadoData[estadoPais] &&
                            municipiosEstadoData[estadoPais].map(
                              (municipio) => (
                                <option key={municipio} value={municipio}>
                                  {municipio}
                                </option>
                              )
                            )}
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblCP">
                          Codigo Postal:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpCP"
                          placeholder="Ingresa Codigo Postal"
                          defaultValue={formData.codigoPostal}
                          name="codigoPostal"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Hace esquina con: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpEsquina"
                          placeholder="Ingresa Referencia"
                          defaultValue={formData.haceEsquina}
                          name="haceEsquina"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Horario laboral: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpHorario"
                          placeholder="Ingresa horario laboral"
                          defaultValue={formData.horario}
                          name="horario"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Observaciones:</Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          placeholder="Observaciones"
                          className="txtObservaciones"
                          as="textarea"
                          rows={2}
                          defaultValue={formData.observaciones}
                          name="observaciones"
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "2vw",
                    fontStyle: "italic",
                  }}
                >
                  Ingresar Datos Fiscales (Opcional)
                </h4>
                <hr />
                <Form onChange={onChange2}>
                  <div>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblRegimen">
                          Régimen Fiscal:
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpRegimen"
                          placeholder="Ingresa Régimen Fiscal"
                          defaultValue={formData2.regimenFiscalDF}
                          name="regimenFiscalDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblCalleFact">
                          Calle:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpCalleFact"
                          placeholder="Ingresa Calle"
                          defaultValue={formData2.calleDF}
                          name="calleDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblNumExtFact">
                          Número Exterior:
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpNumExtFact"
                          placeholder="Ingresa número exterior"
                          defaultValue={formData2.numeroExteriorDF}
                          name="numeroExteriorDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Número Interior: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpNumIntFact"
                          placeholder="Ingresa número interior"
                          defaultValue={formData2.numeroInteriorDF}
                          name="numeroInteriorDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Código Postal: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpCPFact"
                          placeholder="CP"
                          defaultValue={formData2.codigoPostalDF}
                          name="codigoPostalDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Colonia: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpColoniaFact"
                          placeholder="Ingresa Colonia"
                          defaultValue={formData2.coloniaDF}
                          name="coloniaDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Municipio: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpMunicipioFact"
                          placeholder="Ingresa Municipio"
                          defaultValue={formData2.municipioDF}
                          name="municipioDF"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblColonia">
                          Observaciones:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          className="txtObservacionesDoc"
                          as="textarea"
                          rows={3}
                          defaultValue={formData2.observacionesDF}
                          placeholder="Observaciones"
                          name="observacionesDF"
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "2vw",
                    fontStyle: "italic",
                  }}
                >
                  Datos del Doctor a cargo
                </h4>
                <hr />
                <Form onChange={onChange3}>
                  <div>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Nombre(s):</Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpNombreEst"
                          placeholder="Ingresa nombre"
                          name="nombreDoc"
                          defaultValue={formData3.nombreDoc}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Primer Apellido: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpPrimerADoc"
                          placeholder="Ingresa primer apellido"
                          defaultValue={formData3.apellidoPaternoDoc}
                          name="apellidoPaternoDoc"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Segundo Apellido: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="text"
                          className="inpSegundoA"
                          placeholder="Ingresa segundo apellido"
                          defaultValue={formData3.apellidoMaternoDoc}
                          name="apellidoMaternoDoc"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Fecha de nacimiento: (Opcional)</Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="date"
                          className="inpFechaDoc"
                          placeholder="dd/mm/aaaa"
                          defaultValue={formData3.fechaNacimientoDoc}
                          name="fechaNacimientoDoc"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Telefono celular: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="number"
                          className="inpTelefonoDoc"
                          placeholder="Ingresa telefono a 10 digitos"
                          defaultValue={formData3.telefonoCelularDoc}
                          name="telefonoCelularDoc"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Correo Electrónico: </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          type="email"
                          className="inpCorreoDoc"
                          placeholder="ejemplo@ejemplo.com"
                          defaultValue={formData3.emailDoc}
                          name="emailDoc"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblColonia">
                          Observaciones:{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={12} md={9}>
                        <Form.Control
                          className="txtObservacionesDoc "
                          as="textarea"
                          rows={3}
                          placeholder="Observaciones"
                          defaultValue={formData3.observacionesDoc}
                          name="observacionesDoc"
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {currentStep === 4 && (
              <div>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "2vw",
                    fontStyle: "italic",
                  }}
                >
                  Datos guardados correctamente a continuacion se muestra el
                  resumen
                </h4>
                <hr />
                <h4>Establecimiento</h4>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Razón Social</th>
                      <th>Estado</th>
                      <th>Municipio</th>
                      <th>Calle</th>
                      <th>No. Ext</th>
                      <th>No. Int</th>
                      <th>CP</th>
                      <th>Teléfono</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listarUltimoEstablecimiento.map(
                      (establecimiento, index) => (
                        <tr key={index}>
                          <td>{establecimiento.razonSocial}</td>
                          <td>{establecimiento.estadoEstablecimiento}</td>
                          <td>{establecimiento.municipio}</td>
                          <td>{establecimiento.calle}</td>
                          <td>{establecimiento.numeroExterior}</td>
                          <td>{establecimiento.numeroInterior}</td>
                          <td>{establecimiento.codigoPostal}</td>
                          <td>{establecimiento.telefono}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                <hr />
                <h4>Datos Fiscales</h4>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Regimen fiscal</th>
                      <th>Calle</th>
                      <th>No. Ext</th>
                      <th>No. Int</th>
                      <th>CP</th>
                      <th>Colonia</th>
                      <th>Municipio</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listarUltimoFactura.map((factura, index) => (
                      <tr key={index}>
                        <td>{factura.regimenFiscal}</td>
                        <td>{factura.calle}</td>
                        <td>{factura.numeroExterior}</td>
                        <td>{factura.numeroInterior}</td>
                        <td>{factura.codigoPostal}</td>
                        <td>{factura.colonia}</td>
                        <td>{factura.municipio}</td>
                        <td>{factura.observaciones}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <hr />
                <h4>Doctor</h4>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Primer Apellido</th>
                      <th>Segundo Apellido</th>
                      <th>Fecha de Nacimiento</th>
                      <th>Teléfono</th>
                      <th>Email</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listarDoctorUltimo.map((doctor, index) => (
                      <tr key={index}>
                        <td>{doctor.nombre}</td>
                        <td>{doctor.apellidoPaterno}</td>
                        <td>{doctor.apellidoMaterno}</td>
                        <td>{doctor.fechaNacimiento}</td>
                        <td>{doctor.telefonoCelular}</td>
                        <td>{doctor.email}</td>
                        <td>{doctor.observaciones}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
        <div className="buttonsOS">
          <div className="botonAntOS">
            {currentStep > 1 && (
              <Button onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;Anterior
              </Button>
            )}
          </div>
          <div className="botonSigOS">
            {currentStep < 4 ? (
              currentStep === 1 ? (
                <>
                  <Button onClick={registraConsultorio}>
                    <FontAwesomeIcon icon={faArrowRight} />
                    &nbsp;Siguiente
                  </Button>
                </>
              ) : currentStep === 2 ? (
                <Button onClick={insertDatosFiscales}>
                  <FontAwesomeIcon icon={faArrowRight} />
                  &nbsp;Siguiente
                </Button>
              ) : currentStep === 3 ? (
                <Button onClick={insertDoctor}>
                  <FontAwesomeIcon icon={faArrowRight} />
                  &nbsp;Siguiente
                </Button>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
    });
  });
  return dataTemp;
}

function formatModelFacturacion(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      regimenFiscal: data.regimenFiscal,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      colonia: data.colonia,
      municipio: data.municipio,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}
