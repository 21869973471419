import { useState, useEffect } from 'react';
import { obtenerServiciosPorFolio } from '../../api/servicios';

function Servicio(props) {
    const { folio } = props;

    // Para almacenar el nombre del cliente
    const [nombreServicio, setNombreServicio] = useState("");

    const cargarServicio = () => {
        //
        try {
            obtenerServiciosPorFolio(folio).then(response => {
                const { data } = response;
                const { material } = data;
                setNombreServicio(material)
            }).catch(e => {
                console.log(e)
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        cargarServicio();
    }, [folio]);

    return (
        <>
            {nombreServicio}
        </>
    );
}

export default Servicio;