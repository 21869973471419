import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarConfiguracionRutas, enviaEmailRecordatorioConfiguracionRutas } from "../../api/configuracionRutas";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import { listarMensajeros } from "../../api/mensajeros";
import { map } from "lodash";
import { listarVehiculos } from "../../api/vehiculos";

export default function ModificarConfiguracionRutas({
  data,
  history,
  setShow,
}) {
  console.log(data);

  const [selectedMessenger, setSelectedMessenger] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState("");

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const [listarVehiculo, setListVehiculo] = useState([]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVehiculo();
  }, []);

  const idDocumental = data.id;

  const dataTemp = {
    folio: data.folio,
    nombre: data.ruta,
    descripcion: data.descripcion,
    dia: data.dia,
    hora: data.hora,
    nombreMensajeroRuta: data.datosMensajero?.nombre,
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
console.log(selectedMessenger)
  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto

      const dataTemp = {
        descripcion: formData.descripcion,
        dia: formData.dia,
        hora: formData.hora,
        datosMensajero: selectedMessenger,
        datosVehiculo: {
          modeloVehiculo: selectedVehicle.split("|")[0],
          placa: selectedVehicle.split("|")[1],
          economico: selectedVehicle.split("|")[2],
        },
      };

      actualizarConfiguracionRutas(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        history({
          search: queryString.stringify(""),
        });
        enviaEmailRecordatorioConfiguracionRutas({email: dataTemp.datosMensajero.email, dia: dataTemp.dia, hora: dataTemp.hora})
        toast.success(data.mensaje);
        LogsInformativos(
          "Se ha modificado la configuracion de ruta " + dataTemp.nombre,
          dataTemp
        );
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "nombreMensajeroRuta") {
      const [nombre, email, telefono, id, modeloVehiculo, placa, economico] =
        value.split("/");
      setSelectedMessenger({ id, nombre, telefono, email });
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (data.datosVehiculo) {
      setSelectedVehicle(
        data.datosVehiculo.modeloVehiculo +
        "|" +
        data.datosVehiculo.placa +
        "|" +
        data.datosVehiculo.economico
      );
    } else {
      setSelectedVehicle("");
    }
  }, [data]);

  return (
    <>
      {loading && <Load />}
      <div className="generalDoctores">
        <Form className="formDoctores" onSubmit={onSubmit} onChange={onChange}>
          <Form.Label className="lblNombreDoc">Folio: </Form.Label>
          <Form.Control
            type="text"
            className="inpNombreEst"
            placeholder="Ingresa nombre"
            name="nombre"
            value={formData.folio}
            disabled
          />
          <Form.Label className="lblNombreDoc">Hora: <code>*</code></Form.Label>
          <Form.Control
            type="text"
            className="inpNombreEst"
            placeholder="Ingresa la hora en formato 00:00AM/PM"
            name="hora"
            defaultValue={formData.hora}
          />
          <div className="form-group">
            <Form.Label htmlFor="messengerName">
              Mensajero predeterminado: <code>*</code>
            </Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              id="messengerName"
              name="nombreMensajeroRuta"
              defaultValue={formData.nombreMensajeroRuta}
              required
            >
              <option value>Seleccionar</option>
              {map(listarMensajero, (cat, index) => (
                <option
                  key={index}
                  value={`${cat.nombre}/${cat.email}/${cat.telefono}/${cat.id}/${cat.modeloVehiculo}/${cat.placa}/${cat.economico}`}
                  selected={cat.nombre == formData.nombreMensajeroRuta}
                >
                  {cat.nombre}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="form-group">
            <Form.Label htmlFor="messengerName">
              Auto seleccionado: <code>*</code>
            </Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              id="messengerName"
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
            >
              <option value>Seleccionar</option>
              {map(listarVehiculo, (car, index) => (
                <option
                  key={index}
                  value={car.modelo + "|" + car.placa + "|" + car.numEconomico}
                >
                  {car.modelo + " - " + car.placa}
                </option>
              ))}
            </Form.Control>
          </div>
          <Form.Group className="formObservacionesDoc">
            <Form.Label>Descripcion:</Form.Label>
            <Form.Control
              className="txtObservacionesDoc "
              as="textarea"
              rows={3}
              placeholder="Descripcion"
              defaultValue={formData.descripcion}
              name="descripcion"
            />
            <Form.Label htmlFor="routeName">
              Selecciona un dia de la semana:
            </Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              id="routeName"
              name="dia"
              defaultValue={formData.dia}
            >
              <option value="">Seleccionar</option>
              <option value="lunes">Lunes</option>
              <option value="martes">Martes</option>
              <option value="miercoles">Miercoles</option>
              <option value="jueves">Jueves</option>
              <option value="viernes">Viernes</option>
              <option value="sabado">Sabado</option>
            </Form.Control>
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    nombre: data.nombre,
    descripcion: data.descripcion,
    dia: data.dia,
    hora: data.hora,
    nombreMensajeroRuta: data.nombreMensajeroRuta,
  };
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      estado: data.estado,
    });
  });
  return dataTemp;
}
