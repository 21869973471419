import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarSueldosGenerales,
    ENDPOINTObtenerSueldosGenerales,
    ENDPOINTListarSueldosGenerales,
    ENDPOINTActualizarSueldosGenerales,
    ENDPOINTEliminarSueldosGenerales,
    ENDPOINTDeshabilitarSueldosGenerales,
    ENDPOINTListarPorFechasSueldosGenerales
} from "./endpoints";
import axios from 'axios';

export async function listarSueldosGenerales(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarSueldosGenerales, config);
}

export async function registraSueldosGenerales(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarSueldosGenerales, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerSueldosGenerales(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerSueldosGenerales + `/${params}`, config);
}

export async function actualizarSueldosGenerales(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarSueldosGenerales + `/${id}`, data, config);
}

export async function eliminarSueldosGenerales(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarSueldosGenerales + `/${id}`, data, config);
}

export async function actualizaDeshabilitarSueldosGenerales(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarSueldosGenerales + `/${id}`, data, config);
}

export async function listarDescuentosEmpEntreFechas(fechaInicial, fechaFinal){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPorFechasSueldosGenerales + `?fecha1=${fechaInicial}&&fecha2=${fechaFinal}`, config);
}