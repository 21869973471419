import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { listarDepartamentos } from "../../api/departamentos";
import { toast } from "react-toastify";
import { registraMaquinas } from "../../api/maquinas";
import { LogsInformativos } from "../logs/logs";

const RegistroMaquinas = ({setShow}) => {
  const [nombreMaquina, setNombreMaquina] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;
          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  const registraEmpleado = (e) => {
    e.preventDefault();

    if (!nombreMaquina || !departamento) {
      toast.warning("Datos incompletos");
    } else {
      try {
        const dataTemp = {
          nombre: nombreMaquina,
          descripcion: observaciones,
          departamento: departamento,
          estado: "true",
        };
        registraMaquinas(dataTemp).then((response) => {
          const { data } = response;
          LogsInformativos("Se ha registrado la maquina " + dataTemp.recibio, dataTemp)
          toast.success(data.mensaje);
          setShow(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <div style={{ padding: "2%" }}>
        <Form>
          <div>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Nombre: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  value={nombreMaquina}
                  onChange={(e) => setNombreMaquina(e.target.value)}
                  placeholder="Ingresa el nombre de la máquina"
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Departamento: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  as="select"
                  value={departamento}
                  onChange={(e) => setDepartamento(e.target.value)}
                >
                  <option value="">Seleccionar departamento</option>
                  {listarDepartamento.map((departamento, index) => (
                    <option value={departamento.nombre}>
                      {departamento.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-start justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Descripción:</Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  as="textarea"
                  placeholder="Ingresa descripción"
                  value={observaciones}
                  onChange={(e) => setObservaciones(e.target.value)}
                />
              </Col>
            </Row>
          </div>
          <div className="divSubmit">
            <Button variant="success" onClick={registraEmpleado}>
              <FontAwesomeIcon icon={faFloppyDisk} /> Registrar
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

export default RegistroMaquinas;
