import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import queryString from "query-string";
import { registraMensajeros } from "../../../api/mensajeros";
import { listarVehiculos } from "../../../api/vehiculos";
import { registraUsuarios } from "../../../api/usuarios";
import { map } from "lodash";
import Dropzone from "../../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import { LogsInformativos } from "../../logs/logs";

export default function Mensajeros({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);


  const [listarVehiculo, setListVehiculo] = useState([]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVehiculo();
  }, []);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [password, setPassword] = useState('');

  const generateRandomPassword = () => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
    const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
    let newPassword = '';

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
  }

  useEffect(() => {
    generateRandomPassword();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
  
    if (!imagenProducto || !formData.nombre || !formData.telefono || !formData.email || !formData.modeloVehiculo) {
      toast.warning("Completa el formulario");
      return;
    }
  
    try {
      // Upload the image to Cloudinary
      const uploadResponse = await subeArchivosCloudinary(imagenProducto, "mensajeros");
      const { data } = uploadResponse;
      setLoading(true);
  
      // Split the vehicle model string into its parts
      const temp = formData.modeloVehiculo.split("/");
  
      // Create the data object to register the messenger
      const dataTemp = {
        nombre: formData.nombre,
        telefono: formData.telefono,
        email: formData.email,
        modeloVehiculo: temp[0],
        placa: temp[1],
        economico: temp[2],
        fecha: `${dia}/${mes}/${year}`,
        estado: "true",
        imagen: data.secure_url
      };
  
      // Create the data object to register the user
      const dataTemp2 = {
        nombre: formData.nombre,
        email: formData.email,
        password: password,
        tipo: "mensajero",
        estado: "true"
      };
  
      // Register the user
      await registraUsuarios(dataTemp2)
        .then(() => {
          // Register the messenger
          return registraMensajeros(dataTemp);
        })
        .then((messengerResponse) => {
          const messengerData = messengerResponse.data;
  
          console.log(messengerData);
          LogsInformativos(`Se ha registrado el mensajero ${dataTemp.nombre}`, dataTemp);
  
          toast.success(messengerData.mensaje);
  
          // Update the history and reset form
          history({ search: queryString.stringify("") });
          setLoading(false);
          setShow(false);
        })
        .catch((ex) => {
          if (ex.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          } else if (ex.response && ex.response.status === 401) {
            toast.warning("Ya existe un usuario con este correo");
          } else {
            console.log(ex);
          }
          setLoading(false);
        });
  
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [placa, setPlaca] = useState("");
  const [economico, setEconomico] = useState("");

  const obtenerDatos = () => {
    const temp = formData.modeloVehiculo.split("/");
    setPlaca(temp[1]);
    setEconomico(temp[2]);
  }

  useEffect(() => {
    obtenerDatos();
  }, [formData.modeloVehiculo]);

  return (
    <>
      {loading && <Load />}
      <div className='facturacionGeneral'>
        <div className="imagenPrincipal">
          <h4 className="textoImagenPrincipal">Sube tu imagen <code>*</code></h4>
          <div title="Seleccionar imagen de la categoría" className="imagenProducto">
            <Dropzone
              setImagenFile={setImagenProducto}
            />
          </div>
        </div>
        <Form className="formMensajero" onSubmit={onSubmit} onChange={onChange}>
          <Form.Label className="lblNomMensajero">
            Nombre completo: <code>*</code>
          </Form.Label>
          <Form.Control
            type="text"
            className="nombreMensajero"
            placeholder="Nombre completo"
            defaultValue={formData.nombre}
            name="nombre"
          />
          <Form.Label className="lblTelMensajero">Teléfono: <code>*</code></Form.Label>
          <Form.Control
            type="tel"
            className="telefonoMensajero"
            placeholder="Teléfono"
            defaultValue={formData.telefono}
            name="telefono"
          />
          <Form.Label className="lblNomMensajero">
            Correo electronico: <code>*</code>
          </Form.Label>
          <Form.Control
            type="email"
            className="emailMensajero"
            placeholder="ejemplo@ejemplo.com"
            defaultValue={formData.email}
            name="email"
          />
          <Form.Label className="lblmodeloMensajero">
            Modelo de vehículo: <code>*</code>
          </Form.Label>
          <Form.Control
            as="select"
            className="modeloMensajero"
            placeholder="Modelo de vehículo"
            defaultValue={formData.modeloVehiculo}
            name="modeloVehiculo"
          >
            <option value="">Seleccionar una opcion</option>
            {map(listarVehiculo, (cat, index) => (
              <option key={index} value={cat?.modelo + "/" + cat?.placa + "/" + cat?.numEconomico}>{cat?.modelo}</option>
            ))}
          </Form.Control>
          <Form.Label className="lblPlacaMensajero">
            Placa:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            className="placaMensajero"
            placeholder="Placa del vehículo"
            defaultValue={formData.placa}
            value={placa}
            onChange={e => setPlaca(e.target.value)}
            name="placa"
            disabled
          />
          <Form.Label className="lblNomMensajero">
            # Económico:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            className="economicoMensajero"
            placeholder="# Económico"
            defaultValue={formData.economico}
            value={economico}
            onChange={e => setEconomico(e.target.value)}
            name="economico"
            disabled
          />
          <Form.Label className="lblFechaMensajero">
            Fecha de registro:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            className="fechaRegMensajero"
            value={dia + "/" + mes + "/" + year}
            disabled
          />
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
};

function initialFormValue() {
  return {
    nombre: "",
    telefono: "",
    email: "",
    modeloVehiculo: "",
    placa: "",
    economico: "",
  };
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      estado: data.estado
    });
  });
  return dataTemp;
}

