import React, { useEffect, useState } from "react";
import { listarEventosPorFolio } from "../../../api/eventos";
import { ProgressBar } from "react-bootstrap";

const ProgresoOrden = ({ data, history, setShow }) => {
    const folioConsultar = data;
  const [eventosFolio, setEventosFolio] = useState([]);
  const [eventosFolioNo, setEventosFolioNo] = useState(0);

  const obtenerEventosFolio = (folio) => {
    listarEventosPorFolio(folio)
      .then((response) => {
        const { data } = response;
        console.log(data);
        setEventosFolio(formatModelEventos(data));
        const entregadoEvent = data.some(
          (evento) => evento.tipoEvento === "Entregada"
        );
        const entregaRuta = data.some(
          (evento) => evento.tipoEvento === "Entrega"
        );
        const ordenFinish = data.some(
          (evento) => evento.motivoEvento === "Se finalizo la orden de cliente"
        );
        setEventosFolioNo(
          entregadoEvent
            ? 100
            : entregaRuta
            ? 87.5
            : ordenFinish
            ? 75
            : (data.length * 100) / 8
        );
      })
      .catch((error) => {
        console.error("Error al obtener eventos por folio:", error);
        // Manejar el error de listarEventosPorFolio apropiadamente, según tus necesidades.
      });
  };

  useEffect(() => {
    obtenerEventosFolio(folioConsultar);
  }, [folioConsultar]);

  return (
    <>
      <div style={{ padding: "1%"}}>
        <div style={{ fontStyle: "italic", textAlign:"center" }}>
          <h2>Progreso de la orden {folioConsultar}</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent:"left",
            width: "100%",
          }}
        >
          {eventosFolio.map((evento, index) => (
            <div key={index} style={{ width: "16%", textAlign:"center" }}>
              <div>{evento.fecha}</div>
              <div>{evento.tipoEvento}</div>
              <div>
                <span style={{ fontSize: "20px", marginLeft: "5px" }}>
                  &#8595;
                </span>
              </div>
            </div>
          ))}
        </div>
        <ProgressBar
          variant="success"
          animated
          now={eventosFolioNo}
          label={`${eventosFolioNo}%`}
          style={{ marginTop: "1%" }}
        />
      </div>
    </>
  );
};

function formatModelEventos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      fecha: data.fecha,
      folioOrden: data.folioOrden,
      usuario: data.usuario,
      tipoEvento: data.tipoEvento,
      motivoEvento: data.motivoEvento,
    });
  });
  return dataTemp;
}

export default ProgresoOrden;
