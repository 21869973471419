import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { listarGastosMensajero } from "../../../api/gastosMensajero";
import { Button, Image, Badge } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import { useNavigate } from "react-router-dom";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import Gastos from "./Gastos";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { toast } from "react-toastify";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblGastosMensajeros(props) {
  const { location, history } = props;
  const correoConsulta = props.data;
  const modulo = props.id;
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [correoUsuario, setCorreoUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarMensajero, setListMensajero] = useState([]);

  // Para definir el enrutamiento
  const enrutamiento = useNavigate();

  const obtenerMensajeros = (correo) => {
    try {
      listarGastosMensajero(correo)
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (modulo === "saldos") {
      obtenerMensajeros(correoConsulta);
    } else {
      obtenerMensajeros(correoUsuario);
    }
  }, [location, correoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarMensajero);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroMensajero = (content) => {
    setTitulosModal("Registrar mensajero");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "FECHA",
     
    },
    {
      name: "concepto",
      label: "CONCEPTO",
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
    },
    {
      name: "comentarios",
      label: "COMENTARIOS",
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        customBodyRender: (value) => {
          const imagen = value;

          return (
            <Image
              src={imagen}
              alt={`Imagen de ${imagen}`}
              style={{ maxWidth: "100px" }}
            />
          );
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",

    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Mensajeros</h1>
      <div class="bg-white">
        {tipoUsuario === "mensajero" && (
          <div className="divButton">
            <Button
              variant="primary"
              className="btnAddTables"
              onClick={() =>
                registroMensajero(
                  <Gastos history={history} setShow={setShowModal} />
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
          </div>
        )}
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de mensajeros registrados"}
          data={listarMensajero}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      fecha: data.fecha,
      concepto: data.concepto,
      cantidad: data.cantidad,
      comentarios: data.comentarios,
      imagen: data.imagen,
      //correoMensajero: data.correoMensajero,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblGastosMensajeros);
