import { Form, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarDoctores } from "../../api/doctores";
import { obtenerEstablecimientosPorFolio } from "../../api/establecimientos";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import estadosPaisData from '../administrador/catalogosJSON/estadosPais.json';
import municipiosEstadoData from '../administrador/catalogosJSON/municipiosEstadosPais.json'

export default function ModificarDoctores({ data, history, setShow }) {

    const idDocumental = data[0];

    const [estadoPais, setEstadoPais] = useState("");
    const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");
    const [calle, setCalle] = useState("");
    const [numeroExterior, setNumeroExterior] = useState("");
    const [numeroInterior, setNumeroInterior] = useState("");
    const [colonia, setColonia] = useState("");
    const [codigoPostal, setCodigoPostal] = useState("");
    const [haceEsquina, setHaceEsquina] = useState("");


    // Estado para almacenar las direcciones
    const [addresses, setAddresses] = useState(data[14]);

    // Function to add address to array
    const handleAddAddress = (e) => {
        e.preventDefault();
        const newAddress = {
            calle,
            numeroExterior,
            numeroInterior,
            colonia,
            estado: estadoPais,
            municipio: municipioSeleccionado,
            codigoPostal,
            haceEsquina,
        };

        setAddresses([...addresses, newAddress]);

        // Limpiar campos después de agregar la dirección
        setCalle("");
        setNumeroExterior("");
        setNumeroInterior("");
        setColonia("");
        setEstadoPais("");
        setMunicipioSeleccionado("");
        setCodigoPostal("");
        setHaceEsquina("");
    };

    const dataTemp = {
        folio: data[1],
        nombre: data[2],
        apellidoPaterno: data[3],
        apellidoMaterno: data[4],
        fechaNacimiento: data[5],
        telefonoCelular: data[6],
        email: data[7],
        rfc: data[8],
        observaciones: data[9],
        folioConsultorio: data[11],
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const [nombreConsultorio, setNombreConsultorio] = useState(data[12]);
    const [correoConsultorio, setCorreoConsultorio] = useState(data[13]);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();
        if (!nombreConsultorio) {
            toast.warning("No existe un consultorio con este Folio");
        } else {
            try {
                setLoading(true);
                // Sube a cloudinary la imagen principal del producto

                const dataTemp = {
                    nombre: formData.nombre,
                    apellidoPaterno: formData.apellidoPaterno,
                    apellidoMaterno: formData.apellidoMaterno,
                    fechaNacimiento: formData.fechaNacimiento,
                    telefonoCelular: formData.telefonoCelular,
                    email: formData.email,
                    rfc: formData.rfc,
                    observaciones: formData.observaciones,
                    consultorio: formData.folioConsultorio,
                    nombreConsultorio: nombreConsultorio,
                    correoConsultorio: correoConsultorio,
                    direcciones: addresses
                };

                actualizarDoctores(idDocumental, dataTemp).then((response) => {
                    const { data } = response;
                    //notificacion
                    LogsInformativos("Se ha modificado el doctor " + dataTemp.nombre, dataTemp)
                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    setLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Label className='lblNombreDoc'>Folio: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreEst'
                        placeholder='Ingresa nombre'
                        name="folio"
                        defaultValue={formData.folio}
                        disabled
                    />
                    <Form.Label className="lblNombreDoc">Consultorio: </Form.Label>
                    <Form.Control
                        type="text"
                        className="inpNombreEst"
                        placeholder="Ingresa el nombre del consultorio"
                        name="nombreConsultorio"
                        value={nombreConsultorio}
                        onChange={(e) => setNombreConsultorio(e.target.value.toUpperCase())}
                    />
                    <Form.Label className='lblNombreDoc'>Nombre(s): </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreEst'
                        placeholder='Ingresa nombre'
                        name="nombre"
                        defaultValue={formData.nombre}
                    />
                    <Form.Group className='datosPersonalesDoc'>
                        <Form.Label className='lblpApellido'>Primer Apellido: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpPrimerADoc'
                            placeholder='Ingresa primer apellido'
                            defaultValue={formData.apellidoPaterno}
                            name="apellidoPaterno"
                        />
                        <Form.Label className='lblsApellido'>Segundo Apellido: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpSegundoA'
                            placeholder='Ingresa segundo apellido'
                            defaultValue={formData.apellidoMaterno}
                            name="apellidoMaterno"
                        />
                    </Form.Group>

                    <Form.Group className='datosDoctores'>
                        <Form.Label className='lblFechaDoc'>Fecha de nacimiento: </Form.Label>
                        <Form.Control
                            type='date'
                            className='inpFechaDoc'
                            placeholder='dd/mm/aaaa'
                            defaultValue={formData.fechaNacimiento}
                            name="fechaNacimiento"
                        />
                        <Form.Label className='lblRFCDoc'>RFC: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpRFCDoc'
                            placeholder='Ingresa RFC'
                            defaultValue={formData.rfc}
                            name="rfc"
                        />
                    </Form.Group>
                    <Form.Group className="contactoDoc">
                        <Form.Label className='lblTelefonoDoc'>Telefono celular: </Form.Label>
                        <Form.Control
                            type='number'
                            className='inpTelefonoDoc'
                            placeholder='Ingresa telefono a 10 digitos'
                            defaultValue={formData.telefonoCelular}
                            name="telefonoCelular"
                        />
                        <Form.Label className='lblCorreoDoc'>Correo Electrónico: </Form.Label>
                        <Form.Control
                            type='email'
                            className='inpCorreoDoc'
                            placeholder='ejemplo@ejemplo.com'
                            defaultValue={formData.email}
                            name="email"
                        />

                    </Form.Group>

                    <Form.Group className='formObservacionesDoc'>
                        <Form.Label>Observaciones:</Form.Label>
                        <Form.Control
                            className='txtObservacionesDoc '
                            as="textarea"
                            rows={3}
                            placeholder='Observaciones'
                            defaultValue={formData.observaciones}
                            name="observaciones"
                        />
                    </Form.Group>

                    <br />
                    <h4>Domicilio (opcional)</h4>
                    <hr />
                    <Form.Group className="dom1Cli">
                        <Form.Label className="lblCalle">Calle: </Form.Label>
                        <Form.Control
                            type="text"
                            className="inpCalle"
                            placeholder="Ingresa calle"
                            value={calle}
                            onChange={(e) => setCalle(e.target.value)}
                        />
                        <Form.Label className="lblnExt">Num Ext: </Form.Label>
                        <Form.Control
                            type="number"
                            className="inpNumExt"
                            placeholder="Ingresa numero exterior"
                            value={numeroExterior}
                            onChange={(e) => setNumeroExterior(e.target.value)}
                        />
                        <Form.Label className="lblnInt">Num Int: </Form.Label>
                        <Form.Control
                            type="number"
                            className="inpNumInt"
                            placeholder="Ingresa numero interior"
                            value={numeroInterior}
                            onChange={(e) => setNumeroInterior(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="dom2Cli">
                        <div className="colonia">
                            <Form.Label className="lblColonia">Colonia: </Form.Label>
                            <Form.Control
                                type="text"
                                className="inpColonia"
                                placeholder="Ingresa colonia"
                                value={colonia}
                                onChange={(e) => setColonia(e.target.value)}
                            />
                        </div>
                        <div className="estadoCli">
                            <Form.Label className="lblEstadoEst">Estado</Form.Label>
                            <Form.Control
                                as="select"
                                value={estadoPais}
                                onChange={(e) => setEstadoPais(e.target.value)}
                            >
                                <option>Selecciona un Estado</option>
                                {estadosPaisData.map((estado) => (
                                    <option key={estado.clave} value={estado.nombre}>
                                        {estado.nombre}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div className="municipioCli">
                            <Form.Label className="lblMunicipio">Municipio</Form.Label>
                            <Form.Control
                                as="select"
                                value={municipioSeleccionado}
                                onChange={(e) => setMunicipioSeleccionado(e.target.value)}
                            >
                                <option>Selecciona un Municipio</option>
                                {municipiosEstadoData[estadoPais] &&
                                    municipiosEstadoData[estadoPais].map((municipio) => (
                                        <option key={municipio} value={municipio}>
                                            {municipio}
                                        </option>
                                    ))}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group className="dom3Cli">
                        <Form.Label className="lblCP">Codigo Postal: </Form.Label>
                        <Form.Control
                            type="number"
                            className="inpCP"
                            placeholder="Ingresa Codigo Postal"
                            value={codigoPostal}
                            onChange={(e) => setCodigoPostal(e.target.value)}
                        />
                        <Form.Label className="lblEsquina">Referencia: </Form.Label>
                        <Form.Control
                            type="text"
                            className="inpEsquina"
                            placeholder="Ingresa Referencia"
                            value={haceEsquina}
                            onChange={(e) => setHaceEsquina(e.target.value)}
                        />
                    </Form.Group>
                    <br />
                    {/* Botón para agregar dirección */}
                    <div>
                        <button type="button" onClick={handleAddAddress}>Agregar Dirección</button>
                    </div>
                    <br />
                    {/* Tabla para mostrar las direcciones */}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Calle</th>
                                <th>Num Ext</th>
                                <th>Num Int</th>
                                <th>Colonia</th>
                                <th>Estado</th>
                                <th>Municipio</th>
                                <th>Codigo Postal</th>
                                <th>Referencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addresses.map((address, index) => (
                                <tr key={index}>
                                    <td>{address.calle}</td>
                                    <td>{address.numeroExterior}</td>
                                    <td>{address.numeroInterior}</td>
                                    <td>{address.colonia}</td>
                                    <td>{address.estado}</td>
                                    <td>{address.municipio}</td>
                                    <td>{address.codigoPostal}</td>
                                    <td>{address.haceEsquina}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        folio: data.folio,
        nombre: data.nombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        fechaNacimiento: data.fechaNacimiento,
        telefonoCelular: data.telefonoCelular,
        email: data.email,
        rfc: data.rfc,
        observaciones: data.observaciones,
        folioConsultorio: data.folioConsultorio
    };
}
