import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { registraSueldosGenerales } from "../../../api/sueldosGenerales";
import { toast } from "react-toastify";

const RegistroDescuento = ({ setShow }) => {
  const [idEmpleado, setIdEmpleado] = useState("");
  const [concepto, setConcepto] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [fecha, setFecha] = useState("");

  const registraSalida = () => {
    try {
      const dataTempIns = {
        idEmpleado: idEmpleado,
        fecha: fecha,
        motivo: concepto,
        cantidad: cantidad,
        estado: "true",
      };
      registraSueldosGenerales(dataTempIns).then((response) => {
        const { data } = response;
        toast.success("Descuento registrado");
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Empleado:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              value={idEmpleado}
              onChange={(e) => setIdEmpleado(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Motivo de descuento:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Ingresa el concepto de la operación"
              value={concepto}
              onChange={(e) => setConcepto(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad descontada:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={cantidad}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    setCantidad(inputValue);
                  }
                }}
                placeholder="Ingresa la cantidad de la operación"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Fecha del descuento:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="date"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-center">
        <Button variant="success" onClick={registraSalida}>
          <FontAwesomeIcon icon={faTag} /> Registrar
        </Button>
      </div>
    </div>
  );
};

export default RegistroDescuento;
