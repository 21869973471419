import React, { useEffect, useState } from "react";
import { listarOrdenesServicioPorCorreoMensajero } from "../../api/ordenesServicio";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Direccion from "./Direccion";

const FiltroRutas = ({ data }) => {
  const correoMensajero = data.email;

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = (correo) => {
    try {
      listarOrdenesServicioPorCorreoMensajero(correo)
        .then((response) => {
          const { data } = response;
          console.log(data)

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio(correoMensajero);
  }, [correoMensajero]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "ORDEN DE CLIENTE",
    },
    {
      name: "localidad",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreDoctor",
      label: "NOMBRE DEL DOCTOR",
    },
    {
      name: "direccion",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Direccion
                correoBuscar={tableMeta.rowData[7]}
                correoEstablecimiento={tableMeta.rowData[6]}
                correoDoctor={tableMeta.rowData[5]}
              />
            </>
          );
        },
      }
    },
    {
      name: "correoDoctor",
      label: "EMAIL DOCTOR",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "correoConsultorio",
      label: "CORREO CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "cargoOrden",
      label: "CARGO ORDEN",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <div>
      <h4>{data.nombre}</h4>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de ordenes de cliente registradas"}
          data={listarOrdenes}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      cargoOrden: data.cargoOrden,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
    });
  });
  return dataTemp;
}

export default FiltroRutas;
