import { faCancel, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { actualizaDeshabilitarOrdenCompra } from "../../../api/ordenCompra";
import { LogsInformativos } from "../../logs/logs";

const Eliminar = ({ data, setShow }) => {
  const idOC = data;
  const onSubmit = (e) => {
    e.preventDefault();
    try {
      const dataTemp = {
        estado: "0",
      };
      actualizaDeshabilitarOrdenCompra(idOC, dataTemp).then((response) => {
        const { data } = response;
        toast.success("Orden de compra cancelada");
        LogsInformativos("Se ha eliminado la orden de compra " + idOC)
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <Form className="formOrden2" onSubmit={onSubmit}>
        <h4 style={{ textAlign: "center" }}>Cancelar orden {idOC}?</h4>
        <div style={{ textAlign: "center", marginTop: "2%" }}>
          <Button variant="warning" onClick={onSubmit}>
            <FontAwesomeIcon icon={faCancel} /> Cancelar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Eliminar;
