import { faBan, faFloppyDisk, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { toast } from "react-toastify";
import { obtenerUsuario } from "../../../api/usuarios";
import {
  actualizarEstablecimientos,
  listarEstablecimientos,
} from "../../../api/establecimientos";
import estadosPaisData from "../catalogosJSON/estadosPais.json";
import municipiosEstadoData from "../catalogosJSON/municipiosEstadosPais.json";

const PerfilConsultorio = () => {
  const [inputEnabled, setInputEnabled] = useState(false);

  const toggleInput = () => {
    setInputEnabled(!inputEnabled);
  };

  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);
  const [idEst, setIdEst] = useState("");
  const [folioEst, setFolioEst] = useState("");
  const [razonSocial, setrazonSocial] = useState("");
  const [aliasEst, setAliasEst] = useState("");
  const [telefonoEst, settelefonoEst] = useState("");
  const [correoEst, setcorreoEst] = useState("");
  const [calleEst, setcalleEst] = useState("");
  const [nExtEs, setnExtEs] = useState("");
  const [nIntEs, setnIntEs] = useState("");
  const [coloniaEst, setcoloniaEst] = useState("");
  const [municipioEst, setmunicipioEst] = useState("");
  const [estadoEst, setestadoEst] = useState("");
  const [cpEst, setcpEst] = useState("");
  const [refEst, setrefEst] = useState("");
  const [horarioEst, sethorarioEst] = useState("");
  const [observacionesEst, setobservacionesEst] = useState("");

  const obtenerConsultorio = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);

            // Filtrar por correo electrónico
            const correoDeseado = correoUsuario;
            const establecimientosFiltrados = datosEstablecimiento.filter(
              (establecimiento) => establecimiento.email === correoDeseado
            );
            setIdEst(establecimientosFiltrados[0]?.id || "");
            setFolioEst(establecimientosFiltrados[0]?.folio || "");
            setrazonSocial(establecimientosFiltrados[0]?.razonSocial || "");
            setAliasEst(establecimientosFiltrados[0]?.alias || "");
            settelefonoEst(establecimientosFiltrados[0]?.telefono || "");
            setcorreoEst(establecimientosFiltrados[0]?.email || "");
            setcalleEst(establecimientosFiltrados[0]?.calle || "");
            setnExtEs(establecimientosFiltrados[0]?.numeroExterior || "");
            setnIntEs(establecimientosFiltrados[0]?.numeroInterior || "");
            setcoloniaEst(establecimientosFiltrados[0]?.colonia || "");
            setmunicipioEst(establecimientosFiltrados[0]?.municipio || "");
            setestadoEst(
              establecimientosFiltrados[0]?.estadoEstablecimiento || ""
            );
            setcpEst(establecimientosFiltrados[0]?.codigoPostal || "");
            setrefEst(establecimientosFiltrados[0]?.haceEsquina || "");
            sethorarioEst(establecimientosFiltrados[0]?.horario || "");
            setobservacionesEst(
              establecimientosFiltrados[0]?.observaciones || ""
            );
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerConsultorio();
    console.log(razonSocial);
    console.log(listarEstablecimiento);
  }, [correoUsuario]);

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      const dataTemp = {
        razonSocial: razonSocial,
        estadoEstablecimiento: estadoEst,
        municipio: municipioEst,
        colonia: coloniaEst,
        calle: calleEst,
        numeroExterior: nExtEs,
        numeroInterior: nIntEs,
        codigoPostal: cpEst,
        telefono: telefonoEst,
        haceEsquina: refEst,
        email: correoEst,
        observaciones: observacionesEst,
        horario: horarioEst,
        alias: aliasEst,
      };
      actualizarEstablecimientos(idEst, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        toast.success(data.mensaje);
        obtenerConsultorio();
        setInputEnabled(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ padding: "2vw" }}>
      <Form>
        <Row className="mb-2">
          <Col>
            <Button
              onClick={toggleInput}
              className="float-right"
              variant={inputEnabled ? "danger" : "warning"}
            >
              {inputEnabled ? (
                <>
                  <FontAwesomeIcon icon={faBan} /> Cancelar
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faPen} /> Editar
                </>
              )}
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Label>Folio:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={folioEst}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Label>Nombre o Razón Social:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={razonSocial}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Label>Alias</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={aliasEst}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={6} lg={6}>
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={telefonoEst}
              disabled={!inputEnabled}
              onChange={(e) => settelefonoEst(e.target.value)}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={correoEst}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={4} lg={4}>
            <Form.Label>Calle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={calleEst}
              onChange={(e) => setcalleEst(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Form.Label>Num. Exterior</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={nExtEs}
              onChange={(e) => setnExtEs(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Form.Label>Num. Interior:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={nIntEs}
              onChange={(e) => setnIntEs(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={4} lg={4}>
            <Form.Label>Colonia:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={coloniaEst}
              onChange={(e) => setcoloniaEst(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
          <Col sm={12} md={4} lg={4}>
          <Form.Label>Estado:</Form.Label>
            {inputEnabled ? (
              <Form.Control
                as="select"
                value={estadoEst}
                onChange={(e) => setestadoEst(e.target.value)}
              >
                <option>Selecciona un Estado</option>
                {estadosPaisData.map((estado) => (
                  <option key={estado.clave} value={estado.nombre}>
                    {estado.nombre}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                placeholder="Ingrese valor"
                value={estadoEst}
                disabled={!inputEnabled}
              />
            )}
          </Col>
          <Col sm={12} md={4} lg={4}>
          <Form.Label>Municipio:</Form.Label>
            {inputEnabled ? (
              <Form.Control
                as="select"
                value={municipioEst}
                onChange={(e) => setmunicipioEst(e.target.value)}
              >
                <option>Selecciona un Municipio</option>
                {municipiosEstadoData[estadoEst] &&
                  municipiosEstadoData[estadoEst].map((municipio) => (
                    <option key={municipio} value={municipio}>
                      {municipio}
                    </option>
                  ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                placeholder="Ingrese valor"
                value={municipioEst}
                disabled={!inputEnabled}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={6} lg={6}>
            <Form.Label>Código Postal:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={cpEst}
              onChange={(e) => setcpEst(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Form.Label>Referencia:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={refEst}
              onChange={(e) => setrefEst(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Label>Horario:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese valor"
              value={horarioEst}
              onChange={(e) => sethorarioEst(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Label>Observaciones:</Form.Label>
            <Form.Control
              as="textarea"
              value={observacionesEst}
              rows={2}
              onChange={(e) => setobservacionesEst(e.target.value)}
              disabled={!inputEnabled}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="text-center">
            {inputEnabled && (
              <Button variant="success" onClick={onSubmit}>
                <FontAwesomeIcon icon={faFloppyDisk} /> Guardar Cambios
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PerfilConsultorio;

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}
