import React, { useEffect, useState } from "react";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Badge, Button, Dropdown } from "react-bootstrap";
import {
  faBars,
  faCancel,
  faPen,
  faPlus,
  faTrash,
  faFilePdf,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegistroOC from "./RegistroOC";
import { Load } from "../../load/load";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarOrdenCompra } from "../../../api/ordenCompra";
import ActualizarOC from "./ActualizarOC";
import Eliminar from "./Eliminar";
import ActualizarEstadoOC from "./ActualizarEstadoOC";
import PDFOC from "./PDFOC";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaOC = () => {
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroOC = (content) => {
    setTitulosModal("Registar orden de compra");
    setContentModal(content);
    setShowModal(true);
  };

  const pdfOC = (content) => {
    setTitulosModal("PDF orden de compra");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarOC = (content) => {
    setTitulosModal("Modificar orden de compra");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarEstadoOC = (content) => {
    setTitulosModal("Completar orden de compra");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarOC = (content) => {
    setTitulosModal("Cancelar orden de compra");
    setContentModal(content);
    setShowModal(true);
  };

  /*********************************************** */
  const [listOC, setListOC] = useState([]);

  const obtenerOC = () => {
    try {
      listarOrdenCompra()
        .then((response) => {
          const { data } = response;
          if (!listOC && data) {
            setListOC(formatModelOC(data));
          } else {
            setListOC(formatModelOC(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (!showModal) {
      obtenerOC();
    }
  }, [showModal]);
  /*********************************************** */
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "proveedor",
      label: "PROVEEDOR",
    },
    {
      name: "productos",
      label: "PRODUCTOS",
    },
    {
      name: "subtotal",
      label: "SUBTOTAL",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "iva",
      label: "IVA",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "total",
      label: "TOTAL",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "elaboro",
      label: "ELABORÓ",
    },
    {
      name: "autorizo",
      label: "AUTORIZÓ",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estadoTexto = "";

          if (estado == "0") {
            estadoTexto = "Cancelada";
          } else if (estado == "1") {
            estadoTexto = "Recibida";
          } else if (estado == "2") {
            estadoTexto = "Activa";
          }

          return (
            <>
              <Badge
                bg={estado == "0" ? "danger" : estado == "1" ? "success" : "warning"}
              //className="estado"
              >
                {estadoTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[9] == "2" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      modificarEstadoOC(
                        <ActualizarEstadoOC
                          setShow={setShowModal}
                          data={tableMeta.rowData[0]}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#000080" }}
                    />
                    &nbsp; Actualizar estado
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      modificarOC(
                        <ActualizarOC
                          setShow={setShowModal}
                          data={tableMeta.rowData[0]}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Modificar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      eliminarOC(
                        <Eliminar
                          setShow={setShowModal}
                          data={tableMeta.rowData[0]}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCancel}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Cancelar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      pdfOC(
                        <PDFOC
                          setShow={setShowModal}
                          data={tableMeta.rowData[0]}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{ color: "#87CEEB" }}
                    />
                    &nbsp; Vista previa
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
            <Dropdown>
              <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                <FontAwesomeIcon icon={faBars} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    pdfOC(
                      <PDFOC
                        setShow={setShowModal}
                        data={tableMeta.rowData[0]}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    style={{ color: "#87CEEB" }}
                  />
                  &nbsp; Vista previa
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  /*********************************************** */

  return (
    <>
      {loading && <Load />}
      <h1>Ordenes de compra</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() => registroOC(<RegistroOC setShow={setShowModal} />)}
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de ordenes de compra registradas"}
          data={listOC}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
};

function formatModelOC(data) {
  const dataTemp = [];
  data.forEach((item) => {
    const materialProductos = item.productos
      .map(
        (productos, index) =>
          `Cantidad: ${productos.cantidad}, Producto: ${productos.producto}`
      )
      .join("  ➡️  ");

    dataTemp.push({
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      productos: materialProductos,
      proveedor: item.datosProveedor.razonSocial,
      iva: item.iva,
      subtotal: item.subtotal,
      total: item.total,
      autorizo: item.autorizo,
      elaboro: item.elaboro,
      estado: item.estado,
    });
  });
  return dataTemp;
}

export default TablaOC;
