import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMovimientosAMP,
    ENDPOINTObtenerMovimientosAMP,
    ENDPOINTListarMovimientosAMP,
    ENDPOINTListarMovimientosAMPFiltroFecha,
    ENDPOINTActualizarMovimientosAMP,
    ENDPOINTEliminarMovimientosAMP,
    ENDPOINTDeshabilitarMovimientosAMP,
    ENDPOINTListarMovimientosAMPFiltroGeneralFecha
} from "./endpoints";
import axios from 'axios';

export async function listarMovimientosAMP(idArticuloAMP) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosAMP + `/?idArticuloAMP=${idArticuloAMP}`, config);
}

export async function listarMovimientosAMPFiltroFecha(idArticuloAMP, inicio, fin) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosAMPFiltroFecha + `/?idArticuloAMP=${idArticuloAMP}&&inicio=${inicio}&&fin=${fin}`, config);
}

export async function listarMovimientosAMPFiltroGeneralFecha(inicio, fin) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosAMPFiltroGeneralFecha + `/?inicio=${inicio}&&fin=${fin}`, config);
}

export async function registraMovimientosAMP(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMovimientosAMP, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMovimientosAMP(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMovimientosAMP + `/${params}`, config);
}

export async function actualizarMovimientosAMP(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMovimientosAMP + `/${id}`, data, config);
}

export async function eliminarMovimientosAMP(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMovimientosAMP + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMovimientosAMP(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMovimientosAMP + `/${id}`, data, config);
}