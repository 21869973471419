import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarColorimetria } from "../../../api/colorimetria";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";
import Dropzone from "../../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../../api/cloudinary";

export default function ModificarColorimetria({ data, history, setShow }) {
    console.log(data);

    //Para almacenar la imagen del producto que se guardara a la bd
    const [imagenProducto, setImagenProducto] = useState(data[3]);

    const idDocumental = data[0];

    const dataTemp = {
        nombre: data[1],
        tonos: data[2],
        imagen: data[3],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            if (imagenProducto != "Sin Imagen") {
                subeArchivosCloudinary(imagenProducto, "colorimetria")
                    .then((response) => {
                        const { data } = response;
                        setLoading(true);
                        // Sube a cloudinary la imagen principal del producto
                        const dataTemp = {
                            nombre: formData.nombre,
                            tonos: formData.tonos,
                            imagen: data.secure_url
                        };
                        actualizarColorimetria(idDocumental, dataTemp).then((response) => {
                            const { data } = response;
                            //notificacion
                            LogsInformativos("Se ha modificado el sistema de colorimetria " + dataTemp.nombre, dataTemp)
                            toast.success(data.mensaje);

                            history({
                                search: queryString.stringify(""),
                            });
                            setLoading(false);
                            setShow(false);
                            //cancelarRegistro()
                        });
                    })
                    .then((e) => {
                        console.log(e);
                    });
            } else {
                    setLoading(true);
                    // Sube a cloudinary la imagen principal del producto
                    const dataTemp = {
                        nombre: formData.nombre,
                        tonos: formData.tonos,
                        imagen: ""
                    };
                    actualizarColorimetria(idDocumental, dataTemp).then((response) => {
                        const { data } = response;
                        //notificacion
                        LogsInformativos("Se ha modificado el sistema de colorimetria " + dataTemp.nombre, dataTemp)
                        toast.success(data.mensaje);

                        history({
                            search: queryString.stringify(""),
                        });
                        setLoading(false);
                        setShow(false);
                        //cancelarRegistro()
                    });
            }
        } catch (e) {
            console.log(e);
        }

    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalSerDentales'>
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <div className="imagenPrincipal">
                        <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
                        <div
                            title="Seleccionar imagen de la categoría"
                            className="imagenProducto"
                        >
                            <Dropzone setImagenFile={setImagenProducto} imagenProductoBD={formData.imagen} />
                        </div>
                    </div>
                    <Form.Label className='lblNombreSis'>Nombre del sistema de colorimetria: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreSis'
                        placeholder='Nombre'
                        name="nombre"
                        defaultValue={formData.nombre}
                    />
                    <Form.Label className='lblTonos'>Tonos: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpTonos'
                        placeholder='Introduzca escalas separadas por comas. Por ejemplo: escala1, escala2, escala3'
                        name="tonos"
                        defaultValue={formData.tonos}
                    />
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        nombre: data.nombre,
        tonos: data.tonos,
        imagen: data.imagen
    };
}
