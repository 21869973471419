import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMantenimiento,
    ENDPOINTObtenerMantenimiento,
    ENDPOINTListarMantenimiento,
    ENDPOINTActualizarMantenimiento,
    ENDPOINTEliminarMantenimiento,
    ENDPOINTDeshabilitarMantenimiento,
    ENDPOINTEventsMantenimiento,
} from "./endpoints";
import axios from 'axios';

export async function listarMantenimiento(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMantenimiento, config);
}

export async function eventsMantenimiento(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTEventsMantenimiento, config);
}

export async function registraMantenimiento(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMantenimiento, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMantenimiento(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMantenimiento + `/${params}`, config);
}

export async function actualizarMantenimiento(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMantenimiento + `/${id}`, data, config);
}

export async function eliminarMantenimiento(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMantenimiento + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMantenimiento(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMantenimiento + `/${id}`, data, config);
}