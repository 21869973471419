import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { eliminarEntregas } from "../../api/entregas";
import { listarOrdenesTrabajo } from "../../api/ordenesTrabajo";
import { listarMensajeros } from "../../api/mensajeros"
import { map } from "lodash";
import { LogsInformativos } from "../logs/logs";

export default function EliminarEntregas({ data, history, setShow }) {

  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    mensajero: data[2],
    fechaEntrega: data[3],
    ordenTrabajo: data[4],
    motivoEntrega: data[5],
    comentarios: data[6],
  };

  const tiposViaje = [
    "Trabajo final",
    "Prueba",
  ];

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const [signInLoading, setSignInLoading] = useState(false);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;

    return `${year}-${mes}-${dia}`;
  };

  // Estado para almacenar la fecha seleccionada
  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    obtenerFechaActual()
  );

  useEffect(() => {
    setFechaSeleccionada(obtenerFechaActual());
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesTrabajo = () => {
    try {
      listarOrdenesTrabajo()
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesTrabajo();
  }, []);

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
      setSignInLoading(true);
      try {
        eliminarEntregas(idDocumental)
          .then((response) => {
            const { data } = response;
            console.log(data);

            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });
            LogsInformativos("Se ha eliminado la entrega " + idDocumental)
            setSignInLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
          .catch((ex) => {
            if (ex.message === "Network Error") {
              toast.error("Conexión al servidor no disponible");
              setSignInLoading(false);
            } else {
              if (ex.response && ex.response.status === 401) {
                const { mensaje } = ex.response.data;
                toast.error(mensaje);
                setSignInLoading(false);
              }
            }
          });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Folio:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="direccion"
                style={{ textTransform: "capitalize" }}
                value={formData.folio}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                value={fechaSeleccionada}
                onChange={(e) => setFechaSeleccionada(e.target.value)}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Mensajero:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar mensajero"
                defaultValue={formData.mensajero}
                name="mensajero"
                disabled
              >
                <option>Elige una opción</option>
                {map(listarMensajero, (cat, index) => (
                  <option key={index} value={cat?.nombre} selected={formData.mensajero == cat?.nombre}>
                    {cat?.nombre}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Orden trabajo:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar mensajero"
                defaultValue={formData.ordenTrabajo}
                name="ordenTrabajo"
                disabled
              >
                <option>Elige una opción</option>
                {map(listarOrdenes, (cat, index) => (
                  <option key={index} value={cat?.folio} selected={formData.ordenTrabajo == cat?.folio}>
                    {cat?.folio}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Motivo de la entrega:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de viaje"
                defaultValue={formData.motivoEntrega}
                name="motivoEntrega"
                disabled
              >
                <option>Seleccionar</option>
                {tiposViaje.map((tipo, index) => (
                  <option key={index} value={tipo} selected={formData.motivoEntrega == tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Comentarios:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="textarea"
                placeholder="Comentarios"
                defaultValue={formData.comentarios}
                name="comentarios"
                disabled
              />
            </Col>
          </Row>
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    mensajero: data.mensajero,
    fechaEntrega: data.fechaEntrega,
    ordenTrabajo: data.ordenTrabajo,
    motivoEntrega: data.motivoEntrega,
    comentarios: data.comentarios,
  };
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
      calle: data.calle,
      nExterior: data.nExterior,
      nInterior: data.nInterior,
      colonia: data.colonia,
      estadoDom: data.estadoDom,
      municipio: data.municipio,
      cPostal: data.cPostal,
      referencia: data.referencia,
    });
  });
  return dataTemp;
}

const formatModelOrdenes = (data) => {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
    });
  });
  return dataTemp;
};