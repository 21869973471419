import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../load/load";
import { eliminarDoctores } from "../../api/doctores";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function EliminarDoctores({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        folio: data[1],
        nombre: data[2],
        apellidoPaterno: data[3],
        apellidoMaterno: data[4],
        fechaNacimiento: data[5],
        telefonoCelular: data[6],
        email: data[7],
        rfc: data[8],
        observaciones: data[9],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            eliminarDoctores(idDocumental).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha eliminado el doctor " + idDocumental)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Label className='lblNombreDoc'>Folio: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreEst'
                        placeholder='Ingresa nombre'
                        name="folio"
                        defaultValue={formData.folio}
                        disabled
                    />
                    <Form.Label className='lblNombreDoc'>Nombre(s): </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpNombreEst'
                        placeholder='Ingresa nombre'
                        name="nombre"
                        defaultValue={formData.nombre}
                        disabled
                    />
                    <Form.Group className='datosPersonalesDoc'>
                        <Form.Label className='lblpApellido'>Primer Apellido: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpPrimerADoc'
                            placeholder='Ingresa primer apellido'
                            defaultValue={formData.apellidoPaterno}
                            name="apellidoPaterno"
                            disabled
                        />
                        <Form.Label className='lblsApellido'>Segundo Apellido: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpSegundoA'
                            placeholder='Ingresa segundo apellido'
                            defaultValue={formData.apellidoMaterno}
                            name="apellidoMaterno"
                            disabled
                        />
                    </Form.Group>

                    <Form.Group className='datosDoctores'>
                        <Form.Label className='lblFechaDoc'>Fecha de nacimiento: </Form.Label>
                        <Form.Control
                            type='date'
                            className='inpFechaDoc'
                            placeholder='dd/mm/aaaa'
                            defaultValue={formData.fechaNacimiento}
                            name="fechaNacimiento"
                            disabled
                        />
                        <Form.Label className='lblRFCDoc'>RFC: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpRFCDoc'
                            placeholder='Ingresa RFC'
                            defaultValue={formData.rfc}
                            name="rfc"
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="contactoDoc">
                        <Form.Label className='lblTelefonoDoc'>Telefono celular: </Form.Label>
                        <Form.Control
                            type='number'
                            className='inpTelefonoDoc'
                            placeholder='Ingresa telefono a 10 digitos'
                            defaultValue={formData.telefonoCelular}
                            name="telefonoCelular"
                            disabled
                        />
                        <Form.Label className='lblCorreoDoc'>Correo Electrónico: </Form.Label>
                        <Form.Control
                            type='email'
                            className='inpCorreoDoc'
                            placeholder='ejemplo@ejemplo.com'
                            defaultValue={formData.email}
                            name="email"
                            disabled
                        />

                    </Form.Group>

                    <Form.Group className='formObservacionesDoc'>
                        <Form.Label>Observaciones:</Form.Label>
                        <Form.Control
                            className='txtObservacionesDoc '
                            as="textarea"
                            rows={3}
                            placeholder='Observaciones'
                            defaultValue={formData.observaciones}
                            name="observaciones"
                            disabled
                        />
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        folio: data.folio,
        nombre: data.nombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        fechaNacimiento: data.fechaNacimiento,
        telefonoCelular: data.telefonoCelular,
        email: data.email,
        rfc: data.rfc,
        observaciones: data.observaciones,
    };
}
