import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { obtenerAlmacenMateriasPrimas, actualizaAlmacenMateriasPrimas } from "../../api/almacenMateriasPrimas"
import { listarDepartamentos } from "../../api/departamentos";
import queryString from "query-string";
import { registraMovimientosAMP } from "../../api/movimientosAlmacenMP";
import { map } from "lodash";
import { listarUnidadMedida } from "../../api/unidadesMedida";
import { LogsInformativos } from "../logs/logs";

export default function MovimientosAMP({ history, setShow, idArticuloAMP }) {
    const [formData, setFormData] = useState(initialFormValue());

    const [listarUnidadesMedida, setListUnidadesMedida] = useState([]);

    const obtenerUnidadesMedida = () => {
        try {
            listarUnidadMedida()
                .then((response) => {
                    const { data } = response;

                    if (!listarUnidadesMedida && data) {
                        setListUnidadesMedida(formatModelUnidadesMedida(data));
                    } else {
                        const datosUnidadesMedida = formatModelUnidadesMedida(data);
                        setListUnidadesMedida(datosUnidadesMedida);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerUnidadesMedida();
    }, []);

    const [listarDepartamento, setListDepartamento] = useState([]);

    const obtenerDepartamento = () => {
        try {
            listarDepartamentos()
                .then((response) => {
                    const { data } = response;

                    if (!listarDepartamento && data) {
                        setListDepartamento(formatModelDepartamento(data));
                    } else {
                        const datosDepartamento = formatModelDepartamento(data);
                        setListDepartamento(datosDepartamento);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDepartamento();
    }, []);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const [nombreProducto, setNombreProducto] = useState("");
    const [idArticulo, setIdArticulo] = useState("");
    const [cantidadEntrada, setCantidadEntrada] = useState("");
    const [cantidadSalida, setCantidadSalida] = useState("");

    useEffect(() => {
        try {
            obtenerAlmacenMateriasPrimas(idArticuloAMP).then(response => {
                const { data } = response;
                setIdArticulo(data._id);
                setNombreProducto(data.producto);
                setCantidadEntrada(Number(data.entrada));
                setCantidadSalida(Number(data.salida));
            }).catch((e) => {
                if (e.message === 'Network Error') {
                    console.log("No hay internet")
                }
            })
        } catch (e) {
            console.log(e)
        }
    }, []);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.tipo || !formData.cantidad) {
            toast.warning("Completa el formulario");
        } else {
            try {
                setLoading(true);
                const temp = formData.unidadMedida.split("/");

                const dataTemp = {
                    idArticuloAMP: idArticulo,
                    producto: nombreProducto,
                    tipo: formData.tipo,
                    cantidadMovimiento: Number(formData.cantidad) * Number(temp[1]),
                    unidadMedida: temp[0],
                    departamento: formData.departamento,
                    observaciones: formData.observaciones
                };

                const dataTemp2 = {
                    entrada: formData.tipo == "Entrada" ? cantidadEntrada + (Number(formData.cantidad) * Number(temp[1])) : cantidadEntrada,
                    salida: formData.tipo == "Salida" ? cantidadSalida + (Number(formData.cantidad) * Number(temp[1])) : cantidadSalida
                };
                registraMovimientosAMP(dataTemp).then((response) => {
                    const { data } = response;
                    //notificacion
                    actualizaAlmacenMateriasPrimas(idArticulo, dataTemp2).then((response) => {
                        history({
                            search: queryString.stringify(""),
                        });
                    });
                    toast.success(data.mensaje);
                    LogsInformativos("Se ha registrado el movimiento del producto " + dataTemp.producto + " en el almacen de materias primas", dataTemp)
                    history({
                        search: queryString.stringify(""),
                    });
                    setLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const palabraEspecifica = "TABLILLA"

    // Convertir ambas la cadena y la palabra a minúsculas (o mayúsculas)
    const cadenaEnMinusculas = nombreProducto.toLowerCase();
    const palabraEnMinusculas = palabraEspecifica.toLowerCase();

    // Utilizar includes() en las cadenas convertidas
    const contienePalabra = cadenaEnMinusculas.includes(palabraEnMinusculas);


    return (
        <>
            {loading && <Load />}
            <div className="contact-form">
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <br />
                    <Form.Label className="lblTonoOS">
                        Movimiento:
                    </Form.Label>
                    <Form.Control
                        as="select"
                        placeholder="Tipo"
                        name="tipo"
                        defaultValue={formData.tipo}
                    >
                        <option value="">Elige una opcion</option>
                        <option value="Entrada">Entrada</option>
                        <option value="Salida">Salida</option>
                    </Form.Control>
                    <br />
                    <Form.Label className="lblTonoOS">
                        Departamento:
                    </Form.Label>
                    <Form.Control
                        name="departamento"
                        as="select"
                        placeholder="Departamento"
                        //onChange={(e) => setTonoSuperior(e.target.value)}
                        defaultValue={formData.departamento}
                    >
                        <option>Elige una opción</option>
                        <option value="no aplica">No aplica</option>
                        {map(listarDepartamento, (cat, index) => (
                            <option key={index} value={cat?.nombre}>
                                {cat?.nombre}
                            </option>
                        ))}
                    </Form.Control>
                    <br />
                    <Form.Label className="lblTonoOS">
                        Cantidad:
                    </Form.Label>
                    <Form.Control
                        placeholder="cantidad del movimiento"
                        type="text"
                        name="cantidad"
                        defaultValue={formData.cantidad}
                    />
                    <br />
                    <Form.Label className="nombreProdMP">Unidad de medida:</Form.Label>
                    <Form.Control
                        placeholder="Unidad medida"
                        as="select"
                        name="unidadMedida"
                        defaultValue={formData.unidadMedida}
                    >
                        <option>Elige una opción</option>
                        {map(listarUnidadesMedida, (cat, index) => (
                            <option key={index} value={cat?.nombre + "/" + cat?.conversion}>
                                {cat?.nombre}
                            </option>
                        ))}
                    </Form.Control>
                    <br />
                    {contienePalabra && (
                        <>
                            <Form.Label className="lblTonoOS">
                                Observaciones:
                            </Form.Label>
                            <Form.Control
                                placeholder="Observaciones"
                                type="text"
                                as="textarea"
                                name="observaciones"
                                defaultValue={formData.observaciones}
                            />
                            <br />
                        </>
                    )}
                    <label></label>
                    <input className="submit" value="Enviar" type="submit" />
                </Form>
            </div>
        </>
    );
}

function initialFormValue() {
    return {
        tipo: "",
        cantidad: "",
        departamento: "",
        observaciones: "",
        unidadMedida: ""
    };
}

function formatModelDepartamento(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            folio: data.folio,
            nombre: data.nombre,
            estado: data.estado
        });
    });
    return dataTemp;
}

function formatModelUnidadesMedida(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            folio: data.folio,
            nombre: data.nombre,
            conversion: data.conversion,
            estado: data.estado,
        });
    });
    return dataTemp;
}

