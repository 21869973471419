import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarEntregas,
    ENDPOINTObtenerEntregas,
    ENDPOINTListarEntregas,
    ENDPOINTListarEntregasCorreoMensajero,
    ENDPOINTActualizarEntregas,
    ENDPOINTEliminarEntregas,
    ENDPOINTDeshabilitarEntregas,
    ENDPOINTObtenerFolioEntregas
} from "./endpoints";
import axios from 'axios';

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroEntregas() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioEntregas, config);
}

export async function listarEntregas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarEntregas, config);
}

export async function listarEntregasCorreoMensajero(correoMensajero){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarEntregasCorreoMensajero + `?correoMensajero=${correoMensajero}`, config);
}

export async function registraEntregas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarEntregas, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerEntregas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerEntregas + `/${params}`, config);
}

export async function actualizarEntregas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarEntregas + `/${id}`, data, config);
}

export async function eliminarEntregas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarEntregas + `/${id}`, data, config);
}

export async function actualizaDeshabilitarEntregas(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarEntregas + `/${id}`, data, config);
}