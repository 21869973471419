import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarMensajeros } from "../../../api/mensajeros";
import { toast } from "react-toastify";
import queryString from "query-string";
import { listarVehiculos } from "../../../api/vehiculos";
import { map } from "lodash";
import Dropzone from "../../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import { LogsInformativos } from "../../logs/logs";

export default function ModificarMensajeros({ data, history, setShow }) {

  const idDocumental = data[0];

  const dataTemp = {
    nombre: data[1],
    telefono: data[2],
    email: data[3],
    modeloVehiculo: data[4],
    placa: data[5],
    economico: data[6],
    fecha: data[7]
  };

  const [listarVehiculo, setListVehiculo] = useState([]);

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(data[9]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVehiculo();
  }, []);

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      subeArchivosCloudinary(imagenProducto, "mensajeros").then(response => {
        const { data } = response;
        setLoading(true);
        // Sube a cloudinary la imagen principal del producto

        const dataTemp = {
          nombre: formData.nombre,
          telefono: formData.telefono,
          email: formData.email,
          modeloVehiculo: formData.modeloVehiculo,
          placa: formData.placa,
          economico: formData.economico,
          imagen: data.secure_url
        };

        actualizarMensajeros(idDocumental, dataTemp).then((response) => {
          const { data } = response;
          //notificacion

          toast.success(data.mensaje);
          LogsInformativos("Se ha modificado el mensajero " + dataTemp.nombre, dataTemp)
          history({
            search: queryString.stringify(""),
          });
          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        });
      }).then(e => {
        console.log(e)
      })
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [placa, setPlaca] = useState(data[5]);
  const [economico, setEconomico] = useState(data[6]);

  const obtenerDatos = () => {
    const temp = formData.modeloVehiculo.split("/");
    setPlaca(temp[1]);
    setEconomico(temp[2]);
  }

  useEffect(() => {
    obtenerDatos();
  }, [formData.modeloVehiculo]);

  return (
    <>
      {loading && <Load />}
      <div className='facturacionGeneral'>
        <div className="imagenPrincipal">
          <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
          <div title="Seleccionar imagen de la categoría" className="imagenProducto">
            <Dropzone
              setImagenFile={setImagenProducto}
              imagenProductoBD={data[9]}
            />
          </div>
        </div>
        <Form className="formMensajero" onSubmit={onSubmit} onChange={onChange}>
          <Form.Label className="lblNomMensajero">
            Nombre completo:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            className="nombreMensajero"
            placeholder="Nombre completo"
            defaultValue={formData.nombre}
            name="nombre"
          />
          <Form.Label className="lblTelMensajero">Teléfono: </Form.Label>
          <Form.Control
            type="tel"
            className="telefonoMensajero"
            placeholder="Teléfono"
            defaultValue={formData.telefono}
            name="telefono"
          />
          <Form.Label className="lblNomMensajero">
            Correo electronico:{" "}
          </Form.Label>
          <Form.Control
            type="email"
            className="emailMensajero"
            placeholder="ejemplo@ejemplo.com"
            defaultValue={formData.email}
            name="email"
          />
          <Form.Label className="lblmodeloMensajero">
            Modelo de vehículo:{" "}
          </Form.Label>
          <Form.Control
            as="select"
            className="modeloMensajero"
            placeholder="Modelo de vehículo"
            defaultValue={formData.modeloVehiculo}
            name="modeloVehiculo"
          >
            <option value="">Seleccionar una opcion</option>
            {map(listarVehiculo, (cat, index) => (
              <option key={index} selected={formData.modeloVehiculo == cat?.modelo} value={cat?.modelo + "/" + cat?.placa + "/" + cat?.numEconomico}>{cat?.modelo}</option>
            ))}
          </Form.Control>
          <Form.Label className="lblPlacaMensajero">
            Placa:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            className="placaMensajero"
            placeholder="Placa del vehículo"
            defaultValue={formData.placa}
            value={placa}
            onChange={e => setPlaca(e.target.value)}
            name="placa"
            disabled
          />
          <Form.Label className="lblNomMensajero">
            # Económico:{" "}
          </Form.Label>
          <Form.Control
            type="text"
            className="economicoMensajero"
            placeholder="# Económico"
            defaultValue={formData.economico}
            value={economico}
            onChange={e => setEconomico(e.target.value)}
            name="economico"
            disabled
          />
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    nombre: data.nombre,
    telefono: data.telefono,
    email: data.email,
    modeloVehiculo: data.modeloVehiculo,
    placa: data.placa,
    economico: data.economico,
    fecha: data.fecha
  };
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      estado: data.estado
    });
  });
  return dataTemp;
}
