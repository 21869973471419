import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { actualizaDeshabilitarRequisicionCompras } from "../../api/compras";
import { LogsInformativos } from "../logs/logs";
import { toast } from "react-toastify";

const Cancelar = ({ data, setShow }) => {
  const idReq = data[0];
  const folioReq = data[1];

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      const dataTemp = {
        estado: "false",
      };

      actualizaDeshabilitarRequisicionCompras(idReq, dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          LogsInformativos(
            "Se ha cancelado la requisicion " + folioReq,
            dataTemp
          );
          toast.success(data.mensaje);
          setShow(false);
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h3 className="d-flex justify-content-center">
        ¿Cancelar la requisición {folioReq}?
      </h3>
      <div className="mt-3 d-flex justify-content-center">
        <Button variant="danger" onClick={onSubmit}>
          <FontAwesomeIcon icon={faCancel} /> Cancelar
        </Button>
      </div>
    </div>
  );
};

export default Cancelar;
