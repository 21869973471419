import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarDepartamentosODT,
    ENDPOINTObtenerDepartamentosODT,
    ENDPOINTListarDepartamentosODT,
    ENDPOINTActualizarDepartamentosODT,
    ENDPOINTEliminarDepartamentosODT,
    ENDPOINTDeshabilitarDepartamentosODT
} from "./endpoints";
import axios from 'axios';

export async function listarDepartamentosODT(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarDepartamentosODT, config);
}

export async function registraDepartamentosODT(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarDepartamentosODT, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerDepartamentosODT(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerDepartamentosODT + `/${params}`, config);
}

export async function actualizarDepartamentosODT(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarDepartamentosODT + `/${id}`, data, config);
}

export async function eliminarDepartamentosODT(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarDepartamentosODT + `/${id}`, data, config);
}

export async function actualizaDeshabilitarDepartamentosODT(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarDepartamentosODT + `/${id}`, data, config);
}