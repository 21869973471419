import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarFacturacion } from "../../../api/facturacion";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function ModificarFacturacion({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        regimenFiscal: data[1],
        calle: data[2],
        numeroExterior: data[3],
        numeroInterior: data[4],
        codigoPostal: data[5],
        colonia: data[6],
        municipio: data[7],
        observaciones: data[8],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            const dataTemp = {
                regimenFiscal: formData.regimenFiscal,
                calle: formData.calle,
                numeroExterior: formData.numeroExterior,
                numeroInterior: formData.numeroInterior,
                codigoPostal: formData.codigoPostal,
                colonia: formData.colonia,
                municipio: formData.municipio,
                observaciones: formData.observaciones,
            };

            actualizarFacturacion(idDocumental, dataTemp).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha actualizado los datos de facturacion para la razon social " + dataTemp.regimenFiscal, dataTemp)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='facturacionGeneral'>
                <Form className='formFacturacion' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Group className='regimen'>
                        <Form.Label className='lblRegimen'>Régimen Fiscal: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpRegimen'
                            placeholder='Ingresa Régimen Fiscal'
                            defaultValue={formData.regimenFiscal}
                            name="regimenFiscal"
                        />
                    </Form.Group>

                    <Form.Group className='dom1Facturacion'>
                        <Form.Label className='lblCalleFact'>Calle: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpCalleFact'
                            placeholder='Ingresa Calle'
                            defaultValue={formData.calle}
                            name="calle"
                        />
                        <Form.Label className='lblNumExtFact'>Número Exterior: </Form.Label>
                        <Form.Control
                            type='number'
                            className='inpNumExtFact'
                            placeholder='Ingresa número exterior'
                            defaultValue={formData.numeroExterior}
                            name="numeroExterior"
                        />
                        <Form.Label className='lblNumIntFact'>Número Interior: </Form.Label>
                        <Form.Control
                            type='number'
                            className='inpNumIntFact'
                            placeholder='Ingresa número interior'
                            defaultValue={formData.numeroInterior}
                            name="numeroInterior"
                        />
                    </Form.Group>

                    <Form.Group className='dom2Facturacion'>
                        <Form.Label className='lblCPFact'>Código Postal: </Form.Label>
                        <Form.Control
                            type='number'
                            className='inpCPFact'
                            placeholder='CP'
                            defaultValue={formData.codigoPostal}
                            name="codigoPostal"
                        />
                        <Form.Label className='lblColoniaFact'>Colonia: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpColoniaFact'
                            placeholder='Ingresa Colonia'
                            defaultValue={formData.colonia}
                            name="colonia"
                        />
                        <Form.Label className='lblMunicipioFact'>Municipio: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpMunicipioFact'
                            placeholder='Ingresa Municipio'
                            defaultValue={formData.municipio}
                            name="municipio"
                        />
                    </Form.Group>

                    <Form.Group className='formObservacionesDoc'>
                        <Form.Label>Observaciones:</Form.Label>
                        <Form.Control
                            className='txtObservacionesDoc'
                            as="textarea"
                            rows={3}
                            defaultValue={formData.observaciones}
                            placeholder='Observaciones'
                            name="observaciones"
                        />
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        regimenFiscal: data.regimenFiscal,
        calle: data.calle,
        numeroExterior: data.numeroExterior,
        numeroInterior: data.numeroInterior,
        codigoPostal: data.codigoPostal,
        colonia: data.colonia,
        municipio: data.municipio,
        observaciones: data.observaciones,
    };
}
