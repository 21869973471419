import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarServiciosDentales,
    ENDPOINTObtenerServiciosDentales,
    ENDPOINTListarServiciosDentales,
    ENDPOINTActualizarServiciosDentales,
    ENDPOINTEliminarServiciosDentales,
    ENDPOINTDeshabilitarServiciosDentales
} from "./endpoints";
import axios from 'axios';

export async function listarServiciosDentales(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarServiciosDentales, config);
}

export async function registraServiciosDentales(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarServiciosDentales, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerServiciosDentales(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerServiciosDentales + `/${params}`, config);
}

export async function actualizarServiciosDentales(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarServiciosDentales + `/${id}`, data, config);
}

export async function eliminarServiciosDentales(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarServiciosDentales + `/${id}`, data, config);
}

export async function actualizaDeshabilitarServiciosDentales(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarServiciosDentales + `/${id}`, data, config);
}