import React from 'react'
import { Button } from 'react-bootstrap'
import { duplicarOrdenesServicio } from '../../api/ordenesServicio';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const RetrabajoOrden = ({orden, setShow}) => {
console.log(orden)
    const registraRetrabajo = (e) => {
        try {
          duplicarOrdenesServicio(orden[0]).then((response) => {
            const { data } = response;
            setShow(false);
            toast.success(data.mensaje)
          });
        } catch (e) {
          console.log(e);
        }
      };
  return (
    <div style={{textAlign:"center"}}>
        <h3 style={{fontStyle:"italic"}}>¿Retrabajar orden con folio: {orden[1]}?</h3>
        <Button onClick={registraRetrabajo} variant='warning'><FontAwesomeIcon icon={faArrowsRotate} /> Retrabajar</Button>
    </div>
  )
}

export default RetrabajoOrden