import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { listarUsuarios } from "../../../api/usuarios";
import { registraMensajeria } from "../../../api/mensajeria";
import { toast } from "react-toastify";

const NuevoMensaje = ({ setShow, nombre, email, tipo }) => {
  const [destinatario, setdestinatario] = useState(null);
  const handleSeleccionChange = (option) => {
    setdestinatario(option.value);
  };

  const [listaClientes, setListaClientes] = useState([]);

  const obtenerUsuariosInternos = () => {
    listarUsuarios()
      .then((response) => {
        const { data } = response;

        if (!listaClientes && data) {
          setListaClientes(formatModelOrdenes(data));
        } else {
          const datosUsuario = formatModelOrdenes(data);
          if (tipo == "mesa de control" || tipo == "administrador") {
            funcArrUsers(datosUsuario);
          } else if (tipo == "almacen") {
            const filteredUsers = datosUsuario.filter(
              (user) =>
                user.tipo == "cProduccion" ||
                user.tipo == "departamento" ||
                user.tipo == tipo
            );
            funcArrUsers(filteredUsers);
          } else if (tipo == "departamento") {
            const filteredUsers = datosUsuario.filter(
              (user) =>
                user.tipo == tipo ||
                user.tipo == "cProduccion" ||
                user.tipo == "almacen"
            );
            funcArrUsers(filteredUsers);
          } else if (tipo == "cProducccion") {
            const filteredUsers = datosUsuario.filter(
              (user) =>
                user.tipo == tipo ||
                user.tipo == "administrador" ||
                user.tipo == "mesa de control" ||
                user.tipo == "departamento" ||
                user.tipo == "almacen"
            );
            funcArrUsers(filteredUsers);
          } else if (tipo == "contabilidad") {
            const filteredUsers = datosUsuario.filter(
              (user) =>
                user.tipo == tipo ||
                user.tipo == "administrador" ||
                user.tipo == "mesa de control"
            );
            funcArrUsers(filteredUsers);
          } else if (tipo == "doctor" || tipo == "mensajero") {
            const filteredUsers = datosUsuario.filter(
              (user) => user.tipo == "mesa de control"
            );
            funcArrUsers(filteredUsers);
          }
        }
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
      });
  };

  useEffect(() => {
    obtenerUsuariosInternos();
  }, [tipo]);

  const funcArrUsers = (datosUsuario) => {
    const newArray = datosUsuario.map(
      ({ email, nombre, departamento, tipo }) => ({
        value: email + "|" + nombre,
        label: nombre + " - " + (departamento ? departamento : tipo),
      })
    );

    setListaClientes(newArray);
  };

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    hora = hora < 10 ? `0${hora}` : hora;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };

  const [mensajeText, setMensajeText] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!mensajeText || !destinatario) {
      toast.warning("Completa el formulario");
    } else {
      try {
        const dataTemp2 = {
          idChat: destinatario.split("|")[0] + ", " + email,
          nombreUsuario: nombre,
          correoUsuario: email,
          tipoMensaje: "text",
          fechaHora: obtenerFechaActual(),
          mensaje: mensajeText,
          nombreIdChat: destinatario.split("|")[1],
          estado: "0",
        };
        registraMensajeria(dataTemp2).then((response) => {
          const { data } = response;
          setMensajeText("");
          setShow(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // useEffect(() => {
  //   if (tipo == "doctor" || tipo == "consultorio") {
  //     setdestinatario("hcd.oficinaherfran@gmail.com" + "|" + nombre);
  //   }
  // }, [tipo]);

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            xs={12}
            md={3}
            lg={3}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Destinatario:</Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Select
              options={listaClientes}
              isSearchable={true}
              placeholder={"Seleccionar..."}
              onChange={(selectedOption) =>
                handleSeleccionChange(selectedOption)
              }
            />
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            md={3}
            lg={3}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Mensaje:</Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              as="textarea"
              placeholder="Escribe tu mensaje"
              value={mensajeText}
              onChange={(e) => setMensajeText(e.target.value)}
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-center mt-4">
        <Button variant="success" onClick={sendMessage}>
          <FontAwesomeIcon icon={faPaperPlane} /> Enviar
        </Button>
      </div>
    </div>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      email: data.email,
      tipo: data.tipo,
      departamento: data.departamento,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default NuevoMensaje;
