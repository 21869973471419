import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMaquinas,
    ENDPOINTObtenerMaquinas,
    ENDPOINTListarMaquinas,
    ENDPOINTActualizarMaquinas,
    ENDPOINTEliminarMaquinas,
    ENDPOINTDeshabilitarMaquinas
} from "./endpoints";
import axios from 'axios';

export async function listarMaquinas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMaquinas, config);
}

export async function registraMaquinas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMaquinas, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMaquinas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMaquinas + `/${params}`, config);
}

export async function actualizarMaquinas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMaquinas + `/${id}`, data, config);
}

export async function eliminarMaquinas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMaquinas + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMaquinas(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMaquinas + `/${id}`, data, config);
}