import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import TablaPagosOrdenes from "../../ordenServicio/pagosOrdenesM/TablaPagosOrdenes";
import TablaPOS from "./TablaPOS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faThumbsUp } from "@fortawesome/free-solid-svg-icons";

const PrincipalPO = () => {
  return (
    <div>
      <Tabs
        defaultActiveKey="home"
        id="fill-tab-example"
        className="mb-3"
        fill
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          marginTop: "1%",
        }}
      >
        <Tab eventKey="home" title={
          <span style={{ textTransform: "capitalize" }}>
            <FontAwesomeIcon icon={faClock} /> Pendientes por pagar
          </span>
        }>
          <TablaPOS />
        </Tab>
        <Tab
          eventKey="profile"
          title={
            <span style={{ textTransform: "capitalize" }}>
              <FontAwesomeIcon icon={faThumbsUp} /> Pagos aprobados
            </span>
          }
        >
          <TablaPagosOrdenes />
        </Tab>
      </Tabs>
    </div>
  );
};

export default PrincipalPO;
