import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarOrdenCompra,
    ENDPOINTObtenerOrdenCompra,
    ENDPOINTListarOrdenCompra,
    ENDPOINTActualizarOrdenCompra,
    ENDPOINTEliminarOrdenCompra,
    ENDPOINTDeshabilitarOrdenCompra,
    ENDPOINTObtenerFolioOrdenCompra,
    ENDPOINTListarOrdenesCompraRecibidas,
} from "./endpoints";
import axios from 'axios';

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroOrdenCompra() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioOrdenCompra, config);
}

export async function listarOrdenCompra(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenCompra, config);
}

export async function listarOrdenCompraRecibidas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesCompraRecibidas, config);
}

export async function registraOrdenCompra(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarOrdenCompra, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerOrdenCompra(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerOrdenCompra + `/${params}`, config);
}

export async function actualizarOrdenCompra(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarOrdenCompra + `/${id}`, data, config);
}

export async function eliminarOrdenCompra(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarOrdenCompra + `/${id}`, data, config);
}

export async function actualizaDeshabilitarOrdenCompra(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarOrdenCompra + `/${id}`, data, config);
}