import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Load } from "../load/load";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { listarDepartamentosODT } from "../../api/departamentosODT";
import { Form, Row, Col } from "react-bootstrap";

function TblTiempoMuertoDepartamento(props) {
  const { location } = props;

  const [loading, setLoading] = useState(true);
  const [listarDoctor, setListDoctor] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const obtenerDoctor = async () => {
    try {
      const response = await listarDepartamentosODT();
      const { data } = response;
      if (data) {
        // Pasar las fechas de filtro a formatModelOrdenes
        const formattedData = formatModelOrdenes(data, fechaInicio, fechaFin);
        setListDoctor(formattedData);
        setFilteredData(formattedData); // Initialize filtered data
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    obtenerDoctor();
  }, [location, fechaInicio, fechaFin]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
    {
      name: "tiempoMuerto",
      label: "TOTAL DE TIEMPO MUERTO",
      options: {
        customBodyRender: (value) => {
          const numericValue = Number(value);
          return (
            <>
              {isNaN(numericValue) ? 0 : numericValue.toFixed(2)} horas
            </>
          );
        },
      },
    },
    {
      name: "tiempoProductivo",
      label: "TOTAL DE TIEMPO PRODUCTIVO",
      options: {
        customBodyRender: (value) => {
          const numericValue = Number(value);
          return (
            <>
              {isNaN(numericValue) ? 0 : numericValue.toFixed(2)} horas
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown ? 'standard' : 'scroll',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Tiempos por departamento</h1>
      <div className="date-filters">
        <Row className="date-filters">
          <Col xs={6}>
            <Form.Label>
              Fecha Inicio:
            </Form.Label>
            <Form.Control
              type="date"
              value={fechaInicio}
              onChange={(e) => {
                const newStartDate = e.target.value;
                setFechaInicio(newStartDate);
                const formattedData = formatModelOrdenes(listarDoctor, newStartDate, fechaFin);
                setFilteredData(formattedData);
              }}
            />
          </Col>
          <Col xs={6}>
            <Form.Label>
              Fecha Fin:
            </Form.Label>
            <Form.Control
              type="date"
              value={fechaFin}
              onChange={(e) => {
                const newEndDate = e.target.value;
                setFechaFin(newEndDate);
                const formattedData = formatModelOrdenes(listarDoctor, fechaInicio, newEndDate);
                setFilteredData(formattedData);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Tiempos por departamentos"}
          data={filteredData}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

function formatModelOrdenes(data, fechaInicio, fechaFin) {
  const filterData = (data, fechaInicio, fechaFin) => {
    return data.filter(item => {
      const itemFechaInicio = item.fechaInicio?.split(" ")[0];
      const itemFechaTermino = item.fechaTermino?.split(" ")[0];
      let include = true;

      if (fechaInicio) {
        const startDate = fechaInicio;
        include = include && itemFechaInicio == startDate;
      }

      if (fechaFin) {
        const endDate = fechaFin;
        include = include && itemFechaTermino == endDate;
      }

      return include;
    });
  };

  const filteredData = filterData(data, fechaInicio, fechaFin);

  const formattedData = filteredData.map((item) => {
    const fechaEntrada = new Date(item.fechaEntrada);
    const fechaInicio = new Date(item.fechaInicio);
    const fechaTermino = new Date(item.fechaTermino);
    const fechaSalida = new Date(item.fechaSalida);

    const diferenciaHoras = (fecha1, fecha2) => {
      return Math.abs((fecha2 - fecha1) / (1000 * 60 * 60));
    };

    const tiempoMuerto = diferenciaHoras(fechaEntrada, fechaInicio) + diferenciaHoras(fechaTermino, fechaSalida);
    const tiempoProductivo = diferenciaHoras(fechaInicio, fechaTermino);

    return {
      id: item._id,
      folioODT: item.folioODT,
      departamento: item.departamento,
      fechaEntrada: item.fechaEntrada,
      fechaInicio: item.fechaInicio,
      fechaTermino: item.fechaTermino,
      fechaSalida: item.fechaSalida,
      estado: item.estado,
      tiempoMuerto: tiempoMuerto,
      tiempoProductivo: tiempoProductivo,
    };
  });

  const groupedData = formattedData.reduce((acc, curr) => {
    const existing = acc.find((item) => item.departamento === curr.departamento);
    if (existing) {
      existing.tiempoMuerto += curr.tiempoMuerto;
      existing.tiempoProductivo += curr.tiempoProductivo;
    } else {
      acc.push({
        departamento: curr.departamento,
        tiempoMuerto: curr.tiempoMuerto,
        tiempoProductivo: curr.tiempoProductivo,
      });
    }
    return acc;
  }, []);

  return groupedData;
}

export default TblTiempoMuertoDepartamento;
