import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarAlmacenProductosLimpieza,
    ENDPOINTObtenerAlmacenProductosLimpieza,
    ENDPOINTListarAlmacenProductosLimpieza,
    ENDPOINTListarAlmacenProductosLimpiezaProveedor,
    ENDPOINTListarPaginandoAlmacenProductosLimpieza,
    ENDPOINTTotalAlmacenProductosLimpieza,
    ENDPOINTActualizarAlmacenProductosLimpieza,
    ENDPOINTEliminarAlmacenProductosLimpieza,
    ENDPOINTDeshabilitarAlmacenProductosLimpieza,
    ENDPOINTObtenerInsumosPorNombre,
    ENDPOINTBuscarInsumosPorIdsAPL
} from "./endpoints";
import axios from 'axios';

export async function listarAlmacenProductosLimpieza(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAlmacenProductosLimpieza, config);
}

export async function listarAlmacenProductosLimpiezaProveedores(proveedor) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAlmacenProductosLimpiezaProveedor + `?departamento=${proveedor}`, config);
}

export async function listarPaginandoAlmacenProductosLimpieza(pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPaginandoAlmacenProductosLimpieza +
        `/?pagina=${pagina}&&limite=${limite}`
  , config);
}

export async function totalAlmacenProductosLimpieza() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalAlmacenProductosLimpieza, config);
}

export async function registraAlmacenProductosLimpieza(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarAlmacenProductosLimpieza, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerAlmacenProductosLimpieza(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerAlmacenProductosLimpieza + `/${params}`, config);
}

export async function actualizarAlmacenProductosLimpieza(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarAlmacenProductosLimpieza + `/${id}`, data, config);
}

export async function eliminarAlmacenProductosLimpieza(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarAlmacenProductosLimpieza + `/${id}`, data, config);
}

export async function actualizaDeshabilitarAlmacenProductosLimpieza(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarAlmacenProductosLimpieza + `/${id}`, data, config);
}

export async function listarAlmacenProductosLimpiezaNombre(searchTerm, pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    };

    return await axios.get(API_HOST + ENDPOINTObtenerInsumosPorNombre + `/?searchTerm=${searchTerm}&&pagina=${pagina}&&limite=${limite}`, config);
}

export async function buscarAlmacenInsumosIds(arrayIds) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTBuscarInsumosPorIdsAPL + `?ids=${arrayIds}`, config);
}