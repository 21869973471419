import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import {
  registraEstablecimientos,
  obtenerNumeroEstablecimiento,
} from "../../../api/establecimientos";
import { subeArchivosCloudinary } from "../../../api/cloudinary";

export default function AvisoPrivacidad({ setShow }) {
  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");

  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [password, setPassword] = useState("");

  const generateRandomPassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
    let newPassword = "";

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
  };

  useEffect(() => {
    generateRandomPassword();
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroEstablecimiento()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noConsultorio } = data;
          setFolioActual(noConsultorio);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.razonSocial || !formData.email) {
      toast.warning("Completa el formulario");
    } else {
      try {
        if (imagenProducto) {
          subeArchivosCloudinary(imagenProducto, "consultorios")
            .then((response) => {
              const { data } = response;
              setLoading(true);

              const dataTemp = {
                folio: folioActual,
                razonSocial: formData.razonSocial,
                estadoEstablecimiento: estadoPais,
                municipio: municipioSeleccionado,
                colonia: formData.colonia,
                calle: formData.calle,
                numeroExterior: formData.numeroExterior,
                numeroInterior: formData.numeroInterior,
                codigoPostal: formData.codigoPostal,
                telefono: formData.telefono,
                haceEsquina: formData.haceEsquina,
                email: formData.email,
                observaciones: formData.observaciones,
                horario: formData.horario,
                estado: "true",
                alias: formData.alias,
                imagen: data.secure_url,
              };
              registraEstablecimientos(dataTemp).then((response) => {
                const { data } = response;

                toast.success(data.mensaje);

                setLoading(false);
                //cancelarRegistro()
              });
            })
            .then((e) => {
              console.log(e);
            });
        } else {
          setLoading(true);

          const dataTemp = {
            folio: folioActual,
            razonSocial: formData.razonSocial,
            estadoEstablecimiento: estadoPais,
            municipio: municipioSeleccionado,
            colonia: formData.colonia,
            calle: formData.calle,
            numeroExterior: formData.numeroExterior,
            numeroInterior: formData.numeroInterior,
            codigoPostal: formData.codigoPostal,
            telefono: formData.telefono,
            haceEsquina: formData.haceEsquina,
            email: formData.email,
            observaciones: formData.observaciones,
            horario: formData.horario,
            estado: "true",
            alias: formData.alias,
            imagen: "",
          };
          registraEstablecimientos(dataTemp).then((response) => {
            const { data } = response;

            toast.success(data.mensaje);

            setLoading(false);
            //cancelarRegistro()
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div>
        <div
          style={{
            margin: "20px",
            fontFamily: "Arial, sans-serif",
            textAlign: "justify",
          }}
        >
          <h2
            style={{
              color: "#2F4F4F",
              textAlign: "center",
              marginBottom: "2%",
            }}
          >
            Aviso de Privacidad
          </h2>
          <p>
            En <strong>Grupo Dental Herfran</strong>, con domicilio en El Roble,
            #38, Colonia Santa Cruz Nieto, CP 76804, San Juan del Río, Qro., y
            con correo electrónico{" "}
            <a href="mailto:mesadecontrol@grupodentalherfran.com">
              mesadecontrol@grupodentalherfran.com
            </a>
            , estamos comprometidos con la protección de tus datos personales.
          </p>
          <p>
            Este Aviso de Privacidad describe cómo recopilamos y usamos, no
            divulgamos y si protegemos tu información personal.
          </p>
          <h5 style={{ color: "#696969" }}>1. Información que Recopilamos</h5>
          <p>
            Recopilamos información personal que nos proporcionas directamente,
            como:
          </p>
          <ul>
            <li>Nombre</li>
            <li>Dirección de correo electrónico</li>
            <li>Número de teléfono</li>
            <li>Domicilio</li>
            <li>Datos de Facturación</li>
          </ul>
          <p>
            También posteriormente al crear una cuenta de usuario con nosotros
            te solicitaremos cierta información sobre tu dispositivo incluyendo:
          </p>
          <ul>
            <li>Información de pago</li>
            <li>Domicilios para recolecciones y entregas</li>
            <li>Horarios laborables</li>
          </ul>
          <h5 style={{ color: "#696969" }}>2. Uso de la Información</h5>
          <p>Utilizamos la información que recopilamos para:</p>
          <ul>
            <li>Proporcionar, operar y mantener nuestro sitio web</li>
            <li>Mejorar, personalizar y expandir nuestros servicios</li>
            <li>
              Desarrollar nuevos productos, servicios, características y
              funcionalidades
            </li>
            <li>
              Comunicarnos contigo, ya sea directamente o a través de uno de
              nuestros ejecutivos, incluyendo para atención al cliente, para
              proporcionarte actualizaciones y otra información relacionada con
              el sitio web, y para fines de marketing y promoción
            </li>
          </ul>
          <h5 style={{ color: "#696969" }}>3. Compartir tu Información</h5>
          <p>
            No compartimos tus datos personales con terceros, excepto en las
            siguientes circunstancias:
          </p>
          <ul>
            <li>Con tu consentimiento explícito</li>
            <li>Para cumplir con una obligación legal</li>
            <li>Para proteger y defender nuestros derechos o propiedad</li>
            <li>
              Para prevenir o investigar posibles infracciones en conexión con
              el sitio web
            </li>
            <li>
              Con proveedores de servicios que realizan servicios en nuestro
              nombre (por ejemplo, servicios de pago y procesamiento de datos)
            </li>
          </ul>
          <h5 style={{ color: "#696969" }}>4. Seguridad de la Información</h5>
          <p>
            Implementamos medidas de seguridad técnicas y organizativas para
            proteger tus datos personales contra el acceso no autorizado, la
            destrucción, la pérdida, la alteración o la divulgación. Sin
            embargo, ten en cuenta que ninguna transmisión de datos por Internet
            es completamente segura.
          </p>
          <h5 style={{ color: "#696969" }}>5. Tus Derechos</h5>
          <p>Tienes derecho a:</p>
          <ul>
            <li>Acceder a tus datos personales que tenemos</li>
            <li>
              Solicitar la corrección de tus datos personales si están
              incorrectos o desactualizados
            </li>
            <li>Solicitar la eliminación de tus datos personales</li>
            <li>Oponerte al procesamiento de tus datos personales</li>
          </ul>
          <p>
            Para ejercer estos derechos, puedes contactarnos en{" "}
            <a href="mailto:mesadecontrol@grupodentalherfran.com">
              mesadecontrol@grupodentalherfran.com
            </a>
            .
          </p>
          <h5 style={{ color: "#696969" }}>
            6. Cambios al Aviso de Privacidad
          </h5>
          <p>
            Nos reservamos el derecho de actualizar este Aviso de Privacidad en
            cualquier momento. Cualquier cambio será publicado en esta página, y
            te notificaremos a través de nuestro sitio web o por correo
            electrónico, si es apropiado.
          </p>
          <h5 style={{ color: "#696969" }}>7. Contacto</h5>
          <p>
            Si tienes alguna pregunta o inquietud sobre este Aviso de Privacidad
            o nuestras prácticas de privacidad, por favor, contacta con nosotros
            en mesadecontrol@grupodentalherfran.com.
          </p>
        </div>
        <Button
          variant="success"
          onClick={() => {
            setShow(false);
          }}
        >
          Aceptar
        </Button>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    razonSocial: "",
    estadoEstablecimiento: "",
    municipio: "",
    colonia: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "",
    codigoPostal: "",
    telefono: "",
    haceEsquina: "",
    email: "",
    horario: "",
    observaciones: "",
    alias: "",
  };
}
