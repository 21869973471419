import React, { useEffect, useState } from "react";
import "./FloatingNotificationButton.css"; // Importa tu archivo CSS para estilos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { listarValesEstado } from "../../../api/vales";
import TablaMaterialesValePendientes from "../../material/TablaPendientes";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import sonidoNot from "../../../assets/sound/delay.mp3";

const FloatingNotificationButton = () => {
  const [showNotifications, setShowNotifications] = useState(true);

  const [listVales, setListVales] = useState();

  const obtenerVales = () => {
    try {
      listarValesEstado()
        .then((response) => {
          const { data } = response;
          if (!listVales && data) {
            setListVales(formatModelVales(data).length);
          } else {
            const datosVales = formatModelVales(data);
            setListVales(datosVales.length);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerVales();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);
  const [size, setSizeModal] = useState(null);

  const registroVale = (content) => {
    setTitulosModal("Vales pendientes por atender");
    setContentModal(content);
    setShowModal(true);
  };

  useEffect(() => {
    // Función para reproducir el sonido de notificación si listVales es mayor que 0
    const reproducirSonido = () => {
      if (listVales > 0) {
        // Obtener el audio
        const audio = new Audio(sonidoNot);
        // Reproducir el sonido
        audio.play();
      }
    };

    // Esperar 10 segundos antes de ejecutar la función por primera vez
    const inicializar = setTimeout(() => {
      reproducirSonido();
      // Establecer el intervalo para ejecutar la función cada 15 minutos
      const intervalo = setInterval(reproducirSonido, 15 * 60 * 1000); // 15 minutos en milisegundos
      // Devolver una función de limpieza para detener el intervalo cuando el componente se desmonte o la dependencia cambie
      return () => clearInterval(intervalo);
    }, 10 * 3000); // 10 segundos en milisegundos

    // Devolver una función de limpieza para cancelar el setTimeout si el componente se desmonta o la dependencia cambia
    return () => clearTimeout(inicializar);
  }, [listVales]); 

  return (
    <div className="floating-notification-container">
      <button
        className={
          "floating-notification-button " +
          (listVales === 0 ? "btn-success" : "btn-danger") +
          (listVales > 0 ? " icon-move" : "") // Agregar clase de movimiento si listVales > 0
        }
        onClick={() =>
          registroVale(<TablaMaterialesValePendientes setShow={setShowModal} />)
        }
        style={{ fontSize: "26px" }}
      >
        <span>
          <FontAwesomeIcon icon={faBell} />
        </span>
        {showNotifications && (
          <div style={{ fontSize: "14px" }} className="notification-popup">
            {listVales} vales registrados requieren atención
          </div>
        )}
      </button>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </div>
  );
};

function formatModelVales(data) {
  const dataTemp = [];
  data.forEach((item) => {
    const materialProductos = item.material
      .map((material, index) => `${material.cantidad}-${material.producto}`)
      .join("  ➡️  ");

    dataTemp.push({
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      material: materialProductos,
      area: item.area,
      recibio: item.recibio,
      autorizo: item.autorizo,
      entrego: item.entrego,
      estado: item.estado,
    });
  });
  return dataTemp;
}

export default FloatingNotificationButton;
