import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
  faChartSimple,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { listarComedor } from "../../api/comedor";
import {
  Button,
  Dropdown,
  Badge,
  Image,
  Row,
  Table,
  Col,
  Tab,
  Tabs,
  Form,
} from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import RegistroComedor from "./comedor";
import ModificarComedor from "./ModificarComedor";
import EliminarComedor from "./EliminarComedor";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import RegistroES from "../finanzas/caja/RegistroES";
import { listarCajaChica } from "../../api/cajaChica";
import DynamicChart from "../administrador/Dashboard/grafica/Grafica";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblComedor(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroViajes = (content) => {
    setTitulosModal("Registar gasto");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarViajes = (content) => {
    setTitulosModal("Modificar comedor");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarViajes = (content) => {
    setTitulosModal("Eliminar comedor");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },

    {
      name: "fechayHora",
      label: "FECHA Y HORA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "tipo",
      label: "TIPO DE OPERACIÓN",
    },
    {
      name: "concepto",
      label: "CONCEPTO",
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "comprobante",
      label: "COMPROBANTE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);

          if (isValidURL) {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                Clic para ver comprobante
              </a>
            );
          } else {
            return "No hay comprobante para visualizar";
          }
        },
      },
    },
    {
      name: "tipoGasto",
      label: "TIPO GASTO",
    },
    {
      name: "idUsuario",
      label: "REALIZÓ OPERACION",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Activa";
            estadoTexto = "Activa";
          } else if (estado == "2") {
            estiloTexto = "Pendiente";
            estadoTexto = "Pendiente";
          } else {
            estiloTexto = "Cancelado";
            estadoTexto = "Cancelado";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "1"
                    ? "success"
                    : estado == "2"
                    ? "warning"
                    : "danger"
                }
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const [listRegistros, setListRegistros] = useState([]);

  const obtenerReq = () => {
    try {
      listarCajaChica()
        .then((response) => {
          const { data } = response;
          if (!listRegistros && data) {
            setListRegistros(formatModelESCaja(data));
          } else {
            setListRegistros(formatModelESCaja(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (!showModal) {
      obtenerReq();
    }
  }, [showModal]);

  const [sumEntradas, setSumEntradas] = useState(0);
  const [sumSalidas, setSumSalidas] = useState(0);
  const [balance, setBalance] = useState(0);
  const [listComedor, setListComedor] = useState([]);

  useEffect(() => {
    const filterCom = listRegistros.filter(
      (data) => data.concepto === "Comedor"
    );
    setListComedor(filterCom);

    let sumaSalidas = 0;
    let sumaEntradas = 0;

    listRegistros.forEach((registro) => {
      if (registro.tipo === "Salida" && registro.estado == "1") {
        sumaSalidas += parseFloat(registro.cantidad);
      } else if (registro.tipo === "Entrada" && registro.estado == "1") {
        sumaEntradas += parseFloat(registro.cantidad);
      }
      setSumEntradas(sumaEntradas);
      setSumSalidas(sumaSalidas);
      setBalance(sumaEntradas - sumaSalidas);
    });
  }, [listRegistros]);

  const [fechasGraficas, setFechasGraficas] = useState([]);
  const [cantidadesGraficas, setCantidadesGraficas] = useState([]);

  useEffect(() => {
    let fechas = listComedor.map((item) => item.fecha);
    let cantidades = listComedor.map((item) => parseFloat(item.cantidad));
    setCantidadesGraficas(cantidades);
    setFechasGraficas(fechas);
  }, [listComedor]);

  const [cantidadesSemanas, setcantidadesSemanas] = useState([]);
  const [semanas, setSemanas] = useState([]);

  useEffect(() => {
    let gruposPorSemana = {};

    listComedor.forEach((item) => {
      let semana = item.semana;
      if (!gruposPorSemana[semana]) {
        gruposPorSemana[semana] = [];
      }
      gruposPorSemana[semana].push(item);
    });

    let sumasPorSemana = [];

    for (let semana in gruposPorSemana) {
      let suma = gruposPorSemana[semana].reduce(
        (total, item) => total + Number(item.cantidad),
        0
      );
      sumasPorSemana[semana] = suma;
    }
    let semanas = Object.keys(sumasPorSemana);
    let cantidades = Object.values(sumasPorSemana);

    setSemanas(semanas);
    setcantidadesSemanas(cantidades);
  }, [listComedor]);

  const cellStyles = {
    backgroundColor: "#9c182d",
    color: "#fff",
    fontWeight: "bold",
  };

  const cellStyles2 = {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  return (
    <>
      {loading && <Load />}
      <h1>Gastos comedor</h1>
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
        justify
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          marginTop: "1%",
        }}
      >
        <Tab
          eventKey="home"
          title={
            <span style={{ textTransform: "capitalize" }}>
              <FontAwesomeIcon icon={faTable} /> Tabla
            </span>
          }
        >
          <div>
            <div>
              <div className="divButton">
                <Button
                  className="btnAddTables"
                  onClick={() =>
                    registroViajes(
                      <RegistroES
                        history={history}
                        setShow={setShowModal}
                        balance={balance}
                        origen={"comedor"}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> Agregar
                </Button>
              </div>
            </div>
            <div className="divTabla">
              <MUIDataTable
                title={"Lista de gastos registrados"}
                data={listComedor}
                columns={columns}
                options={options}
              />
              <Row className="mt-2 mt-md-2 mt-lg-2">
                <Col md={{ span: 4, offset: 8 }}>
                  <Table>
                    <tbody>
                      <tr>
                        <td style={cellStyles}>En Caja:</td>
                        <td style={cellStyles2}>{formatCurrency(balance)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="profile"
          title={
            <span style={{ textTransform: "capitalize" }}>
              <FontAwesomeIcon icon={faChartSimple} /> Gráficas
            </span>
          }
        >
          <div>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <div style={{ maxHeight: "50vh" }}>
                  {(cantidadesGraficas && fechasGraficas) ? (
                    <DynamicChart
                      frecuencias={cantidadesGraficas}
                      etiquetas={fechasGraficas}
                      chartType={"bar"}
                      titInfo={"Gastos por día"}
                      bgColor={"#9c182d"}
                      chartTitle={"Gastos de comedor por día"}
                    />
                  ) : (
                    <p>Cargando...</p>
                  )}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div style={{ maxHeight: "50vh" }}>
                  {cantidadesSemanas && semanas ? (
                    <DynamicChart
                      frecuencias={cantidadesSemanas}
                      etiquetas={semanas}
                      chartType={"bar"}
                      titInfo={"Gastos por semana"}
                      bgColor={"#0c1b30"}
                      chartTitle={"Gastos del comedor por semana"}
                    />
                  ) : (
                    <p>Cargando...</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Tab>
      </Tabs>

      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelViaje(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      fecha: data.fecha,
      monto: data.monto,
      cotizacion: data.cotizacion,
      tipo: data.tipo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelESCaja(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      idUsuario: data.idUsuario.split("/")[0],
      fechayHora: data.fechayHora,
      concepto: data.concepto,
      tipo: data.tipo,
      cantidad: data.cantidad,
      comprobante: data.comprobante,
      tipoGasto: data.tipoGasto,
      fecha: data.fecha,
      semana: data.semana,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblComedor);
