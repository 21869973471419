import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { registraMesas } from "../../../api/mesas";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChair } from "@fortawesome/free-solid-svg-icons";

const RegistroMesas = ({ setShow }) => {
  const [numeroMesa, setNumeroMesa] = useState("");
  const [numeroPersonas, setNumeroPersonas] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [arreglo, setArreglo] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (!numeroMesa || !numeroPersonas || !descripcion) {
        toast.warning("Completa el formulario");
      } else {
        const dataTemp = {
          numeroMesa: numeroMesa,
          descripcion: descripcion,
          numeroPersonas: numeroPersonas,
          lugares: arreglo,
          estado: "0",
        };
        registraMesas(dataTemp).then((response) => {
          const { data } = response;
          console.log(data);
          toast.success(data.mensaje);
          setShow(false);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const generarArreglo = (cantidad) => {
    let nuevoArreglo = [];
    for (let i = 1; i <= cantidad; i++) {
      nuevoArreglo.push({
        estado: "0",
        silla: i.toString(),
      });
    }
    setArreglo(nuevoArreglo);
  };

  useEffect(() => {
    if (numeroPersonas) {
      generarArreglo(numeroPersonas);
    }
  }, [numeroPersonas]);

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Nombre de mesa:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              value={numeroMesa}
              onChange={(e) => setNumeroMesa(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Numero de personas:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              value={numeroPersonas}
              onChange={(e) => setNumeroPersonas(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          ></Col>
          <Col sm={12} md={8} lg={8}>
            {arreglo.length > 0 &&
              numeroPersonas > 0 &&
              arreglo.map((silla, index) => <FontAwesomeIcon icon={faChair} />)}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Descripción:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="textarea"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Button variant="success" type="submit">
            <i className="fa fa-solid fa-check" /> Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RegistroMesas;
