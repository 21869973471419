import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCog
} from "@fortawesome/free-solid-svg-icons";
import { listarServiciosDepartamento } from "../../api/servicios";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "./style.css";
import TransferirDepartamento from "./TransferirDepartamento";

function TablaServiciosDepartamentos(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
          setDepartamentoUsuario(data.departamento)
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      listarServiciosDepartamento(departamentoUsuario)
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location, departamentoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroOrden = (content) => {
    setTitulosModal("Registrar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarOrden = (content) => {
    setTitulosModal("Modificar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const verOrden = (content) => {
    setTitulosModal("Detalles de la orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarOrden = (content) => {
    setTitulosModal("Eliminar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarOrden = (content) => {
    setTitulosModal("Cancelar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const transferirOrden = (content) => {
    setTitulosModal("Transferir orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const transferirDepartamento = (content) => {
    setTitulosModal("Transferir servicio a otro departamento");
    setContentModal(content);
    setShowModal(true);
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [idOrdenSel, setidOrdenSel] = useState("");

  const handleClick = (tableMeta) => {
    // Verifica si tableMeta es undefined antes de acceder a rowData
    if (tableMeta && tableMeta.rowData) {
      // Accede a la información de la fila actual
      const rowData = tableMeta.rowData;

      // Obtén el valor de la primera columna (supongamos que es la posición 0 del array)
      const valorPrimeraColumna = rowData[0];

      // Actualiza la variable con el valor de la primera columna
      setidOrdenSel(valorPrimeraColumna);

      // Puedes hacer cualquier otra lógica que necesites con el valor obtenido
      console.log("Valor de la primera columna:", valorPrimeraColumna);
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "ORDEN DE CLIENTE",
    },
    {
      name: "pieza",
      label: "DESCRIPCIÓN DE LA PIEZA",
    },
    {
      name: "clasificacion",
      label: "MATERIAL",
    },
    {
      name: "descripcionAdicional",
      label: "DESCRIPCION DE PROTESIS",
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Nuevo";
            estadoTexto = "Nuevo";
          } else if (estado == "2") {
            estiloTexto = "Asignado";
            estadoTexto = "Asignado";
          } else if (estado == "0") {
            estiloTexto = "Cancelado";
            estadoTexto = "Cancelado";
          }

          return <>
          <Badge
            bg={estado == "1" ? "info" : estado == "2" ? "warning" : estado == "0" ? "danger" : ""}
          //className="estado"
          >
            {estiloTexto}
          </Badge>
        </>;
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const estado = value;
          // En este método, puedes retornar el JSX del botón para cada fila de la tabla
          return tableMeta.rowData[6] != "0" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
                  id="dropdown-basic"
                >
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    variant="success"
                    title="Asignar"
                    onClick={() =>
                      transferirDepartamento(
                        <TransferirDepartamento
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                          history={history}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCog} /> Transferir servicio
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponible"
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",

    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      <div>
        {loading && <Load />}
        <h1>Ordenes de cliente</h1>
        <div className="divTabla">
          <MUIDataTable
            title={"Lista de servicios registrados"}
            data={listarOrdenes}
            columns={columns}
            options={options}
          />
        </div>
        <BasicModal
          show={showModal}
          setShow={setShowModal}
          title={titulosModal}
        >
          {contentModal}
        </BasicModal>
      </div>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      clasificacion: data.clasificacion,
      material: data.material,
      pieza: data.pieza,
      precio: data.precio,
      descripcionAdicional: data.descripcionAdicional,
      sistemaColor: data.sistemaColor,
      distencion: data.distencion,
      diente: data.diente,
      tonoInferior: data.tonoInferior,
      tonoMedio: data.tonoMedio,
      tonoSuperior: data.tonoSuperior,
      departamento: data.departamento,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TablaServiciosDepartamentos);
