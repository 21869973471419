import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { listarProductosAgotandose } from "../.././api/almacenMateriasPrimas";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import { Load } from "../load/load";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblProductosAgotandose(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      listarProductosAgotandose()
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroUsuarios = (content) => {
    setTitulosModal("Registar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarUsuarios = (content) => {
    setTitulosModal("Modificar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarUsuarios = (content) => {
    setTitulosModal("Eliminar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "clave",
      label: "CLAVE",
    },
    {
      name: "claveAlterna",
      label: "CLAVE ALTERNA",
    },
    {
      name: "producto",
      label: "PRODUCTO",
    },
    {
      name: "minimo",
      label: "MINIMO",
    },
    {
      name: "maximo",
      label: "MAXIMO",
    },
    {
      name: "existencia",
      label: "EXISTENCIA",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "entrada",
      label: "ENTRADA",
    },
    {
      name: "salida",
      label: "SALIDA",
    },
    {
      name: "devolucion",
      label: "DEVOLUCION",
    },
    {
      name: "existenciaReal",
      label: "EXISTENCIA REAL",
    },
    {
      name: "ubicacion",
      label: "UBICACION",
    },
    {
      name: "departamento",
      label: "PROVEEDOR",
    },
    {
      name: "categoria",
      label: "CATEGORIA",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Productos agotandose</h1>
      <div className="divTabla">
        <MUIDataTable
          title={"Listado de productos proximos agotarse agotarse"}
          data={listarOrdenes}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      claveAlterna: data.claveAlterna,
      producto: data.producto,
      minimo: data.minimo,
      maximo: data.maximo,
      existencia: data.existencia,
      unidadMedida: data.unidadMedida,
      entrada: data.entrada,
      salida: data.salida,
      devolucion: data.devolucion,
      existenciaReal:
        Number(data.existencia) +
        Number(data.entrada) +
        Number(data.devolucion) -
        Number(data.salida),
      ubicacion: data.ubicacion,
      departamento: data.departamento,
      categoria: data.categoria,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

export default withRouter(TblProductosAgotandose);
