import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import {
  obtenerAbonosPorFolioOS,
  registraAbonosOrdenes,
} from "../../../api/abonosOrdenes";
import { toast } from "react-toastify";
import { obtenerOrdenesServicioPorFolio } from "../../../api/ordenesServicio";
import { obtenerCyDPorFolioOS } from "../../../api/preciosOrden";
import { LogsInformativos } from "../../logs/logs";
import queryString from "query-string";

const PagosOrdenesM = ({ data, setShow, origen, history }) => {
  const [formaPago, setFormaPago] = useState("efectivo");
  console.log("data", data);
  console.log('origen', origen)
  const folioOS =
    origen == "tablaPagos" ? data[2] : origen === "cxc" ? data.folio : origen === "tablaEntregas" ? data[3] : data[1];
  const cargoOrden = origen == "tablaPagos" ? data[5] : origen === "cxc" ? data.cargoOrden : data[10];
  const [cargo, setCargo] = useState("");

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [precioOrden, setPrecioOrden] = useState(0);
  const [correoDoctor, setCorreoDoctor] = useState("");

  const obtenerOS = (folio) => {
    try {
      obtenerOrdenesServicioPorFolio(folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            setPrecioOrden(data[0].precioTotal);
            setCorreoDoctor(data[0].correoDoctor);
          } else {
            setPrecioOrden(0);
            setCorreoDoctor("");
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [abonosOrden, setAbonosOrden] = useState([]);

  const obtenerAbonosOS = (folio) => {
    try {
      obtenerAbonosPorFolioOS(folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            setAbonosOrden(formatModelAbonos(data));
          } else {
            setAbonosOrden([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [cydOrden, setCydOrden] = useState([]);

  const obtenerCydOS = (folio) => {
    try {
      obtenerCyDPorFolioOS(folio)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setCydOrden(formatModelCargosyDescuentos(data));
          } else {
            setCydOrden([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDatosUsuario();
    obtenerOS(folioOS);
    obtenerAbonosOS(folioOS);
    obtenerCydOS(folioOS);
  }, [folioOS]);

  const [sumaPagosAprobados, setSumaPagosAprobados] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [restante, setRestante] = useState(0);

  useEffect(() => {
    let totalCalculado = 0;
    cydOrden.forEach((item) => {
      // Suma o resta según la operación
      if (item.tipoOperacion === "descuento") {
        totalCalculado -= parseFloat(item.cantidad);
      } else {
        totalCalculado += parseFloat(item.cantidad);
      }
    });
    setTotalPagar(totalCalculado);
  }, [cydOrden]);

  useEffect(() => {
    let totalCalculadoRes = 0;
    abonosOrden.forEach((item) => {
      if (item.estado === "1") {
        totalCalculadoRes += parseFloat(item.cantidadRecibida || 0);
      }
    });
    setSumaPagosAprobados(totalCalculadoRes);
  }, [abonosOrden]);

  useEffect(() => {
    setRestante(parseFloat(totalPagar) - sumaPagosAprobados);
  }, [totalPagar, sumaPagosAprobados]);

  useEffect(() => {
    const resta =
      parseFloat(totalPagar) -
      sumaPagosAprobados -
      (cargo != "" ? parseFloat(cargo) : 0);
    setRestante(resta);
  }, [cargo, sumaPagosAprobados]);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos
  let fechaFormat = `${year}-${mes < 10 ? ("0" + mes) : mes}-${dia < 10 ? ("0" + dia) : dia}`;

  const guardarAbono = async (e) => {
    e.preventDefault();

    if (!cargo) {
      toast.warning("Completa el formulario");
    } else {
      if (parseFloat(cargo) > precioOrden) {
        console.log(parseFloat(cargo));
        console.log(parseFloat(precioOrden));
        toast.warning(
          "La cantidad recibida no puede ser mayor al precio total de la orden"
        );
      } else {
        try {
          const dataTemp = {
            ordenServicio: folioOS,
            cantidadRecibida: cargo,
            idRecibio: nombreUsuario,
            hizoCargo: "",
            aprobo: origen != "tablaPagos" ? "Pendiente" : nombreUsuario,
            estado: origen != "tablaPagos" ? "0" : "1",
            fecha: fechaFormat,
            emailCargo: origen === "tablaEntregas" ? correoDoctor : cargoOrden,
            metodoPago: formaPago
          };
          registraAbonosOrdenes(dataTemp).then((response) => {
            const { data } = response;
            LogsInformativos(
              "Se ha registrado el pago a la orden de cliente" +
              dataTemp.ordenServicio,
              dataTemp
            );
            toast.success(data.mensaje);
            setShow(false);
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  return (
    <div>
      <div>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Orden de cliente:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control type="text" value={folioOS} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Precio inicial:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={precioOrden} type="text" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Precio total por pagar:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={totalPagar} type="text" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad recibida:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={cargo}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    setCargo(inputValue);
                  }
                }}
                type="number"
                placeholder="Ingresa la cantidad recibida"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Restante:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={restante} type="text" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Forma de pago:
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              as="select"
              value={formaPago}
              onChange={(e) => setFormaPago(e.target.value)}
              placeholder={"Ingresa forma de pago"}
            >
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
              <option value="cheques">Cheques</option>
              <option value="depositos">Depositos</option>
            </Form.Control>
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Button variant="success" onClick={guardarAbono}>
            <FontAwesomeIcon icon={faCashRegister} /> Registrar pago
          </Button>
        </div>
      </div>
      <Row className="mt-2 mt-md-4 mt-lg-7">
        <Col>
          <h5
            className="mb-3"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Pagos realizados de la orden
          </h5>
          <Table
            striped
            bordered
            hover
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Cantidad</th>
                <th>Metodo de pago</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {abonosOrden.map((abono, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{abono.fecha}</td>
                  <td>{abono.cantidadRecibida}</td>
                  <td>{!abono.metodoPago ? "Efectivo" : abono.metodoPago}</td>
                  <td>{abono.estado == "0" ? "Por aprobar" : "Aprobado"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <h5
            className="mb-3"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Cargos y descuentos aplicados
          </h5>
          <Table
            striped
            bordered
            hover
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Concepto</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {cydOrden.map((cyd, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{cyd.tipoOperacion}</td>
                  <td>{cyd.concepto}</td>
                  <td>{cyd.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

function formatModelAbonos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      cantidadRecibida: data.cantidadRecibida,
      estado: data.estado,
      fecha: data.fecha,
      metodoPago: data.metodoPago
    });
  });
  return dataTemp;
}

function formatModelCargosyDescuentos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      tipoOperacion: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
    });
  });
  return dataTemp;
}

export default PagosOrdenesM;
