import { faCartPlus, faTrash, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { listarUnidadMedida } from "../../../api/unidadesMedida";
import { listarProveedores } from "../../../api/proveedores";
import Select from "react-select";
import {
  obtenerNumeroOrdenCompra,
  registraOrdenCompra,
} from "../../../api/ordenCompra";
import { LogsInformativos } from "../../logs/logs";
import {
  actualizaDeshabilitarRequisicionCompras,
  obtenerRequisicionCompras,
} from "../../../api/compras";
import { listarAlmacenMateriasPrimasProveedores } from "../../../api/almacenMateriasPrimas";

const RegistroOC = ({ data, setShow }) => {
  const dataTemp = data;
  const idReq = !dataTemp ? "" : dataTemp[0];
  const folioRequi = !dataTemp ? "" : dataTemp[1];
  const proveedorNombre = !dataTemp ? "" : dataTemp[6];

  const [folioProv, setfolioProv] = useState(!dataTemp ? "" : dataTemp[7]);

  const [arrProductos, setArrProductos] = useState([]);
  const [arrProductos2, setArrProductos2] = useState([]);

  const obtenerReq = (id) => {
    try {
      obtenerRequisicionCompras(id)
        .then((response) => {
          const { data } = response;
          if (!arrProductos && data) {
            setArrProductos(data.productos);
            setArrProductos2(data.productos);
          } else {
            setArrProductos(data.productos);
            setArrProductos2(data.productos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  console.log(arrProductos)

  const [prodProv, setProdProv] = useState([]);

  const obtenerProdsProv = (id) => {
    try {
      listarAlmacenMateriasPrimasProveedores(id)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!prodProv && data) {
            setProdProv(formatModelProductosProv(data));
          } else {
            setProdProv(formatModelProductosProv(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (dataTemp) {
      obtenerReq(dataTemp[0]);
      obtenerProdsProv(dataTemp[7]);
    }
  }, [dataTemp]);

  useEffect(() => {
    if (folioProv) {
      obtenerProdsProv(folioProv);
    }
  }, [folioProv]);

  /***********TABLA PROD**********/
  const [producto, setProducto] = useState("");
  const [uMedida, setUMedida] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [precioUnitario, setPrecioUnitario] = useState("");

  const agregarProducto = () => {
    if (!producto || !uMedida || !cantidad) {
      toast.warning("Datos incompletos");
    } else {
      const nuevoProducto = {
        producto: producto.split("|")[0],
        unidadMedida: uMedida,
        cantidad: cantidad,
        cantidadPedida: cantidad,
        pUnitario: precioUnitario,
        totalProducto: parseFloat(precioUnitario) * parseFloat(cantidad),
      };

      setArrProductos([...arrProductos, nuevoProducto]);
      setProducto("");
      setCantidad("");
      setUMedida("");
      setPrecioUnitario("");
    }
  };

  const handleQuit = (index) => {
    const newArray = [...arrProductos];
    newArray.splice(index, 1);
    setArrProductos(newArray);
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const [subtotal, setSubtotal] = useState(0);
  const [iva, setiva] = useState(0);
  const [totalOC, settotalOC] = useState(0);

  useEffect(() => {
    const sumaTotalOC = arrProductos.reduce(
      (totalProducto, venta) => totalProducto + parseFloat(venta.totalProducto),
      0
    );

    const ivaCalc = sumaTotalOC * 0.16;
    const totalCalc = sumaTotalOC + ivaCalc;

    setSubtotal(sumaTotalOC);
    setiva(ivaCalc);
    settotalOC(totalCalc);
  }, [arrProductos]);

  /**************************** */

  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroOrdenCompra()
        .then((response) => {
          const { data } = response;
          const { noOrden } = data;
          setFolioActual(noOrden);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const [listProveedores, setListProveedores] = useState([]);

  const obtenerProveedores = () => {
    try {
      listarProveedores()
        .then((response) => {
          const { data } = response;
          if (!listProveedores && data) {
            setListProveedores(formatModelProveedores(data));
          } else {
            setListProveedores(formatModelProveedores(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerProveedores();
  }, []);

  /********************************** */
  const [selectedOption, setSelectedOption] = useState("");
  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  useEffect(() => {
    setfolioProv(selectedOption.split("/")[0]);
  }, [selectedOption]);

  /********************************** */

  const [autoriza, setAutoriza] = useState("");
  const [elabora, setElabora] = useState("");

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let fechaFormat = `${year}-${mes}-${dia}`;

  const registrarOC = () => {
    try {
      const proveedor = selectedOption
        ? selectedOption.split("/")[1]
        : proveedorNombre;

      const verifica = selectedOption ? "0" : "1";
      console.log(selectedOption);
      console.log(verifica);
      console.log(proveedor);
      const dataTemp = {
        folio: folioActual,
        fecha: fechaFormat,
        datosLaboratorio: {
          razonSocial: "",
          rfc: "",
          direccion: "",
          telefono: "",
        },
        datosProveedor: {
          razonSocial: proveedor,
          rfc: "",
          direccion: "",
          telefono: "",
          email: "",
        },
        productos: arrProductos,
        subtotal: subtotal,
        iva: iva,
        total: totalOC,
        elaboro: elabora,
        autorizo: autoriza,
        estado: "2",
        estadoPago: "0",
      };
      registraOrdenCompra(dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        LogsInformativos(
          "Se ha registrado la orden de compra " + dataTemp.folio,
          dataTemp
        );
        if (verifica == "1") {
          actualizaReq();
        } else {
          setShow(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizaReq = () => {
    try {
      const dataTemp = {
        estado: "false",
        productos: arrProductos
      };

      actualizaDeshabilitarRequisicionCompras(idReq, dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          toast.success(data.mensaje);
          LogsInformativos(
            "Se ha actualizado el estado de la requisición" + folioRequi,
            dataTemp
          );
          setShow(false)
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setUMedida(producto.split("|")[1]);
    setCantidad(producto.split("|")[3]);
  }, [producto]);

  /************************************************ */
  const [showModal, setShowModal] = useState(false);
  const [cantidadTemp, setCantidadTemp] = useState(0);
  const [productoIndex, setProductoIndex] = useState(-1);
  const [productoNom, setProductoNom] = useState("");

  const handleOpenModal = (cantidad, index, nombre) => {
    setCantidadTemp(cantidad);
    setProductoIndex(index);
    setProductoNom(nombre)
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModificarCantidad = () => {
    // Actualizar la cantidad en el arreglo arrProductos
    const updatedProductos = [...arrProductos]; // Clonar el arreglo original
    updatedProductos[productoIndex].cantidad = cantidadTemp; // Actualizar la cantidad en el producto específico
    updatedProductos[productoIndex].cantidadPedida = cantidadTemp;
    // Aquí puedes hacer una llamada a una función para enviar los datos actualizados al servidor, si es necesario
    // Luego, actualiza el estado y cierra el modal
    setArrProductos(updatedProductos);
    handleCloseModal();
  };
  /************************************************ */

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Folio: <code>*</code></Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Folio de OC"
              value={folioActual}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Proveedor: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {!dataTemp ? (
              <Select
                options={listProveedores}
                isSearchable={true}
                placeholder="Seleccionar..."
                onChange={(selectedOption) =>
                  handleSeleccionChange(selectedOption)
                }
              />
            ) : (
              <Form.Control type="text" value={dataTemp[6]} disabled />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Elabora: <code>*</code></Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien elabora"
              value={elabora}
              onChange={(e) => setElabora(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Autoriza: <code>*</code></Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              placeholder="Nombre de quien autoriza"
              value={autoriza}
              onChange={(e) => setAutoriza(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col sm={12} md={2} lg={2}>
            <Form.Label>Producto: <code>*</code></Form.Label>
            <Form.Control
              as="select"
              value={producto}
              onChange={(e) => setProducto(e.target.value)}
            >
              <option value="">Seleccionar</option>
              {prodProv.map((prod, index) => (
                <option value={prod.value}>{prod.producto}</option>
              ))}
            </Form.Control>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <Form.Label>Unidad de medida:</Form.Label>
            <Form.Control type="text" value={uMedida} />
          </Col>
          <Col sm={12} md={2} lg={2}>
            <Form.Label>Cantidad: <code>*</code></Form.Label>
            <Form.Control
              type="text"
              value={cantidad}
              onChange={(e) => {
                let inputValue = e.target.value;
                if (/^\d*\.?\d*$/.test(inputValue)) {
                  setCantidad(inputValue);
                }
              }}
            />
          </Col>
          <Col sm={12} md={1} lg={1} className="d-flex align-items-end">
            <Button onClick={agregarProducto}>Agregar</Button>
          </Col>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Producto</th>
                <th>Unidad de medida</th>
                <th>Cantidad</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {arrProductos.map((producto, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{producto.producto}</td>
                  <td>{producto.unidadMedida}</td>
                  <td>{producto.cantidad}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleOpenModal(producto.cantidad, index, producto.producto)}
                      title="Modificar cantidad"
                    >
                      <FontAwesomeIcon icon={faHashtag} />
                    </Button>
                    &nbsp;
                    <Button variant="danger" onClick={() => handleQuit(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Modificar Cantidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formCantidad">
                <h4 style={{ textAlign: "center", fontStyle: "italic" }}>{productoNom}</h4>
                <Form.Label>Nueva Cantidad:</Form.Label>
                <Form.Control
                  type="number"
                  value={cantidadTemp}
                  onChange={(e) => setCantidadTemp(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleModificarCantidad}>
                Actualizar
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registrarOC}>
            <FontAwesomeIcon icon={faCartPlus} />
            &nbsp;Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

function formatModelUM(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelProductosProv(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      producto: data.producto,
      value:
        data.producto +
        "|" +
        data.unidadMedida +
        "|" +
        data.clave +
        "|" +
        data.minimo,
    });
  });
  return dataTemp;
}

const formatModelProveedores = (data) => {
  return data.map((proveedor) => ({
    value: proveedor.folio + "/" + proveedor.nombre,
    label: proveedor.nombre, // Puedes personalizar el formato del label según tus necesidades
  }));
};

export default RegistroOC;
