// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagenesServicioMaterial{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imagenesServicioMaterial > div {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(25% - 10px);
    margin: 5px;
    box-sizing: border-box;
  }`, "",{"version":3,"sources":["webpack://./src/components/ordenServicio/material/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B;IAC1B,WAAW;IACX,sBAAsB;EACxB","sourcesContent":[".imagenesServicioMaterial{\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n\n.imagenesServicioMaterial > div {\n    flex: 1 0 calc(25% - 10px);\n    margin: 5px;\n    box-sizing: border-box;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
