import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { registraUbicaciones } from "../../api/ubicaciones";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function Ubicaciones({ history, setShow }) {
    const [formData, setFormData] = useState(initialFormValue());

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.nombre) {
            toast.warning("Completa el formulario");
        } else {
            try {
                setLoading(true);

                const dataTemp = {
                    nombre: formData.nombre,
                    estado: "true"
                };
                registraUbicaciones(dataTemp).then((response) => {
                    const { data } = response;

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha registrado la ubicacion " + dataTemp.nombre, dataTemp)
                    setFormData(initialFormValue())
                    setLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Group>
                        <Form.Label>Nombre: <code>*</code> </Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Ingresa el nombre de la ubicacion'
                            defaultValue={formData.nombre}
                            name="nombre"
                        />
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    )
}

function initialFormValue() {
    return {
        nombre: "",
    };
}

