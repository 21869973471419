import { useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { listarServiciosODT } from '../../../api/servicios';

Chart.register(...registerables);

function GraficasTiempo() {
  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerServiciosRuta = async () => {
    try {
      const response = await listarServiciosODT();
      const { data } = response;
      if (data) {
        setListOrdenes(formatModelOrdenes(data));
      }
    } catch (e) {
      if (e.message === "Network Error") {
        toast.error("Conexión al servidor no disponible");
      } else {
        toast.error("Error al obtener los datos");
      }
    }
  };

  useEffect(() => {
    obtenerServiciosRuta();
  }, []);

  // Función para calcular la diferencia en minutos entre dos fechas
  const calcularDiferenciaMinutos = (fecha1, fecha2) => {
    const fechaInicio = new Date(fecha1);
    const fechaFin = new Date(fecha2);
    const diferencia = Math.abs(fechaFin - fechaInicio);
    return Math.ceil(diferencia / (1000 * 60)); // Convertir de milisegundos a minutos
  };

  // Filtrar y mapear etiquetas y datos
  const labels = listarOrdenes.map(item => item.folio); // Etiquetas con el campo 'folio'
  const dataValuesAsignacionInicio = listarOrdenes.map(item => 
    calcularDiferenciaMinutos(item.fechaAsignacion, item.fechaInicio)
  ); // Diferencia de minutos entre 'fechaAsignacion' y 'fechaInicio'
  const dataValuesInicioFin = listarOrdenes.map(item => 
    calcularDiferenciaMinutos(item.fechaInicio, item.fechaFin)
  ); // Diferencia de minutos entre 'fechaInicio' y 'fechaFin'

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Tiempo muerto",
        backgroundColor: "rgba(0, 0, 255, 0.6)",
        borderColor: "rgb(0, 0, 255)",
        borderWidth: 1,
        data: dataValuesAsignacionInicio,
        stack: 1,
      },
      {
        label: "Tiempo efectivo",
        backgroundColor: "rgba(255, 0, 0, 0.6)",
        borderColor: "rgb(255, 0, 0)",
        borderWidth: 1,
        data: dataValuesInicioFin,
        stack: 1,
      },
    ],
  };

  const opciones = {
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className='App' style={{ width: "100%", height: "500px" }}>
      <h2>Tabla minutos</h2>
      <Bar data={data} options={opciones} />
    </div>
  );
}

function formatModelOrdenes(data) {
  return data.map((item) => ({
    id: item._id,
    folio: item.folio,
    folioODS: item.ODT,
    fecha: item.fechaHora,
    pieza: item.pieza,
    sistemaColor: item.sistemaColor,
    tipoDentadura: item.distencion,
    tonos: `${item.tonoInferior}, ${item.tonoMedio}, ${item.tonoSuperior}`,
    dientes: item.diente,
    departamento: item.departamento,
    imagenDiente: item.imagenDiente,
    imagenEsquema: item.imagenEsquema,
    prioridad: item.prioridad,
    estado: item.estado,
    direccionConsultorio: item.municipio,
    dia: item.dia,
    numeroProceso: item.numeroProceso,
    nombreProceso: item.nombreProceso,
    motivoPausa: item.motivoPausa,
    imagenResultadoFinal: item.imagenResultadoFinal || "Sin Imagen",
    garantia: item.garantia || "1",
    fechaAsignacion: item.fechaAsignacion,
    fechaInicio: item.fechaInicio,
    fechaFin: item.fechaFin,
    cantidad: item.cantidad // Asegúrate de que este campo existe si lo necesitas
  }));
}

export default GraficasTiempo;
