import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actualizaDeshabilitarOrdenesTrabajo } from "../../api/ordenesTrabajo";
import queryString from "query-string";
import { Load } from "../load/load";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { actualizaDeshabilitarServicios } from "../../api/servicios";
import { LogsInformativos } from "../logs/logs";

export default function CancelarOrdenTrabajo({ data, history, setShow }) {
  console.log(data)
  const idDocumental = data[0];

  const dataTemp = {
    folio: data[2],
    fecha: data[2],
    localidad: data[3],
    nombreDoctor: data[4],
    nombrePaciente: data[5],
    tipoDentadura: data[6],
    modeloSuperior: data[7],
    modeloInferior: data[8],
    mordidaCera: data[9],
    mordeidaSilicon: data[10],
    sAntagonista: data[11],
    llenadoCucharilla: data[12],
    otros: data[13],
    recibio: data[14],
    fechaEntrada: data[15],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [fechaNueva, setFechaNueva] = useState(dia + "/" + mes + "/" + year);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

      try {
        setLoading(true);

        const dataTemp = {
          estado: "0"
        }

        actualizaDeshabilitarServicios(idDocumental, dataTemp).then((response) => {
          const { data } = response;
          console.log(data);

          toast.success(data.mensaje);
          LogsInformativos("Se ha deshabilitado el servicio " + idDocumental, dataTemp)
          history({
            search: queryString.stringify(""),
          });
          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        });
      } catch (e) {
        console.log(e);
      }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit} onChange={onChange}>
          <h4 style={{textAlign:"center"}}>¿Cancelar la orden con folio {dataTemp.folio}?</h4>
          <div style={{textAlign:"center", marginTop:"2%"}}>
            <Button variant="danger" onClick={onSubmit}><FontAwesomeIcon icon={faCancel} /> Cancelar</Button>
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    fecha: data.fecha,
    localidad: data.localidad,
    nombreDoctor: data.nombreDoctor,
    nombrePaciente: data.nombrePaciente,
    tipoDentadura: data.tipoDentadura,
    modeloSuperior: data.modeloSuperior,
    modeloInferior: data.modeloInferior,
    mordidaCera: data.mordidaCera,
    mordidaSilicon: data.mordeidaSilicon,
    sAntagonista: data.sAntagonista,
    llenadoCucharilla: data.llenadoCucharilla,
    otros: data.otros,
    recibio: data.recibio,
    fechaEntrada: data.fechaEntrada,
  };
}
