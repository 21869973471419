import { Form, Button, Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarPrecios } from "../../../api/precios";
import { toast } from "react-toastify";
import queryString from "query-string";
import { TablaMateriales } from "./TablaMateriales";
import { TablaPiezas } from "./TablaPiezas"
import { LogsInformativos } from "../../logs/logs";

export default function ModificarPrecio({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const dataTemp = {
        clasificacion: data[1],
    };

    console.log(data)

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const handleClose3 = () => setShow3(false);

    //load
    const [loading, setLoading] = useState(true);

    const [materiales, setMateriales] = useState(data[2]);
    const [material, setMaterial] = useState("");

    const agregarMaterial = () => {
        const nuevoMaterial = { material };
        setMateriales([...materiales, nuevoMaterial]);
        handleClose2();
    };

    const [piezas, setPiezas] = useState(data[3]);
    const [pieza, setPieza] = useState("");
    const [precio, setPrecio] = useState("");

    const agregarPiezas = () => {
        const nuevaPieza = { pieza, precio };
        setPiezas([...piezas, nuevaPieza]);
        handleClose3();
    };

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            // Sube a cloudinary la imagen principal del producto

            const dataTemp = {
                clasificacion: formData.clasificacion,
                tipoServicios: formData.tipoServicios,
                servicios: formData.servicios,
                precio: formData.precio,
                sistemaColorPrincipal: formData.sistemaColorPrincipal,
                otrosColores: formData.otrosColores,
                materiales: materiales,
                piezas: piezas,
            };

            actualizarPrecios(idDocumental, dataTemp).then((response) => {
                const { data } = response;
                //notificacion
                LogsInformativos("Se ha modificado el precio del material" + dataTemp.clasificacion, dataTemp)
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                //cancelarRegistro()
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalPrecios'>
                <Form className='formPrecios' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Group>
                        <Form.Label className='lblClasificacionPre'>Clasificación: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpNombreSis'
                            placeholder='Nombre'
                            name="clasificacion"
                            defaultValue={formData.clasificacion}
                        />
                        <Button className="btnModal" variant="primary" onClick={handleShow2}>
                            Agregar Material
                        </Button>
                        <Modal show={show2} onHide={handleClose2}>
                            <Modal.Header closeButton>
                                <Modal.Title>Agregar material</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Label>Material:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="inpMedida"
                                        onChange={(e) => setMaterial(e.target.value)}
                                    />
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose2}>
                                    Cerrar
                                </Button>
                                <Button variant="primary" onClick={agregarMaterial}>
                                    Agregar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Form.Group>
                            {/************************COMPONENT TABLA************************ */}
                            <TablaMateriales productos={materiales} setProductos={setMateriales} />
                        </Form.Group>

                        <Button className="btnModal" variant="primary" onClick={handleShow3}>
                            Agregar Pieza
                        </Button>
                        <Modal show={show3} onHide={handleClose3}>
                            <Modal.Header closeButton>
                                <Modal.Title>Agregar pieza</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Label>Pieza:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="inpMedida"
                                        onChange={(e) => setPieza(e.target.value)}
                                    />
                                    <Form.Label>Precio:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="inpMedida"
                                        onChange={(e) => setPrecio(e.target.value)}
                                    />
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose3}>
                                    Cerrar
                                </Button>
                                <Button variant="primary" onClick={agregarPiezas}>
                                    Agregar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Form.Group>
                            {/************************COMPONENT TABLA************************ */}
                            <TablaPiezas productos={piezas} setProductos={setPiezas} />
                        </Form.Group>
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        clasificacion: data.clasificacion,
        tipoServicios: data.tipoServicios,
        servicios: data.servicios,
        precio: data.precio,
        sistemaColorPrincipal: data.sistemaColorPrincipal,
        otrosColores: data.otrosColores
    };
}
