import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarOrdenesTrabajo,
    ENDPOINTObtenerOrdenesTrabajo,
    ENDPOINTListarOrdenesTrabajo,
    ENDPOINTActualizarOrdenesTrabajo,
    ENDPOINTEliminarOrdenesTrabajo,
    ENDPOINTDeshabilitarOrdenesTrabajo,
    ENDPOINTListarOrdenesTrabajoPorCorreoDoctor,
    ENDPOINTListarOrdenesTrabajoPorCorreoConsultorio,
    ENDPOINTObtenerFolioOrdenesTrabajo,
    ENDPOINTActualizarPrioridadOrdenesTrabajo,
    ENDPOINTCalcularHorasTranscurridasOrdenesTrabajo
} from "./endpoints";
import axios from 'axios';

export async function listarOrdenesTrabajo(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesTrabajo, config);
}

export async function calcularHorasTranscurridasOrdenesTrabajo(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTCalcularHorasTranscurridasOrdenesTrabajo, config);
}

export async function listarOrdenesTrabajoPorCorreoDoctor(correoDoctor){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesTrabajoPorCorreoDoctor + `?correoDoctor=${correoDoctor}`, config);
}

export async function listarOrdenesTrabajoPorCorreoConsultorio(correoConsultorio){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesTrabajoPorCorreoConsultorio + `?correoConsultorio=${correoConsultorio}`, config);
}


export async function registraOrdenesTrabajo(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarOrdenesTrabajo, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerOrdenesTrabajo(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerOrdenesTrabajo + `/${params}`, config);
}

export async function actualizarOrdenesTrabajo(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarOrdenesTrabajo + `/${id}`, data, config);
}

export async function eliminarOrdenesTrabajo(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarOrdenesTrabajo + `/${id}`, data, config);
}

export async function actualizaDeshabilitarOrdenesTrabajo(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarOrdenesTrabajo + `/${id}`, data, config);
}

export async function actualizarPrioridadOrdenesTrabajo(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarPrioridadOrdenesTrabajo + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroOrdenTrabajo() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioOrdenesTrabajo, config);
}