import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
  faUsersGear,
  faHospitalUser,
} from "@fortawesome/free-solid-svg-icons";
import { listarUsuarios } from "../../api/usuarios";
import { Button, Dropdown, Badge, Tabs, Tab } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import RegistroUsuario from "./Registro";
import EliminarUsuarios from "./Eliminar";
import ModificarUsuarios from "./Modificar";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function TblUsuarios(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      listarUsuarios()
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [listarUsuariosI, setListUsuariosI] = useState([]);

  const obtenerUsuariosInternos = () => {
    listarUsuarios()
      .then((response) => {
        const { data } = response;

        if (!listarUsuariosI && data) {
          setListUsuariosI(formatModelOrdenes(data));
        } else {
          const datosDoctor = formatModelOrdenes(data);
          const filteredUsers = datosDoctor.filter(
            (user) =>
              user.tipo === "doctor" ||
              user.tipo === "consultorio" 
          );

          if (filteredUsers.length > 0) {
            setListUsuariosI(filteredUsers);
          } else {
            console.log(
              "No se encontró ningún elemento con el tipo especificado"
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
      });
  };

  const [listarUsuariosE, setListUsuariosE] = useState([]);

  const obtenerUsuariosExternos = () => {
    listarUsuarios()
      .then((response) => {
        const { data } = response;

        if (!listarUsuariosE && data) {
          setListUsuariosE(formatModelOrdenes(data));
        } else {
          const datosDoctor = formatModelOrdenes(data);
          const filteredUsers = datosDoctor.filter(
            (user) =>
              user.tipo != "doctor" &&
              user.tipo != "consultorio"
          );

          if (filteredUsers.length > 0) {
            setListUsuariosE(filteredUsers);
          } else {
            console.log(
              "No se encontró ningún elemento con el tipo especificado"
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
      });
  };

  useEffect(() => {
    obtenerOrdenesServicio();
    obtenerUsuariosInternos();
    obtenerUsuariosExternos();
  }, [location]);

  console.log(listarUsuariosE);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroUsuarios = (content) => {
    setTitulosModal("Registar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarUsuarios = (content) => {
    setTitulosModal("Modificar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarUsuarios = (content) => {
    setTitulosModal("Eliminar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombre",
      label: "NOMBRE",
    },
    {
      name: "email",
      label: "EMAIL",
    },
    {
      name: "password",
      label: "PASSWORD",
    },
    {
      name: "tipo",
      label: "TIPO",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      modificarUsuarios(
                        <ModificarUsuarios
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Modificar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      eliminarUsuarios(
                        <EliminarUsuarios
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Eliminar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Usuarios</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroUsuarios(
                <RegistroUsuario history={history} setShow={setShowModal} />
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <div className="divTabla">
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
        justify
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          marginTop: "1%",
        }}
      >
        <Tab
          eventKey="home"
          title={
            <span style={{ textTransform: "capitalize" }}>
              <FontAwesomeIcon icon={faUsersGear} /> Usuarios internos
            </span>
          }
        >
          <div className="divTabla">
            <MUIDataTable
              title={"Listado de usuarios internos registrados"}
              data={listarUsuariosE}
              columns={columns}
              options={options}
            />
          </div>
        </Tab>
        <Tab
          eventKey="profile"
          title={
            <span style={{ textTransform: "capitalize" }}>
              <FontAwesomeIcon icon={faHospitalUser} /> Ususarios externos
            </span>
          }
        >
          <div className="divTabla">
            <MUIDataTable
              title={"Listado de usuarios externos registrados"}
              data={listarUsuariosI}
              columns={columns}
              options={options}
            />
          </div>
        </Tab>
      </Tabs>
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      email: data.email,
      password: data.password,
      tipo: data.tipo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblUsuarios);
