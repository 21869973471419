import {
  faBellConcierge,
  faCircleCheck,
  faEye,
  faMessage,
  faPaperPlane,
  faPlane,
  faQuestion,
  faTriangleExclamation,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { listarEventosPorFolio, registraEvento } from "../../api/eventos";
import { toast } from "react-toastify";
import { LogsInformativos } from "../logs/logs";
import { registraIncidencias, obtenerNumeroIncidencia } from "../../api/incidencias";

const AgregaIncidenciaOT = ({ data, usuario, setShow }) => {
  const folioOrden = data[2];
  const nombreUsuario = usuario;

  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    setTodayDate(`${year}-${month}-${day}`);
  }, []);

  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroIncidencia()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noIncidencia } = data;
          setFolioActual(noIncidencia);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const guardarIncidencia = async () => {
    try {
      const dataTemp = {
        folio: folioActual,
        fecha: todayDate,
        descripcion: mensajeIncidencia,
        departamento: "Produccion",
        comentarios: "sin comentarios",
        estado: "1",
      };
      registraIncidencias(dataTemp).then((response) => {
        const { data } = response;
        LogsInformativos("Se ha registrado la incidencia " + folioActual)
        toast.success(data.mensaje);
      });
    } catch (e) {
      console.log(e);
    }
  };

const [mensajeIncidencia, setMensajeIncidencia] = useState("");

  const [listEventos, setListEventos] = useState([]);

  const obtieneEventos = (folio) => {
    try {
      listarEventosPorFolio(folio)
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            if (!listEventos && data) {
              setListEventos(formatModelEventos(data));
            } else {
              const datosEvento = formatModelEventos(data);
              setListEventos(datosEvento);
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error("Error en la función obtieneOrdenID:", e);
    }
  };

  useEffect(() => {
    obtieneEventos(folioOrden);
  }, [folioOrden]);

  let fecha = new Date();
  let dia = fecha.getDate(); // Obtener el día (del 1 al 31)
  let mes = fecha.getMonth() + 1; // Obtener el mes (del 0 al 11, por eso se suma 1)
  let anio = fecha.getFullYear(); // Obtener el año (ej. 2023)
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos

  let datosFecha = `${dia}/${mes}/${anio} ${hora}:${minutos}`;

  const guardarEvento = async () => {
    try {
      const dataTemp2 = {
        fecha: datosFecha,
        folioOrden: folioOrden,
        usuario: nombreUsuario,
        tipoEvento: "Advertencia",
        motivoEvento: mensajeIncidencia,
        estado: "activo",
        leido: 0,
      };
      registraEvento(dataTemp2).then((response) => {
        const { data } = response;
        LogsInformativos("Se ha registrado una incidencia en la orden de trabajo " + dataTemp2.folioOrden, dataTemp2)
        guardarIncidencia();
        toast.success(data.mensaje);
        obtieneEventos(folioOrden);
        setMensajeIncidencia("");
        setShow(false)
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: "2%" }}>
        Eventos de la orden {folioOrden}
      </h3>
      <div>
        <Row>
          <Col
            sm={12}
            md={3}
            lg={3}
            className="d-flex align-items-center justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Detalles:</Form.Label>
          </Col>
          <Col sm={12} md={7} lg={7}>
            <Form.Control
              as="textarea"
              placeholder="Describe los detalles de la incidencia"
              style={{ height: "10vh" }}
              value={mensajeIncidencia}
              onChange={(e) => setMensajeIncidencia(e.target.value)}
            />
          </Col>
          <Col
            sm={12}
            md={2}
            lg={2}
            className="d-flex align-items-center justify-content-center mt-2"
          >
            <Button variant="success" onClick={guardarEvento}>
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <div>
            <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
              Eventos registrados de la orden
            </h5>
            <Timeline>
              {listEventos.map((event, index) => (
                <TimelineEvent
                  key={index}
                  title={event.usuario}
                  createdAt={event.fecha}
                  icon={
                    event.tipoEvento === "Otro" ? (
                      <FontAwesomeIcon icon={faQuestion} />
                    ) : event.tipoEvento === "Mensaje" ? (
                      <FontAwesomeIcon icon={faMessage} />
                    ) : event.tipoEvento === "Advertencia" ? (
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    ) : event.tipoEvento === "Observación" ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : event.tipoEvento === "Registro" ? (
                      <FontAwesomeIcon icon={faBellConcierge} />
                    ) : event.tipoEvento === "Entrega" ? (
                      <FontAwesomeIcon icon={faTruck} />
                    ) : event.tipoEvento === "Entregada" ? (
                      <FontAwesomeIcon icon={faCircleCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faQuestion} />
                    )
                  }
                >
                  <h6>{event.tipoEvento}</h6>
                  {event.motivoEvento}
                </TimelineEvent>
              ))}
            </Timeline>
          </div>
        </Row>
      </div>
    </div>
  );
};

function formatModelEventos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      fecha: data.fecha,
      folioOrden: data.folioOrden,
      usuario: data.usuario,
      tipoEvento: data.tipoEvento,
      motivoEvento: data.motivoEvento,
    });
  });
  return dataTemp;
}

export default AgregaIncidenciaOT;
