import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMensajeria,
    ENDPOINTObtenerMensajeria,
    ENDPOINTObtenerMensajeriaIdChat,
    ENDPOINTListarMensajeria,
    ENDPOINTActualizarMensajeria,
    ENDPOINTEliminarMensajeria,
    ENDPOINTDeshabilitarMensajeria,
    ENDPOINTListarChatsMensajeria,
    ENDPOINTListarChatsIDMensajeria,
    ENDPOINTMarcarLeidos
} from "./endpoints";
import axios from 'axios';

export async function listarMensajeria(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMensajeria, config);
}

export async function listarChats(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarChatsMensajeria, config);
}

export async function registraMensajeria(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMensajeria, data, config);
}

// Para obtener todos los datos del usuario
export async function marcarMensajesComoLeidos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.put(API_HOST + ENDPOINTMarcarLeidos + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMensajeria(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMensajeria + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMensajeriaIdChat(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMensajeriaIdChat + `/${params}`, config);
}

export async function obtenerChatsIdChat(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarChatsIDMensajeria + `/${params}`, config);
}

export async function actualizarMensajeria(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMensajeria + `/${id}`, data, config);
}

export async function eliminarMensajeria(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMensajeria + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMensajeria(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMensajeria + `/${id}`, data, config);
}