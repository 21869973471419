import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarAbonosOrdenes,
    ENDPOINTListarFechaAbonosOrdenes,
    ENDPOINTObtenerAbonosOrdenes,
    ENDPOINTListarAbonosOrdenes,
    ENDPOINTActualizarAbonosOrdenes,
    ENDPOINTEliminarAbonosOrdenes,
    ENDPOINTDeshabilitarAbonosOrdenes,
    ENDPOINTOObtenerAbonosPorFolioOS,
    ENDPOINTListarAbonosAprobados,
    ENDPOINTFiltrarFechaAbonos,
    ENDPOINTAbonosPorRuta,
    ENDPOINTListaridsRecibio,
    ENDPOINTListarPoridRecibio,
    ENDPOINTListarAbonosPorCliente,
    ENDPOINTListarAbonosPorCargo,
    ENDPOINTFiltrarAbonosPorFechas
} from "./endpoints";
import axios from 'axios';

export async function listarAbonosOrdenes(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAbonosOrdenes, config);
}

export async function listarAbonosidRecibio(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListaridsRecibio, config);
}

export async function listarFechaAbonosOrdenes(fecha){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarFechaAbonosOrdenes + `?fecha=${fecha}`, config);
}

export async function listarAbonosPorRuta(id){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTAbonosPorRuta + `?idRuta=${id}`, config);
}

export async function listarAbonosAprobadosPorCliente(correo){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAbonosPorCliente + `?email=${correo}`, config);
}

export async function listarAbonosPorCargo(correo){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAbonosPorCargo + `?email=${correo}`, config);
}

export async function listarAbonosPoridRecibio(idREcibio){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPoridRecibio + `?recibioNombre=${idREcibio}`, config);
}

export async function registraAbonosOrdenes(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarAbonosOrdenes, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerAbonosOrdenes(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerAbonosOrdenes + `/${params}`, config);
}

export async function actualizarAbonosOrdenes(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarAbonosOrdenes + `/${id}`, data, config);
}

export async function eliminarAbonosOrdenes(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarAbonosOrdenes + `/${id}`, data, config);
}

export async function actualizaDeshabilitarAbonosOrdenes(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarAbonosOrdenes + `/${id}`, data, config);
}

// Para obtener por folio de orden
export async function obtenerAbonosPorFolioOS(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTOObtenerAbonosPorFolioOS + `/${params}`, config);
}

export async function listarAbonosAprobados(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAbonosAprobados, config);
}

export async function listarAbonosEntreFechas(fechaInicial, fechaFinal){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTFiltrarFechaAbonos + `?fechaInicial=${fechaInicial}&&fechaFinal=${fechaFinal}`, config);
}

export async function listarAbonosEntreFechasGral(fechaInicial, fechaFinal){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTFiltrarAbonosPorFechas + `?fechaInicial=${fechaInicial}&&fechaFinal=${fechaFinal}`, config);
}