import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { actualizaMaterialesRecepcion, listarOrdenesServicio } from "../../../api/ordenesServicio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "../../dropzone/Dropzone";
import { TablaServicios } from "../TablaServicios";
import { toast } from "react-toastify";
import { registraRecepciones } from "../../../api/recepciones";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import './style.css';
import { LogsInformativos } from "../../logs/logs";

export default function MaterialEntrega({ history, setShow, data }) {
  const idOrdenDet = data[0];
  const folioOrdenDet = data[1];

  const [idUsuario, setIdeUsuario] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setIdeUsuario(data._id);
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicios = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;
          if (!listOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const ordenesFilter = data.filter(
              (ordenes) => ordenes.servicios.length === 0
            );
            setListOrdenes(formatModelOrdenes(ordenesFilter))
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicios();
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [formData, setFormData] = useState(initialFormValue());
  const [formData2, setFormData2] = useState(initialFormValue2());

  const [clasificacionMat, setClasificacionMat] = useState([]);
  const [servicio, setServicio] = useState("");
  const [cantidad, setCantidad] = useState("");

  const agregarClas = () => {
    const nuevaClas = { servicio, cantidad };
    setClasificacionMat([...clasificacionMat, nuevaClas]);
  };

  useEffect(() => {
    console.log(clasificacionMat);
  }, [clasificacionMat]);

  const [imagenOrden1, setImagenOrden1] = useState(null);
  const [imagenOrden2, setImagenOrden2] = useState(null);
  const [imagenOrden3, setImagenOrden3] = useState(null);
  const [imagenOrden4, setImagenOrden4] = useState(null);
  const [imagenOrden5, setImagenOrden5] = useState(null);
  const [imagenOrden6, setImagenOrden6] = useState(null);

  const [linkImagen1, setLinkImagen1] = useState("");

  const cargarImagen11 = () => {
    try {
      subeArchivosCloudinary(imagenOrden1, "laboratorio")
        .then((response) => {
          const { data } = response;
          console.log(data);
          const { secure_url } = data;
          setLinkImagen1(secure_url);
          console.log(linkImagen1);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen11();
  }, [imagenOrden1]);

  const [linkImagen2, setLinkImagen2] = useState("");

  const cargarImagen2 = () => {
    try {
      subeArchivosCloudinary(imagenOrden2, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen2(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen2();
  }, [imagenOrden2]);

  const [linkImagen3, setLinkImagen3] = useState("");

  const cargarImagen3 = () => {
    try {
      subeArchivosCloudinary(imagenOrden3, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen3(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen3();
  }, [imagenOrden3]);

  const [linkImagen4, setLinkImagen4] = useState("");

  const cargarImagen4 = () => {
    try {
      subeArchivosCloudinary(imagenOrden4, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen4(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen4();
  }, [imagenOrden4]);

  const [linkImagen5, setLinkImagen5] = useState("");

  const cargarImagen5 = () => {
    try {
      subeArchivosCloudinary(imagenOrden5, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen5(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen5();
  }, [imagenOrden5]);

  const [linkImagen6, setLinkImagen6] = useState("");

  const cargarImagen6 = () => {
    try {
      subeArchivosCloudinary(imagenOrden6, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen6(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen6();
  }, [imagenOrden6]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const dataTemp = {
        servicios: clasificacionMat,
        otros: formData.otros,
        recibio: nombreUsuario,
        imagen1: linkImagen1,
        observacionesImagen1: formData2.observaciones1,
        imagen2: linkImagen2,
        observacionesImagen2: formData2.observaciones2,
        imagen3: linkImagen3,
        observacionesImagen3: formData2.observaciones3,
        imagen4: linkImagen4,
        observacionesImagen4: formData2.observaciones4,
        imagen5: linkImagen5,
        observacionesImagen5: formData2.observaciones5,
        imagen6: linkImagen6,
        observacionesImagen6: formData2.observaciones6,
        estado: "4"
      };
      console.log(idOrdenDet);
      console.log(dataTemp)
      actualizaMaterialesRecepcion(idOrdenDet, dataTemp).then((response) => {
        const { data } = response;
        LogsInformativos("Se ha registrado la entrega de material " + dataTemp.recibio, dataTemp)
        toast.success(data.mensaje);
        setLoading(false);
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Form onSubmit={onSubmit} onChange={onChange}>
        <div style={{ padding: "2%" }}>
          <div style={{ marginTop: "2%" }}>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Orden de cliente:</Form.Label>
              </Col>
              <Col sm={12} md={10} lg={10}>
                <Form.Control type="text" value={folioOrdenDet} disabled/>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                xs={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblClaSerOS">Material a enviar:</Form.Label>
              </Col>
              <Col xs={12} md={10} lg={10}>
                <Form.Control
                  as="select"
                  className="inpClasifSerOS"
                  placeholder="Clasificación"
                  onChange={(e) => setServicio(e.target.value)}
                  name="servicio"
                  defaultValue={formData.servicio}
                >
                  <option>Elige una opción</option>
                  <option value="Modelo superior">Modelo superior</option>
                  <option value="Modelo inferior">Modelo inferior</option>
                  <option value="Mordida de cera">Mordida de cera</option>
                  <option value="Mordida en silicon">Mordida en silicon</option>
                  <option value="Sin antagonista">Sin antagonista</option>
                  <option value="Cobrar llenado de cucharilla">
                    Cobrar llenado de cucharilla
                  </option>
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                xs={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblTipoSerOS">Cantidad:</Form.Label>
              </Col>
              <Col xs={12} md={10} lg={10}>
                <Form.Control
                  name="cantidad"
                  type="numer"
                  className="inpMaterialOS"
                  placeholder="Cantidad"
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Col>
            </Row>
          </div>
          <div className="btnServiciosTabla">
            <Row>
              <Col md={{ span: 11, offset: 10 }} lg={{ span: 11, offset: 10 }}>
                <Button className="btnAddServiceTable" onClick={agregarClas}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Agregar
                </Button>
              </Col>
            </Row>
          </div>
          <TablaServicios
            productos={clasificacionMat}
            setProductos={setClasificacionMat}
          />
          <div>
            <Form.Group controlId="formFileMultiple" className="form5">
              <div className="imagenPrincipal" style={{ width: "100%" }}>
                <h4 className="textoImagenPrincipal">Subir imagenes</h4>
                  <div title="Seleccionar imagenes" className="imagenesServicioMaterial">
                    <div>
                      <Dropzone setImagenFile={setImagenOrden1} />
                      <p>Imagen 1</p>
                      <Form.Control
                        name="observaciones1"
                        defaultValue={formData2.observaciones1}
                        as="textarea"
                        placeholder="Observaciones imagen 1"
                        rows={2}
                      />
                    </div>
                    <div>
                      <Dropzone setImagenFile={setImagenOrden2} />
                      <p>Imagen 2</p>
                      <Form.Control
                        name="observaciones2"
                        defaultValue={formData2.observaciones2}
                        as="textarea"
                        placeholder="Observaciones imagen 2"
                        rows={2}
                      />
                    </div>
                    <div>
                      <Dropzone setImagenFile={setImagenOrden3} />
                      <p>Imagen3</p>
                      <Form.Control
                        name="observaciones3"
                        defaultValue={formData2.observaciones3}
                        as="textarea"
                        placeholder="Observaciones imagen 3"
                        rows={2}
                      />
                    </div>
                    <div>
                      <Dropzone setImagenFile={setImagenOrden4} />
                      <p>Imagen 4</p>
                      <Form.Control
                        name="observaciones4"
                        defaultValue={formData2.observaciones4}
                        as="textarea"
                        placeholder="Observaciones imagen 4"
                        rows={2}
                      />
                    </div>
                    <div>
                      <Dropzone setImagenFile={setImagenOrden5} />
                      <p>Imagen 5</p>
                      <Form.Control
                        name="observaciones5"
                        defaultValue={formData2.observaciones5}
                        as="textarea"
                        placeholder="Observaciones imagen 5"
                        rows={2}
                      />
                    </div>
                    <div>
                      <Dropzone setImagenFile={setImagenOrden6} />
                      <p>Imagen 6</p>
                      <Form.Control
                        name="observaciones6"
                        defaultValue={formData2.observaciones6}
                        as="textarea"
                        placeholder="Observaciones imagen 6"
                        rows={2}
                      />
                      {linkImagen6 != "" && (
                        <a href={linkImagen6}>Ver</a>
                      )}
                    </div>
                  </div>
              </div>
              <br />
            </Form.Group>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                xs={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Otros:</Form.Label>
              </Col>
              <Col xs={12} md={10} lg={10}>
                <Form.Control
                  name="otros"
                  defaultValue={formData.otros}
                  as="textarea"
                  rows={2}
                />
              </Col>
            </Row>

          </div>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </div >
      </Form >
    </>
  );
};

const formatModelOrdenes = (data) => {
  return data.map((orden) => ({
    value: orden._id,
    label: `Orden ${orden.folio}`,
  }));
};

function initialFormValue() {
  return {
    status: "",
    nombreSolicitante: "",
    departamento: "",
    observaciones: "",
    clasificacion: "",
    sistemaColor: "",
    descripcionServicio: "",
    consultorio: "",
    nombreDoctor: "",
    nombrePaciente: "",
  };
}

function initialFormValue2() {
  return {
    clasificacion: "",
    material: "",
    pieza: "",
    descripcionServicio: "",
    observaciones1: "",
    observaciones2: "",
    observaciones3: "",
    observaciones4: "",
    observaciones5: "",
    observaciones6: "",
  };
}
