import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { registraComedor } from "../../api/comedor";
import { LogsInformativos } from "../logs/logs";
import Dropzone from "../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../api/cloudinary";

export default function RegistroComedor({ history, setShow }) {

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JS van de 0 a 11, por eso se le suma 1.
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());

  const tiposGastos = ["Hoy", "Semana"];

  const [formData, setFormData] = useState(initialFormValue());
  const [signInLoading, setSignInLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.monto) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      try {
        subeArchivosCloudinary(imagenProducto, "comedor").then(response => {
          const { data } = response;
          setSignInLoading(true);

          const dataTemp = {
            fecha: currentDate,
            monto: formData.monto,
            cotizacion: data.secure_url,
            tipo: formData.tipo,
            estado: "true",
          };
          registraComedor(dataTemp)
            .then((response) => {
              const { data } = response;
              console.log(data);

              toast.success(data.mensaje);

              history({
                search: queryString.stringify(""),
              });
              LogsInformativos("Se ha registrado el comedor " + dataTemp.direccion, dataTemp)
              setSignInLoading(false);
              setShow(false);
              //cancelarRegistro()
            })
            .catch((ex) => {
              if (ex.message === "Network Error") {
                toast.error("Conexión al servidor no disponible");
                setSignInLoading(false);
              } else {
                if (ex.response && ex.response.status === 401) {
                  const { mensaje } = ex.response.data;
                  toast.error(mensaje);
                  setSignInLoading(false);
                }
              }
            });
        }).then(e => {
          console.log(e)
        })
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div className="imagenPrincipal">
          <h4 className="textoImagenPrincipal">Sube tu cotizacion</h4>
          <div title="Seleccionar imagen de la categoría" className="imagenProducto">
            <Dropzone
              setImagenFile={setImagenProducto}
            />
          </div>
        </div>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                value={currentDate}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Monto:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Monto"
                name="monto"
                style={{ textTransform: "capitalize" }}
                defaultValue={formData.monto}

              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Tipo:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de cliente"
                defaultValue={formData.tipo}
                name="tipo"
              >
                <option>Seleccionar</option>
                {tiposGastos.map((tipo, index) => (
                  <option key={index} value={tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue() {
  return {
    monto: "",
    tipo: "",
  };
}