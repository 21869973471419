import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { actualizaDeshabilitarIncidencias } from "../../api/incidencias";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function CambiarEstadoIncidencias({ data, history, setShow }) {

    const idDocumental = data[0];

    const [formData, setFormData] = useState(initialFormValue);
    const [signInLoading, setSignInLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setSignInLoading(true);
        try {
            const dataTemp = {
                estado: formData.estado,
                comentarios: formData.comentarios
            };
            actualizaDeshabilitarIncidencias(idDocumental, dataTemp).then((response) => {
                const { data } = response;

                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                LogsInformativos("Se ha actualizado el estado de la incidencia " + idDocumental, dataTemp)
                setSignInLoading(false);
                setShow(false);
                //cancelarRegistro()
            }).catch((ex) => {
                if (ex.message === "Network Error") {
                    toast.error("Conexión al servidor no disponible");
                    setSignInLoading(false);
                } else {
                    if (ex.response && ex.response.status === 401) {
                        const { mensaje } = ex.response.data;
                        toast.error(mensaje);
                        setSignInLoading(false);
                    }
                }
            });
        } catch (ex) {
            toast.error("Error al iniciar sesión");
            setSignInLoading(false);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Estado: </Form.Label>
                    <Form.Control
                        as="select"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="estado"
                        defaultValue={formData.estado}
                    >
                        <option value="">Selecciona una opcion</option>
                        <option value="0">Cancelada</option>
                        <option value="2">Atendida</option>
                    </Form.Control>

                    <Form.Label>Comentarios: </Form.Label>
                    <Form.Control
                        type="text"
                        className="emailNewUser"
                        placeholder="Comentarios"
                        name="comentarios"
                        defaultValue={formData.comentarios}
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue(data) {
    return {
        estado: "",
        comentarios: ""
    };
}
