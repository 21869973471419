import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarCajaChica,
    ENDPOINTObtenerCajaChica,
    ENDPOINTListarCajaChica,
    ENDPOINTActualizarCajaChica,
    ENDPOINTEliminarCajaChica,
    ENDPOINTDeshabilitarCajaChica
} from "./endpoints";
import axios from 'axios';

export async function listarCajaChica(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCajaChica, config);
}

export async function registraCajaChica(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarCajaChica, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerCajaChica(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerCajaChica + `/${params}`, config);
}

export async function actualizarCajaChica(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCajaChica + `/${id}`, data, config);
}

export async function eliminarCajaChica(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarCajaChica + `/${id}`, data, config);
}

export async function actualizaDeshabilitarCajaChica(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarCajaChica + `/${id}`, data, config);
}