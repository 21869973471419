import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { registraServiciosDentales } from "../../../api/serviciosDentales";
import queryString from "query-string";
import { LogsInformativos } from "../../logs/logs";

export default function ServiciosDentales({ history, setShow }) {
    const [formData, setFormData] = useState(initialFormValue());

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.clasificacion || !formData.tipo || !formData.servicio || !formData.precio || !formData.colorimetria) {
            toast.warning("Completa el formulario");
        } else {
            try {
                setLoading(true);

                const dataTemp = {
                    clasificacion: formData.clasificacion,
                    tipo: formData.tipo,
                    servicio: formData.servicio,
                    precio: formData.precio,
                    colorimetria: formData.colorimetria,
                    tonos: formData.tono1 + "," + formData.tono2 + "," + formData.tono3 + "," + formData.tono4,
                    estado: "true"
                };
                registraServiciosDentales(dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)
                    LogsInformativos("Se ha registrado el servicio dental " + dataTemp.servicio, dataTemp)
                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });

                    setFormData(initialFormValue())
                    setLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalSerDentales'>
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <Form.Label className='lblClaSer'>Clasificación de Servicio: </Form.Label>
                    <Form.Control
                        type='text'
                        className='inpClasifSer'
                        defaultValue={formData.clasificacion}
                        name="clasificacion"
                        placeholder='Escribe la clasificacion del material. Ejemplo: Zirconia'
                    />
                    <Form.Label className='lblTipoSer'>Tipo de Servicio: </Form.Label>
                    <Form.Control
                        defaultValue={formData.tipo}
                        name="tipo"
                        type='text'
                        className='inpTipoSer'
                        placeholder='Tipo de servicio que se dará de alta. Ejemplo: Prostodoncia'
                    />
                    <Form.Label className='lblSerSer'>Servicio: </Form.Label>
                    <Form.Control
                        defaultValue={formData.servicio}
                        name="servicio"
                        type='text'
                        className='inpServiciosSer'
                        placeholder='Nombre del servicio. Ejemplo: Endoposte'
                    />

                    <Form.Label className='lblPrecioSer'>Precio: </Form.Label>
                    <Form.Control
                        defaultValue={formData.precio}
                        name="precio"
                        type='text'
                        className='inpPrecioSer'
                        placeholder='Precio'
                    />
                    <div className='checksDiv'>
                        <Form.Label className='lblColSer'>Selección del sistema de colorimetria: </Form.Label>
                        {['radio'].map((type) => (
                            <div className="selColorimetria">
                                <Form.Check
                                    type={type}
                                    id={`VC`}
                                    label={`VITA CLASSICAL`}
                                    value="VITA CLASSICAL"
                                    name="colorimetria"
                                    defaultValue={formData.colorimetria}
                                />
                                <Form.Check
                                    type={type}
                                    id={`V3M`}
                                    name="colorimetria"
                                    label={`VITAPAN 3D MASTER`}
                                    value="VITAPAN 3D MASTER"
                                    defaultValue={formData.colorimetria}
                                />
                            </div>
                        ))}

                        <Form.Label className='lblTonosSer'>Tonos: </Form.Label>
                        {['checkbox'].map((type) => (
                            <div className="selTonos">
                                <Form.Check
                                    type={type}
                                    id={`A1`}
                                    label={`A1`}
                                    value="A1"
                                    defaultValue={formData.tono1}
                                    name="tono1"
                                />
                                <Form.Check
                                    type={type}
                                    id={`A2`}
                                    label={`A2`}
                                    value="A2"
                                    defaultValue={formData.tono2}
                                    name="tono2"
                                />
                                <Form.Check
                                    type={type}
                                    id={`A3`}
                                    label={`A3`}
                                    value="A3"
                                    defaultValue={formData.tono3}
                                    name="tono3"
                                />
                                <Form.Check
                                    type={type}
                                    id={`A4`}
                                    label={`A4`}
                                    value="A4"
                                    defaultValue={formData.tono4}
                                    name="tono4"
                                />
                            </div>
                        ))}
                    </div>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    )
}

function initialFormValue() {
    return {
        clasificacion: "",
        tipo: "",
        servicio: "",
        precio: "",
        colorimetria: "",
        tono1: "",
        tono2: "",
        tono3: "",
        tono4: "",
    };
}

