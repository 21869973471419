import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMovimientosAPL,
    ENDPOINTObtenerMovimientosAPL,
    ENDPOINTListarMovimientosAPL,
    ENDPOINTListarMovimientosAPLFiltroFecha,
    ENDPOINTActualizarMovimientosAPL,
    ENDPOINTEliminarMovimientosAPL,
    ENDPOINTDeshabilitarMovimientosAPL,
    ENDPOINTListarMovimientosAPLFiltroGeneralFecha
} from "./endpoints";
import axios from 'axios';

export async function listarMovimientosAPL(idArticuloAPL) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosAPL + `/?idArticuloAPL=${idArticuloAPL}`, config);
}

export async function listarMovimientosAPLFiltroFecha(idArticuloAPL, inicio, fin) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosAPLFiltroFecha + `/?idArticuloAPL=${idArticuloAPL}&&inicio=${inicio}&&fin=${fin}`, config);
}

export async function listarMovimientosAPLFiltroGeneralFecha(inicio, fin) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosAPLFiltroGeneralFecha + `/?inicio=${inicio}&&fin=${fin}`, config);
}

export async function registraMovimientosAPL(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMovimientosAPL, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMovimientosAPL(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMovimientosAPL + `/${params}`, config);
}

export async function actualizarMovimientosAPL(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMovimientosAPL + `/${id}`, data, config);
}

export async function eliminarMovimientosAPL(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMovimientosAPL + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMovimientosAPL(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMovimientosAPL + `/${id}`, data, config);
}