import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { eliminarCursos } from "../../api/cursos";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function EliminarCursos({ data, history, setShow }) {
    const tipoArr = [
        "Curso",
        "Capacitacion",
    ];
    const idDocumental = data[0];

    const dataTemp = {
        nombre: data[1],
        tipo: data[2],
        descripcion: data[3],
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));
    const [signInLoading, setSignInLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setSignInLoading(true);
        try {

            eliminarCursos(idDocumental).then((response) => {
                const { data } = response;
                console.log(data)

                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                LogsInformativos("Se ha eliminado el usuario " + idDocumental)
                setSignInLoading(false);
                setShow(false);
                //cancelarRegistro()
            }).catch((ex) => {
                if (ex.message === "Network Error") {
                    toast.error("Conexión al servidor no disponible");
                    setSignInLoading(false);
                } else {
                    if (ex.response && ex.response.status === 401) {
                        const { mensaje } = ex.response.data;
                        toast.error(mensaje);
                        setSignInLoading(false);
                    }
                }
            });
        } catch (ex) {
            toast.error("Error al iniciar sesión");
            setSignInLoading(false);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Nombre: </Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="nombre"
                        defaultValue={formData.nombre}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Tipo: </Form.Label>
                    <Form.Control
                        as="select"
                        className="tipo"
                        placeholder="tipo"
                        name="tipo"
                        defaultValue={formData.tipo}
                        disabled
                    >
                        <option value="">Seleccionar</option>
                        {tipoArr.map((tipo, index) => (
                            <option value={tipo} key={index} selected={tipo == formData.tipo}>
                                {tipo}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control
                        type="text"
                        className="descripcion"
                        placeholder="Ingrese una descripcion"
                        name="descripcion"
                        defaultValue={formData.descripcion}
                        disabled
                    />
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue(data) {
    return {
        nombre: data.nombre,
        tipo: data.tipo,
        descripcion: data.descripcion,
        fecha: data.fecha,
    };
}
