import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMensajeros,
    ENDPOINTObtenerMensajeros,
    ENDPOINTListarMensajeros,
    ENDPOINTActualizarMensajeros,
    ENDPOINTEliminarMensajeros,
    ENDPOINTDeshabilitarMensajeros,
    ENDPOINTEnviarEmailRecordatorioMensajero
} from "./endpoints";
import axios from 'axios';

export async function enviaEmailRecordatorioMensajero(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTEnviarEmailRecordatorioMensajero, data, config);
}

export async function listarMensajeros(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMensajeros, config);
}

export async function registraMensajeros(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMensajeros, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMensajeros(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMensajeros + `/${params}`, config);
}

export async function actualizarMensajeros(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMensajeros + `/${id}`, data, config);
}

export async function eliminarMensajeros(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMensajeros + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMensajeros(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMensajeros + `/${id}`, data, config);
}