import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarUbicaciones,
    ENDPOINTObtenerUbicaciones,
    ENDPOINTListarUbicaciones,
    ENDPOINTActualizarUbicaciones,
    ENDPOINTEliminarUbicaciones,
    ENDPOINTDeshabilitarUbicaciones
} from "./endpoints";
import axios from 'axios';

export async function listarUbicaciones(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarUbicaciones, config);
}

export async function registraUbicaciones(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarUbicaciones, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerUbicaciones(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUbicaciones + `/${params}`, config);
}

export async function actualizarUbicaciones(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarUbicaciones + `/${id}`, data, config);
}

export async function eliminarUbicaciones(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarUbicaciones + `/${id}`, data, config);
}

export async function actualizaDeshabilitarUbicaciones(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarUbicaciones + `/${id}`, data, config);
}