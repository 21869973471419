import axios from "axios";
import { API_HOST } from "../utils/constants";
import { ENDPOINTCambiarEstadoPermisos, ENDPOINTConsultarPermisos, ENDPOINTListarPermisos } from "./endpoints";

export async function listarPermisos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPermisos, config);
}

export async function consultarPermisosModulos(tipo, modulo){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTConsultarPermisos + `?tipo=${tipo}&&modulo=${modulo}`, config);
}

export async function actualizaEstadoPermisos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTCambiarEstadoPermisos + `/${id}`, data, config);
}
