import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarEvaluaciones360,
    ENDPOINTObtenerEvaluaciones360,
    ENDPOINTObtenerUltimoEvaluaciones360,
    ENDPOINTListarEvaluaciones360,
    ENDPOINTActualizarEvaluaciones360,
    ENDPOINTEliminarEvaluaciones360,
    ENDPOINTDeshabilitarEvaluaciones360
} from "./endpoints";
import axios from 'axios';

export async function listarEvaluaciones360(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarEvaluaciones360, config);
}

export async function registraEvaluaciones360(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarEvaluaciones360, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerEvaluaciones360(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerEvaluaciones360 + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerUltimoEvaluaciones360() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUltimoEvaluaciones360, config);
}

export async function actualizarEvaluaciones360(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarEvaluaciones360 + `/${id}`, data, config);
}

export async function eliminarEvaluaciones360(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarEvaluaciones360 + `/${id}`, data, config);
}

export async function actualizaDeshabilitarEvaluaciones360(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarEvaluaciones360 + `/${id}`, data, config);
}