import {
  faCancel,
  faDollar,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Badge, Button, Modal, Table } from "react-bootstrap";
import { actualizaDeshabilitarOrdenCompra } from "../../../api/ordenCompra";
import { toast } from "react-toastify";
import { LogsInformativos } from "../../logs/logs";

const TablaOCC = ({ data, setShowModal }) => {
  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const [dataOrderSelect, setDataOrderSelect] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (order) => {
    console.log("order", order);
    setShow(true);
    setDataOrderSelect(order);
  };

  const handleSwitchChange = () => {
    try {
      const dataTemp = {
        estadoPago: "1",
      };
      actualizaDeshabilitarOrdenCompra(dataOrderSelect._id, dataTemp).then(
        (response) => {
          const { data } = response;
          // LogsInformativos(
          //   "Se actualiza el estado de pago de orden de compra",
          //   dataTemp
          // );
          toast.success("Se ha marcado como pagada la orden");
          handleClose();
          setShowModal(false)
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Fecha</th>
            <th>Total</th>
            <th>Estado</th>
            <th>Estado Pago</th>
            <th>Pagar</th>
          </tr>
        </thead>
        <tbody>
          {data.map((orden) => (
            <tr key={orden._id}>
              <td>{orden.folio}</td>
              <td>{new Date(orden.fecha).toLocaleDateString()}</td>
              <td>{formatCurrency(orden.total)}</td>
              <td>{orden.estado == "1" ? "Recibida" : null}</td>
              <td>
                <Badge bg={orden.estadoPago == "0" ? "warning" : "success"}>
                  {orden.estadoPago == "0" ? "Por Pagar" : "Pagado"}
                </Badge>
              </td>
              <td>
                {orden.estadoPago == "0" ? (
                  <Button
                    variant="success"
                    title="Cambiar a pagada"
                    onClick={() => handleShow(orden)}
                  >
                    <FontAwesomeIcon icon={faDollar} />
                  </Button>
                ) : (
                  "No disponibles"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Estado del pago de orden de compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ textAlign: "center", fontStyle: "italic" }}>
            ¿La orden {dataOrderSelect.folio} fué pagada?
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            <FontAwesomeIcon icon={faCancel} /> Cancelar
          </Button>
          <Button variant="success" onClick={handleSwitchChange}>
            <FontAwesomeIcon icon={faMoneyBill} /> Pagada
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TablaOCC;
