import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import MUIDataTable from "mui-datatables";
import { listarDoctores } from "../../../api/doctores";
import { listarAbonosEntreFechasGral } from "../../../api/abonosOrdenes";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarCargosyDescFechas } from "../../../api/preciosOrden";
import { listarPagosPorPeriodo } from "../../../api/pagos";

const TablaMovimientosGral = ({ activador }) => {
  console.log("activador", activador);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [fechaInicial, setfechaInicial] = useState(null);
  const [fechaFinal, setfechaFinal] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedOption, setSelectedOption] = useState("todos");

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            funcArrSelect(datosDoctor, setListDoctor);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const funcArrSelect = (element, arr) => {
    const newArray = element.map(({ email, razonSocial }) => ({
      value: email,
      label: razonSocial,
    }));
    arr(newArray);
  };

  useEffect(() => {
    if (activador) {
      obtenerDoctor();
    }
  }, [activador]);

  const [arrClients, setArrClients] = useState([]);

  useEffect(() => {
    const arrTodos = [{ value: "todos", label: "Todos los Clientes" }];
    const arrClient = [...arrTodos, ...listarDoctor];
    setArrClients(arrClient);
  }, [listarDoctor]);

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));
  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros coincidentes con los filtros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "fecha",
    },
    {
      name: "tipo",
      label: "tipo",
    },
    {
      name: "email",
      label: "email cliente",
    },
    {
      name: "concepto",
      label: "Concepto",
    },
    {
      name: "cantidad",
      label: "cantidad",
      options: {
        customBodyRender: (value) => formatCurrency(value != null ? value : 0),
      },
    },
    {
      name: "estado",
      label: "estado",
    },
  ];

  const [abonosPeriodos, setAbonosPeriodos] = useState([]);
  const obtenerAbonosFechas = (date1, date2) => {
    try {
      listarAbonosEntreFechasGral(date1, date2)
        .then((response) => {
          const { data } = response;
          console.log("🚀 ~ .then ~ data:", data);
          if (!abonosPeriodos && data) {
            setAbonosPeriodos(data);
          } else {
            setAbonosPeriodos(formatModelAbonos(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [pagosPeriodos, setPagosPeriodos] = useState([]);
  const obtenerPagosFechas = (date1, date2) => {
    try {
      listarPagosPorPeriodo(date1, date2)
        .then((response) => {
          const { data } = response;
          console.log("🚀 ~ .then ~ data:", data);
          if (!pagosPeriodos && data) {
            setPagosPeriodos(data);
          } else {
            setPagosPeriodos(formatModelPagos(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [cyDPeriodos, setcyDPeriodos] = useState([]);
  const obtenerCargosyDescuentos = (date1, date2, select) => {
    try {
      listarCargosyDescFechas(date1, date2)
        .then((response) => {
          const { data } = response;
          console.log("🚀 ~ .then ~ data:", data);
          if (!cyDPeriodos && data) {
            setcyDPeriodos(data);
          } else {
            if (select == "cargos") {
              const cargos = data.filter(
                (registro) => registro.tipo == "cargo"
              );
              setcyDPeriodos(formatModelCargosyDesc(cargos));
            } else if (select == "descuentos") {
              const descuentos = data.filter(
                (registro) => registro.tipo == "descuento"
              );
              setcyDPeriodos(formatModelCargosyDesc(descuentos));
            } else {
              setcyDPeriodos(formatModelCargosyDesc(data));
            }
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (selectedFilter == "todos") {
      obtenerAbonosFechas(fechaInicial, fechaFinal);
      obtenerCargosyDescuentos(fechaInicial, fechaFinal, selectedFilter);
      obtenerPagosFechas(fechaInicial, fechaFinal);
    } else if (selectedFilter == "cargos" || selectedFilter == "descuentos") {
      obtenerCargosyDescuentos(fechaInicial, fechaFinal, selectedFilter);
    } else if (selectedFilter == "pagosAbonos") {
      obtenerCargosyDescuentos(fechaInicial, fechaFinal);
      obtenerPagosFechas(fechaInicial, fechaFinal);
    }
  }, [selectedFilter, fechaInicial, fechaFinal]);

  const [arrayCompleto, setarrayCompleto] = useState([]);
  useEffect(() => {
    if (selectedFilter == "todos") {
      const newArray = [...cyDPeriodos, ...abonosPeriodos, ...pagosPeriodos];
      setarrayCompleto(newArray);
    }
  }, [selectedFilter, cyDPeriodos, abonosPeriodos, pagosPeriodos]);

  const [arrayPagosAbonos, setarrayPagos] = useState([]);
  useEffect(() => {
    if (selectedFilter == "pagosAbonos") {
      const newArray = [...abonosPeriodos, ...pagosPeriodos];
      setarrayPagos(newArray);
    }
  }, [selectedFilter, abonosPeriodos, pagosPeriodos]);

  useEffect(() => {
    if (selectedOption) {
      if (selectedFilter == "todos") {
        filtrarCliente(selectedOption, arrayCompleto);
      } else if (selectedFilter == "cargos" || selectedFilter == "descuentos") {
        filtrarCliente(selectedOption, cyDPeriodos);
      } else if (selectedFilter == "pagosAbonos") {
        filtrarCliente(selectedOption, arrayPagosAbonos);
      }
    }
  }, [
    selectedOption,
    selectedFilter,
    cyDPeriodos,
    arrayPagosAbonos,
    arrayCompleto,
  ]);

  const [filtersCliente, setfiltersCliente] = useState([]);

  const filtrarCliente = (email, array) => {
    const arrayCliente = array.filter((registro) => registro.email == email);
    setfiltersCliente(arrayCliente);
  };

  return (
    <div>
      <Row>
        <Col sm={12} md={3} lg={3}>
          <label>Selecciona movimientos</label>
          <Form.Control
            as="select"
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
          >
            <option value="">Seleccionar</option>
            <option value="todos">Todos los movimientos</option>
            <option value="cargos">Cargos</option>
            <option value="descuentos">Descuentos</option>
            <option value="pagosAbonos">Pagos y abonos</option>
          </Form.Control>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <label>Fecha Inicial</label>
          <Form.Control
            type="date"
            value={fechaInicial}
            onChange={(e) => setfechaInicial(e.target.value)}
          />
        </Col>
        <Col sm={12} md={3} lg={3}>
          <label>Fecha Final</label>
          <Form.Control
            type="date"
            value={fechaFinal}
            onChange={(e) => setfechaFinal(e.target.value)}
          />
        </Col>
        <Col sm={12} md={3} lg={3}>
          <label>Cliente</label>
          <Select
            placeholder={"Seleccionar cliente"}
            isSearchable={true}
            options={arrClients}
            onChange={(selectedOption) => handleSeleccionChange(selectedOption)}
          />
        </Col>
      </Row>
      <Row>
        <MUIDataTable
          data={
            selectedOption && selectedOption != "todos"
              ? filtersCliente
              : selectedOption == "todos"
              ? selectedFilter == "todos"
                ? arrayCompleto
                : selectedFilter == "pagosAbonos"
                ? arrayPagosAbonos
                : selectedFilter == "cargos" || selectedFilter == "descuentos"
                ? cyDPeriodos
                : []
              : []
          }
          columns={columns}
          options={options}
        />
      </Row>
    </div>
  );
};

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      razonSocial:
        data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno,
      email: data.email,
    });
  });
  return dataTemp;
}

function formatModelAbonos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      concepto: data.ordenServicio,
      email: data.emailCargo,
      estado: data.estado == "1" ? "Aprobado" : "Pendiente",
      fecha: data.fecha,
      cantidad: data.cantidadRecibida,
      tipo: "Abono",
    });
  });
  return dataTemp;
}

function formatModelPagos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      concepto: data.concepto,
      email: data.correoCliente,
      estado: data.estado == "Activo" ? "Aprobado" : "Pendiente",
      fecha: data.fecha,
      cantidad: data.cantidad,
      tipo: "Pago",
    });
  });
  return dataTemp;
}

function isEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function formatModelCargosyDesc(data) {
  const dataTemp = [];
  data.forEach((item) => {
    let concepto = item.concepto;

    if (!isEmail(item.ordenServicio)) {
      concepto += `: ${item.ordenServicio}`;
    }

    dataTemp.push({
      id: item._id,
      concepto: concepto,
      email: item.ordenServicio,
      estado: item.estado === "Activo" ? "Aprobado" : "Pendiente",
      fecha: item.fecha,
      cantidad: item.cantidad,
      tipo: item.tipo,
    });
  });
  return dataTemp;
}

export default TablaMovimientosGral;
