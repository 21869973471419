import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarCapacitacionesEmpleados,
    ENDPOINTObtenerCapacitacionesEmpleados,
    ENDPOINTListarCapacitacionesEmpleados,
    ENDPOINTActualizarCapacitacionesEmpleados,
    ENDPOINTEliminarCapacitacionesEmpleados,
    ENDPOINTDeshabilitarCapacitacionesEmpleados,
    ENDPOINTListarAgrupamientoCapacitacionesEmpleados
} from "./endpoints";
import axios from 'axios';

export async function listarCapacitacionesEmpleados(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCapacitacionesEmpleados, config);
}

export async function listarAgrupamientoCapacitacionesEmpleados(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarAgrupamientoCapacitacionesEmpleados, config);
}

export async function registraCapacitacionesEmpleados(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarCapacitacionesEmpleados, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerCapacitacionesEmpleados(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerCapacitacionesEmpleados + `/${params}`, config);
}

export async function actualizarCapacitacionesEmpleados(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCapacitacionesEmpleados + `/${id}`, data, config);
}

export async function eliminarCapacitacionesEmpleados(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarCapacitacionesEmpleados + `/${id}`, data, config);
}

export async function actualizaDeshabilitarCapacitacionesEmpleados(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarCapacitacionesEmpleados + `/${id}`, data, config);
}