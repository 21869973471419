import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { actualizarViajes } from "../../api/viajes";
import { listarEstablecimientos } from "../../api/establecimientos";
import { listarMensajeros } from "../../api/mensajeros";
import { listarDoctores } from "../../api/doctores";
import { listarVehiculos } from "../../api/vehiculos";
import { map } from "lodash";
import { LogsInformativos } from "../logs/logs";

export default function ModificarViajes({ data, history, setShow }) {

  const idDocumental = data[0];

  const dataTemp = {
    fecha: data[1],
    tipoCliente: data[2],
    clienteVisitar: data[3],
    direccion: data[4],
    ubicacion: data[5],
    motivoViaje: data[6],
    descripcionViaje: data[7],
    mensajero: data[8],
    doctor: data[9],
  };

  const tiposViaje = [
    "Viaje Especial",
    "Viaje Responsabilidad",
    "Asistencia Clínica Especial",
    "Asistencia Clínica Responsabilidad",
  ];

  const tiposCliente = [
    "Doctor",
    "Consultorio",
  ];

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const [signInLoading, setSignInLoading] = useState(false);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;

    return `${year}-${mes}-${dia}`;
  };

  // Estado para almacenar la fecha seleccionada
  const [fechaSeleccionada, setFechaSeleccionada] = useState(obtenerFechaActual());

  useEffect(() => {
    setFechaSeleccionada(obtenerFechaActual());
  }, []);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            setListEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, []);

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.mensajero) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      setSignInLoading(true);
      try {
        const dataTemp = {
          fecha: fechaSeleccionada,
          tipoCliente: formData.tipoCliente,
          clienteVisitar: formData.clienteVisitar,
          direccion: formData.direccion,
          ubicacion: formData.ubicacion,
          motivoViaje: formData.motivoViaje,
          descripcionViaje: formData.descripcionViaje,
          cargoAñadido: formData.cargoAñadido,
          mensajero: formData.mensajero,
          doctor: formData.doctor,
        };
        actualizarViajes(idDocumental, dataTemp).then((response) => {
          const { data } = response;
          console.log(data)

          toast.success(data.mensaje);

          history({
            search: queryString.stringify(""),
          });
          LogsInformativos("Se ha modificado el viaje " + dataTemp.direccion, dataTemp)
          setSignInLoading(false);
          setShow(false);
          //cancelarRegistro()
        }).catch((ex) => {
          if (ex.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
            setSignInLoading(false);
          } else {
            if (ex.response && ex.response.status === 401) {
              const { mensaje } = ex.response.data;
              toast.error(mensaje);
              setSignInLoading(false);
            }
          }
        });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [listarVehiculo, setListVehiculo] = useState([]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVehiculo();
  }, []);

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                value={fechaSeleccionada}
                onChange={(e) => setFechaSeleccionada(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label hidden >Tipo de cliente:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de cliente"
                value={formData.tipoCliente}
                name="tipoCliente"
                hidden
              >
                <option>Seleccionar</option>
                {tiposCliente.map((tipo, index) => (
                  <option key={index} value={tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          {formData.tipoCliente == "Doctor" && (
            <>
              <Row className="mb-2 mb-md-4 mb-lg-7">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                >
                  <Form.Label>Cliente a visitar:</Form.Label>
                </Col>
                <Col sm={12} md={8} lg={8}>
                  <Form.Control
                    as="select"
                    aria-label="Seleccionar cliente"
                    name="clienteVisitar"
                    defaultValue={formData.clienteVisitar}
                  >
                    <option>Elige una opción</option>
                    {map(listarEstablecimiento, (cat, index) => (
                      <option key={index} value={cat?.alias} selected={formData.clienteVisitar == cat?.alias}>
                        {cat?.alias}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </>
          )}
          {formData.tipoCliente == "Consultorio" && (
            <>
              <Row className="mb-2 mb-md-4 mb-lg-7">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                >
                  <Form.Label>Cliente a visitar:</Form.Label>
                </Col>
                <Col sm={12} md={8} lg={8}>
                  <Form.Control
                    as="select"
                    aria-label="Seleccionar cliente"
                    name="clienteVisitar"
                    defaultValue={formData.clienteVisitar}
                  >
                    <option>Elige una opción</option>
                    {map(listarDoctor, (cat, index) => (
                      <option key={index} value={cat?.nombre + " " + cat?.apellidoPaterno + " " + cat?.apellidoMaterno} selected={formData.clienteVisitar == cat?.nombre + " " + cat?.apellidoPaterno + " " + cat?.apellidoMaterno}>
                        {cat?.nombre + " " + cat?.apellidoPaterno + " " + cat?.apellidoMaterno}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </>
          )}
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Dirección:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="direccion"
                defaultValue={formData.direccion}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Motivo del Viaje:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar tipo de viaje"
                defaultValue={formData.motivoViaje}
                name="motivoViaje"
              >
                <option>Seleccionar</option>
                {tiposViaje.map((tipo, index) => (
                  <option key={index} value={tipo} selected={formData.motivoViaje == tipo}>
                    {tipo}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Descripción del Viaje:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="textarea"
                placeholder="Ingresa una descripción"
                defaultValue={formData.descripcionViaje}
                name="descripcionViaje"
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Vehiculo:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="select"
                aria-label="Seleccionar mensajero"
                defaultValue={formData.mensajero}
                name="mensajero"
              >
                <option>Elige una opción</option>
                {map(listarVehiculo, (cat, index) => (
                  <option key={index} value={cat?.modelo + "-" + cat?.placa} selected={formData.mensajero == cat?.modelo + "-" + cat?.placa}>
                    {cat?.modelo + "-" + cat?.placa}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Doctor:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Nombre del doctor"
                aria-label="Seleccionar doctor"
                defaultValue={formData.doctor}
                name="doctor"
              />
            </Col>
          </Row>
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
};

function initialFormValue(data) {
  return {
    fecha: data.fecha,
    tipoCliente: "Doctor",
    clienteVisitar: data.clienteVisitar,
    direccion: data.direccion,
    ubicacion: data.ubicacion,
    motivoViaje: data.motivoViaje,
    descripcionViaje: data.descripcionViaje,
    cargoAñadido: data.cargoAñadido,
    mensajero: data.mensajero,
    doctor: data.doctor,
  };
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio
    });
  });
  return dataTemp;
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      estado: data.estado
    });
  });
  return dataTemp;
}