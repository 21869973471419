import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarDepartamentoServicios } from "../../api/servicios";
import { listarDepartamentosProduccion } from "../../api/departamentos";
import { toast } from "react-toastify";
import { map } from "lodash";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import { registraDepartamentosODT, listarDepartamentosODT, actualizarDepartamentosODT } from "../../api/departamentosODT";

export default function AsignarDepartamento({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data[0];
  console.log(idDocumental)

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDepartamentosProduccion()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const [listarUltimoDepartamento, setListUltimoDepartamento] = useState([]);

  const obtenerUltimoDepartamento = () => {
    try {
      listarDepartamentosODT()
        .then((response) => {
          const { data } = response;

          if (!listarUltimoDepartamento && data) {
            setListUltimoDepartamento(formatModelUltimoDepartamento(data));
          } else {
            const datosUltimoDepartamento = formatModelUltimoDepartamento(data);
            setListUltimoDepartamento(datosUltimoDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUltimoDepartamento();
  }, []);

  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds(); // Obtener los minutos
  let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;

  const guardarDepartamentoODT = () => {
    try {
      const temp = formData.departamento.split("/");
      const dataTemp = {
        folioODT: data[2],
        departamento: temp[1],
        fechaEntrada: datosFecha,
        estado: "1"
      };

      registraDepartamentosODT(dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha asignado un departamento a la orden de cliente " + idDocumental, dataTemp)
          toast.success(data.mensaje);
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const finalizarUltimoDepartamentoODT = () => {
    try {
      const temp = formData.departamento.split("/");
      const dataTemp = {
        fechaSalida: datosFecha,
      };

      actualizarDepartamentosODT(listarUltimoDepartamento[0].id, dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha asignado un departamento a la orden de cliente " + idDocumental, dataTemp)
          toast.success(data.mensaje);

          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto
      const temp = formData.departamento.split("/");

      const dataTemp = {
        folioDepartamento: temp[0],
        departamento: temp[1],
        email: temp[2],
        estado: "5"
      }

      actualizarDepartamentoServicios(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        //notificacion
        LogsInformativos("Se ha asignado un departamento a la orden de trabajo " + idDocumental, dataTemp)
        toast.success(data.mensaje);

        history({
          search: queryString.stringify(""),
        });
        guardarDepartamentoODT();
        finalizarUltimoDepartamentoODT();
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
          <Form.Group>
            <Form.Label className='lblTelefono'>Departamento: </Form.Label>
            <Form.Control
              as="select"
              className='inpTelefono'
              placeholder='Ingresa modelo'
              defaultValue={formData.departamento}
              name="departamento"
            >
              <option>Elige una opción</option>
              {map(listarDoctor, (cat, index) => (
                <option key={index} value={cat?.folio + "/" + cat?.nombre + "/" + cat?.email}>
                  {" "}
                  {cat?.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    departamento: "",
  };
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      email: data.email,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelUltimoDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folioODT: data.folioODT,
      departamento: data.departamento,
      fechaEntrada: data.fechaEntrada,
      fechaSalida: data.fechaSalida,
      estado: data.estado,
    });
  });
  return dataTemp;
}
