import { useState, useEffect } from 'react';
import { eventsVacaciones } from '../../../api/vacaciones';
import { withRouter } from '../../../utils/withRouter';
import { Load } from "../../load/load";
import AgregarVacaciones from "./AgregarVacaciones";
import { Button } from "react-bootstrap";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Calendario from '../../calendario/calendario';

function Vacaciones(props) {
  const { location, history } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [eventosIniciales, setEventosIniciales] = useState([]);

  const obtenerEventos = () => {
    try {
      eventsVacaciones()
        .then((response) => {
          const { data } = response;

          setEventosIniciales(data);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEventos();
  }, [location]);

  console.log(eventosIniciales)

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroVacaciones = (content) => {
    setTitulosModal("Registar vacaciones de empleado");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <>
      {loading && <Load />}
      <h1>Vacaciones</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroVacaciones(
                <AgregarVacaciones
                  setShow={setShowModal}
                  history={history}
                />
              )
            }
          >
            <FontAwesomeIcon icon={faCalendarPlus} /> Registrar
          </Button>
        </div>
      </div>
      <Calendario
        eventosIniciales={eventosIniciales}
      />
      <div className="divTabla"></div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

export default withRouter(Vacaciones);