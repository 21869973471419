import React, { useState, useEffect } from "react";
import MapQuestClic from "./MapQuestClic";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot, faPlus } from "@fortawesome/free-solid-svg-icons";

export const MapaClic = ({ onDataFromChild }) => {
  const [lat, setLat] = useState("20.39625902133748");
  const [lng, setLng] = useState("-99.97020833213179");
  const [addressDetails, setAddressDetails] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [arrCords, setArrCords] = useState([]);

  const enviarDatosAlPadre = () => {
    const datos = addressDetails;
    // Llamar a la función de retorno con los datos
    onDataFromChild(datos);
    setAddressDetails("");
  };

  const setCenter = (lat, lng) => {
    setLat(lat);
    setLng(lng);

    window.L.mapquest.Map.getMap("map").setView(
      new window.L.LatLng(lat, lng),
      12
    );
  };

  const addMarker = (lat, lng, title) => {
    console.log("Adding marker:", lat, lng, title);
    const marker = window.L.mapquest
      .textMarker(new window.L.LatLng(lat, lng), {
        text: title || "",
        subtext: "",
        position: "right",
        type: "marker",
        icon: {
          primaryColor: "#a8190f",
          secondaryColor: "db2c2c",
          size: "md",
        },
      })
      .addTo(window.L.mapquest.Map.getMap("map"));

    setMarkers((prevMarkers) => [...prevMarkers, marker]);
  };

  const handleMapClick = async (event) => {
    // Obtener las coordenadas del clic
    const { latlng } = event;
    const clickedLat = latlng.lat;
    const clickedLng = latlng.lng;

    try {
      // Hacer una llamada a la API de geocodificación para obtener la dirección completa
      const response = await fetch(
        `https://www.mapquestapi.com/geocoding/v1/reverse?key=2eei5oDr1QNMmUGDCY8115cQclIbiAq3&location=${clickedLat},${clickedLng}`
      );

      if (!response.ok) {
        throw new Error("Error en la solicitud de geocodificación");
      }

      const data = await response.json();
      const location = data.results[0]?.locations[0];

      const addressDetailsText = `${location.street}, ${location.postalCode}, ${location.adminArea5}, ${location.adminArea3}, ${location.adminArea1}`;

      setAddressDetails(addressDetailsText);
      // Crear un nuevo objeto con los datos de latitud, longitud y ciudad
      const newCoords = `${clickedLat},${clickedLng},${location.adminArea5}`;
      // Actualizar la variable de estado arrCords con el nuevo objeto
      setArrCords(newCoords);

      addMarker(clickedLat, clickedLng, location.adminArea5);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    setCenter(lat, lng);

    // Limpiar marcadores al cambiar las coordenadas
    markers.forEach((marker) => {
      window.L.mapquest.Map.getMap("map").removeLayer(marker);
    });
  }, [lat, lng]);

  return (
    <div className="containerFluid">
      <MapQuestClic
        height="50vh"
        width="auto"
        center={[lat, lng]}
        tileLayer={"map"}
        zoom={12}
        apiKey="2eei5oDr1QNMmUGDCY8115cQclIbiAq3"
        onClick={handleMapClick}
      />
      {addressDetails && (
        <div style={{ marginTop: "2%" }}>
          <p style={{ fontStyle: "italic", fontWeight: "bold", fontSize: 20 }}>
            Domicilio seleccionado: {addressDetails}
          </p>
          <div style={{ textAlign: "center" }}>
            <Button onClick={enviarDatosAlPadre} variant="success">
              <FontAwesomeIcon icon={faPlus} />
              &nbsp; Agregar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
