import { useState, useEffect } from 'react';
import { obtenerDoctoresPorCorreo } from "../../api/doctores";
import { obtenerEstablecimientosPorCorreo } from "../../api/establecimientos";

function Direccion(props) {
    const { correoBuscar, correoEstablecimiento, correoDoctor } = props;
    console.log(correoBuscar, correoEstablecimiento, correoDoctor)

    // Para almacenar el nombre del cliente
    const [establecimiento, setEstablecimiento] = useState("");

    const cargarCategoria = () => {
        //
        try {
            if (correoBuscar == correoEstablecimiento) {
                obtenerEstablecimientosPorCorreo(correoEstablecimiento).then(response => {
                    const { data } = response;
                    const {calle, numeroExterior, numeroInterior, codigoPostal, municipio } = data;
                    setEstablecimiento(calle + ", NE: " + numeroExterior + "NI: " + numeroInterior + ", " + municipio + " CP: " + codigoPostal)
                }).catch(e => {
                    console.log(e)
                })
            } else {
                obtenerDoctoresPorCorreo(correoDoctor).then(response => {
                    const { data } = response;
                    const { calle, nExterior, nInterior, cPostal, municipio } = data;
                    setEstablecimiento(calle + ", NE: " + nExterior + " NI: " + nInterior + ", " + municipio + " CP: " + cPostal)
                }).catch(e => {
                    console.log(e)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        cargarCategoria();
    }, [correoBuscar, correoEstablecimiento, correoDoctor]);

    return (
        <>
            {establecimiento}
        </>
    );
}

export default Direccion;