import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { Load } from '../../load/load';
import { TablaServicios } from '../TablaServicios';
import { actualizarRecepciones } from '../../../api/recepciones';
import { Col, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { listarOrdenesServicio } from '../../../api/ordenesServicio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { LogsInformativos } from "../../logs/logs";

const ModificarMaterial = ({ data, history, setShow }) => {
  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    materiales: data[2],
    otros: data[4],
  };

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [loading, setLoading] = useState(true);

  const onSubmit = (e) => {
    e.preventDefault();

      try {
        setLoading(true);

        const dataTemp = {
          folioOrden: selectedOption,
          materialesEnviados: clasificacionMat
        };
        actualizarRecepciones(idDocumental, dataTemp).then((response) => {
          const { data } = response;
          console.log(data);
          LogsInformativos("Se ha modificado la entrega de material " + dataTemp.folioOrden, dataTemp)
          toast.success(data.mensaje);

          history({
            search: queryString.stringify(""),
          });
          setLoading(false);
          setShow(false);
        });
      } catch (e) {
        console.log(e);
      }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [clasificacionMat, setClasificacionMat] = useState(dataTemp.materiales);
  const [servicio, setServicio] = useState("");
  const [cantidad, setCantidad] = useState("");

  const agregarClas = () => {
    const nuevaClas = { servicio, cantidad };
    setClasificacionMat([...clasificacionMat, nuevaClas]);
    handleClose3();
  };

  const [listOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicios = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;

          if (!listOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicios();
  }, []);

  return (
    <>
      <Form onSubmit={onSubmit} onChange={onChange}>
        <div style={{ padding: "2%" }}>
          <div style={{ marginTop: "2%" }}>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Orden de cliente:</Form.Label>
              </Col>
              <Col sm={12} md={10} lg={10}>
                <Select
                  options={listOrdenes}
                  isSearchable={true}
                  placeholder={dataTemp.folio}
                  onChange={(selectedOption) =>
                    handleSeleccionChange(selectedOption)
                  }
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                xs={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblClaSerOS">
                  Material a enviar:
                </Form.Label>
              </Col>
              <Col xs={12} md={10} lg={10}>
                <Form.Control
                  as="select"
                  className="inpClasifSerOS"
                  placeholder="Clasificación"
                  onChange={(e) => setServicio(e.target.value)}
                  name="servicio"
                  defaultValue={formData.servicio}
                >
                  <option>Elige una opción</option>
                  <option value="Modelo superior">Modelo superior</option>
                  <option value="Modelo inferior">Modelo inferior</option>
                  <option value="Mordida de cera">Mordida de cera</option>
                  <option value="Mordida en silicon">Mordida en silicon</option>
                  <option value="Sin antagonista">Sin antagonista</option>
                  <option value="Cobrar llenado de cucharilla">
                    Cobrar llenado de cucharilla
                  </option>
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                xs={12}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblTipoSerOS">Cantidad:</Form.Label>
              </Col>
              <Col xs={12} md={10} lg={10}>
                <Form.Control
                  name="cantidad"
                  type="numer"
                  className="inpMaterialOS"
                  placeholder="Cantidad"
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Col>
            </Row>
          </div>
          <div className="btnServiciosTabla">
            <Row>
              <Col md={{ span: 11, offset: 10 }} lg={{ span: 11, offset: 10 }}>
                <Button className="btnAddServiceTable" onClick={agregarClas}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Agregar
                </Button>
              </Col>
            </Row>
          </div>
          <TablaServicios
            productos={clasificacionMat}
            setProductos={setClasificacionMat}
          />
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ModificarMaterial;

function initialFormValue(data) {
    return {
      folio: data.folio,
    };
  }

  const formatModelOrdenes = (data) => {
    return data.map((orden) => ({
      value: orden.folio,
      label: `Orden ${orden.folio}`,
    }));
  };
  