import React, { useState } from "react";

function OpenpayTransferencias() {
  const [paymentData, setPaymentData] = useState({
    customer_id: "aswrjptj8ih4othmcthl", // Reemplaza con el ID del cliente real
    customer_name: "John Doe", // Asegúrate de incluir el nombre del cliente
    customer_email: "john.doe@example.com", // Asegúrate de incluir el email del cliente
    amount: 12.50,
    description: "Transferencia entre cuentas",
    order_id: "oid-1245"
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const YOUR_API_KEY = "sk_bf32c8b3b2d342baace620c67b7f10c2"; // Reemplaza con tu propia API Key
      const YOUR_API_SECRET = "pk_2b13389c063a4b789e8e81e4040557e1"; // Reemplaza con tu propia Secret Key

      const response = await fetch(
        "https://sandbox-api.openpay.mx/v1/mh3qxtumcdo4u0fuvbg3/charges", // Reemplaza con tu Merchant ID
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Basic ${btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`)}`,
          },
          body: JSON.stringify({
            method: 'bank_account',
            amount: paymentData.amount,
            description: paymentData.description,
            order_id: paymentData.order_id,
            customer: {
              id: paymentData.customer_id,
              name: paymentData.customer_name,
              email: paymentData.customer_email, // Incluye el email del cliente aquí
            },
          }),
        }
      );
      
      if (response.ok) {
        const newPayment = await response.json();
        console.log("Transferencia realizada con éxito:", newPayment);
        // Puedes agregar lógica para manejar el éxito, como limpiar el formulario o redirigir
      } else {
        const errorDetails = await response.json();
        console.log(`Error al realizar la transferencia: ${errorDetails.description || response.statusText}`);
      }
    } catch (error) {
      console.log(`Error de red: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="number"
        name="amount"
        placeholder="Monto"
        value={paymentData.amount}
        onChange={handleChange}
      />
      <input
        type="text"
        name="description"
        placeholder="Descripción"
        value={paymentData.description}
        onChange={handleChange}
      />
      <input
        type="text"
        name="customer_name"
        placeholder="Nombre del Cliente"
        value={paymentData.customer_name}
        onChange={handleChange}
      />
      <input
        type="email"
        name="customer_email"
        placeholder="Email del Cliente"
        value={paymentData.customer_email}
        onChange={handleChange}
      />
      <button type="submit" disabled={loading}>
        {loading ? "Procesando..." : "Realizar transferencia"}
      </button>
    </form>
  );
}

export default OpenpayTransferencias;
