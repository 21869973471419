import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { actualizarVehiculos } from "../../../api/vehiculos";
import { toast } from "react-toastify";
import queryString from "query-string";
import Dropzone from "../../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import { LogsInformativos } from "../../logs/logs";

export default function ModificarVehiculo({ data, history, setShow }) {

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(data[7]);

  const idDocumental = data[0];

  const dataTemp = {
    modelo: data[1],
    placa: data[2],
    numEconomico: data[3],
    color: data[4],
    kilometrajeActual: data[5],
    imagen: data[7]
  };

  console.log(data)

  const [formData, setFormData] = useState(initialFormValue(dataTemp));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      subeArchivosCloudinary(imagenProducto, "vehiculos").then(response => {
        const { data } = response;
        setLoading(true);
        // Sube a cloudinary la imagen principal del producto

        const dataTemp = {
          conductor: formData.conductor,
          modelo: formData.modelo,
          placa: formData.placa,
          numEconomico: formData.numEconomico,
          color: formData.color,
          kilometrajeActual: formData.kilometrajeActual,
          imagen: data.secure_url,
        };

        actualizarVehiculos(idDocumental, dataTemp).then((response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha modificado el vehiculo con placa " + dataTemp.placa, dataTemp)
          toast.success(data.mensaje);

          history({
            search: queryString.stringify(""),
          });
          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        });
      }).then(e => {
        console.log(e)
      })
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
          <div className="imagenPrincipal">
            <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
            <div title="Seleccionar imagen de la categoría" className="imagenProducto">
              <Dropzone
                setImagenFile={setImagenProducto}
                imagenProductoBD={formData.imagen}
              />
            </div>
          </div>
          <Form.Group>
            <Form.Label className='lblTelefono'>Modelo: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa modelo'
              defaultValue={formData.modelo}
              name="modelo"
            />
            <Form.Label className='lblEmail'>Placa: </Form.Label>
            <Form.Control
              type='text'
              className='inpNombreEst'
              placeholder='placa'
              defaultValue={formData.placa}
              name="placa"
            />
          </Form.Group>
          <Form.Group className='lblTelefono'>
            <Form.Label className='lblTelefono'># Economico: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa economico'
              defaultValue={formData.numEconomico}
              name="numEconomico"
            />
            <Form.Label className='lblTelefono'>Color: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa color'
              defaultValue={formData.color}
              name="color"
            />
            <Form.Label className='lblTelefono'>Kilometraje actual: </Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa kilometraje actual'
              defaultValue={formData.kilometrajeActual}
              name="kilometrajeActual"
            />
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    conductor: data.conductor,
    modelo: data.modelo,
    placa: data.placa,
    numEconomico: data.numEconomico,
    color: data.color,
    kilometrajeActual: data.kilometrajeActual,
    imagen: data.imagen
  };
}
