import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { Load } from "../load/load";
import { guardarImagenServicios } from "../../api/servicios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from "../logs/logs";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import Dropzone from "../dropzone/Dropzone";

export default function GuardarImagenServicio({ data, history, setShow }) {
  console.log(data);
  const idDocumental = data[0];

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  const [formData, setFormData] = useState(initialFormValue());
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [fechaNueva, setFechaNueva] = useState(dia + "/" + mes + "/" + year);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      if (imagenProducto != null) {
        subeArchivosCloudinary(imagenProducto, "ordenTrabajo").then(response => {
          const { data } = response;
          setLoading(true);

          const dataTemp = {
            imagenResultadoFinal: data.secure_url
          };

          guardarImagenServicios(idDocumental, dataTemp).then((response) => {
            const { data } = response;
            console.log(data);
            LogsInformativos("Se ha guardado la imagen del resultado final del servicio " + idDocumental, dataTemp)
            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });
            setLoading(false);
            setShow(false);
            //cancelarRegistro()
          });
        }).then(e => {
          console.log(e)
        })
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <Form className="formOrden2" onSubmit={onSubmit} onChange={onChange}>
          <div className="divSubmit">
            <div className="imagenPrincipal">
              <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
              <div title="Seleccionar imagen de la categoría" className="imagenProducto">
                <Dropzone
                  setImagenFile={setImagenProducto}
                />
              </div>
            </div>
            <div style={{ marginTop: "2%" }}>
              <Button variant="warning" onClick={onSubmit}>
                <FontAwesomeIcon icon={faRefresh} /> Actualizar
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    numero: "",
    nombre: ""
  };
}
