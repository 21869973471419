import { faCashRegister, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import {
  actualizaDeshabilitarAbonosOrdenes,
  obtenerAbonosPorFolioOS,
} from "../../../api/abonosOrdenes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { obtenerCyDPorFolioOS } from "../../../api/preciosOrden";
import { actualizaPagoOrdenesServicioFolio } from "../../../api/ordenesServicio";
import { LogsInformativos } from "../../logs/logs";
import { registraCajaChica } from "../../../api/cajaChica";
import queryString from "query-string";

const AprobarPagos = ({ dataOrden, history, setShowModal }) => {
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [abonosOrdenes, setAbonosOrdenes] = useState({});
  const [cydOrdenes, setCydOrdenes] = useState({});
  const [sumaPagosAprobados, setSumaPagosAprobados] = useState({});
  const [totalPagar, setTotalPagar] = useState({});
  const [restante, setRestante] = useState({});

  // Filtra las órdenes con estado igual a 0
  const filteredOrders = dataOrden.filter(order => order.estado === "0");

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const obtenerDatosUsuario = useCallback(async () => {
    try {
      const response = await obtenerUsuario(obtenidusuarioLogueado(getTokenApi()));
      const { data } = response;
      setNombreUsuario(data.nombre);
    } catch (e) {
      if (e.message === "Network Error") {
        toast.error("Conexión al servidor no disponible");
      }
    }
  }, []);

  const processOrders = async (orders) => {
    try {
      const abonos = {};
      const cyd = {};
      const totals = {};
      const payments = {};
      const remainders = {};

      // Filtra las órdenes con estado igual a 0
      const filteredOrders = orders.filter(order => order.estado === "0");

      await Promise.all(filteredOrders.map(async (order) => {
        const { ordenServicio, cantidadRecibida, metodoPago, id } = order;

        // Extrae el array de la propiedad `data`
        const abonosResponse = await obtenerAbonosPorFolioOS(ordenServicio);
        const abonosData = abonosResponse.data; // Extraído del objeto de respuesta
        abonos[ordenServicio] = formatModelAbonos(abonosData);

        const cydResponse = await obtenerCyDPorFolioOS(ordenServicio);
        const cydData = cydResponse.data; // Extraído del objeto de respuesta
        cyd[ordenServicio] = formatModelCargosyDescuentos(cydData);

        const totalCalculado = cyd[ordenServicio].reduce((acc, item) => {
          return item.tipoOperacion === "descuento" ? acc - parseFloat(item.cantidad) : acc + parseFloat(item.cantidad);
        }, 0);
        totals[ordenServicio] = totalCalculado;

        const totalPagos = abonos[ordenServicio].reduce((acc, item) => {
          return item.estado === "1" ? acc + parseFloat(item.cantidadRecibida || 0) : acc;
        }, 0);
        payments[ordenServicio] = totalPagos;

        const restante = totalCalculado - (totalPagos + parseFloat(cantidadRecibida || 0));
        remainders[ordenServicio] = restante;

        console.log(remainders);

        if (metodoPago.toLowerCase() === "efectivo") {
          await guardarCajaChica({ ...order, nombreUsuario });
        }

        if (restante <= 0) {
          await pagoTerminado(ordenServicio);
        }

        await actualizaDeshabilitarAbonosOrdenes(id, { aprobo: nombreUsuario, estado: "1" });

        //oast.success("Pago aprobado con éxito");
      }));

      setAbonosOrdenes(abonos);
      setCydOrdenes(cyd);
      setTotalPagar(totals);
      setSumaPagosAprobados(payments);
      setRestante(remainders);
      toast.success("Pagos aprobados con éxito");
    } catch (e) {
      console.log(e);
    }
  };

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    hora = hora < 10 ? `0${hora}` : hora;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };

  const obtenerNumeroDeSemana = (fecha) => {
    let fechaCopia = new Date(Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()));
    fechaCopia.setUTCDate(fechaCopia.getUTCDate() + 4 - (fechaCopia.getUTCDay() || 7));
    let primerDiaDelAno = new Date(Date.UTC(fechaCopia.getUTCFullYear(), 0, 1));
    let numeroDeSemana = Math.ceil(((fechaCopia - primerDiaDelAno) / 86400000 + 1) / 7);
    return numeroDeSemana;
  };

  const guardarCajaChica = async (order) => {
    const fechaActual = obtenerFechaActual();
    const semana = obtenerNumeroDeSemana(new Date());
    try {
      const dataTemp2 = {
        idUsuario: nombreUsuario,
        fechayHora: fechaActual,
        concepto: "Abono de la Orden de cliente " + order.ordenServicio,
        cantidad: order.cantidadRecibida,
        tipo: "Entrada",
        semana: semana + "-" + fechaActual.split('-')[0],
        tipoGasto: "dia",
        fecha: fechaActual.split(' ')[0],
        comprobante: "",
        estado: "1",
      };
      const response = await registraCajaChica(dataTemp2);
      const { data } = response;
      //toast.success(data.mensaje);
    } catch (e) {
      console.log(e);
    }
  };

  const pagoTerminado = async (folio) => {
    try {
      const dataTemp = { estadoPago: "1" };
      const response = await actualizaPagoOrdenesServicioFolio(folio, dataTemp);
      const { data } = response;
      LogsInformativos("Se actualizó el estado del pago" + nombreUsuario, dataTemp);
      toast.success(data.mensaje);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAceptar = async () => {
    if (!dataOrden || dataOrden.length === 0) {
      toast.error("No hay órdenes para procesar");
      return;
    }
    try {
      await processOrders(dataOrden);
      history({
        search: queryString.stringify(""),
      });
      //toast.success("Pagos procesados exitosamente");
      setShowModal(false); // Cierra el modal si se está usando uno
    } catch (e) {
      toast.error("Error al procesar los pagos: " + e.message);
    }
  };


  return (
    <div style={{ padding: "1%" }}>
      <h1>Aprobar los pagos</h1>

      <Table
        striped
        bordered
        hover
        style={{ textAlign: "center", textTransform: "capitalize" }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha</th>
            <th>Cantidad</th>
            <th>Metodo de pago</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((abono, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{abono.fecha}</td>
              <td>{abono.cantidadRecibida}</td>
              <td>{!abono.metodoPago ? "Efectivo" : abono.metodoPago}</td>
              <td>{abono.estado == "0" ? "Por aprobar" : "Aprobado"}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div style={{ textAlign: "center" }}>
        <Button variant="success" onClick={handleAceptar}>
          <FontAwesomeIcon icon={faCashRegister} /> Aprobar
        </Button>
      </div>
    </div>
  );
};

function formatModelAbonos(data) {
  return data.map((item) => ({
    id: item._id,
    ordenServicio: item.ordenServicio,
    cantidadRecibida: item.cantidadRecibida,
    estado: item.estado,
    fecha: item.fecha,
    metodoPago: item.metodoPago
  }));
}

function formatModelCargosyDescuentos(data) {
  return data.map((item) => ({
    id: item._id,
    tipoOperacion: item.tipo,
    concepto: item.concepto,
    cantidad: item.cantidad,
  }));
}

export default AprobarPagos;
