import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { registraEstablecimientos, obtenerNumeroEstablecimiento } from "../../../api/establecimientos";
import { registraUsuarios } from "../../../api/usuarios";
import queryString from "query-string";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import Dropzone from "../../dropzone/Dropzone";
import estadosPaisData from '../catalogosJSON/estadosPais.json';
import municipiosEstadoData from '../catalogosJSON/municipiosEstadosPais.json'
import { LogsInformativos } from "../../logs/logs";

export default function AltaCliente({ history, setShow, fechaInicio, fechaFin }) {
  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");

  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);


  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [password, setPassword] = useState('');

  const generateRandomPassword = () => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
    const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
    let newPassword = '';

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
  }

  useEffect(() => {
    generateRandomPassword();
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroEstablecimiento().then(response => {
        const { data } = response;
        // console.log(data)
        const { noConsultorio } = data;
        setFolioActual(noConsultorio)
      }).catch(e => {
        console.log(e)
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    obtenerFolio();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate form field
    if (!formData.razonSocial) {
      toast.warning("Completa el formulario");
      return;
    }

    try {
      setLoading(true);

      let imagenUrl = "";

      // Upload the image to Cloudinary if available
      if (imagenProducto) {
        const uploadResponse = await subeArchivosCloudinary(imagenProducto, "consultorios");
        imagenUrl = uploadResponse.data.secure_url;
      }

      // Create the data object to register the consultorio
      const dataTemp = {
        folio: folioActual,
        razonSocial: formData.razonSocial,
        estadoEstablecimiento: estadoPais,
        municipio: municipioSeleccionado,
        colonia: formData.colonia,
        calle: formData.calle,
        numeroExterior: formData.numeroExterior,
        numeroInterior: formData.numeroInterior,
        codigoPostal: formData.codigoPostal,
        telefono: formData.telefono,
        haceEsquina: formData.haceEsquina,
        email: formData.email,
        observaciones: formData.observaciones,
        horario: formData.horario,
        estado: "true",
        alias: formData.alias,
        imagen: imagenUrl
      };

      // Create the data object to register the user
      const dataTemp2 = {
        nombre: formData.razonSocial,
        email: formData.email,
        password: password,
        tipo: "consultorio",
        estado: "true"
      };

      await registraUsuarios(dataTemp2)
        .then(() => {
          // Register the messenger
          return registraEstablecimientos(dataTemp);
        })
        .then((messengerResponse) => {
          const messengerData = messengerResponse.data;

          console.log(messengerData);
          LogsInformativos(`Se ha registrado el doctor ${dataTemp.nombre}`, dataTemp);

          toast.success(messengerData.mensaje);

          // Update the history and reset form
          history({ search: queryString.stringify("") });
          setLoading(false);
          setShow(false);
        })
        .catch((ex) => {
          if (ex.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          } else if (ex.response && ex.response.status === 401) {
            toast.warning("Ya existe un usuario con este correo");
          } else {
            console.log(ex);
          }
          setLoading(false);
        });

    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className='generalEstablecimiento'>
        <div className="imagenPrincipal">
          <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
          <div title="Seleccionar imagen de la categoría" className="imagenProducto">
            <Dropzone
              setImagenFile={setImagenProducto}
            />
          </div>
        </div>
        <Form className='formEstablecimiento' onSubmit={onSubmit} onChange={onChange}>
          <Form.Label className='lblNombreEst'>Folio: <code>*</code></Form.Label>
          <Form.Control
            type='text'
            className='inpNombreEst'
            placeholder='Ingresa nombre comercial o razon social'
            value={folioActual}
            name="folio"
            disabled
          />
          <Form.Label className='lblNombreEst'>Nombre o Razón Social: <code>*</code></Form.Label>
          <Form.Control
            type='text'
            className='inpNombreEst'
            placeholder='Ingresa nombre comercial o razon social'
            defaultValue={formData.razonSocial}
            name="razonSocial"
          />
          <Form.Label className='lblNombreEst'>Alias: <code>*</code></Form.Label>
          <Form.Control
            type='text'
            className='inpNombreEst'
            placeholder='Ingresa Alias'
            defaultValue={formData.alias}
            name="alias"
          />
          <Form.Group className='contactoCli'>
            <Form.Label className='lblTelefono'>Telefono: <code>*</code></Form.Label>
            <Form.Control
              type='text'
              className='inpTelefono'
              placeholder='Ingresa telefono'
              defaultValue={formData.telefono}
              name="telefono"
            />
            <Form.Label className='lblEmail'>Correo Electrónico: <code>*</code></Form.Label>
            <Form.Control
              type='email'
              className='inpEmail'
              placeholder='ejemplo@ejemplo.com'
              defaultValue={formData.email}
              name="email"
            />
          </Form.Group>
          <Form.Group className='dom1Cli'>
            <Form.Label className='lblCalle'>Calle: <code>*</code></Form.Label>
            <Form.Control
              type='text'
              className='inpCalle'
              placeholder='Ingresa calle'
              defaultValue={formData.calle}
              name="calle"
            />
            <Form.Label className='lblnExt'>Num Ext: <code>*</code></Form.Label>
            <Form.Control
              type='number'
              className='inpNumExt'
              placeholder='Ingresa numero exterior'
              defaultValue={formData.numeroExterior}
              name="numeroExterior"
            />
            <Form.Label className='lblnInt'>Num Int: </Form.Label>
            <Form.Control
              type='number'
              className='inpNumInt'
              placeholder='Ingresa numero interior'
              defaultValue={formData.numeroInterior}
              name="numeroInterior"
            />

          </Form.Group>
          <Form.Group className='dom2Cli'>
            <div className="colonia">
              <Form.Label className='lblColonia'>Colonia: <code>*</code></Form.Label>
              <Form.Control
                type='text'
                className='inpColonia'
                placeholder='Ingresa colonia'
                defaultValue={formData.colonia}
                name="colonia"
              />
            </div>
            <div className="estadoCli">
              <Form.Label className='lblEstadoEst'>Estado: <code>*</code></Form.Label>
              <Form.Control
                as="select"
                value={estadoPais}
                onChange={(e) => setEstadoPais(e.target.value)}
              >
                <option>Selecciona un Estado</option>
                {estadosPaisData.map((estado) => (
                  <option key={estado.clave} value={estado.nombre}>
                    {estado.nombre}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="municipioCli">
              <Form.Label className='lblMunicipio'>Municipio: <code>*</code></Form.Label>
              <Form.Control
                as="select"
                value={municipioSeleccionado}
                onChange={(e) => setMunicipioSeleccionado(e.target.value)}
              >
                <option>Selecciona un Municipio</option>
                {municipiosEstadoData[estadoPais] &&
                  municipiosEstadoData[estadoPais].map((municipio) => (
                    <option key={municipio} value={municipio}>
                      {municipio}
                    </option>
                  ))}
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group className='dom3Cli'>
            <Form.Label className='lblCP'>Codigo Postal: <code>*</code></Form.Label>
            <Form.Control
              type='number'
              className='inpCP'
              placeholder='Ingresa Codigo Postal'
              defaultValue={formData.codigoPostal}
              name="codigoPostal"
            />
            <Form.Label className='lblEsquina'>Hace esquina con: <code>*</code></Form.Label>
            <Form.Control
              type='text'
              className='inpEsquina'
              placeholder='Ingresa Referencia'
              defaultValue={formData.haceEsquina}
              name="haceEsquina"
            />
          </Form.Group>
          <Form.Label className='lblHorario'>Horario laboral: <code>*</code></Form.Label>
          <Form.Control
            type='text'
            className='inpHorario'
            placeholder='Ingresa horario laboral'
            defaultValue={formData.horario}
            name="horario"
          />
          <Form.Group className='bloque6Est'>
            <Form.Label>Observaciones:</Form.Label>
            <Form.Control
              placeholder="Observaciones"
              className='txtObservaciones'
              as="textarea" rows={2}
              defaultValue={formData.observaciones}
              name="observaciones"
            />
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  )
}

function initialFormValue() {
  return {
    razonSocial: "",
    estadoEstablecimiento: "",
    municipio: "",
    colonia: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "",
    codigoPostal: "",
    telefono: "",
    haceEsquina: "",
    email: "",
    horario: "",
    observaciones: "",
    alias: ""
  };
}
