import { Col, Container, Form, Image, Navbar, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { registraDoctores, obtenerNumeroDoctor } from "../../../api/doctores";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import Dropzone from "../../dropzone/Dropzone";
import estadosPaisData from "../catalogosJSON/estadosPais.json";
import municipiosEstadoData from "../catalogosJSON/municipiosEstadosPais.json";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import AvisoPrivacidad from "./AvisoProvacidad";
import { LogsInformativos } from "../../logs/logs";
import Encabezado from "../../../assets/img/encabezado_formulario_2.png";
import "./Alta.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function AltaCliente3() {
  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");

  const [estadoPais2, setEstadoPais2] = useState("");
  const [municipioSeleccionado2, setMunicipioSeleccionado2] = useState("");

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const modificarCliente = (content) => {
    setTitulosModal("Aviso privacidad");
    setContentModal(content);
    setShowModal(true);
  };

  useEffect(() => {
    modificarCliente(<AvisoPrivacidad setShow={setShowModal} />);
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroDoctor()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noDoctor } = data;
          setFolioActual(noDoctor);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();
    if (showAdditionalSections == true) {
      if (
        !formData.nombre ||
        !formData.apellidoPaterno ||
        !formData.telefonoCelular ||
        !formData.email ||
        !formData.numeroExterior ||
        !formData.colonia ||
        !estadoPais ||
        !municipioSeleccionado ||
        !formData.codigoPostal ||
        !formData.haceEsquina ||
        !formData.numeroExteriorFiscal ||
        !formData.coloniaFiscal ||
        !estadoPais2 ||
        !municipioSeleccionado2 ||
        !formData.codigoPostalFiscal ||
        !formData.haceEsquinaFiscal ||
        !formData.razonSocial ||
        !formData.tipoPersona ||
        !formData.regimenFiscal ||
        !formData.cfdi
      ) {
        toast.warning("Completa el formulario");
      } else {
        try {
          if (imagenProducto) {
            subeArchivosCloudinary(imagenProducto, "doctores")
              .then((response) => {
                const { data } = response;
                setLoading(true);

                const dataTemp = {
                  folio: folioActual,
                  nombre: formData.nombre,
                  apellidoPaterno: formData.apellidoPaterno,
                  apellidoMaterno: formData.apellidoMaterno,
                  fechaNacimiento: formData.fechaNacimiento,
                  telefonoCelular: formData.telefonoCelular,
                  email: formData.email,
                  rfc: formData.rfc,
                  observaciones: formData.observaciones,
                  estado: "true",
                  nombreConsultorio: "",
                  correoConsultorio: "",
                  imagen: "",
                  calle: formData.calle,
                  nExterior: formData.numeroExterior,
                  nInterior: formData.numeroInterior,
                  colonia: formData.colonia,
                  estadoDom: estadoPais,
                  municipio: municipioSeleccionado,
                  cPostal: formData.codigoPostal,
                  referencia: formData.haceEsquina,
                  direcciones: [{
                    calle: formData.calle,
                    numeroExterior: formData.numeroExterior,
                    numeroInterior: formData.numeroInterior,
                    colonia: formData.colonia,
                    estado: estadoPais,
                    municipio: municipioSeleccionado,
                    codigoPostal: formData.codigoPostal,
                    haceEsquina: formData.referencia
                  }],
                  constancia: data.secure_url,
                  razonSocial: formData.razonSocial,
                  tipoPersona: formData.tipoPersona,
                  regimenFiscal: formData.regimenFiscal,
                  cdfi: formData.cfdi,

                  calleFiscal: formData.calleFiscal,
                  nExteriorFiscal: formData.numeroExteriorFiscal,
                  nInteriorFiscal: formData.numeroInteriorFiscal,
                  coloniaFiscal: formData.coloniaFiscal,
                  estadoDomFiscal: estadoPais2,
                  municipioFiscal: municipioSeleccionado2,
                  cPostalFiscal: formData.codigoPostalFiscal,
                  referenciaFiscal: formData.haceEsquinaFiscal,
                };
                registraDoctores(dataTemp).then((response) => {
                  const { data } = response;
                  LogsInformativos(
                    "Se ha registrado el doctor " + dataTemp.folio,
                    dataTemp
                  );

                  console.log(data);

                  toast.success(data.mensaje);
                  window.location.reload();
                  //setFormData(initialFormValue())
                  setLoading(false);
                  //cancelarRegistro()
                }).catch((ex) => {
                  if (ex.message === "Network Error") {
                    toast.error("Conexión al servidor no disponible");
                  } else {
                    if (ex.response && ex.response.status === 401) {
                      const { mensaje } = e.response.data;
                      toast.error(mensaje);
                      setLoading(false);
                    }
                  }
                });
              })
              .then((e) => {
                console.log(e);
              });
          } else {
            setLoading(true);

            const dataTemp = {
              folio: folioActual,
              nombre: formData.nombre,
              apellidoPaterno: formData.apellidoPaterno,
              apellidoMaterno: formData.apellidoMaterno,
              fechaNacimiento: formData.fechaNacimiento,
              telefonoCelular: formData.telefonoCelular,
              email: formData.email,
              rfc: formData.rfc,
              observaciones: formData.observaciones,
              estado: "true",
              nombreConsultorio: "",
              correoConsultorio: "",
              imagen: "",
              direcciones: [{
                calle: formData.calle,
                numeroExterior: formData.numeroExterior,
                numeroInterior: formData.numeroInterior,
                colonia: formData.colonia,
                estado: estadoPais,
                municipio: municipioSeleccionado,
                codigoPostal: formData.codigoPostal,
                haceEsquina: formData.referencia
              }],
              calle: formData.calle,
              nExterior: formData.numeroExterior,
              nInterior: formData.numeroInterior,
              colonia: formData.colonia,
              estadoDom: estadoPais,
              municipio: municipioSeleccionado,
              cPostal: formData.codigoPostal,
              referencia: formData.haceEsquina,
            };
            registraDoctores(dataTemp).then((response) => {
              const { data } = response;
              LogsInformativos(
                "Se ha registrado el doctor " + dataTemp.folio,
                dataTemp
              );

              console.log(data);

              toast.success(data.mensaje);
              window.location.reload()
              //setFormData(initialFormValue())
              setLoading(false);
              //cancelarRegistro()
            }).catch((ex) => {
              if (ex.message === "Network Error") {
                toast.error("Conexión al servidor no disponible");
              } else if (ex.response && ex.response.status === 401) {
                toast.warning("Ya existe un usuario con este correo");
              } else {
                console.log(ex);
              }
              setLoading(false);
            });


          }

        } catch (e) {
          console.log(e);
        }
      }
    } else {
      if (
        !formData.nombre ||
        !formData.apellidoPaterno ||
        !formData.telefonoCelular ||
        !formData.email ||
        !formData.numeroExterior ||
        !formData.colonia ||
        !estadoPais ||
        !municipioSeleccionado ||
        !formData.codigoPostal ||
        !formData.haceEsquina
      ) {
        toast.warning("Completa el formulario");
      } else {
        try {
          if (imagenProducto) {
            subeArchivosCloudinary(imagenProducto, "doctores")
              .then((response) => {
                const { data } = response;
                setLoading(true);

                const dataTemp = {
                  folio: folioActual,
                  nombre: formData.nombre,
                  apellidoPaterno: formData.apellidoPaterno,
                  apellidoMaterno: formData.apellidoMaterno,
                  fechaNacimiento: formData.fechaNacimiento,
                  telefonoCelular: formData.telefonoCelular,
                  email: formData.email,
                  rfc: formData.rfc,
                  observaciones: formData.observaciones,
                  estado: "true",
                  nombreConsultorio: "",
                  correoConsultorio: "",
                  imagen: "",
                  calle: formData.calle,
                  nExterior: formData.numeroExterior,
                  nInterior: formData.numeroInterior,
                  colonia: formData.colonia,
                  estadoDom: estadoPais,
                  municipio: municipioSeleccionado,
                  cPostal: formData.codigoPostal,
                  referencia: formData.haceEsquina,
                  direcciones: [{
                    calle: formData.calle,
                    numeroExterior: formData.numeroExterior,
                    numeroInterior: formData.numeroInterior,
                    colonia: formData.colonia,
                    estado: estadoPais,
                    municipio: municipioSeleccionado,
                    codigoPostal: formData.codigoPostal,
                    haceEsquina: formData.referencia
                  }],

                  constancia: data.secure_url,
                  razonSocial: formData.razonSocial,
                  tipoPersona: formData.tipoPersona,
                  regimenFiscal: formData.regimenFiscal,
                  cdfi: formData.cfdi,

                  calleFiscal: formData.calleFiscal,
                  nExteriorFiscal: formData.numeroExteriorFiscal,
                  nInteriorFiscal: formData.numeroInteriorFiscal,
                  coloniaFiscal: formData.coloniaFiscal,
                  estadoDomFiscal: estadoPais2,
                  municipioFiscal: municipioSeleccionado2,
                  cPostalFiscal: formData.codigoPostalFiscal,
                  referenciaFiscal: formData.haceEsquinaFiscal,
                };
                registraDoctores(dataTemp).then((response) => {
                  const { data } = response;
                  LogsInformativos(
                    "Se ha registrado el doctor " + dataTemp.folio,
                    dataTemp
                  );

                  console.log(data);

                  toast.success(data.mensaje);
                  window.location.reload();
                  //setFormData(initialFormValue())
                  setLoading(false);
                  //cancelarRegistro()
                }).catch((ex) => {
                  if (ex.message === "Network Error") {
                    toast.error("Conexión al servidor no disponible");
                  } else {
                    if (ex.response && ex.response.status === 401) {
                      const { mensaje } = e.response.data;
                      toast.error(mensaje);
                      setLoading(false);
                    }
                  }
                });
              })
              .then((e) => {
                console.log(e);
              });
          } else {
            setLoading(true);

            const dataTemp = {
              folio: folioActual,
              nombre: formData.nombre,
              apellidoPaterno: formData.apellidoPaterno,
              apellidoMaterno: formData.apellidoMaterno,
              fechaNacimiento: formData.fechaNacimiento,
              telefonoCelular: formData.telefonoCelular,
              email: formData.email,
              rfc: formData.rfc,
              observaciones: formData.observaciones,
              estado: "true",
              nombreConsultorio: "",
              correoConsultorio: "",
              imagen: "",
              calle: formData.calle,
              nExterior: formData.numeroExterior,
              nInterior: formData.numeroInterior,
              colonia: formData.colonia,
              estadoDom: estadoPais,
              municipio: municipioSeleccionado,
              cPostal: formData.codigoPostal,
              referencia: formData.haceEsquina,
              direcciones: [{
                calle: formData.calle,
                numeroExterior: formData.numeroExterior,
                numeroInterior: formData.numeroInterior,
                colonia: formData.colonia,
                estado: estadoPais,
                municipio: municipioSeleccionado,
                codigoPostal: formData.codigoPostal,
                haceEsquina: formData.referencia
              }],
            };
            registraDoctores(dataTemp).then((response) => {
              const { data } = response;
              LogsInformativos(
                "Se ha registrado el doctor " + dataTemp.folio,
                dataTemp
              );

              console.log(data);

              toast.success(data.mensaje);
              window.location.reload()
              //setFormData(initialFormValue())
              setLoading(false);
              //cancelarRegistro()
            }).catch((ex) => {
              if (ex.message === "Network Error") {
                toast.error("Conexión al servidor no disponible");
              } else if (ex.response && ex.response.status === 401) {
                toast.warning("Ya existe un usuario con este correo");
              } else {
                console.log(ex);
              }
              setLoading(false);
            });

          }

        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const footerStyle = {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#0c1b30",
    color: "white",
    textAlign: "center",
    padding: "10px 0",
  };

  // Estado para controlar la visibilidad de las secciones adicionales
  const [showAdditionalSections, setShowAdditionalSections] = useState(false);

  // Función para manejar el cambio del checkbox
  const handleCheckboxChange = (e) => {
    setShowAdditionalSections(e.target.checked);
  };

  return (
    <>
      {loading && <Load />}
      <div>
        <Navbar
          className="justify-content-center"
          style={{
            backgroundColor: "#0c1b30",
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Navbar.Brand>
            <img
              alt=""
              src={Encabezado}
              width="100%"
              height="100%"
            />
          </Navbar.Brand>
        </Navbar>
        <div style={{ marginTop: "80px", marginBottom: "80px" }}>
          <Form
            onSubmit={onSubmit}
            onChange={onChange}
            style={{ padding: "2%" }}
          >
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div>
                <h4>Datos Generales:</h4>
              </div>
              <div className="d-flex align-items-end" style={{ fontSize: "larger" }}>
                <code>*</code> CAMPOS OBLIGATORIOS
              </div>
            </div>
            <hr />

            <Row className="mb-2">
              <Col>
                <Form.Label>
                  Folio: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa nombre"
                  name="folio"
                  value={folioActual}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={4} lg={4}>
                <Form.Label>
                  Nombre(s): <code>*</code>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa nombre"
                  name="nombre"
                  defaultValue={formData.nombre}
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label>
                  Primer Apellido: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa primer apellido"
                  defaultValue={formData.apellidoPaterno}
                  name="apellidoPaterno"
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label>Segundo Apellido: </Form.Label>
                <Form.Control
                  type="text"
                  className="inpSegundoA"
                  placeholder="Ingresa segundo apellido"
                  defaultValue={formData.apellidoMaterno}
                  name="apellidoMaterno"
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblFechaDoc">
                  Fecha de nacimiento:
                </Form.Label>
                <Form.Control
                  type="date"
                  className="inpFechaDoc"
                  placeholder="dd/mm/aaaa"
                  defaultValue={formData.fechaNacimiento}
                  name="fechaNacimiento"
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblTelefonoDoc">
                  Teléfono celular: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="number"
                  className="inpTelefonoDoc"
                  placeholder="Ingresa teléfono a 10 digitos"
                  defaultValue={formData.telefonoCelular}
                  name="telefonoCelular"
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblCorreoDoc">
                  Correo Electrónico: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="email"
                  className="inpCorreoDoc"
                  placeholder="ejemplo@ejemplo.com"
                  defaultValue={formData.email}
                  name="email"
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Label>Observaciones:</Form.Label>
                <Form.Control
                  className="txtObservacionesDoc "
                  as="textarea"
                  rows={3}
                  placeholder="Observaciones"
                  defaultValue={formData.observaciones}
                  name="observaciones"
                />
              </Col>
            </Row>

            <br />
            <h4>Domicilio</h4>
            <hr />

            <Row className="mb-2">
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblCalle">Calle: </Form.Label>
                <code>*</code>
                <Form.Control
                  type="text"
                  className="inpCalle"
                  placeholder="Ingresa calle"
                  defaultValue={formData.calle}
                  name="calle"
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblnExt">Num Ext: </Form.Label>
                <code>*</code>
                <Form.Control
                  type="number"
                  className="inpNumExt"
                  placeholder="Ingresa numero exterior"
                  defaultValue={formData.numeroExterior}
                  name="numeroExterior"
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblnInt">Num Int: </Form.Label>
                <Form.Control
                  type="number"
                  className="inpNumInt"
                  placeholder="Ingresa numero interior"
                  defaultValue={formData.numeroInterior}
                  name="numeroInterior"
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblColonia">Colonia: </Form.Label>
                <code>*</code>
                <Form.Control
                  type="text"
                  className="inpColonia"
                  placeholder="Ingresa colonia"
                  defaultValue={formData.colonia}
                  name="colonia"
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblEstadoEst">Estado</Form.Label>
                <code>*</code>
                <Form.Control
                  as="select"
                  value={estadoPais}
                  onChange={(e) => setEstadoPais(e.target.value)}
                >
                  <option>Selecciona un Estado</option>
                  {estadosPaisData.map((estado) => (
                    <option key={estado.clave} value={estado.nombre}>
                      {estado.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Label className="lblMunicipio">Municipio</Form.Label>
                <code>*</code>
                <Form.Control
                  as="select"
                  value={municipioSeleccionado}
                  onChange={(e) => setMunicipioSeleccionado(e.target.value)}
                >
                  <option>Selecciona un Municipio</option>
                  {municipiosEstadoData[estadoPais] &&
                    municipiosEstadoData[estadoPais].map((municipio) => (
                      <option key={municipio} value={municipio}>
                        {municipio}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={6} lg={6}>
                <Form.Label className="lblCP">Código Postal: </Form.Label>
                <code>*</code>
                <Form.Control
                  type="number"
                  className="inpCP"
                  placeholder="Ingresa Código Postal"
                  defaultValue={formData.codigoPostal}
                  name="codigoPostal"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Form.Label className="lblEsquina">Referencia: </Form.Label>
                <code>*</code>
                <Form.Control
                  type="text"
                  className="inpEsquina"
                  placeholder="Ingresa Referencia"
                  defaultValue={formData.haceEsquina}
                  name="haceEsquina"
                />
              </Col>
            </Row>

            <Form.Group controlId="additionalSectionsCheckbox">
              <Form.Check
                type="checkbox"
                label="Mostrar informacion de facturacion"
                checked={showAdditionalSections}
                onChange={handleCheckboxChange}
              />
            </Form.Group>

            {showAdditionalSections == true && (
              <>
                <br />
                <h4>Datos fiscales</h4>
                <hr />
                <Row className="mb-2">
                  <Col>
                    <div className="imagenPrincipal">
                      <h4 className="textoImagenPrincipal">
                        Constancia de situacion fiscal
                      </h4>
                      <div
                        title="Seleccionar imagen de la categoría"
                        className="imagenProducto"
                      >
                        <Dropzone setImagenFile={setImagenProducto} />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <Form.Label className="lblpApellido">
                      Nombre completo o razon social:
                    </Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="text"
                      className="inpPrimerADoc"
                      placeholder="Ingresa el nombre completo o la razon social"
                      defaultValue={formData.razonSocial}
                      name="razonSocial"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblpApellido">RFC:</Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="text"
                      className="inpPrimerADoc"
                      placeholder="Ingresa el rfc"
                      defaultValue={formData.rfc}
                      name="rfc"
                    />
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label>Tipo de persona:</Form.Label>
                    <code>*</code>
                    <Form.Control
                      as="select"
                      placeholder="Tipo de persona"
                      name="tipoPersona"
                      defaultValue={formData.tipoPersona}
                    >
                      <option>Elige una opción</option>
                      <option
                        value="Fisica"
                        selected={formData.tipoPersona === "Fisica"}
                      >
                        Fisica
                      </option>
                      <option
                        value="Moral"
                        selected={formData.tipoPersona === "Moral"}
                      >
                        Moral
                      </option>
                    </Form.Control>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label>Régimen fiscal:</Form.Label>
                    <code>*</code>
                    <>
                      <Form.Control
                        as="select"
                        defaultValue={formData.regimenFiscal}
                        name="regimenFiscal"
                      >
                        <option>Elige una opción</option>
                        {formData.tipoPersona === "Moral" && (
                          <>
                            <option
                              value="601-General de Ley Personas Morales"
                              selected={
                                formData.regimenFiscal ===
                                "601-General de Ley Personas Morales"
                              }
                            >
                              601-General de Ley Personas Morales
                            </option>
                            <option
                              value="603-Personas Morales con Fines no Lucrativos"
                              selected={
                                formData.regimenFiscal ===
                                "603-Personas Morales con Fines no Lucrativos"
                              }
                            >
                              603-Personas Morales con Fines no Lucrativos
                            </option>
                            <option
                              value="607-Régimen de Enajenación o Adquisición de Bienes"
                              selected={
                                formData.regimenFiscal ===
                                "607-Régimen de Enajenación o Adquisición de Bienes"
                              }
                            >
                              607-Régimen de Enajenación o Adquisición de Bienes
                            </option>
                            <option
                              value="609-Consolidación"
                              selected={
                                formData.regimenFiscal === "609-Consolidación"
                              }
                            >
                              609-Consolidación
                            </option>
                            <option
                              value="610-Residentes en el Extranjero sin Establecimiento Permanente en México"
                              selected={
                                formData.regimenFiscal === "610-Residentes en el Extranjero sin Establecimiento Permanente en México"
                              }
                            >
                              610-Residentes en el Extranjero sin Establecimiento Permanente en México
                            </option>
                            <option
                              value="620-Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos"
                              selected={
                                formData.regimenFiscal ===
                                "620-Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos"
                              }
                            >
                              620-Sociedades Cooperativas de Producción que optan
                              por Diferir sus Ingresos
                            </option>
                            <option
                              value="622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"
                              selected={
                                formData.regimenFiscal ===
                                "622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"
                              }
                            >
                              622-Actividades Agrícolas, Ganaderas, Silvícolas y
                              Pesqueras
                            </option>
                            <option
                              value="623-Opcional para Grupos de Sociedades"
                              selected={
                                formData.regimenFiscal ===
                                "623-Opcional para Grupos de Sociedades"
                              }
                            >
                              623-Opcional para Grupos de Sociedades
                            </option>
                            <option
                              value="624-Coordinados"
                              selected={formData.regimenFiscal === "624-Coordinado"}
                            >
                              624-Coordinados
                            </option>
                            <option
                              value="628-Hidrocarburos"
                              selected={
                                formData.regimenFiscal === "628-Hidrocarburos"
                              }
                            >
                              628-Hidrocarburos
                            </option>
                          </>
                        )}
                        {formData.tipoPersona === "Fisica" && (
                          <>
                            <option value="605-Sueldos y Salarios e Ingresos Asimilados a Salarios">
                              605-Sueldos y Salarios e Ingresos Asimilados a
                              Salarios
                            </option>
                            <option value="606-Arrendamiento">
                              606-Arrendamiento
                            </option>
                            <option value="608-Demás ingresos">
                              608-Demás ingresos
                            </option>
                            <option value="610-Residentes en el Extranjero sin Establecimiento Permanente en México"
                            >
                              610-Residentes en el Extranjero sin Establecimiento Permanente en México
                            </option>
                            <option value="611-Ingresos por Dividendos (socios y accionistas)">
                              611-Ingresos por Dividendos (socios y accionistas)
                            </option>
                            <option value="612-Personas Físicas con Actividades Empresariales y Profesionales">
                              612-Personas Físicas con Actividades Empresariales y
                              Profesionales
                            </option>
                            <option value="614-Ingresos por intereses">
                              614-Ingresos por intereses
                            </option>
                            <option value="615-Régimen de los ingresos por obtención de premios">
                              615-Régimen de los ingresos por obtención de premios
                            </option>
                            <option value="616-Sin obligaciones fiscales">
                              616-Sin obligaciones fiscales
                            </option>
                            <option value="621-Incorporación Fiscal">
                              621-Incorporación Fiscal
                            </option>
                            <option value="622-Actividades Agrícolas, Ganaderas, Silvícolas y
                              Pesqueras">
                              622-Actividades Agrícolas, Ganaderas, Silvícolas y
                              Pesqueras
                            </option>
                            <option value="625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">
                              625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas
                            </option>
                            <option value="626 - Régimen Simplificado de Confianza">
                              626 - Régimen Simplificado de Confianza
                            </option>
                            <option value="629-De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales">
                              629-De los Regímenes Fiscales Preferentes y de las
                              Empresas Multinacionales
                            </option>
                            <option value="630-Enajenación de acciones en bolsa de valores">
                              630-Enajenación de acciones en bolsa de valores
                            </option>
                          </>
                        )}
                      </Form.Control>
                    </>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Label>Uso CFDI:</Form.Label>
                    <code>*</code>
                    <Form.Control
                      as="select"
                      defaultValue={formData.cfdi}
                      name="cfdi"
                    >
                      <option>Elige una opción</option>
                      {formData.tipoPersona === "Moral" && (
                        <>
                          <option value="G01-Adquisición de mercancías">
                            G01-Adquisición de mercancías
                          </option>
                          <option value="G02-Devoluciones, descuentos o bonificaciones">
                            G02-Devoluciones, descuentos o bonificaciones
                          </option>
                          <option value="G03-Gastos en general">
                            G03-Gastos en general
                          </option>
                          <option value="I01-Construcciones">
                            I01-Construcciones
                          </option>
                          <option value="I02-Mobiliario y equipo de oficina por inversiones">
                            I02-Mobiliario y equipo de oficina por inversiones
                          </option>
                          <option value="I03-Equipo de transporte">
                            I03-Equipo de transporte
                          </option>
                          <option value="I04-Equipo de computo y accesorios">
                            I04-Equipo de computo y accesorios
                          </option>
                          <option value="I05-Dados, troqueles, moldes, matrices y herramental">
                            I05-Dados, troqueles, moldes, matrices y herramental
                          </option>
                          <option value="I06-Comunicaciones telefónicas.">
                            I06-Comunicaciones telefónicas
                          </option>
                          <option value="I07-Comunicaciones satelitales">
                            I07-Comunicaciones satelitales
                          </option>
                          <option value="I08-Otra maquinaria y equipo">
                            I08-Otra maquinaria y equipo
                          </option>
                          <option value="S01-Sin efectos fiscales">
                            S01-Sin efectos fiscales
                          </option>
                          <option value="CP01-Pagos">CP01-Pagos</option>
                        </>
                      )}
                      {formData.tipoPersona === "Fisica" && (
                        <>
                          <option value="G01-Adquisición de mercancías">
                            G01-Adquisición de mercancías
                          </option>
                          <option value="G02-Devoluciones, descuentos o bonificaciones">
                            G02-Devoluciones, descuentos o bonificaciones
                          </option>
                          <option value="G03-Gastos en general">
                            G03-Gastos en general
                          </option>
                          <option value="I01-Construcciones">
                            I01-Construcciones
                          </option>
                          <option value="I02-Mobiliario y equipo de oficina por inversiones">
                            I02-Mobiliario y equipo de oficina por inversiones
                          </option>
                          <option value="I03-Equipo de transporte">
                            I03-Equipo de transporte
                          </option>
                          <option value="I04-Equipo de computo y accesorios">
                            I04-Equipo de computo y accesorios
                          </option>
                          <option value="I05-Dados, troqueles, moldes, matrices y herramental">
                            I05-Dados, troqueles, moldes, matrices y herramental
                          </option>
                          <option value="I06-Comunicaciones telefónicas.">
                            I06-Comunicaciones telefónicas
                          </option>
                          <option value="I07-Comunicaciones satelitales">
                            I07-Comunicaciones satelitales
                          </option>
                          <option value="I08-Otra maquinaria y equipo">
                            I08-Otra maquinaria y equipo
                          </option>
                          <option value="D01-Honorarios médicos, dentales y gastos hospitalarios">
                            D01-Honorarios médicos, dentales y gastos hospitalarios
                          </option>
                          <option value="D02-Gastos médicos por incapacidad o discapacidad">
                            D02-Gastos médicos por incapacidad o discapacidad
                          </option>
                          <option value="D03-Gastos funerales">
                            D03-Gastos funerales
                          </option>
                          <option value="D04-Donativos">D04-Donativos</option>
                          <option value="D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)">
                            D05-Intereses reales efectivamente pagados por créditos
                            hipotecarios (casa habitación)
                          </option>
                          <option value="D06-Aportaciones voluntarias al SAR">
                            D06-Aportaciones voluntarias al SAR
                          </option>
                          <option value="D07-Primas por seguros de gastos médicos">
                            D07-Primas por seguros de gastos médicos
                          </option>
                          <option value="D08-Gastos de transportación escolar obligatoria">
                            D08-Gastos de transportación escolar obligatoria
                          </option>
                          <option value="D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">
                            D09-Depósitos en cuentas para el ahorro, primas que
                            tengan como base planes de pensiones
                          </option>
                          <option value="D10-Pagos por servicios educativos (colegiaturas)">
                            D10-Pagos por servicios educativos (colegiaturas)
                          </option>
                          <option value="S01-Sin efectos fiscales">
                            S01-Sin efectos fiscales
                          </option>
                          <option value="CP01-Pagos">CP01-Pagos</option>
                          <option value="CN01-Nómina">CN01-Nómina</option>
                        </>
                      )}
                    </Form.Control>
                  </Col>
                </Row>
                <br />
                <h4>Domicilio fiscal</h4>
                <hr />
                <Row className="mb-2">
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblCalle">Calle: </Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="text"
                      className="inpCalle"
                      placeholder="Ingresa calle"
                      defaultValue={formData.calleFiscal}
                      name="calleFiscal"
                    />
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblnExt">Num Ext: </Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="number"
                      className="inpNumExt"
                      placeholder="Ingresa numero exterior"
                      defaultValue={formData.numeroExteriorFiscal}
                      name="numeroExteriorFiscal"
                    />
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblnInt">Num Int: </Form.Label>
                    <Form.Control
                      type="number"
                      className="inpNumInt"
                      placeholder="Ingresa numero interior"
                      defaultValue={formData.numeroInteriorFiscal}
                      name="numeroInteriorFiscal"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblColonia">Colonia: </Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="text"
                      className="inpColonia"
                      placeholder="Ingresa colonia"
                      defaultValue={formData.coloniaFiscal}
                      name="coloniaFiscal"
                    />
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblEstadoEst">Estado</Form.Label>
                    <code>*</code>
                    <Form.Control
                      as="select"
                      value={estadoPais2}
                      onChange={(e) => setEstadoPais2(e.target.value)}
                    >
                      <option>Selecciona un Estado</option>
                      {estadosPaisData.map((estado) => (
                        <option key={estado.clave} value={estado.nombre}>
                          {estado.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <Form.Label className="lblMunicipio">Municipio</Form.Label>
                    <code>*</code>
                    <Form.Control
                      as="select"
                      value={municipioSeleccionado2}
                      onChange={(e) => setMunicipioSeleccionado2(e.target.value)}
                    >
                      <option>Selecciona un Municipio</option>
                      {municipiosEstadoData[estadoPais2] &&
                        municipiosEstadoData[estadoPais2].map((municipio) => (
                          <option key={municipio} value={municipio}>
                            {municipio}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={12} md={6} lg={6}>
                    <Form.Label className="lblCP">Código Postal: </Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="number"
                      className="inpCP"
                      placeholder="Ingresa Código Postal"
                      defaultValue={formData.codigoPostalFiscal}
                      name="codigoPostalFiscal"
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Label className="lblEsquina">Referencia: </Form.Label>
                    <code>*</code>
                    <Form.Control
                      type="text"
                      className="inpEsquina"
                      placeholder="Ingresa Referencia"
                      defaultValue={formData.haceEsquinaFiscal}
                      name="haceEsquinaFiscal"
                    />
                  </Col>
                </Row>
              </>
            )}
            <br />
            <label></label>
            <div className="divSubmit">
              <input className="submit" value="Enviar" type="submit" />
            </div>
          </Form>
        </div>
        <div style={footerStyle}>
          <p style={{ marginBottom: 0 }}>
            <FontAwesomeIcon icon={faLocationDot} /> EL ROBLE #38, COLONIA SANTA
            CRUZ NIETO, CP 76804, SAN JUAN DEL RÍO, QRO.
          </p>
          <a
            href="mailto:mesadecontrol@grupodentalherfran.com"
            style={{ color: "#fff" }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function initialFormValue() {
  return {
    razonSocial: "",
    estadoEstablecimiento: "",
    municipio: "",
    colonia: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "",
    codigoPostal: "",
    telefono: "",
    haceEsquina: "",
    email: "",
    horario: "",
    observaciones: "",
    alias: "",
    rfc: "",
    regimenFiscal: "",
    tipoPersona: "",
    cfdi: "",

    municipioFiscal: "",
    coloniaFiscal: "",
    calleFiscal: "",
    numeroExteriorFiscal: "",
    numeroInteriorFiscal: "",
    codigoPostalFiscal: "",
    haceEsquinaFiscal: "",
  };
}
