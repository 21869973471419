import { faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import {
  listarPreciosOrden,
  registraPreciosOrden
} from "../../../api/preciosOrden";
import {
  actualizarTotalOrdenesServicio
} from "../../../api/ordenesServicio";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LogsInformativos } from "../../logs/logs";

const PreciosOrden = ({ setShow, orden, totalOrden, ordenServicio }) => {
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarCargosyDescuentos, setListarCargosyDescuentos] = useState([]);

  const obtenerCyD = () => {
    try {
      listarPreciosOrden()
        .then((response) => {
          const { data } = response;
          console.log(data);

          if (data) {
            const datosViaje = formatModelCargosyDescuentos(data);
            const filtroPorFolio = datosViaje.filter(
              (item) => item.ordenServicio === orden
            );
            console.log(filtroPorFolio);
            if (filtroPorFolio.length > 0) {
              setListarCargosyDescuentos(filtroPorFolio);
            } else {
              console.warn(
                "No se encontraron datos con el folio proporcionado."
              );
            }
          }
        })
        .catch((e) => {
          console.error("Error al listar precios de la orden:", e);
        });
    } catch (e) {
      console.error("Error en el bloque try-catch principal:", e);
    }
  };

  useEffect(() => {
    obtenerCyD();
  }, [orden]);

  const [selectTipoOperacion, setSelectTipoOperacion] = useState("cargo");
  const [concepto, setConcepto] = useState("");
  const [cargo, setCargo] = useState("");

  const actualizarPreciosOrden = async () => {
    let totalFloat = parseFloat(total)
    let cargoFloat = parseFloat(cargo)
    let totalReg = selectTipoOperacion === "cargo" ? (totalFloat + cargoFloat) : (totalFloat - cargoFloat);
    try {
      const dataTemp = {
        precioTotal: totalReg
      };
      actualizarTotalOrdenesServicio(ordenServicio, dataTemp).then((response) => {
        const { data } = response;
        LogsInformativos("Se ha registrado el precio de la orden " + ordenServicio, dataTemp)
        toast.success(data.mensaje);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [ban, setBan] = useState(0);

  const registrarPreciosOrden = () => {
    if (!cargo || !concepto) {
      toast.warning("Completa el formulario");
    } else {
      try {
        const dataTemp2 = {
          ordenServicio: orden,
          tipo: selectTipoOperacion,
          concepto: concepto,
          cantidad: cargo,
          correoRegistro: correoUsuario,
          estado: "activo",
        };
  
        // Calcula la nueva cantidad para actualizar totalOrden
        const cantidadFloat = parseFloat(cargo);
        let nuevoTotalOrden = parseFloat(totalOrden);
        
        if (selectTipoOperacion === "cargo") {
          nuevoTotalOrden += cantidadFloat;
        } else if (selectTipoOperacion === "descuento") {
          nuevoTotalOrden -= cantidadFloat;
        }
  
        // Actualiza el estado de totalOrden
        setTotal(nuevoTotalOrden);
  
        // Llama a la función para actualizar precios en la API
        registraPreciosOrden(dataTemp2).then((response) => {
          const { data } = response;
          toast.success(data.mensaje);
          actualizarPreciosOrden()
          // Se elimina la llamada a actualizarPreciosOrden(), ya que el total se actualiza localmente
          obtenerCyD();
          setCargo("");
          setConcepto("");
        });
      } catch (e) {
        console.log(e);
      }
    }
  };
  

  const [total, setTotal] = useState(totalOrden);

  const totald = selectTipoOperacion == "cargo" ? Number(totalOrden + cargo) : selectTipoOperacion == "descuento" ? Number(totalOrden - cargo) : 0;

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Orden de cliente</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control type="text" value={orden} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Precio de orden (actual):</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control type="text" value={"$" + total} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Tipo de operación</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Select
              value={selectTipoOperacion}
              onChange={(e) => setSelectTipoOperacion(e.target.value)}
            >
              <option value="cargo">Cargo</option>
              <option value="descuento">Descuento</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Concepto:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              value={concepto}
              onChange={(e) => setConcepto(e.target.value)}
              placeholder={"Ingresa el concepto del " + selectTipoOperacion}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={cargo}
                onChange={(e) => setCargo(e.target.value)}
                type="number"
              />
            </InputGroup>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registrarPreciosOrden}>
            <FontAwesomeIcon icon={faMoneyBillTrendUp} />
            &nbsp;Registrar
          </Button>
        </div>
        <div>
          <Table striped bordered hover style={{ textAlign: "center", textTransform: "capitalize" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Operación</th>
                <th>Concepto</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {listarCargosyDescuentos.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.tipoOperacion}</td>
                  <td>{item.concepto}</td>
                  <td>{(item.tipoOperacion === "descuento" ? "-" : "+") + "$" + item.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
            <label>Total:</label>
            <Form.Control style={{ width: "20vw" }} type="text" value={"$" + total + " MXN"} disabled />
          </div>
        </div>
      </Form>
    </div>
  );
};

function formatModelCargosyDescuentos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      tipoOperacion: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
      correoRegistro: data.correoRegistro,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default PreciosOrden;
