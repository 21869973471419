import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";

const Imagenes = ({ links, folio }) => {
  const linksimagenes = links;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = linksimagenes;

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <div style={{ textAlign: "center", fontStyle: "italic" }}>
        <h2>Imágenes de la {folio}</h2>

        {linksimagenes.length > 0 ? (
          linksimagenes.map((src, index) => (
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              width="250"
              height="270"
              key={index}
              style={{ margin: "5px" }}
              alt=""
            />
          ))
        ) : (
          <div className="errorSesionBody">
            <div className="error-container">
              <div className="error-text">😵</div>
              <h1 className="error-title">ERROR!!!</h1>
              <p className="error-message">No hay imagenes para esta orden</p>
            </div>
          </div>
        )}

        {isViewerOpen && (
          <div style={{ position: "absolute", zIndex: "9999" }}>
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              style={{ position: "absolute", zIndex: 9999 }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Imagenes;
