import React from 'react'
import { HeaderDash } from '../../components/administrador/Dashboard/Dashboard'
import { MenuDash } from '../../components/administrador/Dashboard/Menu'
import { FooterDash } from '../../components/administrador/Dashboard/Footer'

export const Admin = () => {
  return (
    <div>
        <HeaderDash/>
        <MenuDash/>
    </div>
  )
}
