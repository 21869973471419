import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarPreciosOrden,
    ENDPOINTObtenerPreciosOrden,
    ENDPOINTListarPreciosOrden,
    ENDPOINTActualizarPreciosOrden,
    ENDPOINTEliminarPreciosOrden,
    ENDPOINTDeshabilitarPreciosOrden,
    ENDPOINTListarCorreoPreciosOrden,
    ENDPOINTObtenerPreciosOrdenPorFolio,
    ENDPOINTFiltrarFechasCargos
} from "./endpoints";
import axios from 'axios';

export async function listarPreciosOrden(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPreciosOrden, config);
}

export async function listarCorreoPreciosOrden(correoCliente){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarCorreoPreciosOrden + `?ordenServicio=${correoCliente}`, config);
}

export async function registraPreciosOrden(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarPreciosOrden, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerPreciosOrden(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPreciosOrden + `/${params}`, config);
}

export async function actualizarPreciosOrden(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarPreciosOrden + `/${id}`, data, config);
}

export async function eliminarPreciosOrden(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarPreciosOrden + `/${id}`, data, config);
}

export async function actualizaDeshabilitarPreciosOrden(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarPreciosOrden + `/${id}`, data, config);
}

// Para obtener por folio de orden
export async function obtenerCyDPorFolioOS(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPreciosOrdenPorFolio + `/${params}`, config);
}

export async function listarCargosyDescFechas(fechaInicial, fechaFinal){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTFiltrarFechasCargos + `?fechaInicial=${fechaInicial}&&fechaFinal=${fechaFinal}`, config);
}