import { faCancel, faPause, faRepeat } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { actualizaDeshabilitarServicios } from "../../api/servicios";
import { toast } from "react-toastify";
import { LogsInformativos } from "../logs/logs";

const PausarOT = ({ setShow, data }) => {
  const [motivo, setMotivo] = useState(""); // Reason for pausing
  const [descripcion, setDescripcion] = useState(""); // Description if "Otros" is selected
  const datos = data;

  const handleMotivoChange = (e) => {
    setMotivo(e.target.value);
    if (e.target.value !== "Otros") {
      setDescripcion(""); // Clear description if not "Otros"
    }
  };

  const actualizarEstadoS = async () => {
    try {
      const dataTemp = {
        estado: datos[14] === "3" ? "2" : "3", // Toggle state
        motivoPausa: motivo === "Otros" ? descripcion : motivo, // Use description if "Otros"
      };
      const response = await actualizaDeshabilitarServicios(datos[0], dataTemp);
      const { data } = response;
      toast.success(data.mensaje);
      LogsInformativos(`Se ha pausado la orden de trabajo ${datos[0]}`, dataTemp);
      setShow(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {datos[14] === "3" ? (
        <h3 style={{ textAlign: "center" }}>Reanudar {datos[2]}?</h3>
      ) : (
        <h3 style={{ textAlign: "center" }}>¿Pausar {datos[2]}?</h3>
      )}
      <div style={{ marginTop: "2%", marginBottom: "2%" }}>
        <Row>
          {datos[14] !== "3" && (
            <>
              <Row>
                <Form.Label className="lblPiezaOS">
                  Motivo de la pausa: <code>*</code>
                </Form.Label>
                <Form.Control
                  as="select"
                  className="inpPiezaOS"
                  value={motivo}
                  onChange={handleMotivoChange}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="No hay material">No hay material</option>
                  <option value="A peticion de cliente">A petición de cliente</option>
                  <option value="Caso dirigido">Caso dirigido</option>
                  <option value="Otros">Otros</option>
                </Form.Control>
              </Row>
              {motivo === "Otros" && (
                <Row>
                  <Form.Label className="lblPiezaOS">
                    Descripción: <code>*</code>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inpPiezaOS"
                    placeholder="Descripción"
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                  />
                </Row>
              )}
            </>
          )}
          <Col className="text-center">
            <Button variant="danger" onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faCancel} /> Cancelar
            </Button>
          </Col>
          <Col className="text-center">
            <Button
              variant={datos[14] === "3" ? "success" : "warning"}
              onClick={actualizarEstadoS}
            >
              {datos[14] === "3" ? (
                <>
                  <FontAwesomeIcon icon={faRepeat} /> Reanudar
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faPause} /> Pausar
                </>
              )}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PausarOT;
