import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { actualizaDeshabilitarVales } from "../../api/vales";
import { toast } from "react-toastify";
import { LogsInformativos } from "../logs/logs";
import { registraIncidencias, obtenerNumeroIncidencia } from "../../api/incidencias";
import { listarUsuarios, obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { registraMensajeria } from "../../api/mensajeria";

const RegistrarIncidenciasVales = ({ data, setShow }) => {
  const idVale = data[0];

  const [correoUsuario, setCorreoUsuario] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarUsuariosI, setListUsuariosI] = useState([]);

  const obtenerUsuariosInternos = () => {
    listarUsuarios()
      .then((response) => {
        const { data } = response;

        if (!listarUsuariosI && data) {
          setListUsuariosI(formatModelOrdenes(data));
        } else {
          const datosDoctor = formatModelOrdenes(data);
          const filteredUsers = datosDoctor.filter(
            (user) =>
              user.tipo === "administrador" ||
              user.tipo === "cProduccion"
          );

          if (filteredUsers.length > 0) {
            setListUsuariosI(filteredUsers);
          } else {
            console.log(
              "No se encontró ningún elemento con el tipo especificado"
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
      });
  };

  useEffect(() => {
    obtenerUsuariosInternos();
  }, []);

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    hora = hora < 10 ? `0${hora}` : hora;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };

  const guardarEvento = async (e) => {
    e.preventDefault();

      try {
        const dataTemp2 = {
          idChat: resolver.split("/")[0] + ", " + correoUsuario,
          nombreUsuario: nombreUsuario,
          correoUsuario: correoUsuario,
          tipoMensaje: "text",
          fechaHora: obtenerFechaActual(),
          mensaje: "resulve la incidencia " + incidencia,
          nombreIdChat: resolver.split("/")[2],
          estado: "0",
        };
        registraMensajeria(dataTemp2).then((response) => {
          const { data } = response;
          toast.success(data.mensaje);
        });
      } catch (e) {
        console.log(e);
      }
  };

  const [incidencia, setIncidencia] = useState("");

  const [resolver, setResolver] = useState("");

  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    setTodayDate(`${year}-${month}-${day}`);
  }, []);

  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroIncidencia()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noIncidencia } = data;
          setFolioActual(noIncidencia);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const guardarIncidencia = async () => {
    try {
      const dataTemp = {
        folio: folioActual,
        fecha: todayDate,
        descripcion: incidencia,
        departamento: "Almacen",
        comentarios: "sin comentarios",
        estado: "1",
        email: resolver.split("/")[0],
        resuelve: resolver.split("/")[1],
      };
      registraIncidencias(dataTemp).then((response) => {
        const { data } = response;
        guardarEvento()
        LogsInformativos("Se ha registrado la incidencia " + folioActual)
        toast.success(data.mensaje);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizarEstadoV = async () => {
    try {
      const dataTemp = {
        estado: "0",
        incidencia: incidencia,
      };
      actualizaDeshabilitarVales(idVale, dataTemp).then((response) => {
        const { data } = response;
        guardarIncidencia();
        LogsInformativos("Se ha cancelado el vale " + idVale)
        toast.warning("Vale cancelado");
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ padding: "2%", textAlign: "center" }}>
      <div style={{ textAlign: "center" }}>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Incidencia</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Descripcion de la incidencia"
              value={incidencia}
              onChange={(e) => setIncidencia(e.target.value)}
            />
          </Col>

          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>¿Quien puede resolver?</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="select"
              placeholder="Descripcion de la incidencia"
              value={resolver}
              onChange={(e) => setResolver(e.target.value)}
            >
              <option>Seleccionar</option>
              {listarUsuariosI.map((departamento, index) => (
                <option key={index} value={departamento.email + "/" + departamento.tipo + "/" + departamento.nombre}>
                  {departamento.nombre}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Button variant="warning" onClick={actualizarEstadoV}>
          <FontAwesomeIcon icon={faCancel} /> Cancelar
        </Button>
      </div>
    </div>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      email: data.email,
      password: data.password,
      tipo: data.tipo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default RegistrarIncidenciasVales;
