import React, { useState, useEffect } from "react";
import { withRouter } from "../../utils/withRouter";
import { listarCursos } from "../../api/cursos";
import { listarAgrupamientoCapacitacionesEmpleados } from "../../api/capacitacionesEmpleados";
import BasicModal from "../Modal/BasicModal/BasicModal";
import AltaCapacitacion from "./AltaCapacitacion";
import { Load } from "../load/load";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TblCapacitaciones(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listCursos, setListCursos] = useState([]);
  const [listCapacitaciones, setListCapacitaciones] = useState([]);

  useEffect(() => {
    obtenerCursos();
    obtenerCapacitaciones();
  }, [location]);

  const obtenerCursos = () => {
    try {
      listarCursos()
        .then((response) => {
          const { data } = response;
          setListCursos(formatModelCursos(data));
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const obtenerCapacitaciones = () => {
    try {
      listarAgrupamientoCapacitacionesEmpleados()
        .then((response) => {
          const { data } = response;
          setListCapacitaciones(formatModelCapacitaciones(data));
        })
        .catch((e) => {});
    } catch (e) {}
  };
  console.log(listCapacitaciones);


  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroAsistencia = (content) => {
    setTitulosModal("Registar capacitaciones");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <>
      {loading && <Load />}

      
      <div>
        <h1>Matriz de Capacitación de Empleados</h1>
        <div className="divButton">
        <Button
          className="btnAddTables"
          onClick={() =>
            registroAsistencia(
              <AltaCapacitacion history={history} setShow={setShowModal} />
            )
          }
        >
          <FontAwesomeIcon icon={faPlus} /> Agregar
        </Button>
      </div>
        <div className="divTabla">
          <Table responsive>
            <thead>
              <tr>
                <th style={{ backgroundColor: "#9c182d", color: "#FFF" }}>
                  Empleado
                </th>
                {listCursos.map((course) => (
                  <th
                    key={course.id}
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "#9c182d",
                      color: "#FFF",
                    }}
                  >
                    {course.nombre}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listCapacitaciones.map(({ empleado, capacitaciones }) => (
                <tr key={empleado}>
                  <td style={{ textTransform: "capitalize" }}>{empleado}</td>
                  {listCursos.map((course) => (
                    <td
                      key={course.id}
                      style={{
                        backgroundColor:
                          capacitaciones.find(
                            (cap) => cap.nombre === course.nombre
                          )?.estado === "Completado"
                            ? "#BBFF9A"
                            : capacitaciones.find(
                                (cap) => cap.nombre === course.nombre
                              )
                            ? "#FDFF76"
                            : "#FEB8B8", // Si no se encuentra, color blanco
                      }}
                    >
                      {capacitaciones.find(
                        (cap) => cap.nombre === course.nombre
                      )?.estado === "Completado"
                        ? "Completado"
                        : capacitaciones.find(
                            (cap) => cap.nombre === course.nombre
                          )
                        ? "En capacitación"
                        : "No capacitado"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelCursos(data) {
  return data.map((course) => ({
    id: course._id,
    nombre: course.nombre,
    tipo: course.tipo,
    descripcion: course.descripcion,
    fecha: course.fecha,
    estado: course.estado,
  }));
}

function formatModelCapacitaciones(data) {
  return data.map((item) => ({
    empleado: item.empleado,
    capacitaciones: item.capacitaciones.map((cap) => ({
      nombre: cap.curso,
      estado: cap.estado,
      // Add more properties if needed
    })),
  }));
}

export default withRouter(TblCapacitaciones);
