import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Load } from "../../load/load";
import { listarOrdenCompraRecibidas } from "../../../api/ordenCompra";
import { Button } from "react-bootstrap";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import TablaOCC from "./TablaOCC";

const TablaCxP = () => {
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros coincidentes con los filtros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const modificarSistemasColorimetria = (content) => {
    setTitulosModal("Lista de ordenes de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "RAZÓN SOCIAL",
    },
    {
      name: "totalOrdenes",
      label: "TOTAL DE COMPRAS",
    },
    {
      name: "ordenesCompra",
      label: "LISTA",
      options: {
        customBodyRender: (value) => {
          if (!Array.isArray(value)) {
            console.error("ordenesCompra no es un array:", value);
            return null;
          }
          return (
            <Button
              variant="link"
              title="Ver ordenes"
              style={{ textDecoration: "none" }}
              onClick={() =>
                modificarSistemasColorimetria(
                  <TablaOCC
                    setShowModal={setShowModal}
                    data={value}
                  />
                )
              }
            >
              Ver compras
            </Button>
          );
        },
      },
    },
    {
      name: "totalMontoEstado1",
      label: "TOTAL PAGADO",
      options: {
        customBodyRender: (value) => formatCurrency(value != null ? value : 0),
      },
    },
    {
        name: "totalMontoEstado0",
        label: "POR PAGAR",
        options: {
          customBodyRender: (value) => formatCurrency(value != null ? value : 0),
        },
      },
    // {
    //   name: "Acciones",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <>
    //         <Dropdown>
    //           <Dropdown.Toggle
    //             style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
    //             id="dropdown-basic"
    //           >
    //             <FontAwesomeIcon icon={faBars} />
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>
    //             <Dropdown.Item
    //               onClick={() =>
    //                 registrarPago(
    //                   <PagosClientes
    //                     history={history}
    //                     setShow={setShowModal}
    //                     data={tableMeta.rowData}
    //                   />
    //                 )
    //               }
    //             >
    //               <FontAwesomeIcon
    //                 icon={faDollarSign}
    //                 style={{ color: "#28a745", fontWeight: "bold" }}
    //               />
    //               &nbsp;Registrar Pago
    //             </Dropdown.Item>
    //             <Dropdown.Item
    //               onClick={() =>
    //                 verPagos(
    //                   <TablaPagos
    //                     history={history}
    //                     setShow={setShowModal}
    //                     data={tableMeta.rowData}
    //                   />
    //                 )
    //               }
    //             >
    //               <FontAwesomeIcon
    //                 icon={faEye}
    //                 style={{ color: "#fd7e14", fontWeight: "bold" }}
    //               />
    //               &nbsp;Ver pagos
    //             </Dropdown.Item>
    //             <Dropdown.Item
    //               onClick={() =>
    //                 registrarMovimiento(
    //                   <Movimientos
    //                     history={history}
    //                     setShow={setShowModal}
    //                     data={tableMeta.rowData}
    //                   />
    //                 )
    //               }
    //             >
    //               <FontAwesomeIcon
    //                 icon={faExchange}
    //                 style={{ color: "#000080", fontWeight: "bold" }}
    //               />
    //               &nbsp;Registrar cargo/descuento
    //             </Dropdown.Item>
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       </>
    //     ),
    //   },
    // },
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const [listOCR, setListOCR] = useState([]);

  const obtenerMovimientos = () => {
    try {
      listarOrdenCompraRecibidas()
        .then((response) => {
          const { data } = response;
          if (!listOCR && data) {
            setListOCR(formatModelOCR(data));
          } else {
            setListOCR(formatModelOCR(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (!showModal) {
      obtenerMovimientos();
    }
  }, [showModal]);

  return (
    <>
      {loading && <Load />}
      <h1>Cuentas por pagar</h1>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de saldos de clientes registrados"}
          data={listOCR}
          columns={columns}
          options={options}
        />
        <BasicModal
          show={showModal}
          setShow={setShowModal}
          title={titulosModal}
        >
          {contentModal}
        </BasicModal>
      </div>
    </>
  );
};

function formatModelOCR(data) {
  const dataTemp = [];
  data.forEach((item) => {
    dataTemp.push({
      id: item.razonSocial,
      totalOrdenes: item.totalOrdenes,
      totalMontoEstado0: item.totalMontoEstado0,
      totalMontoEstado1: item.totalMontoEstado1,
      ordenesCompra: item.ordenesCompra,
    });
  });
  console.log("🚀 ~ formatModelOCR ~ dataTemp:", dataTemp)
  return dataTemp;
  
}
  

export default TablaCxP;
