import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarProveedores,
    ENDPOINTObtenerProveedores,
    ENDPOINTListarProveedores,
    ENDPOINTActualizarProveedores,
    ENDPOINTEliminarProveedores,
    ENDPOINTDeshabilitarProveedores,
    ENDPOINTObtenerFolioProveedores,
} from "./endpoints";
import axios from 'axios';

export async function listarProveedores(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarProveedores, config);
}

export async function registraProveedores(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarProveedores, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerProveedores(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerProveedores + `/${params}`, config);
}

export async function actualizarProveedores(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarProveedores + `/${id}`, data, config);
}

export async function eliminarProveedores(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarProveedores + `/${id}`, data, config);
}

export async function actualizaDeshabilitarProveedores(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarProveedores + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroProveedores() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioProveedores, config);
}