import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import imagenDT from "../../assets/img/DT.jpeg";
import imagen from "../../assets/img/DP.jpeg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actualizaEstadoCambiosOrdenesServicio } from "../../api/ordenesServicio";
import queryString from "query-string";
import { Load } from "../load/load";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { registraEvento } from "../../api/eventos";
import { LogsInformativos } from "../logs/logs";

export default function ActualizarEstadoCambiosOrdenServicio({ data, aprobado, history, setShow }) {
  const idDocumental = data[0];
  console.log(data);
  const dataTemp = {
    folio: data[1],
    fecha: data[2],
    localidad: data[3],
    nombreDoctor: data[4],
    nombrePaciente: data[5],
    tipoDentadura: data[6],
    modeloSuperior: data[7],
    modeloInferior: data[8],
    mordidaCera: data[9],
    mordeidaSilicon: data[10],
    sAntagonista: data[11],
    llenadoCucharilla: data[12],
    otros: data[13],
    recibio: data[14],
    fechaEntrada: data[15],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  const [fechaNueva, setFechaNueva] = useState(dia + "/" + mes + "/" + year);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  let fecha2 = new Date();
  let dia2 = fecha2.getDate(); // Obtener el día (del 1 al 31)
  let mes2 = fecha2.getMonth() + 1; // Obtener el mes (del 0 al 11, por eso se suma 1)
  let anio2 = fecha2.getFullYear(); // Obtener el año (ej. 2023)
  let hora2 = fecha2.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos2 = fecha2.getMinutes(); // Obtener los minutos

  let datosFecha2 = `${dia2}/${mes2}/${anio2} ${hora2}:${minutos2}`;

  const registraEventos = (e) => {
    try {
      setLoading(true);
      const dataTemp5 = {
        fecha: datosFecha2,
        folioOrden: data.folio,
        usuario: nombreUsuario,
        tipoEvento: "Advertencia",
        motivoEvento: "Se cancelo la orden de cliente",
        estado: "activo",
        leido: "0",
      };
      registraEvento(dataTemp5).then((response) => {
        const { data } = response;
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const dataTemp = {
        cambios: "1",
        aprobado: "Cambios aprobados por " + aprobado
      }

      actualizaEstadoCambiosOrdenesServicio(idDocumental, dataTemp).then((response) => {
        const { data } = response;
        console.log(data);
        toast.success(data.mensaje);
        LogsInformativos("Se ha cancelado la orden de cliente " + idDocumental, dataTemp)
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalOrden2">
        <br />
        <h1>¿Deseas confirmar los cambios para la ODS {formData.folio}?</h1>
        <Form className="formOrden2" onSubmit={onSubmit} onChange={onChange}>

          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    fecha: data.fecha,
    localidad: data.localidad,
    nombreDoctor: data.nombreDoctor,
    nombrePaciente: data.nombrePaciente,
    tipoDentadura: data.tipoDentadura,
    modeloSuperior: data.modeloSuperior,
    modeloInferior: data.modeloInferior,
    mordidaCera: data.mordidaCera,
    mordidaSilicon: data.mordeidaSilicon,
    sAntagonista: data.sAntagonista,
    llenadoCucharilla: data.llenadoCucharilla,
    otros: data.otros,
    recibio: data.recibio,
    fechaEntrada: data.fechaEntrada,
  };
}
