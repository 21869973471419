import React, { useEffect, useState } from "react";
import RegistroMaquinas from "./RegistroMaquinas";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { Badge, Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarMaquinas } from "../../api/maquinas";

const TablaMaquinas = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroMaquinas = (content) => {
    setTitulosModal("Registrar máquinas");
    setContentModal(content);
    setShowModal(true);
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombre",
      label: "NOMBRE",
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
    {
      name: "descripcion",
      label: "DESCRIPCIÓN",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
  ];

  const [listMaquinas, setListMaquinas] = useState([]);

  const obtenerMaquinas = () => {
    try {
      listarMaquinas()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listMaquinas && data) {
            setListMaquinas(formatModelMaquinas(data));
          } else {
            const datosMaquinas = formatModelMaquinas(data);
            setListMaquinas(datosMaquinas);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerMaquinas();
  }, []);
  useEffect(() => {
    console.log(listMaquinas);
  }, [listMaquinas]);

  return (
    <>
      {loading && <Load />}
      <h1>Máquinas</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroMaquinas(<RegistroMaquinas setShow={setShowModal} />)
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Registrar
          </Button>
        </div>
        <div className="divTabla">
          <MUIDataTable
            title={"Listado de máquinas registradas"}
            data={listMaquinas}
            columns={columns}
            options={options}
          />
        </div>
      </div>
      {/*<Calendario eventosIniciales={eventosIniciales} />*/}
      <div className="divTabla"></div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
};

function formatModelMaquinas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      descripcion: data.descripcion,
      departamento: data.departamento,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default TablaMaquinas;
