import { Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarDepartamentoServicios, obtenerFolioODTServicio } from "../../api/servicios";
import { listarDepartamentosProduccion } from "../../api/departamentos";
import { toast } from "react-toastify";
import { map } from "lodash";
import { LogsInformativos } from "../logs/logs";
import { registraDepartamentosODT } from "../../api/departamentosODT";

export default function CrearODT({ data, history, setShow }) {
  console.log(data);

  const idDocumental = data[0];

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDepartamentosProduccion()
        .then((response) => {
          const { data } = response;
          console.log(data)

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  console.log(listarDoctor)

  useEffect(() => {
    obtenerDoctor();
  }, []);

    // Para almacenar el folio actual
    const [folioActual, setFolioActual] = useState("");

    const obtenerFolio = () => {
      try {
        obtenerFolioODTServicio()
          .then((response) => {
            const { data } = response;
            // console.log(data)
            const { noOrden } = data;
            setFolioActual(noOrden);
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };
  
    useEffect(() => {
      obtenerFolio();
    }, []);

  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds(); // Obtener los minutos
  let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;

  const guardarDepartamentoODT = () => {
    try {
      const temp = formData.departamento.split("/");
      const dataTemp = {
        folioODT: data[7],
        departamento: temp[1],
        fechaEntrada: datosFecha,
        estado: "1"
      };

      registraDepartamentosODT(dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha asignado un departamento a la orden de cliente " + idDocumental, dataTemp)
          toast.success(data.mensaje);

          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto
      const temp = formData.departamento.split("/");

      const dataTemp = {
        folioDepartamento: temp[0],
        departamento: temp[1],
        email: temp[2],
        ODT: folioActual,
        prioridad: formData.prioridad,
        fechaAsignacion: datosFecha,
        estado: "5",
      };

      actualizarDepartamentoServicios(idDocumental, dataTemp).then(
        (response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha asignado un departamento a la orden de cliente " + idDocumental, dataTemp)
          toast.success(data.mensaje);
          guardarDepartamentoODT();

          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalEstablecimiento">
        <Form
          className="formEstablecimiento"
          onSubmit={onSubmit}
          onChange={onChange}
        >
          <Row className="mb-2 mb-md-4 mb-lg-7" style={{display:"none"}}>
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Folio:</Form.Label>
            </Col>
            <Col
              sm={12}
              md={8}
              lg={8}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Control
                type="text"
                placeholder="Folio"
                value={!folioActual ? "ODT-1" : folioActual}
                name="folio"
                disabled
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Departamento:</Form.Label>
            </Col>
            <Col
              sm={12}
              md={8}
              lg={8}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Control
                as="select"
                placeholder="Ingresa modelo"
                defaultValue={formData.departamento}
                name="departamento"
              >
                <option>Elige una opción</option>
                {map(listarDoctor, (cat, index) => (
                  <option
                    key={index}
                    value={cat?.folio + "/" + cat?.nombre + "/" + cat?.email}
                  >
                    {cat?.nombre}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Prioridad:</Form.Label>
            </Col>
            <Col
              sm={12}
              md={8}
              lg={8}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Control
                as="select"
                placeholder="Ingresa modelo"
                defaultValue={formData.prioridad}
                name="prioridad"
              >
                <option>Elige una opción</option>
                <option value="normal">Normal</option>
                <option value="urgencia">Urgencia</option>
              </Form.Control>
            </Col>
          </Row>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    departamento: "",
    prioridad: ""
  };
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      email: data.email,
      estado: data.estado,
    });
  });
  return dataTemp;
}
