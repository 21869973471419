import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { eliminarIncidencias } from "../../api/incidencias";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function EliminarIncidencias({ data, history, setShow }) {

    const idDocumental = data[0];

    const dataTemp = {
        folio: data[1],
        fecha: data[2],
        descripcion: data[3],
        departamento: data[4]
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));
    const [signInLoading, setSignInLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setSignInLoading(true);
        try {

            eliminarIncidencias(idDocumental).then((response) => {
                const { data } = response;
                console.log(data)

                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                LogsInformativos("Se ha eliminado la incidencia " + idDocumental, dataTemp)
                setSignInLoading(false);
                setShow(false);
                //cancelarRegistro()
            }).catch((ex) => {
                if (ex.message === "Network Error") {
                    toast.error("Conexión al servidor no disponible");
                    setSignInLoading(false);
                } else {
                    if (ex.response && ex.response.status === 401) {
                        const { mensaje } = ex.response.data;
                        toast.error(mensaje);
                        setSignInLoading(false);
                    }
                }
            });
        } catch (ex) {
            toast.error("Error al iniciar sesión");
            setSignInLoading(false);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="containerInicio">
            <Form onChange={onChange} onSubmit={onSubmit}>
                <Form.Group className="datosPersonalesReg">
                    <Form.Label>Folio: </Form.Label>
                    <Form.Control
                        type="text"
                        className="nomNewUser"
                        placeholder="Nombre (s)"
                        name="folio"
                        value={formData.folio}
                        disabled
                    />
                </Form.Group>
                <Form.Group className="datosAcceso">
                    <Form.Label>Fecha: </Form.Label>
                    <Form.Control
                        type="date"
                        className="emailNewUser"
                        placeholder="fecha"
                        name="fecha"
                        defaultValue={formData.fecha}
                        disabled
                    />
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control
                        type="text"
                        className="passNewUser"
                        placeholder="Ingrese la descripcion"
                        name="descripcion"
                        defaultValue={formData.descripcion}
                        disabled
                    />
                    <Form.Label className="folioOS2">
                        Departamento
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="localidadOS"
                        name="departamento"
                        defaultValue={formData.departamento}
                        placeholder="Departamento"
                        disabled
                    >
                    </Form.Control>
                </Form.Group>
                <br />
                <label></label>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
};

function initialFormValue(data) {
    return {
        folio: data.folio,
        fecha: data.fecha,
        descripcion: data.descripcion,
        departamento: data.departamento
    };
}
