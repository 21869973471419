import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarRutas,
    ENDPOINTObtenerRutas,
    ENDPOINTObtenerOrdenesRutas,
    ENDPOINTListarRutas,
    ENDPOINTListarGeneralRutas,
    ENDPOINTListarRutasPorCorreo,
    ENDPOINTActualizarRutas,
    ENDPOINTEliminarRutas,
    ENDPOINTDeshabilitarRutas,
    ENDPOINTObtenerFolioRutas,
    ENDPOINTListarRutasPorIdOrden,
} from "./endpoints";
import axios from 'axios';

export async function listarRutas(fecha){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRutas + `?fecha=${fecha}`, config);
}



export async function listarRutasPorIdOrden(idsCad){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRutasPorIdOrden + `?ids=${idsCad}`, config);
}

export async function listarGeneralRutas(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarGeneralRutas, config);
}

export async function listarRutasPorCorreo(fecha, correoMensajero){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarRutasPorCorreo + `?fecha=${fecha}&&correoMensajero=${correoMensajero}`, config);
}

export async function registraRutas(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarRutas, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerRutas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerRutas + `/${params}`, config);
}

// Para obtener todos los datos del usuario
export async function obtenerOrdenesRutas(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerOrdenesRutas + `/${params}`, config);
}

export async function actualizarRutas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarRutas + `/${id}`, data, config);
}

export async function eliminarRutas(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarRutas + `/${id}`, data, config);
}

export async function actualizaDeshabilitarRutas(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarRutas + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerFolioRutas() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioRutas, config);
}