import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { actualizaDeshabilitarVales } from "../../api/vales";
import { toast } from "react-toastify";
import { LogsInformativos } from "../logs/logs";

const CancelarVale = ({data, setShow}) => {
    const idVale = data[0];

    const actualizarEstadoV = async () => {
        try {
          const dataTemp = {
            estado: "0",
          };
          actualizaDeshabilitarVales(idVale, dataTemp).then((response) => {
            const { data } = response;
            LogsInformativos("Se ha cancelado el vale " + idVale)
            toast.warning("Vale cancelado");
            setShow(false);
          });
        } catch (e) {
          console.log(e);
        }
      };
    
  return (
    <div style={{ padding: "2%", textAlign:"center" }}>
      <h4 style={{marginBottom:"2%"}}>¿Cancelar vale de material?</h4>
      <div style={{ textAlign: "center" }}>
        <Button variant="warning" onClick={actualizarEstadoV}>
          <FontAwesomeIcon icon={faCancel} /> Cancelar
        </Button>
      </div>
    </div>
  );
};

export default CancelarVale;
