import React, { useState, useEffect } from 'react';
import MapQuest from './MapQuest';

export const MapaMarcaUbicacion = ({ markersData }) => {
  const [markers, setMarkers] = useState([]);
  console.log(markersData);

  useEffect(() => {
    // Limpiar marcadores existentes
    markers.forEach((marker) => marker.remove());

    // Crear y agregar nuevos marcadores
    const newMarkers = Object.keys(markersData).map((key) => {
      const { lat, lng, title } = markersData[key];
      const marker = window.L.mapquest.textMarker(new window.L.LatLng(lat, lng), {
        text: title || '',
        subtext: '',
        position: 'right',
        type: 'marker',
        icon: {
          primaryColor: '#a8190f',
          secondaryColor: 'db2c2c',
          size: 'md',
        },
      }).addTo(window.L.mapquest.Map.getMap('map'));

      return marker;
    });

    setMarkers(newMarkers);

    // Ajustar el centro del mapa basado en la primera marca (puedes ajustar esto según tus necesidades)
    if (newMarkers.length > 0) {
      const { lat, lng } = newMarkers[0].getLatLng();
      const zoomLevel = newMarkers.length > 1 ? 10 : 12; // Disminuir el zoom si hay dos o más marcadores
      window.L.mapquest.Map.getMap('map').setView(new window.L.LatLng(lat, lng), zoomLevel);
    }
  }, [markersData]);

  return (
    <div className="containerFluid">
      <MapQuest
        height="50vh"
        width="auto"
        center={markers.length > 0 ? markers[0].getLatLng() : [0, 0]}
        tileLayer={'map'}
        zoom={12}
        apiKey="2eei5oDr1QNMmUGDCY8115cQclIbiAq3"
      />
    </div>
  );
};
