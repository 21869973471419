import { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { listarServiciosFolio } from "../../api/servicios";
import BasicModal from "../Modal/BasicModal/BasicModal";
import EliminarServicio from "./EliminarServicio";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

export const Tabla = ({ productos, setProductos }) => {

  // Para eliminar productos del listado
  const removeItem = (producto) => {
    let newArray = productos;
    newArray.splice(newArray.findIndex(a => a.servicio === producto.servicio), 1);
    setProductos([...newArray]);
  }

  let sumaTotal = 0;

  return (
    <div className="generaltablaOrdenes">
      <div className="tablaServiciosOrdenes">
        <Table striped bordered hover size="sm" className="tablaServiciosOrden">
          <thead>
            <tr>
              <th>#</th>
              <th>Clasificación</th>
              <th>Material</th>
              <th>Pieza</th>
              <th>Precio</th>
              <th>Descripción</th>
              <th>Sistema de color</th>
              <th>Tipo dentadura</th>
              <th>Diente</th>
              <th>Tono inferior</th>
              <th>Tono medio</th>
              <th>Tono Superior</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {productos.map((servicios, index) => {
              sumaTotal += parseFloat(servicios.precio);
              return (
                <tr key={index}>
                  <td>{servicios.folio}</td>
                  <td>{servicios.clasificacion}</td>
                  <td>{servicios.material}</td>
                  <td>{servicios.pieza}</td>
                  <td>${''}
                    {new Intl.NumberFormat('es-MX', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(servicios.precio)} MXN</td>
                  <td>{servicios.descripcionAdicional}</td>
                  <td>{servicios.sistemaColor}</td>
                  <td>{servicios.distencion}</td>
                  <td>{servicios.diente.join(', ')}</td>
                  <td>{servicios.tonoInferior}</td>
                  <td>{servicios.tonoMedio}</td>
                  <td>{servicios.tonoSuperior}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => {
                        removeItem(servicios)
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="totales">
        <Form.Label className="lbltotal">Precio total:</Form.Label>
        &nbsp;
        <Form.Control
          type="text"
          className="inpTotalSol"
          name="total"
          value={"$" + new Intl.NumberFormat('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(sumaTotal) + "MXN"}
          disabled
        />

      </div>
    </div>
  );
};

function initialFormValue() {
  return {
    sumaTotal: "",
  };
}

function formatModelServicios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      clasificacion: data.clasificacion,
      material: data.material,
      pieza: data.pieza,
      precio: data.precio,
      descripcionAdicional: data.descripcionAdicional,
      sistemaColor: data.sistemaColor,
      distencion: data.distencion,
      diente: data.diente,
      tonoInferior: data.tonoInferior,
      tonoMedio: data.tonoMedio,
      tonoSuperior: data.tonoSuperior,
    });
  });
  return dataTemp;
}
