import { useState, useEffect, useRef } from "react";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import { Tabla } from "./Tabla";
import imagenDos from "../../assets/img/color.png";
import imagenDT from "../../assets/img/TEMPORAL2.png";
import imagen from "../../assets/img/PERMANENTE2.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  registraOrdenesServicio,
  obtenerNumeroOrdenServicio,
  listarOrdenesServicio,
} from "../../api/ordenesServicio";
import { withRouter } from "../../utils/withRouter";
import queryString from "query-string";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import { listarServiciosFolio, registraServicios } from "../../api/servicios";
import { listarMensajeros } from "../../api/mensajeros";
import { Load } from "../load/load";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faArrowRight,
  faArrowLeft,
  faCloudArrowUp,
  faFile,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { listarPiezasPorMaterial } from "../../api/precios";
import {
  obtenerColorimetria,
} from "../../api/colorimetria";
import { map } from "lodash";
import { TablaServicios } from "./TablaServicios";
import { listarDoctores, listarDoctoresPorCorreo, listarDoctoresPorCorreoDoctor } from "../../api/doctores";
import { listarEstablecimientos } from "../../api/establecimientos";
import html2canvas from "html2canvas";
import { registraEvento } from "../../api/eventos";
import Dropzone from "../dropzone/Dropzone";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { registraPreciosOrden } from "../../api/preciosOrden";
import { LogsInformativos } from "../logs/logs";
import { listarPorTipoDepartamento } from "../../api/departamentos";
import { listarMaterialPorDep } from "../../api/materialesCat";
import { listarConfiguracionRutas } from "../../api/configuracionRutas";
import { registraAbonosOrdenes } from "../../api/abonosOrdenes";

function OrdenServicioNueva({ history }) {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [formaPago, setFormaPago] = useState("efectivo");

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado basado en el tamaño de la pantalla
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1024px)").matches);
    };

    // Configuramos el listener para el cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Inicializamos el estado basado en el tamaño de la pantalla actual
    handleResize();

    // Limpiamos el listener cuando el componente se desmonte
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [listRutas, setListRutas] = useState([]);
  const obtenerRutas = () => {
    try {
      listarConfiguracionRutas()
        .then((response) => {
          const { data } = response;
          if (!listRutas && data) {
            setListRutas(formatModelRutasList(data));
          } else {
            const datosRutas = formatModelRutasList(data);
            setListRutas(datosRutas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerRutas();
  }, []);

  const [formData, setFormData] = useState(initialFormValue());
  const [formData2, setFormData2] = useState(initialFormValue2());

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [entrega, setEntrega] = useState("")

  useEffect(() => {
    setEntrega(formData.consultorio.split("/")[2]);
  }, [formData.consultorio.split("/")[2]]);

  const [estadoPais, setEstadoPais] = useState("QUERETARO");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("SAN JUAN DEL RIO");

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const [nombreMensajeroFilter, setnombreMensajeroFilter] = useState("");
  const [telefonoMensajeroFilter, settelefonoMensajeroFilter] = useState("");

  useEffect(() => {
    if (tipoUsuario === "mensajero") {
      const filterMensajero = listarMensajero.find(
        (data) => data.email === correoUsuario
      );
      console.log(filterMensajero);
      if (filterMensajero) {
        setnombreMensajeroFilter(filterMensajero.nombre);
        settelefonoMensajeroFilter(filterMensajero.telefono);
      } else {
        console.error("Datos del mensajero no encontrados");
      }
    }
  }, [listarMensajero, correoUsuario, tipoUsuario]);

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenOrden1, setImagenOrden1] = useState(null);
  const [imagenOrden2, setImagenOrden2] = useState(null);
  const [imagenOrden3, setImagenOrden3] = useState(null);
  const [imagenOrden4, setImagenOrden4] = useState(null);
  const [imagenOrden5, setImagenOrden5] = useState(null);
  const [imagenOrden6, setImagenOrden6] = useState(null);

  const [linkImagen1, setLinkImagen1] = useState("");

  const cargarImagen11 = () => {
    try {
      subeArchivosCloudinary(imagenOrden1, "laboratorio")
        .then((response) => {
          const { data } = response;
          console.log(data);
          const { secure_url } = data;
          setLinkImagen1(secure_url);
          console.log(linkImagen1);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen11();
  }, [imagenOrden1]);

  const [linkImagen2, setLinkImagen2] = useState("");

  const cargarImagen2 = () => {
    try {
      subeArchivosCloudinary(imagenOrden2, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen2(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen2();
  }, [imagenOrden2]);

  const [linkImagen3, setLinkImagen3] = useState("");

  const cargarImagen3 = () => {
    try {
      subeArchivosCloudinary(imagenOrden3, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen3(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen3();
  }, [imagenOrden3]);

  const [linkImagen4, setLinkImagen4] = useState("");

  const cargarImagen4 = () => {
    try {
      subeArchivosCloudinary(imagenOrden4, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen4(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen4();
  }, [imagenOrden4]);

  const [linkImagen5, setLinkImagen5] = useState("");

  const cargarImagen5 = () => {
    try {
      subeArchivosCloudinary(imagenOrden5, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen5(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen5();
  }, [imagenOrden5]);

  const [linkImagen6, setLinkImagen6] = useState("");

  const cargarImagen6 = () => {
    try {
      subeArchivosCloudinary(imagenOrden6, "laboratorio")
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { secure_url } = data;
          setLinkImagen6(secure_url);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagen6();
  }, [imagenOrden6]);

  const canvasRef = useRef(null);
  const [markerX, setMarkerX] = useState(0);
  const [markerY, setMarkerY] = useState(0);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [filteredDoctors2, setFilteredDoctors2] = useState([]);

  useEffect(() => {
    const filterDoctors = () => {
      const emailToMatch = tipoUsuario != "doctor" ? formData.nombreDoctor.split("/")[1] : correoUsuario;
      const filtered = listarDoctor.filter(doctor => doctor.email === emailToMatch);
      setFilteredDoctors2(filtered[0]?.direcciones);
    };

    filterDoctors(); // Llama la función de filtrado

  }, [formData.nombreDoctor, correoUsuario]); // Ejecuta el efecto cada vez que nombreDoctor cambie

  console.log(filteredDoctors2)

  const obtenerDoctorConsultorio = () => {
    try {
      listarDoctoresPorCorreo(formData.consultorio.split("/")[1])
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtieneOrdenID = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              const filterID = datosOrdenes.find(
                (data) => data.folio === folioActual
              ); // Utiliza 'find' en lugar de 'filter'
              if (filterID) {
                setListOrdenes([filterID]); // Actualiza el estado con un array que contiene el elemento filtrado
                console.log(filterID);
              } else {
                console.log(
                  "No se encontró ningún elemento con el ID especificado"
                );
              }
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error("Error en la función obtieneOrdenID:", e);
    }
  };

  const [listServiciosFolio, setListServiciosFolio] = useState([]);

  const obtenerServiciosFolio = () => {
    try {
      listarServiciosFolio(folioActual)
        .then((response) => {
          const { data } = response;

          if (!listServiciosFolio && data) {
            setListServiciosFolio(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServiciosFolio(datosServicios);
            console.log(listServiciosFolio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [sumaPrecios, setSumaPrecios] = useState(0);

  useEffect(() => {
    const calcularSumaPrecios = () => {
      if (listServiciosFolio && listServiciosFolio.length > 0) {
        const nuevaSumaPrecios = listServiciosFolio.reduce(
          (acumulador, orden) => acumulador + parseFloat(orden.precio),
          0
        );
        setSumaPrecios(nuevaSumaPrecios);
        console.log(sumaPrecios);
      } else {
        setSumaPrecios(0);
      }
    };

    calcularSumaPrecios();
  }, [listServiciosFolio]);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            setListEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, []);

  const [nombreConsultorio, setnombreConsultorio] = useState("");
  const [municipioConsultorio, setMunicipioConsultorio] = useState("");
  const [cpConsultorio, setcpConsultorio] = useState("");
  const [localidadConsultorio, setLocalidadConsultorio] = useState("");
  const [listDoctorFilter, setlistDoctorFilter] = useState([]);
  const [nombreDocFilter, setnombreDocFilter] = useState("");
  const [listConsultoriosFilter, setlistConsultoriosFilter] = useState([]);
  const [emailConsDoc, setemailConsDoc] = useState("");

  const obtenerDoctorConsultorioPerf = (correo) => {
    try {
      listarDoctoresPorCorreo(correo)
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setlistDoctorFilter(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setlistDoctorFilter(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerDoctorPorCorreo = (correo) => {
    try {
      listarDoctoresPorCorreoDoctor(correo)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            const filterDoc = data[0]
            const nombreDoc = filterDoc.nombre +
              " " +
              filterDoc.apellidoPaterno +
              " " +
              filterDoc.apellidoMaterno;
            setnombreDocFilter(nombreDoc)
            setemailConsDoc(filterDoc.correoConsultorio)
            //setListDoctor(formatModelDoctor(data));
          } else {

            //setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [direcciones, setDirecciones] = useState();

  const obtenerDoctorPorCorreo2 = (correo) => {
    try {
      listarDoctoresPorCorreoDoctor(correo)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setDirecciones(data[0].direcciones)
            //setemailConsDoc(filterDoc.correoConsultorio)
            //setListDoctor(formatModelDoctor(data));
          } else {

            //setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario === "consultorio") {
      obtenerDoctorConsultorioPerf(correoUsuario)
    } else if (tipoUsuario === "doctor") {
      obtenerDoctorPorCorreo(correoUsuario)
    }
  }, [tipoUsuario, correoUsuario])

  useEffect(() => {
    if (tipoUsuario === "doctor") {
      const filterID = listarDoctor.find(
        (data) => data.email === correoUsuario
      );
      if (filterID) {
        //setnombreConsultorio(filterID.razonSocial);
        setMunicipioConsultorio(filterID.municipio);
        setcpConsultorio(filterID.codigoPostal);
        setLocalidadConsultorio(filterID.colonia);
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  }, [listarEstablecimiento, correoUsuario, tipoUsuario]);

  useEffect(() => {
    const filterID = listarEstablecimiento.filter(
      (data) => data.email === emailConsDoc
    );
    if (filterID) {
      setlistConsultoriosFilter(filterID);
    } else {
      console.log("No se encontró ningún elemento con el ID especificado");
    }
  }, [emailConsDoc, listarEstablecimiento]);

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [selectedCoordinate, setSelectedCoordinate] = useState("");
  const [nombreDiente, setNombreDiente] = useState("");

  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedElementsArr, setSelectedElementsArr] = useState([]);

  const handleCoordinateChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedElements((prevSelectedElements) => [
      ...prevSelectedElements,
      selectedValue,
    ]);
    setSelectedElementsArr((prevSelectedElements1) => [
      ...prevSelectedElements1,
      selectedValue.split(",")[selectedValue.split(",").length - 1],
    ]);
  };

  const handleDeleteSelected = (index, event) => {
    event.preventDefault();
    const newSelectedElements = [...selectedElements];
    newSelectedElements.splice(index, 1);
    setSelectedElements(newSelectedElements);
  };


  useEffect(() => {
    selectedElements.forEach((selectedValue) => {
      const [x, y, nombreDiente] = selectedValue.split(",").map(Number);
      setMarkerX(x);
      setMarkerY(y);
      setNombreDiente(nombreDiente);
    });
  }, [selectedElements]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");

      // Borrar el contenido anterior del canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Cargar la imagen de fondo
      const backgroundImage = new Image();
      backgroundImage.src =
        opcion == "DP" ? imagen : opcion == "DT" ? imagenDT : null;

      backgroundImage.onload = () => {
        // Obtén el contenedor del canvas
        const container = document.querySelector(".containerCanvasMap");

        if (container) {
          // Establece el fondo del contenedor para mostrar la imagen seleccionada
          container.style.backgroundImage = `url('${backgroundImage.src}')`;
          // Elimina cualquier imagen de fondo anterior
          const containerChildren = container.children;
          for (let i = 0; i < containerChildren.length; i++) {
            if (
              containerChildren[i] !== backgroundImage &&
              containerChildren[i] !== canvas
            ) {
              containerChildren[i].style.display = "none";
            }
          }
        }

        // Iterar sobre todos los elementos en selectedElements y dibujar un marcador para cada uno
        selectedElements.forEach((selectedValue) => {
          const [x, y, nombreDiente] = selectedValue.split(",").map(Number);

          ctx.fillStyle = "red";
          ctx.beginPath();
          ctx.arc(x, y, 6, 0, Math.PI * 2);
          ctx.fill();
        });
      };
    }
  }, [selectedElements]);

  useEffect(() => {
    setSelectedElements([]);
    setSelectedElementsArr([]);
  }, [opcion]);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarPorTipoDepartamento("Produccion")
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  const [listarMateriales, setListMateriales] = useState([]);

  const obtenerMaterialesFolio = (folio) => {
    try {
      listarMaterialPorDep(folio)
        .then((response) => {
          const { data } = response;

          if (!listarMateriales && data) {
            setListMateriales(formatModelMateriales(data));
          } else {
            setListMateriales(formatModelMateriales(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarPiezas, setListPiezas] = useState([]);
  const [precio, setPrecio] = useState("");
  const [piezaSelect, setPiezaSelect] = useState("");

  useEffect(() => {
    setListPiezas([]);
    setPiezaSelect("");
    setPrecio("");
    if (formData2.clasificacion) {
      obtenerMaterialesFolio(formData2.clasificacion.split("|")[0]);
    }
  }, [formData2.clasificacion]);

  const obtenerPiezas = (material) => {
    try {
      listarPiezasPorMaterial(material)
        .then((response) => {
          const { data } = response;

          if (!listarPiezas && data) {
            setListPiezas(formatModelPieza(data));
          } else {
            setListPiezas(formatModelPieza(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  console.log(listarPiezas)

  useEffect(() => {
    setPiezaSelect("");
    setPrecio("");
    if (formData2.material) {
      obtenerPiezas(formData2.material.split("|")[0]);
    }
  }, [formData2.material]);

  useEffect(() => {
    if (piezaSelect != "") {
      setPrecio(piezaSelect.split("|")[1]);
    } else {
      setPrecio("");
    }
  }, [piezaSelect]);

  const [listarColorimetrias, setListColorimetrias] = useState([]);

  useEffect(() => {
    if (piezaSelect) {
      const selectedPieza = listarPiezas.find(
        (pieza) => pieza.nombrePieza === piezaSelect.split('|')[0]
      );
      if (selectedPieza) {
        setListColorimetrias(selectedPieza.colorimetrias);
      }
    }
  }, [piezaSelect]);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds(); // Obtener los minutos
  let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;


  // Luego, si necesitas usar esto en React con useState:
  const [fechaNueva, setFechaNueva] = useState(datosFecha);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);


  const currentYear = new Date().getFullYear();

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const temp = formData.nombreDoctor.split("/");
      const temp2 = formData.consultorio.split("/");
      const dataTemp = {
        folio: folioActual,
        consecutivo: consecutivo,
        fecha: fechaNueva,
        localidad: tipoUsuario != "consultorio" ? temp2[0] : nombreConsultorio,
        nombreDoctor: tipoUsuario != "doctor" ? temp[0] : nombreDocFilter,
        cargoOrden: tipoUsuario != "doctor" ? temp[1] : correoUsuario,
        precioTotal: precioTotal,
        nombrePaciente: formData.nombrePaciente,
        tipoDentadura: formData.tipoDentadura,
        servicios: clasificacionMat,
        otros: formData.otros,
        recibio:
          tipoUsuario != "mensajero" ? formData.recibio : nombreMensajeroFilter,
        fechaEntrada: formData.fechaEntrada,
        estado: ((tipoUsuario == "mesa de control" && clasificacionMat.length == 0) ? "1" : tipoUsuario != "mensajero" && tipoUsuario != "administrador" && tipoUsuario != "mesa de control") ? "1" : "4",
        correoDoctor: tipoUsuario != "doctor" ? temp[1] : correoUsuario,
        correoConsultorio:
          tipoUsuario != "consultorio" ? temp2[1] : correoUsuario,
        imagen1: linkImagen1,
        observacionesImagen1: formData2.observaciones1,
        imagen2: linkImagen2,
        observacionesImagen2: formData2.observaciones2,
        imagen3: linkImagen3,
        observacionesImagen3: formData2.observaciones3,
        imagen4: linkImagen4,
        observacionesImagen4: formData2.observaciones4,
        imagen5: linkImagen5,
        observacionesImagen5: formData2.observaciones5,
        imagen6: linkImagen6,
        observacionesImagen6: formData2.observaciones6,
        telefonoDoctor: temp[2],
        mensajero: tipoUsuario != "mensajero" ? "" : nombreMensajeroFilter,
        correoMensajero: tipoUsuario != "mensajero" ? "" : correoUsuario,
        telefono: tipoUsuario != "mensajero" ? "" : telefonoMensajeroFilter,
        fechaProgramadaRecoleccion:
          (tipoUsuario != "mensajero" && tipoUsuario != "administrador" && tipoUsuario != "mesa de control") ? "" : "Recolectado",
        fechaRealProgramacion: "",
        estadoPago: "0",
        direccionConsultorio: {
          municipio: formData.direccion ? formData.direccion.split("-")[0] : tipoUsuario != "doctor" ? temp[3] : municipioConsultorio,
          CP: tipoUsuario != "doctor" ? temp[5] : cpConsultorio,
          localidad: tipoUsuario != "doctor" ? temp[4] : localidadConsultorio,
        },
        //direccionConsultorio:
        // tipoUsuario == "mesa de control" ? municipioSeleccionado : tipoUsuario != "consultorio" ? temp2[2] : municipioConsultorio,
        datosDireccion: formData.especificacionesAdicionales
      };
      registraOrdenesServicio(dataTemp).then((response) => {
        const { data } = response;

        {
          serviciosAgg.map((servicios, index) => {
            registraServicios(servicios);
          });
        }

        toast.success(data.mensaje);
        LogsInformativos(
          "Se ha registrado la orden de cliente " + dataTemp.folio,
          dataTemp
        );
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        //cancelarRegistro()
        registraEventos();
        registrarPreciosOrden();
        if (formData.rutaMensajero) {
          registraAbonos()
        }
        setTimeout(() => {
          nextStep();
          obtenerServiciosFolio();
          obtieneOrdenID();
        }, 3000);
      }).catch(e => {
        console.log(e)
        if (e.message === 'Network Error') {
          //console.log("No hay internet")
          toast.error("Conexión al servidor no disponible");
          setLoading(false);
        } else {
          if (e.response && e.response.status === 401) {
            const { mensaje } = e.response.data;
            toast.error(mensaje);
            setLoading(false);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('nombreDoctor', formData.nombreDoctor)
    if (tipoUsuario == "mesa de control") {
      setMunicipioSeleccionado(formData.nombreDoctor.split('/')[3])
      setcpConsultorio(formData.nombreDoctor.split('/')[5])
      setLocalidadConsultorio(formData.nombreDoctor.split('/')[4])
    }
  }, [formData.nombreDoctor, tipoUsuario])


  const registraEventos = (e) => {
    try {
      setLoading(true);

      const dataTemp5 = {
        fecha: datosFecha,
        folioOrden: folioActual,
        usuario: nombreUsuario,
        tipoEvento: "Registro",
        motivoEvento: "Se realizó el registro de la orden",
        estado: "activo",
        leido: "0",
      };
      registraEvento(dataTemp5).then((response) => {
        const { data } = response;
        console.log(data);
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const registraAbonos = (e) => {
    try {
      setLoading(true);
      const temp = formData.nombreDoctor.split("/");
      const dataTemp5 = {
        ordenServicio: folioActual,
        cantidadRecibida: formData.pagoOrden,
        estado: "0",
        idRecibio: tipoUsuario != "mensajero" ? formData.recibio : nombreMensajeroFilter,
        hizoCargo: "",
        aprobo: "Administrador",
        fecha: fechaNueva.split(" ")[0],
        emailCargo: tipoUsuario != "doctor" ? temp[1] : correoUsuario,
        metodoPago: formaPago
      };
      registraAbonosOrdenes(dataTemp5).then((response) => {
        const { data } = response;
        console.log(data);
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const registrarPreciosOrden = () => {
    try {
      const dataTemp3 = {
        ordenServicio: folioActual,
        tipo: "Cargo",
        concepto: "Precio inicial de la orden",
        cantidad: precioTotal,
        correoRegistro: correoUsuario,
        estado: "activo",
      };
      registraPreciosOrden(dataTemp3).then((response) => {
        const { data } = response;
      });
    } catch (e) {
      console.log(e);
    }
  };

  async function ejecutarCapturasYMostrarLogs() {
    if (
      !formData2.clasificacion ||
      !piezaSelect ||
      !formData2.material ||
      !formData2.descripcionServicio ||
      !formData2.sistemaColor
    ) {
      toast.warning("Faltan datos por ingresar");
    } else {
      try {
        await Promise.all([
          ejecutarCapturaEImagen(),
          ejecutarCapturaEImagenCanvas(),
        ]);
      } catch (error) {
        console.error("Error en ejecutarCapturasYMostrarLogs:", error);
      }
    }
  }

  function esperar(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  console.log(listarDoctor)

  const [linkImagenEt, setLinkImagenEt] = useState("");

  const capturarPantalla = () => {
    return new Promise((resolve, reject) => {
      const elemento = document.getElementById("imgGenera");

      html2canvas(elemento, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        cargarImagen1(imgData).then(() => {
          resolve(); // Resuelve la promesa después de cargar la imagen
        });
      });
    });
  };

  const cargarImagen1 = (imgData) => {
    return new Promise((resolve, reject) => {
      try {
        subeArchivosCloudinary(imgData, "laboratorio")
          .then((response) => {
            const { data } = response;
            const { secure_url } = data;
            setLinkImagenEt(secure_url);
            resolve(); // Resuelve la promesa después de establecer el enlace de la imagen
          })
          .catch((e) => {
            console.log(e);
            reject(e); // Rechaza la promesa si hay un error
          });
      } catch (e) {
        console.log(e);
        reject(e); // Rechaza la promesa si hay un error
      }
    });
  };

  const [linkImagenCanvas, setLinkImagenCanvas] = useState("");

  const capturarPantallaCanvas = () => {
    return new Promise((resolve, reject) => {
      const elementoCanvas = document.getElementById("canvasDiag");

      html2canvas(elementoCanvas, { useCORS: true }).then((canvas) => {
        const imgDataCanvas = canvas.toDataURL("image/png");
        cargarImagenCanvas(imgDataCanvas).then(() => {
          resolve(); // Resuelve la promesa después de cargar la imagen
        });
      });
    });
  };

  const cargarImagenCanvas = (imgDataCanvas) => {
    return new Promise((resolve, reject) => {
      try {
        subeArchivosCloudinary(imgDataCanvas, "laboratorio")
          .then((response) => {
            const { data } = response;
            const { secure_url } = data;
            setLinkImagenCanvas(secure_url);
            resolve(); // Resuelve la promesa después de establecer el enlace de la imagen
          })
          .catch((e) => {
            console.log(e);
            reject(e); // Rechaza la promesa si hay un error
          });
      } catch (e) {
        console.log(e);
        reject(e); // Rechaza la promesa si hay un error
      }
    });
  };

  const ejecutarCapturaEImagen = async () => {
    try {
      await capturarPantalla();
      console.log("Enlace de la imagen: " + linkImagenEt); // Mover el console.log aquí
    } catch (error) {
      console.error("Error al capturar o cargar la imagen:", error);
    }
  };

  const ejecutarCapturaEImagenCanvas = async () => {
    try {
      await capturarPantallaCanvas();
      console.log("Nuevo enlace de la imagen del canvas:", linkImagenCanvas); // Mover el console.log aquí
    } catch (error) {
      console.error("Error al capturar o cargar la imagen del canvas:", error);
    }
  };

  const [serviciosAgg, setServiciosAgg] = useState([]);

  const agregarServicios = () => {
    console.log("enlaces funcion ", linkImagenCanvas, linkImagenEt);

    const temp2 = formData2.sistemaColor.split("/");
    const temp = formData.nombreDoctor.split("/");
    const temp3 = formData.consultorio.split("/");
    const dataTemp = {
      folio: folioActual,
      clasificacion: formData2.clasificacion.split("|")[1],
      material: formData2.material.split("|")[1],
      pieza: piezaSelect.split("|")[0],
      precio: Number(precio) * Number(selectedElements.length),
      descripcionAdicional: formData2.descripcionServicio,
      sistemaColor: temp2[1],
      distencion: formData2.tipoDentadura,
      diente: selectedElementsArr,
      tonoInferior: formData2.tonoInferior,
      tonoMedio: formData2.tonoMedio,
      tonoSuperior: formData2.tonoSuperior,
      imagenEsquema: linkImagenCanvas,
      imagenDiente: linkImagenEt,
      departamento: formData2.clasificacion.split("|")[1],
      fechaHora: fechaNueva,
      estado: "1",
      municipio: tipoUsuario == "mesa de control" ? municipioSeleccionado : tipoUsuario != "doctor" ? temp[3] : municipioConsultorio,
    };
    setServiciosAgg([...serviciosAgg, dataTemp]);
    console.log(dataTemp);
    toast.success("Servicio agregado");
    setLinkImagenCanvas("");
    setLinkImagenEt("");
    setSelectedElements([]);
    setSelectedElementsArr([]);
    setFormData2(prevState => ({
      ...prevState,
      clasificacion: '',
      material: '',
      descripcionServicio: '',
      tipoDentadura: '',
      tonoInferior: '',
      tonoMedio: '',
      tonoSuperior: '',
      sistemaColor: ''
    }));
  };

  console.log(formData.nombreDoctor)

  useEffect(() => {
    if (linkImagenCanvas != "" && linkImagenEt != "") {
      agregarServicios();
    } else {
      console.log("enlaces vacios");
    }
  }, [linkImagenCanvas, linkImagenEt]);

  const precioTotal = serviciosAgg.reduce(
    (amount, item) => amount + (item.precio ? parseFloat(item.precio) : 0),
    0
  );

  const [clasificacionMat, setClasificacionMat] = useState([{
    "id": "1",
    "servicio": "Modelo superior",
    "cantidad": "0"
  },
  {
    "id": "2",
    "servicio": "Modelo inferior",
    "cantidad": "0"
  },
  {
    "id": "3",
    "servicio": "Mordida de cera",
    "cantidad": "0"
  },
  {
    "id": "4",
    "servicio": "Mordida de silicon",
    "cantidad": "0"
  },
  {
    "id": "5",
    "servicio": "Sin antagonista",
    "cantidad": "0"
  },
  {
    "id": "6",
    "servicio": "Cobrar llenado de cucharilla",
    "cantidad": "0"
  },]);
  const [servicio, setServicio] = useState("");
  const [cantidad, setCantidad] = useState("");

  const agregarClas = () => {
    const nuevaClas = { servicio, cantidad };
    setClasificacionMat([...clasificacionMat, nuevaClas]);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormData2({ ...formData2, [e.target.name]: e.target.value });
  };

  const [colores, setColores] = useState([]);

  const obtenerColores = () => {
    const temp = formData.sistemaColor.split("/");
    console.log(temp);
    try {
      obtenerColorimetria(temp[0])
        .then((response) => {
          const { data } = response;
          const temp2 = data.tonos.split(",");
          const resultado = temp2.map((tonos) => ({ tonos }));
          setColores(resultado);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerColores();
  }, [formData.sistemaColor]);

  const [iniciales, setIniciales] = useState("");

  useEffect(() => {
    const temp = formData.nombreDoctor.split("/");
    const palabras = (tipoUsuario == "administrador" || tipoUsuario == "mesa de control") ? temp[0].split(" ") : nombreDocFilter.split(" "); // Dividir la cadena en palabras
    const iniciales = palabras.map((palabra) => palabra[0]); // Obtener la inicial de cada palabra
    setIniciales(iniciales.join(""));
  }, [formData.nombreDoctor, tipoUsuario, nombreDocFilter]);

  // Convertir el año a una cadena y obtener los dos últimos caracteres
  const lastTwoDigits = currentYear.toString().slice(-2);
  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");
  const [consecutivo, setConsecutivo] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroOrdenServicio()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noOrden } = data;
          setFolioActual(iniciales.toUpperCase() + lastTwoDigits + "-" + noOrden);
          setConsecutivo(noOrden);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, [iniciales]);

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    if (tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control") {
      if (currentStep < 6) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(piezaSelect)

  const [selectedEmail, setSelectedEmail] = useState('');

  // Function to handle changes in the first dropdown
  const handleDoctorChange = (event) => {
    const selectedValue = event.target.value;
    const selectedDoctor = listarDoctor.find(
      (doctor) =>
        (doctor.nombre + ' ' + doctor.apellidoPaterno + ' ' + doctor.apellidoMaterno + '/' + doctor.email) === selectedValue
    );
    setSelectedEmail(selectedDoctor?.email || '');
  };

  // Filter the doctors based on the selected email
  const filteredDoctors = listarDoctor.filter(
    (doctor) => doctor.email === selectedEmail
  );


  return (
    <>
      {loading && <Load />}
      <div className="contenedorPrincipalOrden">
        <div className="containerOrden">
          <div className="containerDatosOS">
            <h1 className="ordenTituloP">Nueva orden de ciente</h1>
            {!isMobile ? (
              <>
                <div className="barraProgreso">
                  {((tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control")
                    ? [1, 2, 3, 4, 5]
                    : [1, 2, 3, 4]
                  ).map((stepNum) => (
                    <div
                      key={stepNum}
                      className={`step ${stepNum <= currentStep ? "active" : ""} `}
                    >
                      {stepNum}
                      <div>
                        {stepNum === 1 ? (
                          <>
                            <span className="textocontaineret">
                              Datos generales
                            </span>
                            <p className="textoCardStep">
                              Datos de consultorio y doctor
                            </p>
                          </>
                        ) : stepNum === 2 ? (
                          <>
                            <span className="textocontaineret">
                              Añadir servicios
                            </span>
                            <p className="textoCardStep">
                              Descripcion del servicio
                            </p>
                          </>
                        ) : stepNum === 3 ? (
                          <>
                            <span className="textocontaineret">
                              Verificar servicios
                            </span>
                            <p className="textoCardStep">
                              Resumen de servicios agregados
                            </p>
                          </>
                        ) : stepNum === 4 ? (
                          (tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control") ? (
                            <>
                              <span className="textocontaineret">
                                Moldes que envia el doctor
                              </span>
                              <p className="textoCardStep">
                                Informacion de moldes y materiales
                              </p>
                            </>
                          ) : (
                            <>
                              <span className="textocontaineret">Terminar</span>
                            </>
                          )
                        ) : stepNum === 5 ? (
                          <>
                            <span className="textocontaineret">Recibió</span>
                            <p className="textoCardStep">
                              Informacion de quien recibe
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div>
                  {(() => {
                    const steps = tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control"
                      ? [1, 2, 3, 4, 5]
                      : [1, 2, 3, 4];

                    // Solo renderiza el paso actual
                    const stepNum = currentStep;
                    const stepContent = steps.includes(stepNum) ? {
                      title: stepNum === 1
                        ? "Datos generales"
                        : stepNum === 2
                          ? "Añadir servicios"
                          : stepNum === 3
                            ? "Verificar servicios"
                            : stepNum === 4
                              ? (tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control")
                                ? "Moldes que envía el doctor"
                                : "Terminar"
                              : stepNum === 5
                                ? "Recibió"
                                : "",
                      description: stepNum === 1
                        ? "Datos de consultorio y doctor"
                        : stepNum === 2
                          ? "Descripción del servicio"
                          : stepNum === 3
                            ? "Resumen de servicios agregados"
                            : stepNum === 4
                              ? (tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control")
                                ? "Información de moldes y materiales"
                                : ""
                              : stepNum === 5
                                ? "Información de quien recibe"
                                : ""
                    } : null;

                    return stepContent ? (
                      <div style={{ width: '100%' }} className={`step ${stepNum <= currentStep ? "active" : ""}`}>
                        {stepNum}
                        <div>
                          <span className="textocontaineret">{stepContent.title}</span>
                          <br />
                          {stepContent.description && (
                            <span className="textocontaineret">{stepContent.description}</span>
                          )}
                        </div>
                      </div>
                    ) : null;
                  })()}
                </div>

              </>
            )}

            {currentStep === 1 && (
              <div>
                <h2>Datos generales</h2>
                <hr />
                <Form onChange={onChange}>
                  <div>

                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="folioOS2">Fecha:</Form.Label>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <Form.Control
                          name="fecha"
                          value={fechaNueva}
                          onChange={handleFechaChange}
                          defaultValue={formData.fecha}
                          className="inpFechaOS2"
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="folioOS2">
                          Nombre del Doctor: <code>*</code>
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        {tipoUsuario != "doctor" ? (

                          <Form.Control
                            as="select"
                            type="text"
                            className="nombreDoctorOS2"
                            name="nombreDoctor"
                            defaultValue={formData.nombreDoctor}
                            onChange={handleDoctorChange} // Handle the change
                            placeholder="Nombre del Doctor"
                          >
                            <option>Elige una opción</option>
                            {map(
                              tipoUsuario != "doctor"
                                ? listarDoctor
                                : listDoctorFilter,
                              (cat, index) => (
                                <option
                                  key={index}
                                  value={
                                    cat?.nombre +
                                    " " +
                                    cat?.apellidoPaterno +
                                    " " +
                                    cat?.apellidoMaterno +
                                    "/" +
                                    cat?.email +
                                    "/" +
                                    cat?.telefonoCelular +
                                    "/" +
                                    cat?.municipio +
                                    "/" +
                                    cat?.colonia +
                                    "/" +
                                    cat?.codigoPostal
                                  }
                                >
                                  {" "}
                                  {cat?.nombre +
                                    " " +
                                    cat?.apellidoPaterno +
                                    " " +
                                    cat?.apellidoMaterno}
                                </option>
                              )
                            )}
                          </Form.Control>
                        ) : (
                          <>
                            <Form.Control
                              type="text"
                              placeholder="Nombre del doctor"
                              value={nombreDocFilter}
                              style={{ textTransform: "capitalize" }}
                              disabled
                            />
                          </>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="folioOS2">
                          Direccion: <code>*</code>
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <Form.Control
                          as="select"
                          type="text"
                          className="nombreDoctorOS2"
                          name="direccion"
                          defaultValue={formData.direccion}
                          placeholder="Direccion"
                        >
                          <option>Elige una opción</option>
                          {map(filteredDoctors2,
                            (cat, index) => (
                              <option
                                key={index}
                                value={
                                  cat?.municipio +
                                  "-" +
                                  cat?.codigoPostal +
                                  "-" +
                                  cat?.colonia
                                }
                              >
                                {" "}
                                {
                                  cat?.municipio +
                                  " - " +
                                  cat?.codigoPostal +
                                  " - " +
                                  cat?.colonia
                                }
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="lblNombrePaciente">
                          Nombre del paciente:
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <Form.Control
                          className="inpNombrePaciente"
                          name="nombrePaciente"
                          defaultValue={formData.nombrePaciente}
                          placeholder="Nombre del Paciente (opcional)"
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="folioOS2">Folio: <code>*</code></Form.Label>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <Form.Control
                          name="folioActual"
                          value={folioActual}
                          onChange={(e) => setFolioActual(e.target.value)}
                          className="inpFechaOS2"
                          disabled={tipoUsuario != "administrador" && tipoUsuario != "mesa de control"}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <h2>Añadir servicios</h2>
                <hr />
                <Form onChange={onChange}>
                  <Form.Label className="lblClaSerOS">Departamento: <code>*</code></Form.Label>
                  <Form.Control
                    as="select"
                    className="inpClasifSerOS"
                    placeholder="Clasificación"
                    //onChange={(e) => setClasificacion(e.target.value)}
                    name="clasificacion"
                    value={formData2.clasificacion}
                  >
                    <option>Elige una opción</option>
                    {listarDepartamento.map((departamento) => (
                      <option
                        value={departamento.folio + "|" + departamento.nombre}
                      >
                        {departamento.nombre}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label className="lblTipoSerOS">Material: <code>*</code></Form.Label>
                  <Form.Control
                    name="material"
                    as="select"
                    className="inpMaterialOS"
                    placeholder="Material (Servicio)"
                    value={formData2.material}
                  >
                    <option>Elige una opción</option>
                    {listarMateriales.map((material) => (
                      <option value={material.folio + "|" + material.nombre}>
                        {material.nombre}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label className="lblPiezaOS">Pieza: <code>*</code></Form.Label>
                  <Form.Control
                    //name="pieza"
                    as="select"
                    className="inpPiezaOS"
                    placeholder="Pieza (Tipo de material)"
                    value={piezaSelect}
                    onChange={(e) => setPiezaSelect(e.target.value)}
                  //onChange={(e) => setPieza(e.target.value)}
                  //defaultValue={formData2.pieza}
                  >
                    <option value="">Elige una opción</option>
                    {listarPiezas.map((pieza) => (
                      <option
                        value={pieza.nombrePieza + "|" + pieza.precioPublico}
                      >
                        {pieza.nombrePieza}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label className="lblPrecioSerOS">Precio: <code>*</code> </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      name="precioServicio"
                      type="text"
                      className="inpPrecioSerOS"
                      placeholder="Seleccionar pieza para obtener precio"
                      value={precio}
                      disabled
                    />
                  </InputGroup>

                  <div>
                    <Row>
                      <Col xs={12} md={4}>
                        <Form.Label className="lblSisColOS">
                          Descripcion de protesis: <code>*</code>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          className="txtDescripcion"
                          rows={4}
                          placeholder="Escribe los detalles adicionales del servicio, para comprender mejor el servicio que pide"
                          //onChange={(e) => setDescripcionAd(e.target.value)}
                          name="descripcionServicio"
                          value={formData2.descripcionServicio}
                        />
                        <Form.Label>Tipo de dentadura: <code>*</code></Form.Label>
                        <Form.Control
                          name="tipoDentadura"
                          value={formData2.tipoDentadura}
                          onChange={cambiaImagen}
                          as="select"
                        >
                          <option value="">Selecciona una opcion</option>
                          <option value="DP">Dentinción Permanente</option>
                          <option value="DT">Dentinción Temporal</option>
                        </Form.Control>
                        {formData2.tipoDentadura == "DP" && (
                          <>
                            <Form.Label>Diente: <code>*</code></Form.Label>
                            <Form.Control
                              as="select"
                              name="diente"
                              defaultValue={formData2.diente}
                              value={selectedCoordinate}
                              onChange={handleCoordinateChange}
                            >
                              <option value="">Selecciona una opcion</option>
                              <option value="90, 50, 16-Primer molar superior derecho">
                                16-Primer molar superior derecho
                              </option>
                              <option value="85, 60, 17-Segundo molar superior derecho">
                                17-Segundo molar superior derecho
                              </option>
                              <option value="85, 67, 18-Tercer molar superior derecho (muela del juicio)">
                                18-Tercer molar superior derecho (muela del
                                juicio)
                              </option>
                              <option value="140, 25, 11-Incisivo central superior derecho">
                                11-Incisivo central superior derecho
                              </option>
                              <option value="123, 25, 12-Incisivo lateral superior derecho">
                                12-Incisivo lateral superior derecho
                              </option>
                              <option value="107, 30, 13-Canino superior derecho">
                                13-Canino superior derecho
                              </option>
                              <option value="100, 35, 14-Primer premolar superior derecho">
                                14-Primer premolar superior derecho
                              </option>
                              <option value="95, 40, 15-Segundo premolar superior derecho">
                                15-Segundo premolar superior derecho
                              </option>
                              <option value="212, 50, 26-Primer molar superior izquierdo">
                                26-Primer molar superior izquierdo
                              </option>
                              <option value="215, 60, 27-Segundo molar superior izquierdo">
                                27-Segundo molar superior izquierdo
                              </option>
                              <option value="218, 67, 28-Tercer molar superior izquierdo (muela del juicio)">
                                28-Tercer molar superior izquierdo (muela del
                                juicio)
                              </option>
                              <option value="160, 25, 21-Incisivo central superior izquierdo">
                                21-Incisivo central superior izquierdo
                              </option>
                              <option value="176, 25, 22-Incisivo lateral superior izquierdo">
                                22-Incisivo lateral superior izquierdo
                              </option>
                              <option value="193, 30, 23-Canino superior izquierdo">
                                23-Canino superior izquierdo
                              </option>
                              <option value="200, 35, 24-Primer premolar superior izquierdo">
                                24-Primer premolar superior izquierdo
                              </option>
                              <option value="205, 40, 25-Segundo premolar superior izquierdo">
                                25-Segundo premolar superior izquierdo
                              </option>
                              <option value="210, 110, 36-Primer molar inferior izquierdo">
                                36-Primer molar inferior izquierdo
                              </option>
                              <option value="215, 100, 37-Segundo molar inferior izquierdo">
                                37-Segundo molar inferior izquierdo
                              </option>
                              <option value="215, 93, 38-Tercer molar inferior izquierdo (muela del juicio)">
                                38-Tercer molar inferior izquierdo (muela del
                                juicio)
                              </option>
                              <option value="160, 136, 31-Incisivo central inferior izquierdo">
                                31-Incisivo central inferior izquierdo
                              </option>
                              <option value="172, 132, 32-Incisivo lateral inferior izquierdo">
                                32-Incisivo lateral inferior izquierdo
                              </option>
                              <option value="185, 131, 33-Canino inferior izquierdo">
                                33-Canino inferior izquierdo
                              </option>
                              <option value="195, 125, 34-Primer premolar inferior izquierdo">
                                34-Primer premolar inferior izquierdo
                              </option>
                              <option value="205, 120, 35-Segundo premolar inferior izquierdo">
                                35-Segundo premolar inferior izquierdo
                              </option>
                              <option value="93, 112, 46-Primer molar inferior derecho">
                                46-Primer molar inferior derecho
                              </option>
                              <option value="87, 100, 47-Segundo molar inferior derecho">
                                47-Segundo molar inferior derecho
                              </option>
                              <option value="87, 91, 48-Tercer molar inferior derecho (muela del juicio)">
                                48-Tercer molar inferior derecho (muela del
                                juicio)
                              </option>
                              <option value="145, 136, 41-Incisivo lateral inferior derecho">
                                41-Incisivo lateral inferior derecho
                              </option>
                              <option value="130, 132, 42-Incisivo central inferior derecho">
                                42-Incisivo central inferior derecho
                              </option>
                              <option value="115, 132, 43-Canino inferior derecho">
                                43-Canino inferior derecho
                              </option>
                              <option value="110, 127, 44-Primer premolar inferior derecho">
                                44-Primer premolar inferior derecho
                              </option>
                              <option value="100, 120, 45-Segundo premolar inferior derecho">
                                45-Segundo premolar inferior derecho
                              </option>
                            </Form.Control>
                          </>
                        )}

                        {formData2.tipoDentadura == "DT" && (
                          <>
                            <Form.Label>Diente: <code>*</code></Form.Label>
                            <Form.Control
                              as="select"
                              name="diente"
                              defaultValue={formData2.diente}
                              value={selectedCoordinate}
                              onChange={handleCoordinateChange}
                            >
                              <option value="">Selecciona una opcion</option>
                              <option value="75, 53, 54-Primer molar temporal superior derecho">
                                54-Primer molar temporal superior derecho
                              </option>
                              <option value="75, 66, 55-Segundo molar temporal superior derecho">
                                55-Segundo molar temporal superior derecho
                              </option>
                              <option value="220, 53, 64-Primer molar temporal superior izquierdo">
                                64-Primer molar temporal superior izquierdo
                              </option>
                              <option value="225, 66, 65-Segundo molar temporal superior izquierdo">
                                65-Segundo molar temporal superior izquierdo
                              </option>
                              <option value="213, 115, 74-Primer molar temporal inferior izquierdo">
                                74-Primer molar temporal inferior izquierdo
                              </option>
                              <option value="223, 100, 75-Segundo molar temporal inferior izquierdo">
                                75-Segundo molar temporal inferior izquierdo
                              </option>
                              <option value="85, 115, 84-Primer molar temporal inferior derecho">
                                84-Primer molar temporal inferior derecho
                              </option>
                              <option value="75, 100, 85-Segundo molar temporal inferior derecho">
                                85-Segundo molar temporal inferior derecho
                              </option>
                              <option value="135, 35, 51-Incisivo central temporal superior derecho">
                                51-Incisivo central temporal superior derecho
                              </option>
                              <option value="108, 39, 52-Incisivo lateral temporal superior derecho">
                                52-Incisivo lateral temporal superior derecho
                              </option>
                              <option value="90, 43, 53-Canino temporal superior derecho">
                                53-Canino temporal superior derecho
                              </option>
                              <option value="165, 35, 61-Incisivo central temporal superior izquierdo">
                                61-Incisivo central temporal superior izquierdo
                              </option>
                              <option value="187, 39, 62-Incisivo lateral temporal superior izquierdo">
                                62-Incisivo lateral temporal superior izquierdo
                              </option>
                              <option value="205, 44, 63-Canino temporal superior izquierdo">
                                63-Canino temporal superior izquierdo
                              </option>
                              <option value="158, 127, 71-Incisivo central temporal inferior izquierdo">
                                71-Incisivo central temporal inferior izquierdo
                              </option>
                              <option value="177, 127, 72-Incisivo lateral temporal inferior izquierdo">
                                72-Incisivo lateral temporal inferior izquierdo
                              </option>
                              <option value="194, 127, 73-Canino temporal inferior izquierdo">
                                73-Canino temporal inferior izquierdo
                              </option>
                              <option value="140, 127, 81-Incisivo central temporal inferior derecho">
                                81-Incisivo central temporal inferior derecho
                              </option>
                              <option value="123, 127, 82-Incisivo lateral temporal inferior derecho">
                                82-Incisivo lateral temporal inferior derecho
                              </option>
                              <option value="105, 127, 83-Canino temporal inferior derecho">
                                83-Canino temporal inferior derecho
                              </option>
                            </Form.Control>
                          </>
                        )}
                        <Form.Label className="lblSisColOS">
                          Sistema de color: <code>*</code>
                        </Form.Label>
                        <Form.Control
                          name="sistemaColor"
                          as="select"
                          className="inpSisColOS"
                          placeholder="Sistema de color"
                          //onChange={(e) => setSistemaColor(e.target.value)}
                          value={formData2.sistemaColor}
                        >
                          <option>Elige una opción</option>
                          {map(listarColorimetrias, (cat, index) => (
                            <option
                              key={index}
                              value={cat?.id + "/" + cat?.nombre}
                            >
                              {cat?.nombre}
                            </option>
                          ))}
                        </Form.Control>
                        {formData2.sistemaColor != "" && (
                          <>
                            <div className="divTonos">
                              <Form.Label className="lblTonoOS">
                                Tonos:
                              </Form.Label>
                              <br />
                              <Form.Label className="lblTonoOS">
                                Cervical: <code>*</code>
                              </Form.Label>
                              <Form.Control
                                name="tonoInferior"
                                as="select"
                                className="inpTono"
                                placeholder="Tono Inferior"
                                //onChange={(e) => setTonoInferior(e.target.value)}
                                value={formData2.tonoInferior}
                              >
                                <option value="">Elige una opción</option>
                                {map(colores, (cat, index) => (
                                  <option key={index} value={cat?.tonos}>
                                    {cat?.tonos}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Label className="lblTonoOS">
                                Media: <code>*</code>
                              </Form.Label>
                              <Form.Control
                                name="tonoMedio"
                                as="select"
                                className="inpTono"
                                placeholder="Tono Medio"
                                //onChange={(e) => setTonoMedio(e.target.value)}
                                value={formData2.tonoMedio}
                              >
                                <option value="">Elige una opción</option>
                                {map(colores, (cat, index) => (
                                  <option key={index} value={cat?.tonos}>
                                    {cat?.tonos}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Label className="lblTonoOS">
                                Incisal: <code>*</code>
                              </Form.Label>
                              <Form.Control
                                name="tonoSuperior"
                                as="select"
                                className="inpTono"
                                placeholder="Tono Superior"
                                //onChange={(e) => setTonoSuperior(e.target.value)}
                                value={formData2.tonoSuperior}
                              >
                                <option value="">Elige una opción</option>
                                {map(colores, (cat, index) => (
                                  <option key={index} value={cat?.tonos}>
                                    {cat?.tonos}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>
                          </>
                        )}
                      </Col>
                      {(formData2.tipoDentadura == "" || formData2.diente == "") ? (
                        <>
                          <Col xs={12} md={4}>
                            <div className="imgTipoOS">
                              <div className="containerCanvasMap">
                                <img src={formData2.tipoDentadura != "DT" ? imagen : imagenDT} className="canvas-map" />
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <Col xs={12} md={4}>
                          <div id="canvasDiag" className="imgTipoOS">
                            <div className="containerCanvasMap">
                              <canvas
                                ref={canvasRef}
                                className="canvas-map"
                              ></canvas>
                            </div>
                          </div>
                          <div>
                            <h5>Dientes Seleccionados:</h5>
                            <ul>
                              {selectedElements.map((element, index) => (
                                <li key={index}>
                                  {
                                    element.split(",")[
                                    element.split(",").length - 1
                                    ]
                                  }
                                  <Button variant="link" style={{ textDecoration: "none", color: "red" }} onClick={(event) => handleDeleteSelected(index, event)}><FontAwesomeIcon icon={faTrash} /></Button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Col>
                      )}

                      <Col xs={12} md={4}>
                        <div id="imgGenera" className="containerImgDiente">
                          <div className="divImgModal">
                            <img
                              src={imagenDos}
                              alt="Diagrama diente"
                              className="imagenDiagrama"
                            />
                          </div>
                          <div className="divOverlay overlay1">
                            {formData2.tonoInferior}
                          </div>
                          <div className="divOverlay overlay2">
                            {formData2.tonoMedio}
                          </div>
                          <div className="divOverlay overlay3">
                            {formData2.tonoSuperior}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <h2>Verificar servicios</h2>
                <hr />
                <Tabla
                  productos={serviciosAgg}
                  setProductos={setServiciosAgg}
                />
              </div>
            )}

            {currentStep === 4 &&
              ((tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control") && (
                <div>
                  <h2>Material que se envia</h2>
                  <hr />
                  <Form onChange={onChange}>
                    <TablaServicios
                      productos={clasificacionMat}
                      setProductos={setClasificacionMat}
                    />
                    <div>
                      <Form.Group
                        controlId="formFileMultiple"
                        className="form5"
                      >
                        <div className="imagenPrincipal">
                          <h4 className="textoImagenPrincipal">
                            Subir imagenes
                          </h4>
                          <div
                            title="Seleccionar imagenes"
                            className="imagenesServicio"
                          >
                            <div>
                              <Dropzone setImagenFile={setImagenOrden1} />
                              <p>Imagen 1</p>
                              <Form.Control
                                name="observaciones1"
                                defaultValue={formData2.observaciones1}
                                as="textarea"
                                placeholder="Observaciones imagen 1"
                                rows={2}
                              />
                            </div>
                            <div>
                              <Dropzone setImagenFile={setImagenOrden2} />
                              <p>Imagen 2</p>
                              <Form.Control
                                name="observaciones2"
                                defaultValue={formData2.observaciones2}
                                as="textarea"
                                placeholder="Observaciones imagen 2"
                                rows={2}
                              />
                            </div>
                            <div>
                              <Dropzone setImagenFile={setImagenOrden3} />
                              <p>Imagen3</p>
                              <Form.Control
                                name="observaciones3"
                                defaultValue={formData2.observaciones3}
                                as="textarea"
                                placeholder="Observaciones imagen 3"
                                rows={2}
                              />
                            </div>
                            <div>
                              <Dropzone setImagenFile={setImagenOrden4} />
                              <p>Imagen 4</p>
                              <Form.Control
                                name="observaciones4"
                                defaultValue={formData2.observaciones4}
                                as="textarea"
                                placeholder="Observaciones imagen 4"
                                rows={2}
                              />
                            </div>
                            <div>
                              <Dropzone setImagenFile={setImagenOrden5} />
                              <p>Imagen 5</p>
                              <Form.Control
                                name="observaciones5"
                                defaultValue={formData2.observaciones5}
                                as="textarea"
                                placeholder="Observaciones imagen 5"
                                rows={2}
                              />
                            </div>
                            <div>
                              <Dropzone setImagenFile={setImagenOrden6} />
                              <p>Imagen 6</p>
                              <Form.Control
                                name="observaciones6"
                                defaultValue={formData2.observaciones6}
                                as="textarea"
                                placeholder="Observaciones imagen 6"
                                rows={2}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                      </Form.Group>
                      <Row className="mb-2 mb-md-4 mb-lg-7">
                        <Col
                          xs={12}
                          md={2}
                          lg={2}
                          className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                        >
                          <Form.Label>Otros:</Form.Label>
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                          <Form.Control
                            name="otros"
                            defaultValue={formData.otros}
                            as="textarea"
                            rows={2}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              ))}
            {currentStep === 5 && (
              <div>
                <Form onChange={onChange}>
                  <h2>Recibió</h2>
                  <hr />
                  <div>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="recibeOS2">Recibió: <code>*</code></Form.Label>
                      </Col>
                      <Col xs={12} md={10} lg={10}>
                        {tipoUsuario != "mensajero" ? (
                          <Form.Control
                            as="select"
                            placeholder="Nombre de quien recibe"
                            defaultValue={formData.recibio}
                            name="recibio"
                          >
                            <option>Elige una opción</option>
                            {map(listarMensajero, (cat, index) => (
                              <option key={index} value={cat?.nombre}>
                                {cat?.nombre}
                              </option>
                            ))}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            type="text"
                            value={nombreMensajeroFilter}
                            placeholder="Nombre Mensajero"
                            style={{ textTransform: "capitalize" }}
                            disabled
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label className="fechaOS2">
                          Fecha de Entrada de protesis: <code>*</code>
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={10} lg={10}>
                        <Form.Control
                          type="date"
                          placeholder="dd/mm/aaaa"
                          defaultValue={formData.fechaEntrada}
                          name="fechaEntrada"
                        />
                      </Col>
                    </Row>
                    {(tipoUsuario == "administrador" || tipoUsuario == "panel mesa de control" || tipoUsuario == "mensajero") && (
                      <>
                        <Row className="mb-2 mb-md-4 mb-lg-7">
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                          >
                            <Form.Label className="recibeOS2">Ruta del mensajero:</Form.Label>
                          </Col>
                          <Col xs={12} md={10} lg={10}>
                            <Form.Control
                              as="select"
                              placeholder="Nombre de quien recibe"
                              defaultValue={formData.rutaMensajero}
                              name="rutaMensajero"
                            >
                              <option>Elige una opción</option>
                              {map(listRutas, (cat, index) => (
                                <option key={index} value={cat?.nombre}>
                                  {cat?.nombre}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-md-4 mb-lg-7">
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                          >
                            <Form.Label className="fechaOS2">
                              Pago:
                            </Form.Label>
                          </Col>
                          <Col xs={12} md={10} lg={10}>
                            <Form.Control
                              type="number"
                              placeholder="Pago de la orden (si existe alguno)"
                              defaultValue={formData.pagoOrden}
                              name="pagoOrden"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-md-4 mb-lg-7">
                          <Col
                            sm={12}
                            md={2}
                            lg={2}
                            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                          >
                            <Form.Label>
                              Forma de pago:
                            </Form.Label>
                          </Col>
                          <Col
                            sm={12}
                            md={10}
                            lg={10}
                            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                          >
                            <Form.Control
                              type="text"
                              as="select"
                              value={formaPago}
                              onChange={(e) => setFormaPago(e.target.value)}
                              placeholder={"Ingresa forma de pago"}
                            >
                              <option value="efectivo">Efectivo</option>
                              <option value="tarjeta">Tarjeta</option>
                              <option value="transferencia">Transferencia</option>
                              <option value="cheques">Cheques</option>
                              <option value="depositos">Depositos</option>
                            </Form.Control>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div >
        <div className="buttonsOS">
          <div className="botonAntOS">
            {currentStep > 1 &&
              currentStep < ((tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control") ? 6 : 4) && (
                <Button onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Anterior
                </Button>
              )}
          </div>
          <div className="botonGuardarServOS">
            {currentStep === 2 && (
              <>
                <Button
                  variant="success"
                  onClick={ejecutarCapturasYMostrarLogs}
                  disabled={selectedElements.length == 0}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} />
                  &nbsp;Agregar Servicio
                </Button>
              </>
            )}
          </div>
          <div className="botonSigOS">
            {currentStep < ((tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control") ? 5 : 3) ? (
              currentStep === 6 ? (
                // Botón que lleva de nuevo al currentStep 1
                <Button onClick={() => setCurrentStep(1)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Volver al Paso 1
                </Button>
              ) : (
                // Renderizar el botón "Siguiente" en otros casos
                <Button onClick={nextStep}>
                  <FontAwesomeIcon icon={faArrowRight} />
                  &nbsp;Siguiente
                </Button>
              )
            ) : currentStep === ((tipoUsuario === "mensajero" || tipoUsuario === "administrador" || tipoUsuario === "mesa de control") ? 6 : 4) ? (
              // Botón que lleva de nuevo al currentStep 1
              <Button variant="warning" onClick={() => setCurrentStep(1)}>
                <FontAwesomeIcon icon={faFile} />
                &nbsp;Nueva Orden
              </Button>
            ) : (
              // Renderizar el botón "Enviar" en otros casos
              <Button variant="success" onClick={onSubmit}>
                <FontAwesomeIcon icon={faCloudArrowUp} />
                &nbsp; Enviar
              </Button>
            )}
          </div>
        </div>
      </div >
    </>
  );
}

function initialFormValue() {
  return {
    status: "",
    nombreSolicitante: "",
    departamento: "",
    observaciones: "",
    clasificacion: "",
    sistemaColor: "",
    descripcionServicio: "",
    consultorio: "",
    nombreDoctor: "",
    nombrePaciente: "",
    consultorio: "",
    direccion: ""
  };
}

function initialFormValue2() {
  return {
    clasificacion: "",
    material: "",
    pieza: "",
    descripcionServicio: "",
    diente: "",
    tipoDentadura: "",
    sistemaColor: "",
    tonoInferior: "",
    tonoMedio: "",
    direccion: "",
    tonoSuperior: "",
    observaciones1: "",
    observaciones2: "",
    observaciones3: "",
    observaciones4: "",
    observaciones5: "",
    observaciones6: "",
    consultorio: ""
  };
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelMateriales(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      folio: data.folio,
      nombre: data.nombreMaterial,
    });
  });
  return dataTemp;
}

function formatModelPieza(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombrePieza: data.nombrePieza,
      material: data.material,
      departamento: data.departamento,
      precioPublico: data.precioPublico,
      colorimetrias: data.colorimetrias,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      correoConsultorio: data.correoConsultorio,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      estadoEstablecimiento: data.estadoDom,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.nExterior,
      numeroInterior: data.nInterior,
      codigoPostal: data.cPostal,
      direcciones: data.direcciones || []
    });
  });
  return dataTemp;
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      precioTotal: data.precioTotal,
      tipoDentadura: data.tipoDentadura,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      servicios: data.servicios,
      direccionConsultorio: data.direccionConsultorio,
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  return data.map((item) => {
    const dienteArray = item.diente;
    let diente = "";

    if (Array.isArray(dienteArray)) {
      diente = dienteArray
        .map(d => d.match(/\d+/)) // Extract numbers from each string
        .filter(Boolean)          // Remove any null values resulting from no match
        .join(", ");              // Join the numbers with comma and space
    }

    return {
      id: item._id,
      folio: item.folio,
      clasificacion: item.clasificacion,
      material: item.material,
      pieza: item.pieza,
      precio: item.precio,
      descripcionAdicional: item.descripcionAdicional,
      sistemaColor: item.sistemaColor,
      distencion: item.distencion,
      diente: diente,
      tonoInferior: item.tonoInferior,
      tonoMedio: item.tonoMedio,
      tonoSuperior: item.tonoSuperior,
      imagenEsquema: item.imagenEsquema,
      imagenDiente: item.imagenDiente,
    };
  });
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelRutasList(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      descripcion: data.descripcion,
      dia: data.dia,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(OrdenServicioNueva);
