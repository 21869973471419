import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarFacturacion,
    ENDPOINTObtenerFacturacion,
    ENDPOINTListarFacturacion,
    ENDPOINTActualizarFacturacion,
    ENDPOINTEliminarFacturacion,
    ENDPOINTDeshabilitarFacturacion,
    ENDPOINTObtenerUltimoFact
} from "./endpoints";
import axios from 'axios';

export async function listarFacturacion(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarFacturacion, config);
}

export async function registraFacturacion(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarFacturacion, data, config);
}

export async function listarUltimoFact(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUltimoFact, config);
}

// Para obtener todos los datos del usuario
export async function obtenerFacturacion(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFacturacion + `/${params}`, config);
}

export async function actualizarFacturacion(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarFacturacion + `/${id}`, data, config);
}

export async function eliminarFacturacion(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarFacturacion + `/${id}`, data, config);
}

export async function actualizaDeshabilitarFacturacion(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarFacturacion + `/${id}`, data, config);
}