import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarVales,
    ENDPOINTObtenerVales,
    ENDPOINTListarVales,
    ENDPOINTListarValesMaterialesRango,
    ENDPOINTListarValesInsumosRango,
    ENDPOINTListarValesMateriales,
    ENDPOINTListarValesInsumos,
    ENDPOINTListarPaginacionVales,
    ENDPOINTActualizarVales,
    ENDPOINTEliminarVales,
    ENDPOINTDeshabilitarVales,
    ENDPOINTObtenerFolioVales,
    ENDPOINTTotalVales,
    ENDPOINTFiltrarPorFechaYArea,
    ENDPOINTListarValesEstado,
    ENDPOINTListarValesDepartamento,
    ENDPOINTListarValesDepartamentoInsumos,
    ENDPOINTListarValesDepartamentoMateriales,
    ENDPOINTIncidenciasVales,
} from "./endpoints";
import axios from 'axios';

export async function listarVales(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarVales, config);
}

export async function listarValesInsumos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesInsumos, config);
}

export async function listarValesMateriales(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesMateriales, config);
}

export async function filtrarPorFechaYArea(fechaInicio, fechaFin, areaEspecifica){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTFiltrarPorFechaYArea + 
        `/?fechaInicio=${fechaInicio}&&fechaFin=${fechaFin}&&areaEspecifica=${areaEspecifica}`, config);
}

export async function listarPaginandoVales(pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPaginacionVales +
        `/?pagina=${pagina}&&limite=${limite}`
  , config);
}

export async function listarValesMaterialesRango(fechaInicio, fechaFin){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesMaterialesRango + `/?fechaInicio=${fechaInicio}&&fechaFin=${fechaFin}`, config);
}

export async function listarValesInsumosRango(fechaInicio, fechaFin){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesInsumosRango + `/?fechaInicio=${fechaInicio}&&fechaFin=${fechaFin}`, config);
}

export async function totalVales() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalVales, config);
}

export async function registraVales(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarVales, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerVales(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerVales + `/${params}`, config);
}

export async function actualizarVales(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarVales + `/${id}`, data, config);
}

export async function eliminarVales(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarVales + `/${id}`, data, config);
}

export async function actualizaDeshabilitarVales(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarVales + `/${id}`, data, config);
}

export async function registrarIncidenciasVales(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTIncidenciasVales + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroVales() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioVales, config);
}

export async function listarValesEstado(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesEstado, config);
}

// Para obtener por folio de orden
export async function obtenerValesDepartamento(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesDepartamento + `/${params}`, config);
}

// Para obtener por folio de orden
export async function obtenerValesDepartamentoInsumos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesDepartamentoInsumos + `/${params}`, config);
}

// Para obtener por folio de orden
export async function obtenerValesDepartamentoMateriales(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarValesDepartamentoMateriales + `/${params}`, config);
}