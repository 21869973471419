import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarPagos,
    ENDPOINTObtenerPagos,
    ENDPOINTListarPagos,
    ENDPOINTActualizarPagos,
    ENDPOINTEliminarPagos,
    ENDPOINTDeshabilitarPagos,
    ENDPOINTListarPagosPorCliente,
    ENDPOINTFiltrarFechasCargos,
    ENDPOINTListarPagosPorPeriodo
} from "./endpoints";
import axios from 'axios';

export async function listarPagos(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPagos, config);
}

export async function registraPagos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarPagos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerPagos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPagos + `/${params}`, config);
}

export async function actualizarPagos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarPagos + `/${id}`, data, config);
}

export async function eliminarPagos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarPagos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarPagos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarPagos + `/${id}`, data, config);
}

export async function listarPagosPorCliente(correo){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPagosPorCliente + `?email=${correo}`, config);
}

export async function listarPagosPorPeriodo(fechaInicial, fechaFinal){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPagosPorPeriodo + `?fechaInicial=${fechaInicial}&&fechaFinal=${fechaFinal}`, config);
}