import { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { registraPreciosOrden } from "../../../api/preciosOrden";
import queryString from "query-string";
import { map } from "lodash";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { LogsInformativos } from "../../logs/logs";

const Movimientos = ({ data, history, setShow }) => {
  const [formData, setFormData] = useState(initialFormValue);
  const [signInLoading, setSignInLoading] = useState(false);

  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos
  let fechaFormat = `${year}-${mes <10 ? ("0"+mes) : mes}-${dia <10 ? ("0"+dia) : dia}`;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.cantidad || !formData.tipo || !formData.concepto) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      setSignInLoading(true);
      try {
        const dataTemp = {
          ordenServicio: data,
          tipo: formData.tipo,
          concepto: formData.concepto,
          cantidad: formData.cantidad,
          correoRegistro: correoUsuario,
          fecha: fechaFormat,
          estado: "Activo",
        };
        registraPreciosOrden(dataTemp)
          .then((response) => {
            const { data } = response;
            console.log(data);

            toast.success("Ajuste registrado");
            LogsInformativos(
              "Se ha registrado el movimiento del cliente con correo " +
                dataTemp.correoRegistro,
              dataTemp
            );
            history({
              search: queryString.stringify(""),
            });

            setSignInLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
          .catch((ex) => {
            if (ex.message === "Network Error") {
              toast.error("Conexión al servidor no disponible");
              setSignInLoading(false);
            } else {
              if (ex.response && ex.response.status === 401) {
                const { mensaje } = ex.response.data;
                toast.error(mensaje);
                setSignInLoading(false);
              }
            }
          });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="containerInicio">
      <Form onChange={onChange} onSubmit={onSubmit}>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Tipo:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              as="select"
              type="text"
              className="nomNewUser"
              placeholder="Nombre (s)"
              name="tipo"
              defaultValue={formData.tipo}
            >
              <option value="">Elige una opcion</option>
              <option value="cargo">Cargo</option>
              <option value="descuento">Descuento</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="text"
                className="emailNewUser"
                placeholder="Cantidad"
                name="cantidad"
                defaultValue={formData.cantidad}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Concepto:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              className="emailNewUser"
              placeholder="Concepto"
              name="concepto"
              defaultValue={formData.concepto}
            />
          </Col>
        </Row>
        <div className="divSubmit">
          <Button onClick={onSubmit} variant="success">
            <FontAwesomeIcon icon={faCashRegister} /> Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

function initialFormValue() {
  return {
    cantidad: "",
    concepto: "",
    nombre: "",
    email: "",
    password1: "",
    password2: "",
    tipo: "",
    departamento: "",
  };
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      email: data.email,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default Movimientos;
