import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faEye,
  faPlus,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  listarMaterialCat,
  listarMaterialPorDep
} from "../../../api/materialesCat";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AltaMaterialesCat from "./AltaMaterialesCat";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { toast } from "react-toastify";
import EliminarMaterial from "./EliminarMaterial";
import NombreDepartamento from "./nombreDepartamento";

function TblMaterialesCat(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [nombreDepartamento, setnombreDepartamento] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setnombreDepartamento(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarPrecio, setListPrecio] = useState([]);

  const obtenerPrecios = () => {
    try {
      listarMaterialCat()
        .then((response) => {
          const { data } = response;

          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerPiezasDept = (departamento) => {
    try {
      listarMaterialPorDep(departamento)
        .then((response) => {
          const { data } = response;

          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario != "departamento") {
      obtenerPrecios();
    } else {
      if (nombreDepartamento) {
        obtenerPiezasDept(nombreDepartamento);
      }
    }
  }, [tipoUsuario, nombreDepartamento]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarPrecio);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  useEffect(() => {
    if (!showModal) {
      obtenerPrecios();
    }
  }, [showModal]);

  const registrarMaterialCategoria = (content) => {
    setTitulosModal("Materiales catalogo");
    setContentModal(content);
    setShowModal(true);
  };

  const registroPrecio = (content) => {
    setTitulosModal("Registro de protesis dentales");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarPrecio = (content) => {
    setTitulosModal("Modificar pieza");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarPrecio = (content) => {
    setTitulosModal("Eliminar material");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "nombreMaterial",
      label: "MATERIAL",
    },
    {
      name: "descripcion",
      label: "DESCRIPCION",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Badge
                style={{ textTransform: "capitalize" }}
                bg={value == "activo" ? "success" : "danger"}
              >
                {value}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "folioDepartamento",
      label: "DEPARTAMENTO",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <NombreDepartamento
                folio={value}
              />
            </>
          );
        },
      },
    },
    {
      name: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (

            <>
              {tipoUsuario != "departamento" ? (
                <Dropdown>
                  <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        eliminarPrecio(
                          <EliminarMaterial
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp; Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <span>No disponibles</span>
              )}

            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  return (
    <>
      {loading && <Load />}
      <h1>Materiales dentales</h1>
      <div>
        {tipoUsuario != "departamento" && (
          <div className="divButton">
            <Button
              className="btnAddTables"
              onClick={() =>
                registrarMaterialCategoria(
                  <AltaMaterialesCat history={history} setShow={setShowModal} />
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} /> Nuevo material
            </Button>
          </div>
        )}
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de piezas registradas"}
          data={listarPrecio}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelPrecio(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombreMaterial: data.nombreMaterial,
      descripcion: data.descripcion,
      folioDepartamento: data.folioDepartamento,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblMaterialesCat);
