import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import {
  registraDepartamentos,
  obtenerNumeroDepartamentos,
} from "../../api/departamentos";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";

export default function Departamentos({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroDepartamentos()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noDepartamento } = data;
          setFolioActual(noDepartamento);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const [tipo, setTipo] = useState("");
  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.nombre) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);

        const dataTemp = {
          folio: folioActual,
          nombre: formData.nombre,
          tipo: tipo,
          estado: "true",
        };
        registraDepartamentos(dataTemp).then((response) => {
          const { data } = response;

          toast.success(data.mensaje);
          LogsInformativos(
            "Se ha registrado el departamento " + dataTemp.nombre,
            dataTemp
          );
          history({
            search: queryString.stringify(""),
          });

          //setFormData(initialFormValue())
          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalDoctores">
        <Form className="formDoctores" onSubmit={onSubmit} onChange={onChange}>
          <Form.Group>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblpApellido">Folio: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  className="inpPrimerADoc"
                  placeholder="Ingresa primer apellido"
                  value={folioActual}
                  name="folio"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label className="lblsApellido">Nombre: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  type="text"
                  className="inpSegundoA"
                  placeholder="Ingresa el nombre del departamento"
                  defaultValue={formData.nombre}
                  name="nombre"
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>Tipo: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={8} lg={8}>
                <Form.Control
                  as="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="">Seleccionar</option>
                  <option value="Produccion">Producción</option>
                  <option value="Administrativo">Administrativo</option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <br />
          <label></label>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    nombre: "",
  };
}
