import React, { useState } from "react";
import MapQuest from "./MapQuest";
import Ubicacion from "./Ubicacion";
import { useEffect } from "react";

export const MapaMarcaUbicacionUno = ({latU, lngU}) => {
    const [lat, setLat] = useState(latU);
    const [lng, setLng] = useState(lngU);

  const [markers, setMarkers] = useState([]);

    const setCenter = (lat, lng) => {
      setLat(lat);
      setLng(lng);

      window.L.mapquest.Map.getMap('map').setView( new window.L.LatLng(lat,lng), 12)
    }

    const addMaker = (lat, lng, title) => {
        // Limpiar marcadores existentes
        markers.forEach(marker => marker.remove());
      const marker = window.L.mapquest.textMarker(
        new  window.L.LatLng(lat,lng),
        {
          text: title || '',
          subtext: '',
          position: 'right',
          type: 'marker',
          icon: {
            primaryColor: "#a8190f",
            secondaryColor: "db2c2c",
            size: 'md'
          }
        }
      )
      .addTo(window.L.mapquest.Map.getMap('map'));

      setMarkers([marker]);
    }

    useEffect(() => {
        addMaker(lat, lng);
        setCenter(lat, lng);
      }, [lat, lng]);
    

  return (
    <div className="containerFluid">
      <MapQuest
        height="50vh"
        width="auto"
        center={[lat, lng]}
        tileLayer={"map"}
        zoom={12}
        apiKey="2eei5oDr1QNMmUGDCY8115cQclIbiAq3"
      />
    </div>
  );
};
